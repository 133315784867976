import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { renderSmileRadioButtons } from '../../fields/V0/Fields';
import { COUNTRIES } from '../../../../utils/enums/countries';
import thirdType from '../../../countriesData/mexico/thirdType';

const mexicoThirdType = () => {
  return (
    <div className='d-flex w-100 justify-content-center col-md-12 mt-3 mb-3'>
      <Field
        name='thirdType'
        component={renderSmileRadioButtons}
        className='w-100 mb-0'
        label={capitalize(
          I18n.get(
            'thirdType',
            'Indica si el contacto que crearás es nacional o extranjero:'
          )
        )}
        options={thirdType}
        defaultValue={thirdType[0].value}
        required
      />
    </div>
  );
};

const renderThirdType = (props) => {
  switch (props.country) {
    case COUNTRIES.MEXICO:
      return mexicoThirdType(props);
    default:
      return null;
  }
};

export default renderThirdType;
