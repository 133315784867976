import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import YouTube from 'react-youtube';
import { I18n } from '@aws-amplify/core';

import Modal from '../../common/Modal';
import {
  companySelector,
  country as countrySelector,
} from '../../../selectors/company';
import { closeModal } from '../../../reducers/modals';
import { Typography, Button } from '@alegradev/smile-ui-react';
import { sendNewGTMEvent } from '../../../reducers/company';
import { useMemo } from 'react';
import { isAfter } from 'date-fns';

export const contactLink = (country) => {
  return `${process.env.REACT_APP_SOPORTE_ALEGRA}${country}?product=alegra-pos`;
};

function ContactSupportModal() {
  const dispatch = useDispatch();
  const { registryDate } = useSelector(companySelector);
  const country = useSelector(countrySelector);
  const isOpen = useSelector((state) =>
    get(state, 'modals.contactSupport.isOpen', false)
  );

  const sendAssistanceEvent = (action) => {
    dispatch(
      sendNewGTMEvent('eco-nms-assistance-popup-managed', {
        action,
      })
    );
  };

  const handleClick = () => {
    window.open(contactLink(country), '_blank');
    dispatch(closeModal({ modal: 'contactSupport' }));
    sendAssistanceEvent('explore-now');
  };

  const modalTexts = useMemo(() => {
    if (isAfter(new Date(registryDate), new Date('2024-05-01'))) {
      return {
        title: I18n.get(
          'contactSupportTitleNewUsers',
          'Explora tus canales de soporte 🧑‍💻'
        ),
        description: I18n.get(
          'contactSupportDescriptionNewUsers',
          '¡Descubre dónde encontrar ayuda cuando la necesites! Explora nuestros canales de soporte disponibles 24/7.'
        ),
        later: I18n.get('later', 'Más tarde'),
        exploreNow: I18n.get(
          'exploreNowNewUsers',
          'Ver nuevo centro de soporte'
        ),
      };
    }

    return {
      title: I18n.get(
        'contactSupportTitle',
        '¡Mejoramos tu experiencia de soporte! 🔥'
      ),
      description: I18n.get(
        'contactSupportDescription',
        'Ahora encontrarás todas tus opciones de ayuda reunidas en un solo lugar y disponibles 24/7. ¡Explora tu nuevo centro de soporte!'
      ),
      later: I18n.get('later', 'Más tarde'),
      exploreNow: I18n.get('exploreNow', 'Explorar ahora'),
    };
  }, [registryDate]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeModal({ modal: 'contactSupport' }))}
      includeHeader={false}
      className='modal__medium-size modal__mobile-bottom'
    >
      <div className='p-4 electronic-payroll-tutorial'>
        <YouTube
          className='electronic-payroll-tutorial-video'
          videoId={I18n.get('', 'rDI_lNsfo6Q')}
        />

        <div className='d-flex flex-column gap-2'>
          <Typography type='heading-4' text={modalTexts.title} />

          <Typography type='body-3-regular' text={modalTexts.description} />
        </div>

        <div className='electronic-payroll-tutorial-actions'>
          <Button
            emphasis='outline'
            size='medium'
            onClick={() => [
              dispatch(closeModal({ modal: 'contactSupport' })),
              sendAssistanceEvent('skipped'),
            ]}
            text={modalTexts.later}
          />
          <Button
            emphasis='filled'
            size='medium'
            text={modalTexts.exploreNow}
            onClick={() => handleClick()}
          />
        </div>
      </div>
    </Modal>
  );
}

export default ContactSupportModal;
