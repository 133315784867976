import { useDispatch, useSelector } from 'react-redux';
import { ContactProvider } from '../contact/ContactProvider';
import { ContactForm } from '../contact/form';

import { closeSideModal } from '../../../reducers/sideModals';

import { get } from 'lodash';
import { SlideOver } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { country as countrySelector } from '../../../selectors/company';

function ContactSideModal() {
  const dispatch = useDispatch();
  const country = useSelector(countrySelector);
  const isOpen = useSelector((state) =>
    get(state, 'sideModals.contact.isOpen', false)
  );
  const sidebarContact = useSelector((state) =>
    get(state, 'sideModals.contact.params.contact', null)
  );

  const handleSeeMore = () => {
    const LINK =
      country === 'colombia'
        ? 'https://ayuda.alegra.com/es/gestiona-tus-contactos-en-el-sistema-punto-de-venta-pos-colombia'
        : 'https://ayuda.alegra.com/es/gestiona-tus-contactos-en-alegra-pos';

    window.open(LINK, '_blank');
  };

  return (
    <SlideOver
      isOpen={isOpen}
      title={
        sidebarContact && sidebarContact.status === 'active'
          ? I18n.get('editClient', 'Editar contacto')
          : I18n.get('newContact', 'Nuevo contacto')
      }
      description={
        sidebarContact && sidebarContact.status === 'active'
          ? ''
          : I18n.get(
              'newClientSubtitle',
              'Crea los contactos que asociarás en tus facturas de venta.'
            )
      }
      descriptionExtra={{
        label: I18n.get('seeMore', 'Ver más'),
        onClick: handleSeeMore,
      }}
      onClose={() => dispatch(closeSideModal({ sideModal: 'contact' }))}
    >
      <ContactProvider from='sideModal'>
        <ContactForm saveOnOffline={true} from='sideModal' />
      </ContactProvider>
    </SlideOver>
  );
}

export default ContactSideModal;
