import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { renderField } from '../../fields/V0/Fields';
import { COUNTRIES } from '../../../../utils/enums/countries';
import { get } from 'lodash';

const mexicoFiscalId = (props) => {
  const thirdType = get(props, 'values.thirdType', '');

  if (thirdType === 'NATIONAL') return null;

  return (
    <Field
      name='fiscalId'
      component={renderField}
      type='text'
      label={capitalize(I18n.get('fiscalId', 'Id fiscal'))}
      className='col-md-12'
      required
    />
  );
};

const renderFiscalId = (props) => {
  switch (props.country) {
    case COUNTRIES.MEXICO:
      return mexicoFiscalId(props);
    default:
      return null;
  }
};

export default renderFiscalId;
