import React from 'react'
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { companySelector, country as countrySelector } from '../../../selectors/company'
import { calculateItemsValues } from '../../../utils';

import { useFormat } from '../../../hooks/useFormat';
import BigNumber from 'bignumber.js';

const Payments = ({ tip, items, setting: { totalLines } }) => {
  const { decimal, fmt } = useFormat();
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);

  if (!items) return null;
  
  const { subtotal, total, taxes, discount, discSubtotal, totalWhitoutTips } = calculateItemsValues({items, decimal, country, company});
  const showDiscount = discount.gt(0);
  const tipAmount = new BigNumber(tip);

  return (
    <div className={`w-100 py-2 d-flex flex-column border-bottom`}>

      <div className={`pb-2`}>
        {(!!showDiscount) && (
          <p className={`container-text-flex}`}>
            <p className='font-weight-bold'>{I18n.get('subtotal', 'subtotal')}:</p>
            <p className='pl-2'>
              {subtotal.toFormat(decimal, fmt)}
            </p>
          </p>
        )}

        {
          !!showDiscount &&
          (
            <>
              <p className={`container-text-flex`}>
                <p className={country === 'republicaDominicana' ? 'font-weight-bold' : "strong"}>{I18n.get('discount', 'descuento')}:</p>
                <p className='pl-2'>
                  {`-${discount.toFormat(decimal, fmt)}`}
                </p>
              </p>
              <p className={`container-text-flex`}>
                <p className={country === 'republicaDominicana' ? 'font-weight-bold' : "strong"}>{I18n.get('subtotal', 'subtotal')}:</p>
                <p className='pl-2'>
                  {discSubtotal.toFormat(decimal, fmt)}
                </p>
              </p>
            </>
          )
        }

        <div className="d-flex align-items-end flex-column">            
          <p>
            <strong className="text-capitalize-first">{I18n.get('subtotal', 'subtotal')}</strong>
            {subtotal.toFormat(decimal, fmt)}
          </p>
          {discount.gt(0) && (
            <>
              <p>
                <strong className="text-capitalize-first">{I18n.get('discount', 'descuento')}</strong>
                {discount.toFormat(decimal, fmt)}
              </p>
              <p>
                <strong className="text-capitalize-first">{I18n.get('subtotal', 'subtotal')}</strong>
                {discSubtotal.toFormat(decimal, fmt)}
              </p>
            </>
          )}
          {taxes.map((tax, index) => (
            <p key={index}>
              <strong>{tax.name}</strong>
              {tax.value.toFormat(decimal, fmt)}
            </p>
          ))}
          {tipAmount.isGreaterThan(0) && (
            <>
              <p>
                <strong className="text-capitalize-first">{I18n.get('total', 'total')}</strong>
                {totalWhitoutTips.toFormat(decimal, fmt)}
              </p>
              <p>
                <strong className="text-capitalize-first">{I18n.get('tip', 'Propina')}</strong>
                {tipAmount.toFormat(decimal, fmt)}
              </p>
            </>
          )}
          
          <p>
            <strong className="text-capitalize-first font-weight-bold">
              {tipAmount.isGreaterThan(0) ? I18n.get('totalToRefund', 'total a devolver') : I18n.get('total', 'total')}
            </strong>
            {total.plus(tipAmount).toFormat(decimal, fmt)}
          </p>
            
        </div>

      </div>

      
      {
        totalLines && (
          <div className="text-left">
            <p>
              <strong>{I18n.get('totalRows', 'total de líneas')}</strong>
              {items.length}
            </p>
            <p>
              <strong>{I18n.get('totalItems', 'total de productos')}</strong>
              {items.map(item => +get(item, 'quantity', 0)).reduce((prev, curr) => prev + curr, 0)}
            </p>
          </div>
        )
      }
    </div>
  )
};

export default Payments;