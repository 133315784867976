import React, { useEffect, useCallback, useState } from 'react'
import { I18n } from '@aws-amplify/core'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import notificationsAPI from '../../reducers/notificationsAPI'
import { userSelector } from '../../selectors/auth'
import { country as countrySelector, notificationsSelector } from '../../selectors/company';
import { APIGraphqlSelector } from '../../selectors/app'
import { graphqlOperation } from 'aws-amplify'
import { updateUser } from '../../graphql/mutations'
import { notificationsTransformer } from './utils'
import { setUser } from '../../reducers/auth'
import { isElectronicPOSDocumentAvailableSelector } from '../../selectors/numerations'
import { get } from 'lodash'

const ElectronicPOSDocumentSecondTopNotification = () => {
  const dispatch = useDispatch();
  const APIGraphql = useSelector(APIGraphqlSelector);
  const user = useSelector(userSelector);
  const country = useSelector(countrySelector);
  const notifications = useSelector(notificationsSelector);
  const isElectronicPOSDocumentAvailable = useSelector(isElectronicPOSDocumentAvailableSelector);
  const planName = get(user, 'membership.planKeyword', null)

  const [notificationSent, setNotificationSent] = useState(false);

  const selectedBody = useCallback(() => {
    return {
      userId: user.idGlobal.toString(),
      title: "1",
      text: I18n.get('', '<b>¡Actualización normativa!</b> 📢 A partir del 1 de julio la DIAN solo aceptará como soporte de ventas las facturas y documentos POS electrónicos.'),
      actionCall: 'https://pos.alegra.com/electronic-documents',
      actionLabel: I18n.get('', 'Cómo estar actualizado'),
      type: "info_dark",
      to: dayjs().add(30, 'days').toISOString(),
      country,
    }
  }, [user, country, planName]);

  useEffect(() => {
    async function checkNotification() {
      if (notificationSent) return;

      const notificationName = `electronic-pos-document-second-top-notification`

      if (!planName || !country || planName === 'demo' || planName === 'readonly' || !["colombia"].includes(country)) return;
      if (!["demo", "readonly"].includes(planName) && isElectronicPOSDocumentAvailable) return;
      if (notifications.find(n => n[`${notificationName}`])) return;

      try {
        const response = await APIGraphql(graphqlOperation(updateUser, notificationsTransformer(notificationName)));

        if (!!get(response, 'data.updateUser.id', null)) {
          dispatch(setUser(get(response, 'data.updateUser')));
        }

        if (!notificationSent) {
          await notificationsAPI.post('/notifications',
            notificationBody({
              ...selectedBody(),
            }));
          setNotificationSent(true);
        }

      } catch (err) {
        console.log("error while creating notification", err);
      }
    }

    checkNotification();
  }, [country, planName, selectedBody, notifications, dispatch, APIGraphql, notificationSent])

  return null;
}

const notificationBody = ({ userId, from = null, to = null, section = null, title, text, actionCall, actionLabel, type, actionType }) => ({
  name: "electronic-pos-document-second-top-notification",
  teamInCharge: "pos",
  receptors: {
    users: [userId]
  },
  availability: {
    from: from ? from : dayjs().toISOString(),
    to: to ? to : dayjs().add(30, 'days').toISOString()
  },
  message: [
    {
      channel: "web",
      type: "top",
      closeable: true,
      section: section ? section : "index-pos-home",
      title,
      text,
      application: "alegra-pos",
      action: {
        actionType,
        actionCall,
        actionLabel
      },
      style: {
        type,
      }
    }
  ]
})

export default ElectronicPOSDocumentSecondTopNotification;
