import React, { useMemo, useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, isArray, isNumber, get } from 'lodash';
import { useSelector } from 'react-redux';
import { BigNumber } from 'bignumber.js';

import { multitax, decimalPrecision, country as countrySelector } from '../../../selectors/company';
import { activeTaxes, getDefaultTax } from '../../../selectors/taxes';
import { renderField, renderSelect } from '../fields/V0/Fields';
import { languageSelector } from '../../../selectors/auth';
import getNoTax from '../../countriesData/general/noTax';
import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';
import { COUNTRIES } from '../../../utils/enums/countries';
import { getTaxesLabel } from './utils';
import { validateSelectTaxes } from '../../../utils';

const Price = (props) => {
  const [lastModified, setLastModified] = useState('salePrice');
  const [noTaxActive, setNoTaxActive] = useState(false);
  const [filteredTaxes, setFilteredTaxes] = useState([]);
  const taxes = useSelector(activeTaxes);
  const isMulti = useSelector(multitax);
  const decimal = useSelector(decimalPrecision);
  const country = useSelector(countrySelector);
  const language = useSelector(languageSelector);
  const defaultTax = useSelector(getDefaultTax);
  const { isDecimalActive } = useDecimalsVersionsGroup();

  const noTax = useMemo(() => getNoTax(language.substring(0, 2)), [language]);

  const allTaxes = useMemo(() => [noTax, ...taxes], [noTax, taxes]);

  function maxDecimals(e, key, decimals = 6) {
    if (!isDecimalActive) return e.target.value;

    const newButton = e.nativeEvent.data;
    const values = props.values;
    values[key] = !values[key] ? '' : values[key];
    e.target.value = !e.target.value ? '' : e.target.value;

    if (newButton === '.') {
      if (!e.target.value.slice(0, -1).includes('.')) {
        return e.target.value;
      } else {
        e.target.value = values[key];
        return values[key];
      }
    }

    if (!isNaN(newButton)) {
      if (!e.target.value.includes('.') || (e.target.value.includes('.') && e.target.value.split('.')[1].length <= decimals)) {
        return e.target.value;
      } else {
        e.target.value = values[key];
        return values[key];
      }
    }

    if (isNaN(newButton)) {
      e.target.value = values[key];
      return values[key];
    }
  }

  const calculateTotalPrice = (taxPercent) => {
    const salePrice = get(props, 'values.salePrice', '0');
    if (!!salePrice) {
      const price = new BigNumber(100).plus(new BigNumber(taxPercent)).dividedBy(100).multipliedBy(salePrice)
        .decimalPlaces(decimal).toNumber();
      if (isNumber(price)) props.form.change('price', '' + price);
    }
  };

  const calculateSalePrice = (taxPercent) => {
    const price = get(props, 'values.price', '0');
    if (!!price) {
      const salePrice = new BigNumber(price).multipliedBy(100).dividedBy(new BigNumber(taxPercent).plus(100))
        .decimalPlaces(6).toNumber();
      if (isNumber(salePrice)) props.form.change('salePrice', '' + salePrice);
    }
  };

  function setUpDecimals(key, decimals = 6) {
    if (!isDecimalActive) return;
    const values = props.values;
    if (values[key] && values[key].includes('.')) {
      if (values[key].slice(-1) === '.') {
        values[key] = values[key].slice(0, -1);
        return;
      }

      if (values[key].split('.')[1].length > decimals)
        values[key] = Number(values[key]).toFixed(decimals);
    }
  }


  const calculateSalePriceWithIEPS = (taxes) => {
    const price = new BigNumber(get(props, 'values.price', '0'));
    const iepsRates = taxes
      .filter(t => t.type === "IEPS")
      .map(t => new BigNumber(t.percentage).dividedBy(100));
    const ivaRates = taxes
      .filter(t => t.type === "IVA")
      .map(t => new BigNumber(t.percentage).dividedBy(100));
    const otherTaxesRates = taxes
      .filter(t => t.type !== "IEPS" && t.type !== "IVA")
      .map(t => new BigNumber(t.percentage).dividedBy(100));

    // Calcular el total de las tasas de IEPS
    const totalIepsRate = iepsRates.reduce((acc, rate) => acc.plus(rate), new BigNumber(0));

    // Calcular el total de las tasas de IVA considerando el IEPS
    const totalIvaRate = ivaRates.reduce((acc, rate) => acc.plus(rate.plus(rate.multipliedBy(totalIepsRate))), new BigNumber(0));

    // Calcular el total de las tasas de otros impuestos
    const totalOtherTaxesRate = otherTaxesRates.reduce((acc, rate) => acc.plus(rate), new BigNumber(0));

    // Calcular el denominador para el precio base
    const denominator = new BigNumber(1).plus(totalIepsRate).plus(totalIvaRate).plus(totalOtherTaxesRate);

    // Calcular el precio base
    const salePrice = price.dividedBy(denominator).decimalPlaces(6).toNumber()
    if (isNumber(salePrice))
      props.form.change('salePrice', '' + salePrice);
  }

  const calculateTotalPriceWithIEPS = (taxes) => {
    const salePrice = new BigNumber(get(props, 'values.salePrice', '0'));

    const iepsValue = taxes.reduce((acc, t) => {
      if (t.type === "IEPS") {
        return acc.plus(new BigNumber(t.percentage).dividedBy(100).multipliedBy(salePrice))
      }
      return acc
    }, new BigNumber(0))

    const totalTaxValue = taxes.reduce((acc, t) => {
      if (t.type === "IVA") {
        return acc.plus(new BigNumber(t.percentage).dividedBy(100).multipliedBy(iepsValue.plus(salePrice)))
      }
      return acc.plus(new BigNumber(t.percentage).dividedBy(100).multipliedBy(salePrice))
    }, new BigNumber(0))

    const price = new BigNumber(salePrice).plus(totalTaxValue).decimalPlaces(decimal).toNumber();

    if (isNumber(price)) props.form.change('price', '' + price);
  }

  const calculatePriceValues = (lastTouched = null) => {
    let taxes = get(props, 'values.tax', []);
    taxes = isArray(taxes) ? taxes : [taxes];

    const haveIEPStax = taxes.some(t => t.type === "IEPS");
    const haveIVAtax = taxes.some(t => t.type === "IVA");

    if (country === COUNTRIES.MEXICO && haveIEPStax && haveIVAtax) {
      if (!!lastTouched) {
        if (lastTouched === 'price') calculateSalePriceWithIEPS(taxes);
        else calculateTotalPriceWithIEPS(taxes);
        setLastModified(lastTouched);
      } else {
        if (lastModified === 'price') calculateSalePriceWithIEPS(taxes);
        else calculateTotalPriceWithIEPS(taxes);
      }
    } else {
      const totalPercent = !!taxes[0]
        ? taxes.reduce((prevTax, tax) => prevTax + +get(tax, 'percentage'), 0)
        : 0;
      if (!!lastTouched) {
        if (lastTouched === 'price') calculateSalePrice(totalPercent);
        else calculateTotalPrice(totalPercent);
        setLastModified(lastTouched);
      } else {
        if (lastModified === 'price') calculateSalePrice(totalPercent);
        else calculateTotalPrice(totalPercent);
      }
    }
  };

  useEffect(() => {
    if (defaultTax) {
      props.form.change('tax', defaultTax);
    } else {
      props.form.change('tax', noTax);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTax, noTax]);

  useEffect(() => {
    if (isMulti && country !== 'peru') {
      const taxes = get(props, 'values.tax', []);
      if (isArray(taxes)) {
        taxes[taxes.length - 1]?.id === noTax.id ? setNoTaxActive(true) : setNoTaxActive(false);
      } else {
        props.form.change('tax', [defaultTax || noTax]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noTax, props.values.tax]);

  useEffect(() => {
    if (noTaxActive) {
      props.form.change('tax', [noTax]);
    } else if (!noTaxActive && isArray(props.values.tax)) {
      props.form.change('tax', props.values.tax.filter((tax) => tax.id !== noTax.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noTaxActive]);

  useEffect(() => {
    const filterTaxes = (allTaxes, selectedTaxes) => {
      selectedTaxes = isArray(selectedTaxes) ? selectedTaxes : [selectedTaxes];
      const selectedTaxTypes = new Set(selectedTaxes.map((tax) => tax.type));

      return allTaxes.filter((tax) => {
        return selectedTaxes.some((selectedTax) => tax.id === selectedTax.id) || !selectedTaxTypes.has(tax.type);
      });
    };

    if (country === 'republicaDominicana') {
      setFilteredTaxes(filterTaxes(allTaxes, get(props, 'values.tax', [])));
    } else {
      setFilteredTaxes(allTaxes);
    }
  }, [props.values.tax, country, allTaxes]);

  return (
    <>
      <div className="col-sm-4 col-6 d-flex">
        <Field
          name="salePrice"
          component={renderField}
          className="col-11 p-0 pr-2"
          type="number"
          required
          label={capitalize(I18n.get('priceBeforeTaxes', 'precio sin impuestos'))}
          onChange={(e) => maxDecimals(e, 'salePrice', 6)}
          onBlur={() => { setUpDecimals('salePrice', 6); calculatePriceValues('salePrice'); }}
        />

        <div
          className="text-primary col-1 p-0 text-center"
          style={{
            lineHeight: '25px',
            fontSize: '20px',
            marginTop: '3rem',
          }}
        >
          +
        </div>
      </div>

      <div className="col-sm-4 col-6 d-flex">
        <Field
          name="tax"
          component={renderSelect}
          showFavoriteIcon={false}
          className="col-11 p-0 pr-2"
          options={filteredTaxes}
          getOptionLabel={(option) => getTaxesLabel(country, option)}
          getOptionValue={(option) => option.id}
          label={capitalize(I18n.get('taxes', 'impuestos'))}
          placeholder={I18n.get('none', 'ninguno')}
          isMulti={country === 'peru' ? false : isMulti}
          onInputChange={() => calculatePriceValues()}
          onChange={(values) => validateSelectTaxes({ values, country, isMultitax: isMulti })}
          helpTooltip={
            country !== 'peru' && isMulti
              ? I18n.get('taxesHelp', 'Si tu producto tiene varios impuestos, puedes seleccionarlos dando clic sobre el nombre de cada uno en el listado desplegable.')
              : null
          }
          menuPosition="absolute"
        />

        <div
          className="text-primary col-1 p-0 text-center"
          style={{
            lineHeight: '25px',
            fontSize: '20px',
            marginTop: '3rem',
          }}
        >
          =
        </div>
      </div>

      <Field
        name="price"
        component={renderField}
        type="number"
        className="col-sm-4 col-12"
        required
        label={capitalize(I18n.get('finalPrice', 'precio final'))}
        onChange={(e) => maxDecimals(e, 'price', decimal)}
        onBlur={() => { setUpDecimals('price', decimal); calculatePriceValues('price'); }}
      />
    </>
  );
};

export default Price;
