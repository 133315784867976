import React from 'react'
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux'; 
import { I18n } from '@aws-amplify/core';
import { Form } from "react-final-form"
import { Icon, Typography } from '@alegradev/smile-ui-react';
import Switch from '../../../common/Switch';
import Button from '../../../common/Button';
import Body from '../../../forms/editTip/Body';
import { tipSelector } from '../../../../selectors/activeRefund';
import { get } from 'lodash';
import { setTip } from '../../../../reducers/activeRefund';

const Tip = ({ isOpen, onClose }) => {
  const tips = useSelector(tipSelector)
  const dispatch = useDispatch()

  const onSubmit = async (values) => {
    const type = get(values, "type", "PERCENTAGE");
    let include = get(values, "include", false);

    if (include) {
      if (type === 'PERCENTAGE' && Number(get(values, "percentage", 0)) === 0) {
        include = false;
      }

      if (type === 'VALUE' && Number(get(values, "value", 0)) === 0) {
        include = false;
      }
    }

    dispatch(setTip({
      include,
      type,
      percentage: get(values, "type", "PERCENTAGE") === 'PERCENTAGE' ? get(values, "percentage", 10) : 0,
      value: get(values, "type", "PERCENTAGE") === 'VALUE' ? get(values, "value", 0) : 0
    }))
    onClose()
  }
  
  return (
    <div className={`${isOpen ? 'open': ''} refund-edit-item border d-none flex-column`}
      style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={{
          include: get(tips, 'include', false),
          type: get(tips, 'type', 'VALUE'),
          percentage: get(tips, 'percentage', 0),
          value: get(tips, 'value', 0),
        }
        }
      >
        {({handleSubmit, values, form, errors}) => (
          <form 
            noValidate
            onSubmit={handleSubmit}
            className="w-100 h-100 p-4 bg-white d-flex flex-column side-modal"
          >
            <h3 className='title-new-refund d-flex justify-content-between align-items-center'>
              {I18n.get('newRefund', 'nueva devolución')} 
              <button
                className={`btn button-transparent visible`}
                type="button"
                onClick={() => onClose()}>
                <Icon icon='x' extraClass="icon-black" />
              </button>
            </h3>
              
            <div className='pt-4'>
              <div className='d-flex align-items-center pl-2 mb-1'>
                <h2 className='m-0 mr-2'>{I18n.get('editTip', 'Editar propina')}</h2>
              </div>
          

              <div className='d-flex align-items-center mb-4 pl-2'>
                <Switch
                  active={values.include}
                  onChange={() => form.change('include', !get(values, 'include', false))}
                  large
                />

                <Typography text={I18n.get('includeTip', 'Incluir propina')} type='body-3-regular' extraClassName='ml-2' />
              </div>

              <div className='side-modal__divider'></div>
            </div>

            <div className='side-modal__body'>
              <Body values={values} form={form} errors={errors} />
            </div>

            <div className='d-flex justify-content-center mt-3 mt-md-5'>
              <button className='refunds-cancel-button' onClick={onClose}>
                {I18n.get('cancel','Cancelar')}
              </button>
              <Button className='ml-4' style={{ width: "116px" }} type="submit" >
                {I18n.get('save', 'guardar')}
              </Button>
            </div>
          </form>
        )}
      </Form>

    </div>
  )
}

Tip.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  selectedIndex: PropTypes.number,
}

export default Tip;