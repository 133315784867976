import { useMemo, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get, isEmpty } from "lodash";
import { graphqlOperation } from 'aws-amplify'
import dayjs from 'dayjs';

import * as mutations from '../../graphql/mutations';
import { openModal } from "../../reducers/modals";
import membershipsAPI from "../../reducers/membershipsAPI";
import { APIGraphqlSelector } from "../../selectors/app";
import { companySelector, membershipSelector, notificationsSelector } from "../../selectors/company";
import { seenWelcome as seenWelcomeSelector } from "../../selectors/onboarding";
import { notificationsTransformer } from "../../components/notifications/utils";

const useHappyWeekPopupValidator = () => {
  const company = useSelector(companySelector);
  const membership = useSelector(membershipSelector);
  const seenWelcome = useSelector(seenWelcomeSelector);
  const notifications = useSelector(notificationsSelector);
  const [userIsElegible, setUserElegibility] = useState(false);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const dispatch = useDispatch();

  function getLatestDate(dateArray) {
    return dateArray.reduce((latestDate, dateObj) => {
      const dateKey = Object.keys(dateObj)[0];
      const currentDate = dayjs(dateObj[dateKey]);

      return !latestDate || currentDate.isAfter(latestDate) ? currentDate : latestDate;
    }, null);
  }

  function notificationIsReady(datetime) {
    return dayjs().isAfter(dayjs(datetime).add(2, 'day'))
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await membershipsAPI.get(`/subscribers/${company.id}/happy-week/is-eligible?product=alegra-pos`);
        setUserElegibility(data.results);
      } catch (error) {
        console.log('error when trying to get userHappyWeekEligibility: ', error);
      }
    }
    fetchData();
  }, [])

  const disponibility = useMemo(() => {
    const companyIdIsOdd = company.id % 2 !== 0

    const happyWeekNotifications = notifications?.filter(n => n['happy-week-experiment']);
    const latestNotificationDatetime = isEmpty(happyWeekNotifications) ? true : getLatestDate(happyWeekNotifications).format();
    const showNotification = latestNotificationDatetime === true ? true : notificationIsReady(latestNotificationDatetime);

    return (companyIdIsOdd && seenWelcome) && (
      userIsElegible
    ) && showNotification
  }, [userIsElegible, notifications, membership])

  const showHappyWeekPopUp = useCallback(async () => {
    const defaultShowPopupTime = (3 * 60) * 1000;
    const isDemoUser = get(membership, 'plan.keyword') === 'demo'
    setTimeout(async () => {
      try {
        await APIGraphql(graphqlOperation(mutations.updateUser, notificationsTransformer('happy-week-experiment')));
      } catch (err) {
        console.log('err: ', err);
      }
      dispatch(openModal({ modal: 'happyWeekPopup' }));
    }, isDemoUser ? defaultShowPopupTime : (2 * 60) * 1000)
  }, [membership])

  useEffect(() => {
    if (disponibility)
      showHappyWeekPopUp();
  }, [disponibility])

  return {
    userIsElegibleForExperiment: disponibility,
    showHappyWeekPopUp
  };
};

export default useHappyWeekPopupValidator;