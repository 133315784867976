import { I18n } from '@aws-amplify/core';
import { Field } from "react-final-form";
import { get } from 'lodash'
import { renderSelect, renderField } from "../../forms/fields/V0/Fields"

import { currency } from '../../../selectors/activeInvoice'
import { getMainCurrency } from '../../../selectors/currencies'
import { useSelector } from 'react-redux';
import { Typography, Button } from '@alegradev/smile-ui-react';

const Body = ({ values, form, errors, refund = false }) => {
  const { include } = values
  const selectedCurrency = useSelector(currency)
  const mainCurrency = useSelector(getMainCurrency)

  const prefix = !!get(selectedCurrency, 'symbol') ? get(selectedCurrency, 'symbol') 
    : !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : ''
  
  return (
    <div className={`side-modal__tip-body pl-5 pr-4 ${!include ? 'inactive' : ''}`}>
      <div className="btn-group col-12 mb-3" role="group">
        <Button
          type="button"
          disabled={!include}
          text={I18n.get('percentage', 'Porcentaje')}
          className={`${values.type === 'PERCENTAGE' ? 'active' : ''}`}
          onClick={() => {
            if (values.type !== 'PERCENTAGE') {
              form.change('type', 'PERCENTAGE')
              form.change('value', 0)
            }
          }}
        />
        <Button
          type="button"
          disabled={!include}
          text={I18n.get('value', 'Valor')}
          className={`${values.type === 'VALUE' ? 'active' : ''}`}
          onClick={() => {
            if (values.type !== 'VALUE') {
              form.change('type', 'VALUE')
              form.change('value', 0)
            }
          }}
        />
      </div>
      
      {
        values.type === 'PERCENTAGE' && (
          <div className='side-modal__tip-body-input-container'>
            <div className='input-group-icon'>
              <Field
                type="text"
                name="percentage"
                disabled={!include}
                component={renderField}
                defaultValue={!!values.percentage ? values.percentage : 10}
                className={`p-0 mt-4`}
                label={I18n.get('percentage', 'Porcentaje')}
              />
              <span>%</span>
            </div>
            <p>{I18n.get('TipSuggested', 'Propina sugerida')}</p>
          </div>
        )
      }

      {
        values.type === 'VALUE' && (
          <div className='input-group-icon'>
            <Field
              type="text"
              name="value"
              disabled={!include}
              component={renderField}
              defaultValue={!!values.value ? values.value : 0}
              className={`p-0 mt-4`}
              label={I18n.get('value', 'Valor')}
            />
            <span className='value-icon'>{prefix}</span>
          </div>
        )
      }

      
    </div>
  )
}

export default Body;