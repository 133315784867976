import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { get } from 'lodash';
import { renderField } from '../../../../../components/forms/fields/V2';
import { COUNTRIES } from '../../../../../utils/enums/countries';
import { Grid } from '@alegradev/smile-ui-react';

const mexicoFiscalId = (props) => {
  const { Col } = Grid;
  const thirdType = get(props, 'values.thirdType', '');

  if (thirdType === 'NATIONAL') return null;

  return (
    <Col xs={12}>
      <Field
        name='fiscalId'
        component={renderField}
        type='text'
        label={capitalize(I18n.get('fiscalId', 'Id fiscal'))}
        required
      />
    </Col>
  );
};

const renderFiscalId = (props) => {
  switch (props.country) {
    case COUNTRIES.MEXICO:
      return mexicoFiscalId(props);
    default:
      return null;
  }
};

export default renderFiscalId;
