import { I18n } from '@aws-amplify/core';
import { get, repeat, capitalize } from 'lodash'
import { defaultClient, clientDataAreEquals } from '../reducers/activeInvoice';

export const isDefaultClient = (client, country) => {
  const defaultC = defaultClient(country)
  try {
    switch (country) {
      case 'costaRica':
        return clientDataAreEquals(defaultC.name, client.name)
          && clientDataAreEquals(defaultC.identificationObject.type, client.identificationObject.type)
          && clientDataAreEquals(defaultC.identificationObject.number, client.identificationObject.number)
      case 'peru':
        return (clientDataAreEquals(defaultC.name, client.name)
          && clientDataAreEquals(defaultC.identification.type, client.identification.type)
          && clientDataAreEquals(defaultC.identification.number, client.identification.number))
          || ((clientDataAreEquals("Cliente general", client.name)
            && clientDataAreEquals("DNI", client.identification.type)
            && clientDataAreEquals("00000000", client.identification.number)))
      case 'colombia':
        if (!!get(client, 'nameObject'))
          return clientDataAreEquals(defaultC.nameObject.firstName, client.nameObject.firstName)
            && clientDataAreEquals(defaultC.nameObject.lastName, client.nameObject.lastName)
            && clientDataAreEquals(defaultC.identificationObject.type, client.identificationObject.type)
            && clientDataAreEquals(defaultC.identificationObject.number, client.identificationObject.number)
        return clientDataAreEquals(defaultC.nameObject.firstName, client.name.firstName)
          && clientDataAreEquals(defaultC.nameObject.lastName, client.name.lastName)
          && clientDataAreEquals(defaultC.identificationObject.type, client.identificationObject.type)
          && clientDataAreEquals(defaultC.identificationObject.number, client.identificationObject.number)
      case 'mexico':
        return clientDataAreEquals(defaultC.name, client.name)
          && clientDataAreEquals(defaultC.identification, client.identification)
      case 'argentina':
        return clientDataAreEquals(defaultC.name, client.name)
          && clientDataAreEquals(defaultC.identification.type, client.identification.type)
          && clientDataAreEquals(defaultC.identification.number, client.identification.number)
      case 'republicaDominicana':
        return clientDataAreEquals(defaultC.name, client.name)
      case 'spain':
        return clientDataAreEquals(defaultC.identification, client.identification)
      default:
        return clientDataAreEquals(defaultC.name, client.name)
    }

  } catch {
    return false
  }
}

const getFormattedNumber = (invoice, number, country) => {
  if (!invoice) return null;

  switch (country) {
    case 'peru':
      return `${!!number ? repeat('0', 8 - number.length) : ''}${number || ''}`
    case 'argentina':
      return `${!!number ? repeat('0', 8 - number.length) : ''}${number || ''}`
    case 'costaRica':
      if (!!get(invoice, 'numberTemplate.stamp', null))
        return `${!!number ? repeat('0', 10 - number.length) : ''}${number || ''}`
      return number;
    case 'republicaDominicana':
      return `${!!number ? repeat('0', 8 - number.length) : ''}${number || ''}`
    default:
      return number;
  }
}

export const getNumber = (invoice, country, refund = false) => {
  if (!invoice) return null;

  let no = I18n.get('number', 'Número');

  let number = get(invoice, 'numberTemplate.number', null);
  let prefix = get(invoice, 'numberTemplate.prefix', null);
  let fullNumber = get(invoice, 'numberTemplate.fullNumber', null);

  if (refund) {
    number = get(invoice, 'number', null);
    prefix = get(invoice, 'prefix', null);
    fullNumber = get(invoice, 'fullNumber', null);
  }

  let formattedNumber = getFormattedNumber(invoice, number, country);

  if (country === 'peru') {
    if (/^B.{3}/.test(prefix))
      no = I18n.get('ticket', 'boleta');
    else if (/^F.{3}/.test(prefix))
      no = I18n.get('invoice', 'factura');
    else
      no = I18n.get('sale', 'venta');
  }

  no = capitalize(no)

  if (!!fullNumber && country !== 'panama' && country !== 'spain')
    return `${no} ${fullNumber || ''}`;

  switch (country) {
    case 'peru':
      return `${no} ${prefix ? `${prefix}-` : ''}${formattedNumber || ''}`;
    case 'argentina':
      return `${no} ${prefix ? `${prefix}-` : ''}${formattedNumber || ''}`;
    case 'panama':
      return `${no} ${prefix ? `${prefix}-` : ''}${formattedNumber || ''}`;
    case 'spain':
      const invoiceType = get(invoice, 'numberTemplate.documentType', null) === 'invoice' ? I18n.get('ordinaryInvoice', 'Factura ordinaria') : I18n.get('invoiceOfSale', 'Ticket de venta');
      return `${invoiceType} ${prefix ? `${prefix}-` : ''}${formattedNumber || ''
        }`;
    default:
      return `${no} ${prefix || ''}${formattedNumber || ''}`;
  }
}


export const getNumberAndPrefix = (invoice, country, refund = false) => {
  if (!invoice) return null;

  let no = I18n.get('number', 'Número');

  const documentType = get(invoice, 'numberTemplate.documentType', null);
  let number = get(invoice, 'numberTemplate.number', null);
  let prefix = get(invoice, 'numberTemplate.prefix', null);
  let fullNumber = get(invoice, 'numberTemplate.fullNumber', null);


  if (refund) {
    number = get(invoice, 'number', null);
    prefix = get(invoice, 'prefix', null);
    fullNumber = get(invoice, 'fullNumber', null);
  }

  let formattedNumber = getFormattedNumber(invoice, number, country);

  if (country === 'peru') {
    if (/^B.{3}/.test(prefix))
      no = I18n.get('ticket', 'boleta');
    else if (/^F.{3}/.test(prefix))
      no = I18n.get('invoice', 'factura');
    else
      no = I18n.get('sale', 'venta');
  }

  if (country === 'colombia') {
    if (documentType === 'saleTicket' && !!get(invoice, 'numberTemplate.isElectronic'))
      no = I18n.get('electronicPOSDocument', 'Documento POS electrónico');
    else if (documentType === 'saleTicket')
      no = I18n.get('posDocument', 'Documento POS');
    else if (documentType === 'invoice' && !!get(invoice, 'numberTemplate.isElectronic'))
      no = I18n.get('electronicSellInvoice', 'factura de venta electrónica');
    else
      no = I18n.get('invoiceOfSale', 'factura de venta');
  }

  // no = capitalize(no)

  if (!!fullNumber && country !== 'panama')
    return `${no} ${fullNumber || ''}`;

  switch (country) {
    case 'peru':
      return `${no} ${prefix ? `${prefix}-` : ''}${formattedNumber || ''}`;
    case 'argentina':
      return `${no} ${prefix ? `${prefix}-` : ''}${formattedNumber || ''}`;
    case 'panama':
      return `${no} ${prefix ? `${prefix}-` : ''}${formattedNumber || ''}`;
    default:
      return `${no} ${prefix || ''}${formattedNumber || ''}`;
  }
}

export const getNumberAndPrefixSimplified = (invoice, country, refund = false) => {
  if (!invoice) return null;

  let no = I18n.get('number', 'Número');

  const documentType = get(invoice, 'numberTemplate.documentType', null);
  let number = get(invoice, 'numberTemplate.number', null);
  let prefix = get(invoice, 'numberTemplate.prefix', null);
  let fullNumber = get(invoice, 'numberTemplate.fullNumber', null);

  if (refund) {
    number = get(invoice, 'number', null);
    prefix = get(invoice, 'prefix', null);
    fullNumber = get(invoice, 'fullNumber', null);
  }

  let formattedNumber = getFormattedNumber(invoice, number, country);

  if (country === 'colombia') {
    if (documentType === 'saleTicket' && !!get(invoice, 'numberTemplate.isElectronic')) {
      no = I18n.get('electronicPOS', 'POS electrónico');
    } else if (documentType === 'saleTicket') {
      no = I18n.get('pos', 'POS');
    } else if (documentType === 'invoice' && !!get(invoice, 'numberTemplate.isElectronic')) {
      no = I18n.get('electronicInvoice', 'factura electrónica');
    } else {
      no = I18n.get('invoiceOfSale', 'factura de venta');
    }
  }

  if (!!fullNumber) return `${no} ${fullNumber}`;

  return `${no} ${prefix || ''}${formattedNumber || ''}`;
}


export const STATUS_IN_PROCESS = {
  inFrontend: 1,
  inBackend: 2,
  inAC: 3
}