import React, { useState, useEffect, useCallback } from 'react';
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { capitalize, get } from 'lodash'
import dayjs from 'dayjs'

import { shiftsEnabled, isOnlyInvoicingPlan, membershipSelector, companySelector } from '../../selectors/company';
import { userSelector } from '../../selectors/auth';
import { isHappyWeekAvailable } from '../../selectors/membership';
import { isOpen, startDate } from '../../selectors/shifts'
import { setNotificationContent } from '../../reducers/notificationsWC';
import { WebComponents, webcomponentHasContent } from '../../selectors/notificationsWC';
import MembershipNotification from './MembershipNotification'
import Notification from './Notification'
import NotificationTop from './NotificactionTop'
import NotificationBox from './NotificationBox';

const Notifications = () => {
  const networkStatus = useSelector(state => get(state, 'app.networkStatus', 'online'))
  const isShiftsEnabled = useSelector(shiftsEnabled)
  const onlyInvoicingPlan = useSelector(isOnlyInvoicingPlan)
  const shiftOpen = useSelector(isOpen)
  const start = useSelector(startDate)
  const membership = useSelector(membershipSelector)
  const company = useSelector(companySelector)
  const happyWeek = useSelector(isHappyWeekAvailable)
  const notificationTopHasContent = useSelector(webcomponentHasContent(WebComponents.componentTop))

  const [notifications, setNotifications] = useState({});

  const closeNotification = useCallback(key => setNotifications(prev => ({ ...prev, [key]: false })), [])

  useEffect(() => {
    let shiftNotification = false
    try {
      shiftNotification = !!isShiftsEnabled && !onlyInvoicingPlan && !!shiftOpen && dayjs().diff(dayjs(start), 'days') > 7
    } catch {
      //
    }
    setNotifications(prev => ({ ...prev, 'shifts': shiftNotification }))

  }, [isShiftsEnabled, onlyInvoicingPlan, shiftOpen, start])

  useEffect(() => {
    console.log('setNotifications networkStatus:', networkStatus);
    setNotifications(prev => ({ ...prev, 'networkStatus': networkStatus !== 'online' }))
  }, [networkStatus])

  useEffect(() => {
    try {
      const isPlanDemo = get(membership, 'plan.keyword', '').includes('demo');
      const isPlanReadonly = get(membership, 'plan.keyword', '').includes('readonly');
      const showPlans = isPlanReadonly
        || (isPlanDemo && dayjs(company.registryDate).isAfter(dayjs('2021-09-15')))

      const planSelected = !!company.subscription
      setNotifications(prev => ({ ...prev, 'membership': (showPlans && !planSelected) || (happyWeek && (isPlanDemo || isPlanReadonly)) }))
    } catch {

    }
  }, [membership, company.subscription, company.registryDate])

  const entries = Object.entries(notifications).filter(entry => !!entry[1]).map(entry => entry[0])

  return (
    <>
      <div className="main-notification-top-container">
        {/* {entries.includes('networkStatus') && (
          <Notification
            type='warning'
            withIcon
            onClose={() => closeNotification('networkStatus')}
            body={
              <div className='d-flex align-items-center justify-content-center gap-2'>
                <Icon icon='circle-off' />
                <p className='m-0 ml-2 h4 text-capitalize-first font-weight-bold'>
                  {I18n.get(
                    'offlineNotificationTitle',
                    '¡Estás usando Alegra POS sin internet!'
                  )}
                  <span className='ml-2 font-weight-light'>
                    {I18n.get(
                      'offlineNotification',
                      'Puedes seguir facturando y tus ventas se guardarán al recuperar la conexión.'
                    )}
                  </span>
                </p>
              </div>
            }
          />
        )} */}
      </div>
      <div className={`external-webcomponents-notification${entries.includes('networkStatus') ? " d-none" : ""}`}>
        <NotificationBoxComponent customClassName="bg-white" />
        <NotificationTopComponent />
      </div>
      {!notificationTopHasContent && (
        <div className={`main-notification-top-container${entries.length > 0 ? ' has-notifications' : ''}`}>
          {entries.includes('shifts') && (
            <Notification
              onClose={() => closeNotification('shifts')}
              type="warning"
              body={(
                <p className="m-0 h4 text-capitalize-first">
                  {I18n.get('shiftIsOpenToMuchTime', 'Tienes el turno abierto hace más de una semana.')}{' '}
                  <a href="https://ayuda.alegra.com/es/apertura-y-cierre-de-turnos-en-alegra-pos" target="_blank" rel="noreferrer">
                    {capitalize(I18n.get('seeMore', 'Ver más'))}
                  </a>
                </p>
              )}
            />
          )}
          {entries.includes('membership') && (
            <div className="d-block d-md-none">
              <Notification
                onClose={() => closeNotification('membership')}
                type="warning"
                body={(
                  <MembershipNotification />
                )}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Notifications;

// wrapper for App.js support mobile
export const NotificationBoxComponent = ({ customClassName }) => {
  const user = useSelector(userSelector)
  const location = useLocation()
  const dispatch = useDispatch()

  if (location.pathname !== '/') {
    dispatch(setNotificationContent({ webcomponent: WebComponents.componentBox, hasContent: false }))
    return null;
  }

  return (
    <div className="d-block d-md-none" >
      <NotificationBox
        customClassName={customClassName}
        userId={user.idGlobal.toString()}
        isMobile />
    </div >
  )
}

// wrapper for App.js
export const NotificationTopComponent = () => {
  const user = useSelector(userSelector)
  return (
    <NotificationTop userId={user.idGlobal} />
  )
}