import { createSlice } from '@reduxjs/toolkit';

import { printSettings } from '../selectors/company'
import { getInitialState } from '../utils';

const initialState = {
  type: null,
  invoice: null,
  shift: null,
  refund: null,
}

export const setTypeToPrint = type => {
  return (dispatch, getState) => {
    dispatch(setType(type))

    const style = document.getElementById('print-style');

    const format = printSettings(getState()).format

    style.innerHTML = document.createTextNode(`@page {size: ${format}mm 250mm !important; margin: 0 !important;}`).textContent

    setTimeout(() => window.print(), 300)
  }
}

const appSlice = createSlice({
  name: 'print',
  initialState: getInitialState('print', initialState),
  reducers: {
    setPrint: (state, action) => {
      state[action.payload.type] = action.payload.value
    },
    setType: (state, action) => {
      state.type = action.payload
    }
  }
});

const { actions, reducer } = appSlice;

export const { setPrint, setType } = actions;

export default reducer;