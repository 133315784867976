import { isObject } from 'lodash';
import { basicPOSClientData } from '../../../utils';

function checkIfIsDefaultClient(contact, country) {
  const defaultClient = basicPOSClientData(country);

  if (defaultClient?.name === contact?.name) return true;

  if (
    isObject(defaultClient?.name) &&
    `${defaultClient?.name?.firstName} ${defaultClient?.name?.lastName}` ===
      contact?.name
  )
    return true;
  if (contact?.identification && defaultClient?.identification?.number === contact?.identification)
    return true;
  if (contact?.identification?.number && defaultClient?.identification?.number === contact?.identification?.number)
    return true;
  return false;
}

export default checkIfIsDefaultClient;
