import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';

import OnlyInvoicing from '../Tooltip/OnlyInvoicing';
import { useNavigationMenu } from './context';
import NavigationItemOption from './option';

/**
 * Represents a navigation item in a menu.
 *
 * @component
 * @param {object} props - The properties passed to the NavigationItem component.
 * @param {string} props.menuName - The name of the menu this item belongs to.
 * @param {JSX.Element} props.icon - The icon element for the navigation item.
 * @param {JSX.Element} props.component - The component element for the navigation item.
 * @param {string} props.title - The title or label of the navigation item.
 * @param {function} props.action - The action to perform when the item is clicked.
 * @param {string} props.to - The target route or URL for the navigation item.
 * @param {object} props.extra - Extra information associated with the navigation item.
 * @param {Array} props.items - An array of sub-items to be displayed as submenu items.
 * @param {boolean} props.onlyInvoicingPlan - Whether the navigation item is available only in invoicing plans.
 * @param {string} props.target - The target attribute for the navigation link.
 * @param {JSX.Element} props.extraIcon - An extra icon element associated with the navigation item.
 * @param {boolean} props.isMenu - Whether the navigation item represents a menu.
 * @param {string} props.layout - Extra class name depending layout
 *
 * @returns {JSX.Element} A React element representing the navigation item.
 */
function NavigationItem({
  menuName,
  icon,
  title,
  action,
  to,
  extra,
  items = [],
  onlyInvoicingPlan,
  target,
  extraIcon,
  isMenu,
  component,
  hover = true,
  layout = 'normal',
  disabled = { status: false, message: '' },
  dataTestId,
}) {
  const { menu } = useNavigationMenu();

  const tooltipProps = () => {
    if (onlyInvoicingPlan) {
      return {
        visible: onlyInvoicingPlan,
      };
    }

    if (disabled && disabled.status) {
      return {
        visible: disabled.status,
        overlay: disabled?.message || '',
      };
    }

    return {
      visible: false,
    };
  };

  return (
    <OnlyInvoicing {...tooltipProps()}>
      <li className={`menu-submenu ${layout === 'big' && 'menu-submenu-big'}`}>
        <NavigationItemOption
          action={action}
          icon={icon}
          hover={hover}
          title={title}
          to={to}
          menuName={menuName}
          hasSubmenu={items.length > 0}
          extra={extra}
          disabled={onlyInvoicingPlan || disabled.status}
          target={target}
          extraIcon={extraIcon}
          isMenu={isMenu}
          items={items}
          component={component}
          dataTestId={dataTestId}
        />
        {items.length > 0 && menu.name === menuName && menu.expandable && (
          <ul className='menu-submenu-list'>
            {items.map((item) => {
              if (item?.hidden) return <></>;
              return (
                <li className='menu-submenu' key={uniqueId()}>
                  <NavigationItemOption
                    action={item?.action}
                    title={item?.title}
                    to={item?.to}
                    menuName={item?.menuName}
                    isSubItem={true}
                    extra={item?.extra}
                    isMenu={item?.isMenu}
                    component={item?.component}
                    hover={item?.hover}
                    dataTestId={item?.dataTestId}
                  />
                </li>
              );
            })}
          </ul>
        )}
      </li>
    </OnlyInvoicing>
  );
}

NavigationItem.propTypes = {
  menuName: PropTypes.string,
  icon: PropTypes.element,
  title: PropTypes.string,
  layout: PropTypes.string,
  action: PropTypes.func,
  to: PropTypes.string,
  extra: PropTypes.shape({
    title: PropTypes.string,
    variant: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func,
      title: PropTypes.string,
      to: PropTypes.string,
      menuName: PropTypes.string,
      extra: PropTypes.shape({
        title: PropTypes.string,
        variant: PropTypes.string,
      }),
      component: PropTypes.object,
    })
  ),
  disabled: PropTypes.shape({
    status: PropTypes.bool,
    message: PropTypes.string,
  }),
  onlyInvoicingPlan: PropTypes.bool,
  target: PropTypes.string,
  extraIcon: PropTypes.element,
  isMenu: PropTypes.bool,
};

export default NavigationItem;
