import React, { useMemo } from 'react'
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux'
import { BigNumber } from 'bignumber.js'
import { get } from 'lodash'

import { companySelector, country as countrySelector, decimalPrecision } from '../../../selectors/company'
import { currency } from '../../../selectors/activeInvoice'
import { getMainCurrency } from '../../../selectors/currencies'
import { calculateSingleItemValues, calculateSingleItemValuesWithDecimals } from '../../../utils';
import { DECIMALS_ACTIVE_COUNTRIES, DECIMALS_ACTIVE_IDS } from '../../../hooks/useDecimalsVersionsGroup/hook';
import { COUNTRIES } from '../../../utils/enums/countries';

const Summary = ({ values }) => {
  const country = useSelector(countrySelector)
  const decimal = useSelector(decimalPrecision)
  const mainCurrency = useSelector(getMainCurrency)
  const selectedCurrency = useSelector(currency)
  const company = useSelector(companySelector);

  const itemValues = useMemo(() => {
    if (DECIMALS_ACTIVE_COUNTRIES.includes(country) || DECIMALS_ACTIVE_IDS.includes(company?.id)) {
      return calculateSingleItemValuesWithDecimals(values, decimal)
    }

    return calculateSingleItemValues(values, decimal)
  }, [values, decimal, country, company]);

  const prefix = !!get(selectedCurrency, 'symbol') ? get(selectedCurrency, 'symbol')
    : !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : ''

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  }

  const getTaxSummary = (tax) => {
    if (
      country === COUNTRIES.COLOMBIA &&
      (get(tax, 'rate') === 'EXEMPT' || get(tax, 'rate') === 'EXCLUDED')
    ) {
      return (
        tax.name +
        ' ' +
        (tax.rate === 'EXEMPT' ? 'Exento' : 'Excluido') +
        '' +
        `(${BigNumber(tax.percentage).toFormat(decimal, { ...fmt, prefix: '' })}%)`
      );
    }
    return (
      tax.name +
      ' ' +
      `(${BigNumber(tax.percentage).toFormat(decimal, { ...fmt, prefix: '' })}%)`
    );
  };

  return (
    <div className="d-flex flex-column p-4 form-row justify-content-end m-0 new-item-summary">
      <div className="col-12">
        {(itemValues.discount > 0 || !!values.tax) && (
          <div className="new-item-summary__row d-flex justify-content-between align-items-center">
            <div className="new-item-summary__row-concept text-capitalize-first">
              {I18n.get('subtotal', 'sub total')}
            </div>
            <div className="new-item-summary__row-value text-capitalize-first">
              {itemValues.subtotal.toFormat(decimal, fmt)}
            </div>
          </div>
        )}

        {itemValues.discount > 0 && (
          <div className="new-item-summary__row d-flex justify-content-between align-items-center">
            <div className="new-item-summary__row-concept text-capitalize-first">
              {I18n.get('discount', 'descuento')}
            </div>
            <div className="new-item-summary__row-value text-capitalize-first">
              -{itemValues.discountValue.toFormat(decimal, fmt)}
            </div>
          </div>
        )}

        {itemValues.discount > 0 && (
          <div className="new-item-summary__row d-flex justify-content-between align-items-center">
            <div className="new-item-summary__row-concept text-capitalize-first">
              {I18n.get('subtotal', 'sub total')}
            </div>
            <div className="new-item-summary__row-value text-capitalize-first">
              {itemValues.discSubtotal.toFormat(decimal, fmt)}
            </div>
          </div>
        )}

        {itemValues.taxesArray.map((tax, i) => !!tax && (
          <div className="new-item-summary__row d-flex justify-content-between align-items-center" key={i}>
            <div className="new-item-summary__row-concept text-capitalize-first">
              {getTaxSummary(tax)}
            </div>
            <div className="new-item-summary__row-value text-capitalize-first">
              {tax.value.toFormat(decimal, fmt)}
            </div>
          </div>
        ))}

        <div className="new-item-summary__row d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first">
            {I18n.get('total', 'total')}
          </div>
          <div className="text-capitalize-first">
            {itemValues.total.toFormat(decimal, fmt)}
          </div>
        </div>

      </div>
    </div>
  )
}

Summary.propTypes = {
  values: PropTypes.object.isRequired
}

export default Summary;