import React from 'react';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import {
  Grid,
  toastHandler,
  useModal,
  Typography,
  Button,
  Tabs,
} from '@alegradev/smile-ui-react';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, get } from 'lodash';

import * as mutations from '../../../../../graphql/mutations';
import { addPriceList } from '../../../../../reducers/priceLists';
import { formError } from '../../../../../utils/errors';
import {
  validate,
  transform,
} from '../../../../../components/forms/priceList/utils';
import { APIGraphqlSelector } from '../../../../../selectors/app';

import { maxDecimals } from '../../../../../utils/decimals/maxDecimals';
import { renderField } from '../../../../../components/forms/fields/V2';
import { useDecimalsVersionsGroup } from '../../../../../hooks/useDecimalsVersionsGroup';

function PriceList({ modalName, onCreated }) {
  const { closeModal } = useModal();
  const { Wrapper, Col, Row } = Grid;
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const dispatch = useDispatch();
  const APIGraphql = useSelector(APIGraphqlSelector);

  const submit = async (values) => {
    const transformedValues = transform(values);

    try {
      const response = await APIGraphql(
        graphqlOperation(mutations.createPriceList, {
          priceList: transformedValues,
        })
      );

      const priceList = !!get(response, 'data.createPriceList')
        ? get(response, 'data.createPriceList')
        : null;

      if (!!priceList) {
        dispatch(addPriceList(priceList));
        if (!!onCreated) onCreated(priceList);
      }

      closeModal(modalName);
      toastHandler({
        type: 'success',
        title: I18n.get(
          'priceListCreatedSuccessfully',
          'lista de precio creada con éxito'
        ),
      });
    } catch (error) {
      return formError(
        error,
        I18n.get(
          'createPriceListError',
          'hubo un error en la creación de la lista de precio'
        )
      );
    }
  };

  const Parent = ({ children }) => {
    return (
      <Form
        onSubmit={submit}
        validate={(values) => validate(values)}
        initialValues={{ type: 'percentage' }}
      >
        {({ handleSubmit, values, submitting, submitError, errors, form }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Wrapper
              fullHeight
              direction={{ lg: 'column' }}
              justify={{ lg: 'between' }}
              gap={10}
            >
              {children({ values, form })}

              <Wrapper direction={{ lg: 'column' }}>
                {submitError && (
                  <Typography
                    type='body-3-regular'
                    variant='error'
                    text={submitError}
                  />
                )}

                <Wrapper gap={10}>
                  <Button
                    emphasis='outline'
                    full
                    disabled={submitting}
                    onClick={() => closeModal(modalName)}
                    type='button'
                    text={I18n.get('cancel', 'Cancelar')}
                  />
                  <Button
                    full
                    disabled={submitting || Object.keys(errors).length > 0}
                    loading={submitting}
                    text={I18n.get('save', 'Guardar')}
                  />
                </Wrapper>
              </Wrapper>
            </Wrapper>
          </form>
        )}
      </Form>
    );
  };

  return (
      <Tabs
        items={[
          {
            id: 'percentage',
            title: 'Porcentaje',
            component: (
              <Parent>
                {({ values, form }) => {
                  form.change('type', 'percentage');
                  return (
                    <Row spacing='md'>
                      <Col xs={6}>
                        <Field
                          name='name'
                          component={renderField}
                          type='text'
                          label={capitalize(I18n.get('name', 'Nombre'))}
                          required
                        />
                      </Col>
                      <Col xs={6}>
                        <Field
                          name='percentage'
                          component={renderField}
                          type='number'
                          label={capitalize(
                            I18n.get('percentage', 'Porcentaje')
                          )}
                          onChange={(e) =>
                            form.change(
                              'percentage',
                              maxDecimals({
                                e,
                                key: `percentage`,
                                decimals: 1,
                                isActive: isDecimalActive,
                                values,
                              })
                            )
                          }
                          required
                        />
                      </Col>
                    </Row>
                  );
                }}
              </Parent>
            ),
          },
          {
            id: 'amount',
            title: 'Valor',
            component: (
              <Parent>
                {({ form }) => {
                  form.change('type', 'amount');
                  return (
                    <Field
                      name='name'
                      component={renderField}
                      type='text'
                      label={capitalize(I18n.get('name', 'Nombre'))}
                      required
                    />
                  );
                }}
              </Parent>
            ),
          },
        ]}
      />
  );
}

export default PriceList;
