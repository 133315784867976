import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux'

import { hasPermissionTo } from '../../../../selectors/auth'
import { activeBanks } from '../../../../selectors/banks'
import { renderField, renderSelect } from '../../fields/V0/Fields';
import { maxDecimals } from '../../../../utils/decimals/maxDecimals';
import { decimalPrecision } from '../../../../selectors/company';
import { useDecimalsVersionsGroup } from '../../../../hooks/useDecimalsVersionsGroup';
import { preventNotNumberKeys } from '../../../../utils/keyDown';

const Transfer = ({ values }) => {
  const can = useSelector(hasPermissionTo)
  const banks = useSelector(activeBanks)
  const decimal = useSelector(decimalPrecision);
  const { isDecimalActive } = useDecimalsVersionsGroup();

  return (
    <>
      <Field
        name="transfer"
        className="col-md-12"
        type="number"
        component={renderField}
        label={capitalize(I18n.get('transfer', 'transferencia'))}
        onChange={e => maxDecimals({e, key: 'transfer', decimals: decimal, isActive: isDecimalActive, values})}
        placeholder={'$'}
        onKeyDown={preventNotNumberKeys}
      />
        
      <Field
        name="transferBank"
        className="col-md-12"
        component={renderSelect}
        options={!can('index', 'bank-accounts') || !can('view', 'bank-accounts') ? [] : banks}
        selectInfo={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')
          ? I18n.get('userNotAllowed.bank-accounts.view', 'no tienes permisos para ver la información de las cuentas de bancos')
          : null}
        hideNoOptionMessage={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        menuPosition="absolute"
      />
    </>
  )
}

export default Transfer;