import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'
import alegraAPI from '../../../reducers/alegraAPI';

import { openModal } from '../../../reducers/modals';
import { hasPermissionTo } from '../../../selectors/auth';
import { companySelector, country as countrySelector } from '../../../selectors/company';
import Typography from '../../common/Typography';

import { setPrint, setTypeToPrint } from '../../../reducers/print';
import { isUserElegibleForNewRefund } from '../utils';
import { Icon, Tooltip } from '@alegradev/smile-ui-react';
import { toast } from '../../../utils';
import parse from 'html-react-parser';


const DetailHeader = ({ refund, onListView, onRefresh }) => {
  const [stampLoading, setStampLoading] = useState(false);
  const dispatch = useDispatch()
  const can = useSelector(hasPermissionTo)
  const country = useSelector(countrySelector)
  const company = useSelector(companySelector)

  if (!refund) return null;

  useEffect(() => {
    if (!!refund)
      dispatch(setPrint({ type: 'refund', value: refund }))
  }, [refund, dispatch])

  const sendRefund = async () => {
    dispatch(openModal({ modal: 'sendRefundEmail', params: { refund } }))
  }

  const getNumber = () => {
    const valuePrefix = I18n.get('number', 'número');

    const number = get(refund, 'numberTemplate.number', null);
    const fullNumber = get(refund, 'numberTemplate.fullNumber', null);
    const prefix = !!get(refund, 'numberTemplate.prefix', null)
      ? get(refund, 'numberTemplate.prefix', null) : '';

    if (!!fullNumber)
      return `${valuePrefix || ''} ${fullNumber || ''}`

    return `${valuePrefix || ''} ${prefix || ''}${number || ''}`
  }

  const getRefundTitle = () => {
    const numberTemplate = get(refund, 'numberTemplate');
    if (!numberTemplate)
      return I18n.get('refund.creditNote');
    if (numberTemplate.documentType !== "creditNote")
      return I18n.get('refund.adjustmentNote')
    else
      return I18n.get('refund.creditNote');

  }
  const isElectronic = get(refund, 'numberTemplate.isElectronic', false);
  const isToBeIssued = !get(refund, 'stamp', false);
  const showButtonToEmit = isElectronic && isToBeIssued;

  const emitRefund = async () => {
    try {
      setStampLoading(true);
      await alegraAPI.get('/credit-notes/' + refund.id + '/stamp/');
      onRefresh()
      
      toast.success({
        title: I18n.get('success', 'Éxito'),
        subtitle: I18n.get(
          get(refund, 'numberTemplate.documentType', '') === 'creditNote' ? 'refund.creditNoteStamped' : 'redund.adjustmentNoteStamped',
        ),
        params: { autoClose: 3000 }
      });
      
    } catch (error) {
      toast.error({
        title: I18n.get('error', 'Error'),
        subtitle: <div className='notification-error-list'>{parse(get(error, 'response.data.message', ''))}</div>,
        params: { autoClose: 3000 }
      });
    } finally {
      setStampLoading(false);
    }
  }

  return (
    <>
      <div className="d-flex justify-content-end pb-5 new-refund-button-container">
        <Tooltip
          visible={!can('add', 'credit-notes')}
          overlay={I18n.get('userNotAllowed.credit-notes.add', 'no tienes permisos para agregar devoluciones')}
        >
          <button
            type="button"
            disabled={!can('add', 'credit-notes')}
            className="btn btn-submit d-none d-sm-block new-refund-button"
            onClick={() => {
              isUserElegibleForNewRefund(country, company)
                ? dispatch(openModal({ modal: 'newRefunds' }))
                : dispatch(openModal({ modal: 'refunds' }))
            }}
          >
            {I18n.get('newRefund', 'nueva devolución')}
          </button>
        </Tooltip>
      </div>
      <div className='px-4 pt-4 new-refund-header'>
        <div className="d-flex justify-content-between align-items-center new-refund-header">
          <Typography
            htmlType="h2"
            type="body-2-bold title"
            variant="primary"
            text={
              <>
                <Icon icon='circle-arrow-down-left' />
                <span className="title-main">
                  {getRefundTitle()}
                </span>
                <span className="title-secondary">
                  {getNumber()}
                </span>
              </>} />
          <div className="new-refund-header_buttons d-none d-sm-flex">
            { showButtonToEmit &&
              <button
                onClick={() => emitRefund()}
                className="btn btn-action d-flex px-4">
                  {stampLoading ? (
                    <Icon icon='loader-2' animated type='primary' />
                  ) : (
                      I18n.get('emit', 'emitir')
                  )}
              </button>
            }
            {isElectronic && <button
              className="btn btn-action d-flex ml-4"
              onClick={sendRefund}
            >
              <Icon icon='mail'  color='#8a8e94' size='small' />
              {I18n.get("send", "enviar")}
            </button>}
            <button
              onClick={() => dispatch(setTypeToPrint('refund'))}
              className="btn btn-action d-flex">
              <Icon icon='printer' color='#8a8e94' size='small' />
              {I18n.get("print", "imprimir")}
            </button>
          </div>
          <div className="actions d-flex align-items-center d-block d-sm-none">
            <div className="w-100 h-25 d-flex justify-content-between align-items-center">
              <button
                type="button"
                className="btn btn-submit d-block d-sm-none"
                onClick={() => onListView()}
              >
                <Icon icon='list' extraClass="icon-white" />
              </button>
            </div>
          </div>
        </div>
        <div className="new-refund-header_buttons d-sm-none d-flex">
          {
            showButtonToEmit &&
              <button
                onClick={() => emitRefund()}
                className="btn btn-action d-flex px-4">
                {stampLoading ? (
                    <Icon icon='loader-2' animated type='primary' />
                  ) : (
                      I18n.get('emit', 'emitir')
                  )}
              </button>
          }
          
          {isElectronic && <button
            className="btn btn-action d-flex ml-4"
            onClick={sendRefund}
          >
            <Icon icon='mail' color='#8a8e94' size='small' />
            {I18n.get("send", "enviar")}
          </button>}
          
          <button
            onClick={() => dispatch(setTypeToPrint('refund'))}
            className="btn btn-action d-flex">
            <Icon icon='printer' color='#8a8e94' size='small' />
            {I18n.get("print", "imprimir")}
          </button>
        </div>
      </div>
    </>
  )
}

DetailHeader.propTypes = {
  refund: PropTypes.object,
  onListView: PropTypes.func
}

export default DetailHeader;