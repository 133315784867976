import React from 'react';
import { useDispatch } from 'react-redux';

import { sendGTMEvent } from '../../../reducers/company';

import { Icon } from '@alegradev/smile-ui-react';

const Bottom = ({
  primaryText,
  secondaryText,
  goToAdvancedForm,
  type,
  disabled,
  submitting,
  hasValidationErrors,
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    return type === "kit"
      ? goToAdvancedForm()
      : dispatch(sendGTMEvent("item-creation-attempted", {
        reationStatus: hasValidationErrors ? "failure" : "success",
        creationOrigin: "basic form",
      }))
  }

  return (
    <div className="modal__bottom modal__item-bottom w-100 p-4 d-flex flex-row justify-content-center 
    align-items-sm-center align-items-start border-top">

      {!!secondaryText && (
        <button
          type="button"
          className="btn btn-cancel text-capitalize-first w-50 mr-3"
          onClick={() => goToAdvancedForm()}
        >
          {secondaryText}
        </button>
      )}

      <button
        type={type === "kit" ? "button" : "submit"}
        data-testid={`modal-${type === "kit" ? 'confirm' : 'submit'}-btn`}
        className={`btn btn-submit absolute-center text-capitalize-first ${!!secondaryText ? 'w-50' : 'w-100'}`}
        disabled={(!!submitting || !!disabled)}
        onClick={handleClick}
      >
        {submitting
          ? <Icon icon='loader-2' animated extraClass=" icon-white" />
          : primaryText
        }
      </button>
    </div>
  )
};

export default Bottom;
