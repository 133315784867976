import { I18n } from '@aws-amplify/core';
import { get, isEmpty } from 'lodash'
import { FORM_ERROR } from 'final-form'
import { BigNumber } from 'bignumber.js'
import { replaceAndParse } from '../../../utils';
import { COUNTRIES } from '../../../utils/enums/countries';

export const validate = (values, { total, country }) => {
  let errors = {};

  const method = get(values, 'method')

  switch (method) {
    case 'cash':
      if (!get(values, 'cash'))
        errors.cash = I18n.get('youNeedToAddThePaymentValue', 'necesitas agregar el valor del pago')
      break;
    case 'debit':
      if (!get(values, 'debit'))
        errors.debit = I18n.get('youNeedToAddThePaymentValue', 'necesitas agregar el valor del pago')
      break;
    case 'credit':
      if (!get(values, 'credit'))
        errors.credit = I18n.get('youNeedToAddThePaymentValue', 'necesitas agregar el valor del pago')
      break;
    case 'transfer':
      if (!get(values, 'transfer'))
        errors.transfer = I18n.get('youNeedToAddThePaymentValue', 'necesitas agregar el valor del pago')
      break;
    case 'combined':
      if (!get(values, 'cash') && !get(values, 'credit') && !get(values, 'debit') && !get(values, 'transfer'))
        errors = {
          ...errors,
          [FORM_ERROR]: I18n.get('youNeedToAddThePaymentValue', 'necesitas agregar el valor del pago'),
        }
      break;

    default:
      if (!get(values, 'transfer'))
        errors.transfer = I18n.get('youNeedToAddThePaymentValue', 'necesitas agregar el valor del pago')
      break;
  }
  
  if(country === COUNTRIES.MEXICO && get(values,'numeration.documentType') === 'invoice') {
    if(!get(values,'use', null)) {
      errors.use = I18n.get('useIsRequired', 'El uso es requerido')
    }
  }

  const debit = new BigNumber(!!get(values, 'debit') ? get(values, 'debit') : 0);
  const credit = new BigNumber(!!get(values, 'credit') ? get(values, 'credit') : 0);
  const transfer = new BigNumber(!!get(values, 'transfer') ? get(values, 'transfer') : 0);

  let electronicPayments = debit.plus(credit).plus(transfer);
  const decimal = get(values, 'decimal')
  if (!!country && !!decimal && values?.isDecimalActive) {
    if (electronicPayments.decimalPlaces(decimal).eq(total.decimalPlaces(decimal)))
    electronicPayments = total;
  }
  if (electronicPayments.gt(total)) {
    errors = {
      ...errors,
      [FORM_ERROR]: I18n.get('electronicPaymentsTotalMustBeLTETotal', 'el total de los pagos electrónicos debe ser menor o igual al total de la factura'),
    }
  }

  return errors;
}

export const transform = (values, country = '') => {
  return {
    payments: {
      cash: +get(values, 'cash', 0),
      credit: +get(values, 'credit', 0),
      debit: +get(values, 'debit', 0),
      transfer: +get(values, 'transfer', 0),
    },
    seller: get(values, 'seller', null),
    cfdiUse: get(values, 'use.key', null),
    anotation: ["republicaDominicana"].includes(country)
      ? get(values, 'anotation', null) === null ? null : get(values, 'anotation').slice(0, 250)
      : get(values, 'anotation', null),
    banks: {
      cash: get(values, 'cashBank.id', null),
      debit: get(values, 'debitBank.id', null),
      credit: get(values, 'creditBank.id', null),
      transfer: get(values, 'transferBank.id', null),
    },
    numeration: get(values, 'numeration', null),
    externalPayment: {
      id: get(values, 'qrId', null),
      provider: get(values, 'method', null)
    },
  }
}

export const validationPreviousCreation = (values) => {
  let error = null;
  switch (values.country) {
    case 'republicaDominicana':
      error = republicaDominicanaValidation(values);
      break;
    default:
      error = { hasError: false, errors: [] }
      break;
  }

  return error;
}

function republicaDominicanaValidation(values) {
  const error = { hasError: false, errors: [] }

  const itemsWithMultipleItbis = get(values, 'items', []).filter(
    item => get(item, 'tax')
      .filter(tax => get(tax, 'type') === 'ITBIS' || get(tax, 'type') === 'EXENTO').length > 1
  )

  if (!isEmpty(itemsWithMultipleItbis)) {
    error.errors.push({
      title: I18n.get('error', 'Error'),
      subtitle: <>
        {I18n.get("emissionError", "La factura no se pudo emitir porque no cumple con las validaciones necesarias:!")}
        <ul>
          {itemsWithMultipleItbis.map((item, index) => (
            <li key={index}>
              {replaceAndParse(I18n.get("emissionError.itbis", "El item {} solo puede tener un impuesto de tipo ITBIS o EXENTO asociado!"), [
                get(item, 'name')
              ])}
            </li>
          ))}
        </ul>
      </>,
      type: "warning"
    })
  }

  if (values.numeration.prefix === "E31" && !values.client) {
    error.errors.push({
      title: "No se puede realizar una factura electrónica a un consumidor final",
      type: "warning"
    })
  } else if (values.numeration.prefix === "E31" && (!values.client?.identificationObject?.number || !values.client?.identificationObject?.type || !values.client?.name)) {
    error.errors.push({
      title: I18n.get('editAndCompleteClientData', 'Edita y completa los datos de tu cliente'),
      subtitle: replaceAndParse(I18n.get('editAndCompleteClientData.description', 'Para crearle una factura electrónica debe contar con el nombre o razón social y un RNC válido, {}'), [
        `<a href="${process.env.REACT_APP_ALEGRA}client/edit/id/${values.client.id}" target="_blank">edita tu cliente aquí</a>`
      ]),
      type: "warning"
    })
  }
  error.hasError = error.errors.length > 0 ? true : false;
  return error;
}