import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, get, set } from 'lodash';

import { renderField, renderPriceListType } from '../fields/V0/Fields';
import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';

const PriceList = ({ values }) => {
  const { isDecimalActive } = useDecimalsVersionsGroup();

  function maxDecimals(e, key, decimals = 6) {
    if (!isDecimalActive)
      return e.target.value;

    const newButton = e.nativeEvent.data;
    set(values, key, !get(values, key) ? "" : get(values, key));
    e.target.value = !e.target.value ? "" : e.target.value;

    if (newButton === '.') {
      if (!e.target.value.slice(0, -1).includes('.')) {
        return e.target.value;
      } else {
        e.target.value = values[key];
        return get(values, key);
      }
    }

    if (!isNaN(newButton)) {
      if (!e.target.value.includes('.') || (e.target.value.includes('.') && e.target.value.split('.')[1].length <= decimals)) {
        return e.target.value;
      } else {
        e.target.value = get(values, key);
        return get(values, key);
      }
    }

    if (isNaN(newButton)) {
      e.target.value = get(values, key);
      return get(values, key);
    }
  }

  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">

        <Field
          name="type"
          className="col-12"
          component={renderPriceListType}
          label={capitalize(I18n.get('type', 'Tipo'))}
        />

        <Field
          name="name"
          className="col-5"
          component={renderField}
          type="text"
          label={capitalize(I18n.get('name', 'Nombre'))}
          required
        />

        {get(values, 'type', '') === 'percentage' && (
          <Field
            name="percentage"
            className="col-5"
            component={renderField}
            type="number"
            label={capitalize(I18n.get('percentage', 'Porcentaje'))}
            onChange={e => maxDecimals(e, `percentage`, 1)}
            required
          />
        )}
      </div>
    </div>
  )
}

export default PriceList;