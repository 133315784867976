import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { capitalize } from 'lodash';

import { renderField } from '../../fields/V0/Fields';

import { Icon } from '@alegradev/smile-ui-react';

const PanamaInfo = (props) => {
  const filterOnlyNumbers = (e, numberLength, splitPosition) => {
    let value = e.target.value.replace(/[^0-9]+/g, "");
    if (value.length >= (splitPosition + 1) && value.length <= (numberLength + 1)) {
      value = value.substring(0, splitPosition) + "-" + value.substring(splitPosition);
    }
    return value.length <= (numberLength + 1) ? value : value.slice(0, numberLength + 1);
  }
  return (
    <>
      <Field
        name="email"
        className="col-md-6"
        component={renderField}
        type="text"
        label={capitalize(I18n.get('e-email', 'Correo Electrónico'))}
      />
      <Field
        name="phonePrimary"
        className="col-md-6"
        component={renderField}
        type="text"
        label={capitalize(I18n.get('phone', 'Teléfono'))}
        onChange={(e) => filterOnlyNumbers(e, 7, 3)}
        placeholder={I18n.get('phone-placeholder', '_ _ _ - _ _ _ _')}
      />
      <Field
        name="mobile"
        className="col-md-6"
        component={renderField}
        type="text"
        label={capitalize(I18n.get('mobilePhone', 'Celular'))}
        onChange={(e) => filterOnlyNumbers(e, 8, 4)}
        placeholder={I18n.get('phone-placeholder', '_ _ _ _ - _ _ _ _')}
      />
    </>
  )
}
const DefaultInfo = (props) => {
  const [extraPhone, setExtraPhone] = useState(false);

  return (
    <>
      {
        props.country !== 'colombia' && (
          <Field
            name="email"
            className="col-md-6"
            component={renderField}
            type="text"
            label={capitalize(I18n.get('email', 'Correo'))}
          />
        )
      }


      {!extraPhone && (
        <div className="form-row d-flex align-items-end col-md-6">
          <Field
            name="phonePrimary"
            className="col-11"
            component={renderField}
            type="text"
            label={capitalize(I18n.get('phone', 'Teléfono'))}
          />

          <button
            type="button"
            className="btn button-transparent btn-sm col-1 mb-4 no-box-shadow p-0"
            onClick={() => setExtraPhone(true)}
          >
            <Icon icon='plus' extraClass="icon-primary" />
          </button>

        </div>
      )}

      {!!extraPhone && (
        <>
          <Field
            name="phonePrimary"
            className="col-md-6"
            component={renderField}
            type="text"
            label={capitalize(I18n.get('phone', 'Teléfono'))}
          />

          <div className="form-row d-flex align-items-end col-md-6">
            <Field
              name="phoneSecondary"
              className="col-11"
              component={renderField}
              type="text"
              label={capitalize(I18n.get('phone', 'Teléfono')) + ' 2'}
            />

            <button
              type="button"
              className="btn button-transparent btn-sm col-1 mb-4 no-box-shadow p-0"
              onClick={() => {
                props.form.change('phoneSecondary', null);
                setExtraPhone(false);
              }}
            >
              <Icon icon='minus' extraClass="icon-primary" />
            </button>
          </div>

        </>
      )}
    </>
  )
}

const RenderInfo = props => {
  const country = props.country;

  switch (country) {
    case 'panama':
      return <PanamaInfo {...props} />;
    default:
      return <DefaultInfo {...props} />;
  }
}

export default RenderInfo;