import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import { BigNumber } from 'bignumber.js';
import Loader from 'react-content-loader';
import dayjs from 'dayjs';

import { openModal } from '../../reducers/modals';
import { sendGTMEvent } from '../../reducers/company';
import {
  decimalPrecision,
  timezone as tzSelector,
  dateFormat as dateFormatSelector,
  country as countrySelector,
  idCompanySelector,
  companySelector
} from '../../selectors/company';
import {
  paymentsLoading,
  paymentsError,
  payments as paymentsSelector,
  baseAmount,
  startDate,
  isOpen,
} from '../../selectors/shifts';
import { get, lowerCase } from 'lodash';
import { Tooltip } from '@alegradev/smile-ui-react';
import { userIsElegibleForTip } from '../settings/utils';

import { Icon } from '@alegradev/smile-ui-react';

const textLoader = () => (
  <Loader title="" height={14} width={72}>
    <rect width='100%' height='100%' x="0" y="0" rx="5" ry="5" />
  </Loader>
)

const NewShiftsCurrent = ({ onShowList, loadShiftPayments }) => {
  const dispatch = useDispatch();
  const shiftOpen = useSelector(isOpen)
  const decimal = useSelector(decimalPrecision);
  const loading = useSelector(paymentsLoading);
  const error = useSelector(paymentsError);
  const payments = useSelector(paymentsSelector);
  const base = useSelector(baseAmount);
  const timezone = useSelector(tzSelector);
  const start = useSelector(startDate);
  const dateFormat = useSelector(dateFormatSelector);
  const country = useSelector(countrySelector);
  const idCompany = useSelector(idCompanySelector);
  const { registryDate } = useSelector(companySelector);

  const cash = new BigNumber(payments.cash);
  const debit = new BigNumber(payments.debit);
  const credit = new BigNumber(payments.credit);
  const transfer = new BigNumber(payments.transfer);
  const refunds = new BigNumber(payments.refunds);
  const inPayment = new BigNumber(payments.inPayment);
  const outPayment = new BigNumber(payments.outPayment);
  const totalsTipCash = new BigNumber(payments.tipsTotalCash);
  const totalsTipDebit = new BigNumber(payments.tipsTotalDebit);
  const totalsTipCredit = new BigNumber(payments.tipsTotalCredit);
  const totalsTipTransfer = new BigNumber(payments.tipsTotalTransfer);

  const totalsTipsOther = totalsTipDebit.plus(totalsTipCredit).plus(totalsTipTransfer);
  const totalSales = new BigNumber(0).plus(cash).plus(debit).plus(credit).plus(transfer);
  // const creditSales = new BigNumber(payments.creditSales);
  const total = new BigNumber(base)
    .plus(cash)
    .plus(debit)
    .plus(credit)
    .plus(transfer)
    .plus(inPayment)
    .plus(totalsTipCash)
    .plus(totalsTipsOther)
    .minus(refunds)
    .minus(outPayment)

  return (
    <div className="shifts__detail h-100 d-flex flex-column">
      <div className="shifts__detail-header mb-2 d-flex justify-content-between align-items-center">
        {shiftOpen && (
          <div>
            <p className="shifts__detail-header-title text-capitalize-first">
              {I18n.get('currentShiftDetails', 'Detalles del turno en curso')}
            </p>
            <p className="shifts__detail-header-description">
              {I18n.get('currentShiftDetails.description', 'Conoce los movimientos de efectivo en tu turno actual.')}{" "}
              <a className="text-decoration-underline" target="_blank" rel="noreferrer" href={I18n.get('learnToUseShifts.link')}>{I18n.get('learnToUseShifts', 'Aprende a usar los turnos')}</a>
            </p>
          </div>
        )}

        <div className="shifts__detail-header-actions d-flex justify-content-end align-items-center">
          {shiftOpen === true && (
            <button
              type="button"
              className="btn btn-submit text-nowrap"
              onClick={() => {
                try {
                  dispatch(openModal({ modal: 'newCloseShift', params: { attemptOrigin: "from shift history" } }))
                  dispatch(sendGTMEvent("shift-closing-attempted", {
                    attemptOrigin: "from shift history",
                    creationStatus: "success",
                  }))
                } catch {
                  dispatch(sendGTMEvent("shift-closing-attempted", {
                    attemptOrigin: "from shift history",
                    creationStatus: "failure",
                  }))
                }
              }}
            >
              {I18n.get('closeShift', 'cerrar turno')}
            </button>
          )}

          <button
            type="button"
            className="btn btn-submit d-block d-sm-none"
            onClick={() => onShowList()}
          >
            <Icon icon='list' extraClass="icon-white" />
          </button>
        </div>
      </div>

      {!!error && (
        <p className="text-capitalize-first text-danger pointer h4 mb-3" onClick={loadShiftPayments}>
          {I18n.get('thereIsAnErrorLoadingShiftValues', 'Hubo un error cargando los valores del turno.')}
          {' '}<span className="text-decoration-underline text-capitalize-first">{I18n.get('runtimeErrorMessage', 'por favor, intente de nuevo')}</span>
        </p>
      )}

      {shiftOpen === true
        ? (
          <>
            <div className="shifts__detail-body card mb-4">

              <div className="d-flex justify-content-between m-0 py-4 px-5 border-bottom">
                <div className="shifts__detail-body-card d-flex flex-column">
                  <strong className="shifts__detail-body-title text-capitalize-first">
                    {I18n.get('startDate', 'fecha de inicio')}
                  </strong>
                  <div className="shifts__detail-body-date">
                    {!!start ? dayjs.tz(start, timezone).format(!!dateFormat ? `${dateFormat.toUpperCase()} h:mm a` : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a')) : null}
                  </div>
                </div>
                {/* <div className="shifts__detail-body-card d-flex flex-column">
                  <strong className="shifts__detail-body-title text-capitalize-first">
                    {I18n.get('immediatePaymentSale', 'Ventas a contado')}
                  </strong>
                  <div className="h4">
                    {!!loading
                      ? textLoader()
                      : new BigNumber(cashPaymentSales).toFormat(decimal)}
                  </div>
                </div> */}
                {/* <div className="shifts__detail-body-card d-flex flex-column">
                  <strong className="shifts__detail-body-title text-capitalize-first">
                    {I18n.get('creditPaymentSales', 'Ventas a crédito')}
                  </strong>
                  <div className="h4">
                    {!!loading
                      ? textLoader()
                      : new BigNumber(creditSales).toFormat(decimal)}
                  </div>
                </div> */}
                <div className="shifts__detail-body-card d-flex flex-column">
                  <strong className="shifts__detail-body-title text-capitalize-first d-flex align-items-center">
                    <p className='mb-0 mr-2'>{I18n.get('total', 'total') + ' ' + lowerCase(I18n.get('of', 'de') + ' ' + I18n.get('sales', 'ventas'))}</p>
                    {
                      userIsElegibleForTip({country, idCompany, registryDate}) && (
                        <Tooltip
                          overlay={
                            I18n.get('HelpTooltipTotalSales','Corresponde a las ventas del turno, sin incluir propinas u otros movimientos de efectivo.')
                          }
                        >
                          <Icon icon='help' color='#00b19d ' />
                        </Tooltip>
                      )
                    }                    
                  </strong>
                  <div className="h4">
                    {!!loading
                      ? textLoader()
                      : totalSales.toFormat(decimal)}
                  </div>
                </div>

              </div>

              <div className="p-4 d-flex flex-column">

                <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                  <strong className="text-capitalize-first h4">{I18n.get('base', 'base inicial')}</strong>
                  <div className="h4">{new BigNumber(base).toFormat(decimal)}</div>
                </div>

                <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                  <strong className="text-capitalize-first h4">{I18n.get('cashSales', 'ventas en efectivo')}</strong>
                  {!!loading
                    ? textLoader()
                    : <div className="h4">{cash.toFormat(decimal)}</div>
                  }
                </div>

                <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                  <strong className="text-capitalize-first h4">{I18n.get('debitSales', 'ventas por tarjeta de débito')}</strong>
                  {!!loading
                    ? textLoader()
                    : <div className="h4">{debit.toFormat(decimal)}</div>
                  }
                </div>

                <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                  <strong className="text-capitalize-first h4">{I18n.get('creditSales', 'ventas por tarjeta de crédito')}</strong>
                  {!!loading
                    ? textLoader()
                    : <div className="h4">{credit.toFormat(decimal)}</div>
                  }
                </div>

                <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                  <strong className="text-capitalize-first h4">{I18n.get('transferSales', 'ventas por transferencias')}</strong>
                  {!!loading
                    ? textLoader()
                    : <div className="h4">{transfer.toFormat(decimal)}</div>
                  }
                </div>

                <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                  <strong className="text-capitalize-first h4">{I18n.get('refundCash', 'Devolución de dinero')}</strong>
                  {!!loading
                    ? textLoader()
                    : <div className="h4 text-danger">{refunds.eq(0) ? '' : '-'}{refunds.toFormat(decimal)}</div>
                  }
                </div>

                <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                  <strong className="text-capitalize-first h4">{I18n.get('invoiceCash', 'ingresos de efectivo')}</strong>
                  {!!loading
                    ? textLoader()
                    : <div className="h4">{inPayment.toFormat(decimal)}</div>
                  }
                </div>

                { (totalsTipCash.isGreaterThan(0) || totalsTipsOther.isGreaterThan(0)) &&
                <>
                  <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                    <strong className="text-capitalize-first h4">{I18n.get('shift.tipsCash', 'Propinas en efectivo')}</strong>
                    {!!loading
                      ? textLoader()
                      : <div className="h4">{totalsTipCash.toFormat(decimal)}</div>
                    }
                  </div>

                  <div className="d-flex align-items-center justify-content-between border-bottom my-2">
                    <strong className="text-capitalize-first h4">{I18n.get('shift.tipsOther', 'Propinas en otros medios')}</strong>
                    {!!loading
                      ? textLoader()
                      : <div className="h4">{totalsTipsOther.toFormat(decimal)}</div>
                    }
                  </div>
                </>
              }

                <div className="d-flex align-items-center justify-content-between pb-2 my-2" style={{ borderBottom: "2px solid #64748B" }}>
                  <strong className="text-capitalize-first h4">{I18n.get('expenseCash', 'retiros de efectivo')}</strong>
                  {!!loading
                    ? textLoader()
                    : <div className="h4 text-danger">{outPayment.eq(0) ? '' : '-'}{outPayment.toFormat(decimal)}</div>
                  }
                </div>

                {!!get(payments, 'currencies', null) && (
                  Object.keys(payments.currencies).map((key, index) => (
                    <div key={index} className="d-flex align-items-center justify-content-between">
                      <strong className="text-capitalize-first h2">{`${I18n.get('totalOfShiftMovements', 'Total de movimientos del turno')} ${key}`}</strong>
                      {!!loading
                        ? textLoader()
                        : <div className="h3">
                          {new BigNumber(get(payments, `currencies.${key}.total`)).toFormat(decimal, {
                            prefix: get(payments, `currencies.${key}.symbol`),
                            decimalSeparator: '.',
                            groupSeparator: ',',
                            groupSize: 3,
                          })}
                        </div>
                      }
                    </div>
                  ))
                )}

                <div className="d-flex align-items-center justify-content-between pt-2">
                  <strong className="text-capitalize-first h2">{I18n.get('totalOfShiftMovements', 'Total de movimientos del turno')}</strong>
                  {!!loading
                    ? textLoader()
                    : <div className="h3">{total.toFormat(decimal)}</div>
                  }
                </div>
              </div>

            </div>
            <div className="shifts__detail-body d-flex flex-row justify-content-between card mb-4 py-4">
              <div className="pl-4">
                <p className="m-0 shifts__detail-body-title-bold">{I18n.get('cashExpectedInClosingShift', 'Dinero esperado en caja')}</p>
                <p className="m-0">{I18n.get('cashInClosingShift.description', 'Base inicial más las ventas e ingresos en efectivo, menos las devoluciones y retiros.')}</p>
              </div>
              <div className="shifts__detail-body-total-cash pr-4 pt-3">
                {new BigNumber(base).plus(cash).plus(inPayment).plus(totalsTipCash).minus(outPayment).minus(refunds).toFormat(decimal)}
              </div>
            </div>
          </>

        )
        : (
          <div className="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
            <p className="text-capitalize-first text-center">
              {I18n.get('actualShiftIsClosed', 'abre un nuevo turno para continuar haciendo operaciones')}
            </p>

            <button
              type="button"
              className="btn btn-submit text-nowrap"
              onClick={() => {
                try {
                  dispatch(openModal({ modal: 'openShift', params: { attemptOrigin: "from shift history" } }))
                  dispatch(sendGTMEvent("shift-opening-attempted", {
                    attemptOrigin: "from shift history",
                    creationStatus: "success",
                  }))
                } catch {
                  dispatch(sendGTMEvent("shift-opening-attempted", {
                    attemptOrigin: "from shift history",
                    creationStatus: "failure",
                  }))
                }
              }}
            >
              {I18n.get('openShift', 'abrir turno')}
            </button>
          </div>
        )
      }
    </div>
  )
}

export default NewShiftsCurrent;