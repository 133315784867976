function peruInitialValues({
  contact,
  defaultAccountReceivableCategory,
  defaultDebtToPayCategory,
}) {
  if (!!contact) {
    return {
      ...contact,
      ...contact?.nameObject,
      firstName: contact?.nameObject?.firstName || contact?.name,
      identification: {
        ...contact?.identificationObject,
        number: contact?.identificationObject
          ? contact?.identificationObject?.number
          : contact?.identification?.number,
        type: contact?.identificationObject
          ? contact?.identificationObject?.type?.key
            ? contact?.identificationObject?.type?.key
            : contact?.identificationObject?.type
          : contact?.identification?.type,
      },
      email: contact?.email,
      address: {
        ...contact?.address,
        combined: `${contact?.address?.district}, ${contact?.address?.city}, ${contact?.address?.state}, ${contact?.address?.ubigeoCode}`,
      },
      seller: contact?.seller?.id ? contact?.seller?.id : contact?.seller,
      priceList: contact?.priceList?.id
        ? contact?.priceList?.id
        : contact?.priceList,
      term: contact?.term?.id ? contact?.term?.id : contact?.term,
      statementAttached: contact?.statementAttached,
      accounting: {
        accountReceivable: contact?.accounting?.accountReceivable?.id,
        debtToPay: contact?.accounting?.debtToPay?.id,
      },
    };
  }

  return {
    accounting: {
      accountReceivable: defaultAccountReceivableCategory?.id,
      debtToPay: defaultDebtToPayCategory?.id,
    },
    address: {
      country: 'PER',
    },
    identification: {
      type: 'RUC',
    },
    statementAttached: true,
    ignoreRepeated: false,
  };
}

export default peruInitialValues;
