import React from "react";
import PropTypes from "prop-types";
import { I18n } from "@aws-amplify/core";

import { Icon } from '@alegradev/smile-ui-react';

const Bottom = ({ onClose, closeText, submitText, disabled, submitting, onConfirm, hideCancel, submitId, hideRequired = false, onResetForm, resetForm, saveText, disableCancel }) => (
  <div
    className="modal__bottom w-100 px-5 py-4 d-flex flex-column flex-sm-row justify-content-between 
    align-items-sm-center align-items-start border-top"
  >
    {hideRequired ? (
      <div className="required-message mb-3 mb-sm-0"></div>
    ) : (
      <div className="required-message mb-3 mb-sm-0">
        <i className="text-primary mr-2">* </i>
        <span className="text-capitalize-first text-muted">{I18n.get("requiredFields", "Campos obligatorios")}</span>
      </div>
    )}

    <div className="modal__bottom-buttons d-flex">
      {!hideCancel && (
        <button 
        type="button" 
        className="btn btn-cancel text-capitalize-first" 
        onClick={() => onClose()}
        disabled={disableCancel}
        >
          {closeText || I18n.get("cancel", "cancelar")}
        </button>
      )}

      {!!onResetForm && (
        <button
          id="test"
          type="submit"
          className="btn btn-cancel text-capitalize-first"
          disabled={!!submitting || !!disabled}
          onClick={() => {
            onResetForm(true);
          }}
        >
          {(submitting && resetForm) ? <Icon icon='loader-2' animated extraClass=" icon-white" /> : saveText || I18n.get("saveAndCreateNew", "Guardar y crear nueva")}
        </button>
      )}

      <button
        id={submitId}
        type={!!onConfirm ? "button" : "submit"}
        data-testid={`modal-${!!onConfirm ? "confirm" : "submit"}-btn`}
        className="btn btn-submit text-capitalize-first d-flex align-items-center justify-content-center"
        disabled={!!submitting || !!disabled}
        onClick={!!onConfirm ? () => onConfirm() : null}
      >
        {(submitting && !resetForm) ? <Icon icon='loader-2' animated extraClass=" icon-white" /> : submitText || I18n.get("save", "guardar")}
      </button>
    </div>
  </div>
);

Bottom.propTypes = {
  closeText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  onConfirm: PropTypes.func,
  hideCancel: PropTypes.bool,
  hideRequired: PropTypes.bool,
  submitId: PropTypes.string,
};

export default Bottom;
