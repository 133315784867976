import {
  Avatar,
  Button,
  Grid,
  Toast,
  Typography,
} from '@alegradev/smile-ui-react';
import { useContactForm } from '../contact/form/FormProvider';
import { capitalize, get } from 'lodash';
import { I18n } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { closeSideModal } from '../../../reducers/sideModals';
import { setClient } from '../../../reducers/activeInvoice';
import useConnectionStatus from '../../../hooks/useConnectionStatus/hook';
import { useContact } from '../contact/ContactProvider';
import { hasPermissionTo } from '../../../selectors/auth';
import { useEffect } from 'react';

function DuplicatedContactModal() {
  const { Wrapper } = Grid;
  const dispatch = useDispatch();
  const isOnline = useConnectionStatus();
  const can = useSelector(hasPermissionTo);
  const { contact, isDuplicatedAlertOpen, setIsDuplicatedAlertOpen } =
    useContactForm();
  const { toggleIgnoreRepeated } = useContact();
  const error = useSelector((state) =>
    get(state, 'sideModals.contact.params.error', null)
  );

  useEffect(() => {
    if (error?.includes('existe un contacto')) {
      setIsDuplicatedAlertOpen(true);
    }
  }, [error]);

  if (!isDuplicatedAlertOpen) return null;

  return (
    <Wrapper
      padding={{ lg: 'lg' }}
      direction={{ lg: 'column' }}
      align={{ lg: 'center' }}
      justify={{ lg: 'between' }}
      extraClasses='duplicated-contact-modal'
    >
      <Wrapper direction={{ lg: 'column' }} fullWidth gap={10}>
        <Typography
          type='body-1-bold'
          text={I18n.get('newClient', 'Nuevo cliente')}
        />

        <Toast
          direction='vertical'
          shadow={false}
          type='warning'
          title='¡Ya existe un contacto con esta identificación!'
          description='El numero de identificación que ingresaste se encuentra asociado a:'
        />
      </Wrapper>
      <Wrapper gap={20} direction={{ lg: 'column' }} align={{ lg: 'center' }}>
        <Avatar size={120} variant='secondary' text={contact?.name} />
        <Wrapper direction={{ lg: 'column' }} align={{ lg: 'center' }}>
          <Typography
            type='body-2-bold'
            text={capitalize(contact?.name) || ''}
          />
          {contact?.identificationObject && !contact?.identification && (
            <Typography
              type='body-3-regular'
              text={`${get(contact, 'identificationObject.type', '')}. ${get(contact, 'identificationObject.number', '')}`}
              variant='tertiary'
            />
          )}
          {contact?.identification && !contact?.identificationObject && (
            <Typography
              type='body-3-regular'
              text={get(contact, 'identification', '')}
              variant='tertiary'
            />
          )}
          {get(contact, 'email') && (
            <Typography
              type='body-3-regular'
              text={get(contact, 'email')}
              variant='tertiary'
            />
          )}
          {get(contact, 'mobile') && (
            <Typography
              type='body-3-regular'
              text={get(contact, 'mobile')}
              variant='tertiary'
            />
          )}
        </Wrapper>
        {isOnline && can('edit', 'contacts') && (
          <Button
            leftIcon='pencil'
            emphasis='text'
            text={I18n.get('editCurrentContact', 'Editar este contacto')}
            onClick={() => setIsDuplicatedAlertOpen(false)}
          />
        )}
      </Wrapper>

      <Wrapper extraClasses='w-100' gap={16}>
        <Button
          leftIcon='add'
          emphasis='outline'
          full
          size='large'
          text={I18n.get(
            'duplicatedIdentificationContinueButton',
            'Crear cliente duplicado'
          )}
          onClick={() => [
            toggleIgnoreRepeated(),
            setIsDuplicatedAlertOpen(false),
          ]}
        />
        <Button
          full
          size='large'
          text={I18n.get('sellCurrentContact', 'Vender al contacto existente')}
          onClick={() => {
            dispatch(setClient(contact));
            dispatch(closeSideModal({ sideModal: 'contact' }));
          }}
        />
      </Wrapper>
    </Wrapper>
  );
}

export default DuplicatedContactModal;
