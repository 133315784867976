import React, { useRef } from 'react';
import { Field } from 'react-final-form';
import { get } from 'lodash';

import { Icon } from '@alegradev/smile-ui-react';

const VariantsImage = ({ values, form, index, disabled }) => {
  const imageInputRef = useRef()

  return (

    <Field
      name="image"
    >
      {(fieldProps) => (

        <div className="image-container">
          {!!get(values, `itemVariants.${index}.image`) && (
            <img
              onClick={(e) => {
                e.stopPropagation()
                imageInputRef.current.click()
              }}
              src={get(values, `itemVariants.${index}.image`)}
              alt=""
              className="w-100 h-100 bg-white"
            />
          )}

          <div
            onClick={(e) => {
              e.stopPropagation()
              imageInputRef.current.click()
            }}
            className="item-view__empty-image w-100 h-100 d-flex align-items-center justify-content-center position-absolute"
          >
            <Icon icon='tag' className="icon-gray icon x2" />
          </div>

          <input
            {...fieldProps.input}
            type="file"
            onClick={e => e.stopPropagation()}
            onChange={(e) => {
              let file = e.target.files[0], reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = ev => {
                form.change(`itemVariants.${index}.image`, ev.target.result)
                form.change(`itemVariants.${index}.imageFile`, file)
              }
            }}
            ref={imageInputRef}
            className="w-100 h-100 d-none"
            disabled={disabled}
          />
        </div>
      )}
    </Field>
  )
}

export default VariantsImage;
