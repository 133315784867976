import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from '@alegradev/smile-ui-react';

let ChartWrapper = ({ chart: Chart, title, data, loading, error, total, fetchData }) => {

  return (
    <div className="card my-3">
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <p className="text-capitalize-first mb-0 mr-2 h3">
            {title}
          </p>
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn button-transparent"
              type="button"
              onClick={() => fetchData()}
            >
              <Icon icon='rotate' extraClass="icon-primary" />
            </button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <Chart
          data={data}
          loading={loading}
          error={error}
          total={total}
        />
      </div>
    </div>
  )
}

ChartWrapper.propTypes = {
  chart: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  title: PropTypes.string,
  data: PropTypes.array, 
  loading: PropTypes.bool, 
  error: PropTypes.bool, 
  total: PropTypes.number, 
  fetchData: PropTypes.func
}

export default ChartWrapper;