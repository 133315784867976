import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Typography from '../Typography';
import { Icon } from '@alegradev/smile-ui-react';

/**
 * A global toast component for consistent text styling throughout the application.
 *
 * @typedef {'error' | 'success' | 'info' | 'neutral' | 'warning'} ToastType
 *
 * @param {object} props
 * @param {ToastType} props.type     // The type of the toast (error, success, info, neutral)
 * @param {string} props.title      // The main title or heading of the toast
 * @param {boolean} props.shadow      // Show or not the container shadow
 * @param {string} props.description // The detailed description or content of the toast
 * @param {boolean} props.closable   // Indicates whether the toast can be closed by the user
 * @param {object} props.action    // An optional action to be triggered in response to the toast
 *
 * @returns {JSX.Element} A React element representing the styled text.
 */
function Toast({
  title,
  description,
  type = 'success',
  closable,
  shadow = true,
  action,
}) {
  const renderIcon = () => {
    switch (type) {
      case 'error':
        return <Icon icon='ban' className='icon-danger' />;
      case 'info':
        return <Icon icon='info-circle' className='icon-indigo' />;
      case 'warning':
        return <Icon icon='alert-triangle' className='icon-amber' />;
      default:
        return <Icon icon='circle-check' className='icon-green' />;
    }
  };

  return (
    <div
      className={`toast-container toast-container-${type} ${
        shadow && 'toast-container-shadow'
      }`}
    >
      <div className='toast-container-body'>
        {type !== 'neutral' && (
          <div className='toast-container-type-icon'>{renderIcon()}</div>
        )}
        <div className='toast-container-body-information'>
          {!!title && (
            <Typography
              text={parse(title)}
              type='body-3-bold'
              variant={type !== 'neutral' ? 'primary' : 'inverse'}
            />
          )}
          {!!description && (
            <Typography
              text={parse(description)}
              type='body-3-regular'
              variant={type !== 'neutral' ? 'secondary' : 'inverse'}
            />
          )}
        </div>
      </div>
      {closable && <div className='toast-container-close-icon'></div>}
      {action && (
        <div className='toast-container-action' onClick={action?.handler}>
          <Typography type='label-2' variant='warning' text={action?.title} />
        </div>
      )}
    </div>
  );
}

Toast.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.oneOf(['error', 'success', 'info', 'neutral', 'warning']),
  closable: PropTypes.bool,
  shadow: PropTypes.bool,
  action: PropTypes.shape({
    title: PropTypes.string,
    handler: PropTypes.func,
  }),
};

export default Toast;
