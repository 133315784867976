import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux'
import { capitalize, get } from 'lodash'
import { useHistory } from 'react-router-dom';

import { removeItem, updateItem as updateItemReducer } from '../../../reducers/items';
import alegraAPI from '../../../reducers/alegraAPI';
import { hasPermissionTo } from '../../../selectors/auth';
import { handleError } from '../../../utils/errors'
import { toast } from '../../../utils'

import { Tooltip, Icon } from '@alegradev/smile-ui-react';

const Actions = ({ item, onRefresh, startLoading, stopLoading }) => {
  const can = useSelector(hasPermissionTo);
  const history = useHistory();
  const dispatch = useDispatch();

  const deleteItem = async () => {
    startLoading()
    try {
      await alegraAPI.delete(`/items/${item.id}`);
      stopLoading()
      toast.success({
        title: I18n.get('itemDeleted', 'producto eliminado con éxito')
      })
      dispatch(removeItem(item.id));

      onRefresh()
    } catch (error) {
      stopLoading()
      let responseError = get(error, 'response.data.message');
      toast.error({
        title: I18n.get('itemDeletedError', 'error eliminando producto'),
        subtitle: !!responseError ? responseError : handleError(error)
      })
    }
  }

  const updateItem = async isActive => {
    startLoading()
    try {
      let newItem = await alegraAPI.put(`/items/status?id=${item.id}`, { status: !!isActive ? 'active' : 'inactive' });

      stopLoading()
      toast.success({
        title: isActive
          ? I18n.get('itemActivated', 'Item activado con éxito')
          : I18n.get('itemDeactivated', 'Item desactivado con éxito')
      })

      dispatch(updateItemReducer({
        id: newItem.data.id,
        changes: newItem.data,
      }));
      onRefresh()
    } catch (error) {
      stopLoading()
      let responseError = get(error, 'response.data.message');
      toast.error({
        title: I18n.get('itemStatusError', 'error al cambiar estado del item'),
        subtitle: !!responseError ? responseError : handleError(error)
      })
    }
  }

  const openEdit = async () => {
    history.push(`/items/edit/${item.id}`);
  }

  return (
    <div className="d-flex align-items-center justify-content-start table-gray-actions">
      <Tooltip
        visible={!can('edit', 'items')}
        overlay={I18n.get('userNotAllowed.items.edit', 'no tienes permisos para editar productos')}
      >
        <button
          type="button"
          className="btn button-transparent"
          disabled={!can('edit', 'items')
            || !get(item, 'editable', true)
          }
          onClick={() => openEdit()}
          title={capitalize(I18n.get('edit', 'editar'))}
        >
            <Icon icon='pencil' />
        </button>
      </Tooltip>

      <Tooltip
        visible={!can('edit', 'items')}
        overlay={I18n.get('userNotAllowed.items.edit', 'no tienes permisos para editar productos')}
      >
        <button
          type="button"
          className="btn button-transparent"
          disabled={!can('edit', 'items') || get(item, 'type') === 'variant'}
          onClick={() => updateItem(!(get(item, 'status') === 'active'))}
          title={get(item, 'status') === 'active'
            ? capitalize(I18n.get('deactivate', 'desactivar'))
            : capitalize(I18n.get('activate', 'activar'))
          }
        >
          {get(item, 'status') === 'active'
            ? <Icon icon='lock-open' extraClass="icon-primary" />
            : <Icon icon='lock' extraClass="icon-primary" />
          }
        </button>
      </Tooltip>

      <Tooltip
        visible={!can('delete', 'items')}
        overlay={I18n.get('userNotAllowed.items.delete', 'no tienes permisos para eliminar productos')}
      >
        <button
          type="button"
          disabled={!can('delete', 'items')
            || !get(item, 'deletable', true)
          }
          className="btn button-transparent button-remove"
          title={capitalize(I18n.get('delete', 'eliminar'))}
          onClick={() => deleteItem()}
        >
          <Icon icon='trash' />
        </button>
      </Tooltip>
    </div>
  )
}

export default Actions;