import { createDraftSafeSelector } from '@reduxjs/toolkit';

const monitoringDataSelector = state => state.monitoring; 

export const calculateActionTimeSelector = createDraftSafeSelector(
  monitoringDataSelector,
  monitoring => (action) => {
    console.log(monitoring)
    const actionState = monitoring[action];

    if(actionState) {
      const diff = actionState.endTime - actionState.startTime;
      const diffSec = diff > 1000 ? diff / 1000 : 0; 
      return Math.floor(diffSec);
    }

    return null;
  }
)