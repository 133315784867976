import React, { useMemo, useState } from "react";
import BigNumber from "bignumber.js";
import { Field } from "react-final-form";
import { useSelector } from "react-redux";
import { I18n } from "@aws-amplify/core";
import { capitalize, get, has, isEmpty } from "lodash";

import { filter } from "../../../database/itemsDB";
import { useFormat } from "../../../hooks/useFormat";
import { renderField, renderSelect } from "../fields/V0/Fields";
import { styleBigNumber } from "../../../utils";

import MinimalTable from "../../common/MinimalTable";
import Modal from '../../common/Modal';
import Bottom from "../../modals/common/Bottom";

import { Icon } from '@alegradev/smile-ui-react';
import { activeInventoryAdjustmentsNumerations } from "../../../selectors/inventoryAdjustmentsNumerations";

const OBJECTIVES = [
  {
    key: "in",
    label: "increment",
  },
  {
    key: "out",
    label: "decrement",
  },
];

const InventoryAdjustmentModal = ({ isOpen, onRequestClose, onConfirm }) => {
  return (
    <Modal
      title={I18n.get("changeCostConfirmationModal.title", "Ajuste del costo promedio")}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal__inventory-adjustment"
    >
      <div className="modal__inventory-adjustment-body d-flex flex-column align-items-center justify-content-center text-center p-5" style={{ gap: "2rem" }}>
        <div
          className="d-flex align-items-center justify-content-center mt-5"
          style={{ width: "40px", height: "40px", background: "#FEF3C7", borderRadius: "29px" }}>
          <Icon icon='alert-triangle' />
        </div>
        <p className="h1">{I18n.get("changeCostConfirmationModal.content.title", "¿Deseas ajustar el costo?")}</p>
        <p className="h3 mb-5">{I18n.get("changeCostConfirmationModal.content.body", "Al cambiar el costo en una disminución de inventario pueden generarse diferencias en tus reportes de rentabilidad.")}</p>
      </div>
      <Bottom
        onClose={onRequestClose}
        onConfirm={onConfirm}
        submitText={I18n.get("changeCostConfirmationModal.confirm", "Sí, deseo ajustar el costo")}
        hideRequired
      />
    </Modal>
  )
}

const InventoryAdjustment = ({ form, values }) => {
    const { fmt, decimal } = useFormat();
  const numerations = useSelector(activeInventoryAdjustmentsNumerations);
  const defaultNumeration = numerations.find((numeration) => get(numeration, 'isDefault') === true)
  const [openModal, setOpenModal] = useState(false);
  const [selectedRowName, setSelectedRowName] = useState("");

  const addItem = () => {
    form.mutators.push("items");
  };

  const formItems = get(form.getFieldState('items'), 'value', []).map((row) => +get(row, 'item.id'));

  const filterItems = async ({ text, categories, limit }) => {
    const allItems = await filter({ text, categories });

    return allItems.filter((item) =>
      (get(item, 'type') === 'product'
        || get(item, 'type') === 'simple'
        || get(item, 'type') === 'variant')
      && get(item, 'inventory.availableQuantity', null) !== null
      && !formItems.includes(+get(item, 'id'))
    );
  }

  const columns = useMemo(() => [
    {
      header: () => (
        <div style={{ textAlign: "start" }}>
          {capitalize(I18n.get("product", "Producto"))}
        </div>
      ),
      Render: ({ row }, name) => {
        if (!has(row, 'item')) {
          return (
            <div style={{ width: "20rem" }}>
              <Field
                name={`${name}.item`}
                component={renderSelect}
                className="m-0"
                placeholder={(
                  <div className="d-flex align-items-center g-3">
                    <Icon icon='search' color="#C3C3C3" />
                    <p className="m-0 ml-2">
                      {I18n.get("addItem", "Agregar producto")}
                    </p>
                  </div>
                )}
                loadOptions={async text => await filterItems({ text, categories: [], limit: 10 })}
                defaultOptions
                value={null}
                getOptionLabel={option => {
                  return (
                    <div className="d-flex align-items-center justify-content-between p-1">
                      <p className="h4 text-truncate m-0">
                        {`${option.name}${!!get(option, 'reference.reference')
                          ? ` (${get(option, 'reference.reference')})` : !!get(option, 'reference')
                            ? ` (${get(option, 'reference')})` : ''}`
                        }
                      </p>
                    </div>
                  )
                }}
                getOptionValue={option => option.id}
                menuPosition="absolute"
                hideDropdownIndicator
                required
              />
            </div>
          )
        }
        return (
          <div
            className='d-flex align-items-center'
            style={{ gap: '2rem', textAlign: 'start' }}
          >
            <div className='new-inventory-adjustment-image-container'>
              {!!get(row, 'item.images.length') ? (
                <img
                  src={get(
                    row,
                    `item.images.${get(row, `item.images.length`, 1) - 1}.url`
                  )}
                  alt=''
                  className='w-100 h-100 bg-white'
                />
              ) : !!get(row, 'images.length') ? (
                <img
                  src={get(
                    row,
                    `images.${get(row, `images.length`, 1) - 1}.url`
                  )}
                  alt=''
                  className='w-100 h-100 bg-white'
                />
              ) : (
                <div className='item-view__empty-image w-100 h-100 d-flex align-items-center justify-content-center'>
                  <Icon icon='tag' extraClass='icon-gray icon x2' />
                </div>
              )}
            </div>
            <div className='d-flex flex-column' style={{ gap: '0.5rem' }}>
              <p className='h5 font-weight-bold'>{`${get(row, 'item.name') ?? get(row, 'name')}`}</p>
              <p className='h5 text-muted'>{`${I18n.get('currentQuantity', 'Cantidad actual')}: ${get(row, 'inventory.availableQuantity') ?? get(row, 'item.inventory.availableQuantity')}`}</p>
            </div>
          </div>
        );
      }
    },
    {
      header: capitalize(I18n.get("cost", "Costo")),
      Render: ({ row }, name) => {
        if (!(get(row, 'objective.key') === 'out') || get(row, 'allowCostChange'))
          return (
            <div style={{ width: "15rem" }}>
              <Field
                name={`${name}.cost`}
                component={renderField}
                className="m-0"
                initialValue={get(row, 'cost') || get(row, 'item.inventory.unitCost')}
                type="number"
                disabled={!get(row, 'item')}
                required
              />
            </div>
          )

        return (
          <Field
            name={`${name}.cost`}
            render={() => (
              <div
                className="form-control d-flex align-items-center justify-content-between pointer"
                style={{ gap: "1rem", width: "15rem", background: "#e9ecef" }}
                onClick={() => {
                  setOpenModal(true);
                  setSelectedRowName(name);
                }}
              >
                <Icon icon='pencil' />
                <p
                  className="text-truncate m-0 styled-hover"
                  hovercontent={get(row, 'item.inventory.unitCost')}
                >
                  {get(row, 'item.inventory.unitCost')}
                </p>
              </div>
            )}
          />
        )
      },
      // width: "8rem"
    },
    {
      header: capitalize(I18n.get("objective", "Objetivo")),
      Render: ({ row }, name) => {
        return (
          <div style={{ width: "13rem" }}>
            <Field
              name={`${name}.objective`}
              component={renderSelect}
              className="m-0"
              options={OBJECTIVES}
              defaultValue={OBJECTIVES[0]}
              getOptionLabel={(option) => I18n.get(option.label, option.label)}
              getOptionValue={(option) => option.key}
              isSearchable={true}
              menuPosition="absolute"
              disabled={!get(row, 'item')}
              required
            />
          </div>
        )
      },
      // width: "100rem"
      // width: "17%",
    },
    {
      header: capitalize(I18n.get("quantity", "Cantidad")),
      Render: ({ row }, name) => {
        const incrementQuantity = () => {
          form.change(`${name}.quantity`, +form.getFieldState(`${name}.quantity`)?.value + 1)
        }

        const decrementQuantity = () => {
          if (+form.getFieldState(`${name}.quantity`)?.value > 1)
            form.change(`${name}.quantity`, +form.getFieldState(`${name}.quantity`)?.value - 1)
          return;
        }

        const quantity = row?.quantity || 1;

        return (
          <div style={{ width: "15rem" }}>
            <Field
              name={`${name}.quantity`}
              className="m-0"
              component={renderField}
              initialValue={quantity}
              placeholder="0"
              type="number"
              incrementIcon
              onIncrement={() => incrementQuantity()}
              onDecrement={() => decrementQuantity()}
              disabled={!get(row, 'objective') || !get(row, 'item')}
              validate={value => {
                if (parseInt(value) <= 0)
                  return 'noZeroAllowed'
              }}
              hideErrorLabel
              required
            />
          </div>
        )
      },
    },
    {
      header: capitalize(I18n.get("finalQuantity", "Cantidad final")),
      Render: ({ row }) => {
        const currentQuantity = +(get(row, 'inventory.availableQuantity') ?? get(row, 'item.inventory.availableQuantity', 0));
        const finalQuantity = get(row, 'objective.key') === 'in' ? currentQuantity + (+get(row, 'quantity', 0)) : currentQuantity - (+get(row, 'quantity', 0));
        return (
          <div style={{ width: "8rem" }}>
            <p className="h4 text-muted text-truncate styled-hover m-0 p-0" hovercontent={finalQuantity || "-"}>
              {finalQuantity || '-'}
            </p>
          </div>
        )
      },
      // width: "10",
    },
    {
      header: capitalize(I18n.get("adjustedTotal", "Total Ajustado")),
      Render: ({ row }) => {
        const totalAdjusted = new BigNumber(get(row, 'cost', 0) * get(row, 'quantity', 0) * (get(row, 'objective.key') === 'in' ? 1 : -1));
        const totalAdjustedFormatted = totalAdjusted.toFormat(decimal, fmt);
        const {
          intPart: totalIntPart,
          decPart: totalDecPart
        } = styleBigNumber(totalAdjustedFormatted, decimal);
        return (
          <div
            className="h4 text-truncate styled-hover d-flex m-0 p-0"
            hovercontent={totalAdjustedFormatted}
            style={{ width: "10rem" }}
          >
            <p className="text-truncate m-0">
              {totalIntPart}
            </p>
            <p className="text-truncate text-muted m-0">
              {totalDecPart}
            </p>
          </div>
        )
      },
      // width: "10%",
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [decimal, fmt, form])


  return (
    <div className="form-body h-100 w-100 overflow-hidden pl-5 pb-5 pr-5 pt-0">
      <div className="form-body__fields form-row justify-content-between px-3">
        <Field
          name="numeration"
          className="col-md-4"
          label={I18n.get("numeration", "Numeracion")}
          component={renderSelect}
          options={numerations}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          isLoading={isEmpty(numerations)}
          defaultValue={defaultNumeration}
          isSearchable={true}
          menuPosition="absolute"
          required
        />
        {/* <Field
          name="warehouse"
          className="col-md-4"
          label={I18n.get("warehouse", "Bodega")}
          component={renderSelect}
          options={warehouses}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          isLoading={isEmpty(warehouses)}
          defaultValue={defaultWarehouse}
          isSearchable={true}
          menuPosition="absolute"
          required
        /> */}
        <Field
          name="observations"
          className="col-md-7"
          label={I18n.get("observations", "Observaciones")}
          component={renderField}
          type="text"
        />
      </div>
      <MinimalTable
        className="px-3 mt-5"
        title={I18n.get("itemsToAdjust", "Productos a ajustar")}
        columns={columns}
        data={values}
        formTable
      />
      <button
        className="btn btn-secondary primary"
        type="button"
        style={{ border: "none", outline: "none", boxShadow: "none" }}
        onClick={() => addItem()}
      >
        <Icon icon='plus' extraClass="icon icon-primary mr-2" />
        {I18n.get("addAnotherItem", "Agregar otro producto")}
      </button>

      <InventoryAdjustmentModal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        onConfirm={() => {
          form.change(`${selectedRowName}.allowCostChange`, true)
          setOpenModal(false)
        }}
      />
    </div>
  )
}

export default InventoryAdjustment;