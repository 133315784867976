import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { country, ivaConditionSelector } from './company'
import { numeration } from './activeInvoice'
import { clientsSelector } from '../reducers/clients'

const clientState = state => get(state, 'clients', null);export const activeClients = createDraftSafeSelector(
  clientsSelector.selectAll,
  clients => !!clients
    ? clients.filter(client => get(client, 'status') === 'active')
    : []
)

export const syncingClient = createDraftSafeSelector(
  clientState,
  clients => get(clients, 'synchronizingClient', false)
)

export const clientsFilterText = createDraftSafeSelector(clientState, state => get(state, 'filters.text', ''));

export const getClientById = id => createDraftSafeSelector(
  state => clientsSelector.selectById(state, id),
  client => client
)

export const invoiceClients = createDraftSafeSelector(
  [activeClients, country, ivaConditionSelector, numeration],
  (clients, country, ivaCondition, invoiceNumeration) => {
    try {
      const numerationSubType = get(invoiceNumeration, 'subDocumentType')
      switch (country) {
        case "argentina":
          // Compañía: Responsable inscrito
          if (ivaCondition === 'IVA_RESPONSABLE') {
            // Numeración tipo A
            if (numerationSubType === 'INVOICE_A')
              return clients.filter(client => (
                get(client, 'ivaCondition') === 'IVA_RESPONSABLE'
                || get(client, 'ivaCondition') === 'UNIQUE_TRIBUTE_RESPONSABLE'
              ))
            // Numeración tipo B
            if (numerationSubType === 'INVOICE_B')
              return clients.filter(client => (
                get(client, 'ivaCondition') === 'FINAL_CONSUMER'
                || get(client, 'ivaCondition') === 'IVA_EXEMPT'
              ))
          // Compañía: Monotributista o Exento
          } else if (ivaCondition === 'UNIQUE_TRIBUTE_RESPONSABLE' || ivaCondition === 'IVA_EXEMPT') {
            // Numeración tipo C
            if (numerationSubType === 'INVOICE_C')
              return clients.filter(client => (
                get(client, 'ivaCondition') === 'FINAL_CONSUMER'
                || get(client, 'ivaCondition') === 'IVA_RESPONSABLE'
                || get(client, 'ivaCondition') === 'UNIQUE_TRIBUTE_RESPONSABLE'
                || get(client, 'ivaCondition') === 'IVA_EXEMPT'
              ))
          }
          
          return clients
        case "republicaDominicana":
          // Numeración electrónica E31 no mostrar consumidor final
          if (!!invoiceNumeration && invoiceNumeration.prefix === 'E31') {
            return clients.filter(client => get(client, 'name') !== 'Consumidor final')
          }

          return clients;
        case "spain":
          if (!!invoiceNumeration && invoiceNumeration.documentType === 'invoice') {
            return clients.filter(
              (client) => get(client, 'name') !== 'Público en general'
            );
          }
          return clients;
        case 'panama':
          return clients.filter(client => client.thirdsType !== 'GOVERNMENT');
        default:
          return clients;
      }
    } catch {
      return clients
    }
  }
)
