import React, { useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';
import Modal from 'react-modal';
import Cropper from 'react-easy-crop';
import { Field } from 'react-final-form';
import { get } from 'lodash';

import { logo as logoSelector, name as nameSelector } from '../../../../selectors/company';
import getCroppedImg from './cropImage'
import ViewContract from './ViewContract';

import { Icon } from '@alegradev/smile-ui-react';

const Logo = props => {
  const name = useSelector(nameSelector)
  const logo = useSelector(logoSelector)
  const [modalOpen, setModalOpen] = useState({ logo: false, contract: false });
  const [loading, setLoading] = useState(false);
  const [validFile, setValidFile] = useState(false);
  const [image, setImage] = useState(null);
  const [showOptions, setshowOptions] = useState(false);
  const [profileImage, setProfileImage] = useState(!!logo ? logo : null);
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const inputComponent = useRef();
  const maxZoom = 10;
  const minZoom = 0.1;
  const zoomSpeed = 0.2;

  const close = (type) => {
    setModalOpen(prev => ({ ...prev, [type]: false }));
    setValidFile(false);
  }

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      setLoading(true);
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      )
      setProfileImage(croppedImage);
      props.form.change('logo', croppedImage);
      setLoading(false);
      close('logo');
    } catch {
      setLoading(false);
    }
  }, [croppedAreaPixels, rotation, image, props])

  return (
    <>
      <div className="form-group d-flex col-12 align-items-center p-0">
        <div className="new-company-logo-container">
          {!!profileImage
            ? <img className="profile-logo" src={profileImage} alt={name} />
            : (
              <div className="profile-logo-empty">
                <h3 className="text-capitalize-first">{I18n.get('useMyLogo', 'utilizar mi logo')}</h3>
                <p className="text-capitalize-first h4">{I18n.get('logoImageSize', '178x53 pixeles')}</p>
              </div>
            )
          }

          <div className="logo-selector">
            <div className="logo-button" onClick={() => setshowOptions(!showOptions)}>
              <Icon icon='camera' extraClass="icon-primary" />
              <Field
                name="logo"
                component="input"
                type="hidden"
              />
              <input
                style={{ display: 'none' }}
                ref={inputComponent}
                type="file"
                accept=".png,.jpg,.jpeg"
                onClick={event => {
                  event.target.value = null;
                  setCrop({ x: 0, y: 0 })
                  setZoom(1)
                  setCroppedAreaPixels(null)
                }}
                onChange={event => {
                  if (!!get(event, 'target.files.0.type', null)) {
                    const fileType = get(event, 'target.files.0.type');
                    if (/^image\/(png|jpeg|jpg)$/.test(fileType)) {
                      setImage(URL.createObjectURL(event.target.files[0]))
                      setValidFile(true);
                    } else { setValidFile(false); }

                    setModalOpen(prev => ({ ...prev, logo: true }));
                  }
                }}
              />
            </div>
            {showOptions && (
              <div className="logo-options-dropdown bg-white">
                <p onClick={() => inputComponent.current.click()}>{I18n.get('uploadImage', 'Subir imagen')}</p>
                <p className="text-muted d-none" style={{ cursor: 'default' }}>{I18n.get('deleteImage', 'Eliminar imagen')}</p>
              </div>
            )}
          </div>
        </div>
        <div>
          <p className="m-0" style={{ fontSize: '16px' }}>{I18n.get('logoSubtitle', 'Se mostrará en la parte superior de tus facturas de venta.')}</p>
          <p className="m-0 text-muted" style={{ fontSize: '14px' }}>
            {I18n.get('logoSubtitle.text1', 'Si necesitas ayuda, puedes conocer')}{" "}
            <a href="https://ayuda.alegra.com/es/agrega-el-logo-de-tu-empresa-en-alegra-pos-colombia" target="_blank" rel="noreferrer">
              {I18n.get('logoSubtitle.text2', 'cómo agregar tu logo')}
            </a>.
          </p>
        </div>
        <Modal
          isOpen={modalOpen.logo}
          onRequestClose={() => close('logo')}
          className="logo-editor-modal"
        >
          <div className="logo-editor-component">
            <div className="logo-editor">
              {validFile ? (
                <Cropper
                  image={image}
                  crop={crop}
                  rotation={rotation}
                  zoom={zoom}
                  minZoom={minZoom}
                  maxZoom={maxZoom}
                  zoomSpeed={zoomSpeed}
                  restrictPosition={false}
                  aspect={10 / 3}
                  onCropChange={setCrop}
                  onRotationChange={setRotation}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />) : (
                <div className="warning-message">
                  {I18n.get('yourLogoMustHaveAnImageFormat', 'El logo debe tener formato de imagen (.png, .jpg o .jpeg)')}
                </div>
              )
              }
              {validFile && (
                <div className="image-actions">
                  <button
                    type="button"
                    className="image-action"
                    disabled={zoom >= maxZoom}
                    onClick={() => zoom < maxZoom ? setZoom(Math.min(zoom + zoomSpeed, maxZoom)) : null}
                  >
                    <Icon icon='plus' />
                  </button>
                  <button
                    type="button"
                    className="image-action"
                    disabled={zoom <= minZoom}
                    onClick={() => zoom > minZoom ? setZoom(Math.max(zoom - zoomSpeed, minZoom)) : null}
                  >
                    <Icon icon='minus' />
                  </button>
                </div>
              )}
            </div>

            <div className="bottom">
              <div className="bottom__buttons">
                {validFile && (
                  <button
                    type="button"
                    className="btn btn-cancel"
                    onClick={() => close('logo')}
                  >
                    {I18n.get('cancelAction', 'cancelar')}
                  </button>
                )}

                <button
                  type="button"
                  className="btn btn-submit"
                  disabled={loading}
                  onClick={() => validFile ? showCroppedImage() : close()}
                >
                  {loading
                    ? <Icon icon='loader-2' animated extraClass=" icon-white" />
                    : validFile
                      ? I18n.get('save', 'guardar')
                      : I18n.get('accept', 'aceptar')
                  }
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div>
        <ViewContract
          setLoading={setLoading}
          close={close}
          loading={loading}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen} />
      </div>
    </>
  )
}

export default Logo;
