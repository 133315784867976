import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';

import { Icon } from '@alegradev/smile-ui-react';

const WizardBottom = props => (
  <div className="bottom w-100 p-3 d-flex justify-content-between align-items-center">
    <div className="bottom-buttons d-flex justify-content-between w-100">
      <button
        type="button"
        className={`btn btn-cancel text-capitalize-first ${!!props.onPrevious ? 'visible' : 'invisible'}`}
        onClick={() => props.onPrevious()}
      >
        {props.previousText || I18n.get('goBack', 'volver')}
      </button>

      <button
        id={props.nextId}
        type={!!props.nextAsSubmit ? "submit": "button"}
        className="btn btn-submit absolute-center text-capitalize-first"
        disabled={props.nextDisabled || props.submitting}
        onClick={!!props.onNext ? () => props.onNext(): null}
      >
        {props.submitting
          ? <Icon icon='loader-2' animated extraClass=" icon-white" />
          : props.nextText || I18n.get('next', 'siguiente')
        }
      </button>
    </div>
  </div>
);

WizardBottom.propTypes = {
  previousText: PropTypes.string,
  onPrevious: PropTypes.func,
  nextText: PropTypes.string,
  nextDisabled: PropTypes.bool,
  onNext: PropTypes.func,
  submitting: PropTypes.bool,
  nextAsSubmit: PropTypes.bool,
  nextId: PropTypes.string
}

export default WizardBottom;
