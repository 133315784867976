import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';
import { Icon, Tag } from '@alegradev/smile-ui-react';

import Typography from '../../../../components/common/Typography';
import { useGlobalInvoiceDetail } from '../context';
import { periodicity } from '../../../../components/forms/globalInvoice/utils';
import { capitalize, find, get } from 'lodash';
import { formatDate } from '../../../../utils/date';
import { globalInvoiceStatusTag } from '../../main/table/columns';
import { useDispatch, useSelector } from 'react-redux';
import { country as countrySelector } from '../../../../selectors/company';
import { basicPOSClientData } from '../../../../utils';
import Button from '../../../../components/common/Button';
import { APIGraphqlSelector } from '../../../../selectors/app';
import { deleteGlobalInvoiceMutation } from '../../main/services/mutations';
import { openModal } from '../../../../reducers/modals';
import { downloadFileFromUrl, printPDFFromUrl } from '../../../../utils/pdf';

function GlobalInvoiceDetailHeader({ noBreadcrump }) {
  const { data } = useGlobalInvoiceDetail();
  const country = useSelector(countrySelector);
  const client = basicPOSClientData(country);
  const history = useHistory();
  const dispatch = useDispatch();
  const APIGraphql = useSelector(APIGraphqlSelector);
  const { mutate } = deleteGlobalInvoiceMutation();

  const handleEdit = () => {
    history.push(`/global-invoices/invoice/${data?.id}`);
  };

  const handleDelete = (id) => {
    mutate({ id, api: APIGraphql, dispatch });
  };

  const detailActions = () => {
    const actions = [];

    if (
      ['draft', 'open'].includes(get(data, 'status')) &&
      !get(data, 'stamp.uuid', null)
    ) {
      actions.push(
        <Button variant='ghost' size='icon' onClick={() => handleEdit()}>
          <Icon icon='pencil' />
        </Button>
      );

      actions.push(
        <Button
          variant='ghost'
          size='icon'
          onClick={() =>
            dispatch(
              openModal({
                modal: 'confirmAction',
                params: {
                  onSuccess: () => handleDelete(data?.id),
                  title: I18n.get(
                    'cofirmDeleteGlobalInvoiceTitle',
                    '¿Deseas eliminar tu factura global?'
                  ),
                  description: I18n.get(
                    'cofirmDeleteGlobalInvoiceDescription',
                    'Al confirmar que deseas eliminar, tu <b>Factura global {{invoice}}</b> borrará de forma definitiva de tus documentos'
                  ).replace('{{invoice}}', get(data, 'numberTemplate.number')),
                  successButton: I18n.get(
                    'cofirmGlobalInvoiceDeletion',
                    'Sí, eliminar factura'
                  ),
                  cancelButton: I18n.get(
                    'cancelGlobalInvoiceDeletion',
                    'Conservar factura'
                  ),
                },
              })
            )
          }
        >
          <Icon icon='trash' />
        </Button>
      );
    }

    if (!!get(data, 'stamp.uuid')) {
      actions.push(
        <Button
          variant='ghost'
          size='icon'
          onClick={() => printPDFFromUrl(get(data, 'pdf'))}
        >
          <Icon icon='printer' />
        </Button>
      );
      actions.push(
        <Button
          variant='ghost'
          size='icon'
          onClick={() =>
            downloadFileFromUrl(
              get(data, 'pdf'),
              `Factura global ${get(data, 'numberTemplate.number')}.pdf`
            )
          }
        >
          <Icon icon='file-type-pdf' />
        </Button>
      );
    }

    return actions;
  };

  const getGlobalInvoiceStatus = () => {
    const isStamped = get(data, 'stamp.uuid', null);
    if (isStamped) {
      return (
        <Tag
          label={globalInvoiceStatusTag.success?.title ?? ''}
          type={globalInvoiceStatusTag.success?.color ?? 'slate'}
        />
      );
    }
    return (
      <Tag
        label={
          globalInvoiceStatusTag[get(data, 'status', 'draft')]?.title ?? ''
        }
        type={
          globalInvoiceStatusTag[get(data, 'status', 'draft')]?.color ?? 'slate'
        }
      />
    );
  };

  return (
    <>
      <div className='mb-4'>
        {noBreadcrump && (
          <div className='w-100 d-flex mb-5 justify-content-between'>
            <div>
              <Typography
                type='body-1-bold'
                variant='secondary'
                text={I18n.get('', 'Factura global #{{invoice}}').replace(
                  '{{invoice}}',
                  get(data, 'numberTemplate.number')
                )}
              />
              <Typography
                type='body-3-regular'
                variant='tertiary'
                text={I18n.get(
                  '',
                  'Creada el {{date}} | Versión CFDI {{version}}'
                )
                  .replace(
                    '{{date}}',
                    formatDate(get(data, 'date'), 'dd/MM/yyyy')
                  )
                  .replace('{{version}}', get(data, 'version', '4.0') || '4.0')}
              />
            </div>
            <div className='d-flex'>{detailActions()}</div>
          </div>
        )}
        <div className='w-100 d-flex justify-content-between'>
          <Typography
            type='body-1-bold'
            variant='secondary'
            text={I18n.get('', 'Información general')}
          />
          {!noBreadcrump && (
            <Typography
              type='body-3-regular'
              variant='tertiary'
              text={I18n.get('', 'Versión CFDI {{version}}').replace(
                '{{version}}',
                get(data, 'version') || '4.0'
              )}
            />
          )}
        </div>
        <div className='row w-100'>
          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('', 'Cliente')}
            />
            <div className='mt-1' />
            <Typography
              type='body-3-regular'
              variant='secondary'
              text={get(client, 'name')}
            />
          </div>
          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('', 'RFC')}
            />
            <div className='mt-1' />
            <Typography
              type='body-3-regular'
              variant='secondary'
              text={get(client, 'identification')}
            />
          </div>
          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('', 'Fecha de creación')}
            />
            <div className='mt-1' />
            <Typography
              type='body-3-regular'
              variant='secondary'
              text={formatDate(get(data, 'date'), 'dd/MM/yyyy')}
            />
          </div>
          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('', 'Periodicidad')}
            />
            <div className='mt-1' />
            <Typography
              type='body-3-regular'
              variant='secondary'
              text={
                find(periodicity, { value: get(data, 'periodicity.period') })
                  ?.label
              }
            />
          </div>
          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('', 'Mes / Año')}
            />
            <div className='mt-1' />
            <Typography
              type='body-3-regular'
              variant='secondary'
              text={capitalize(formatDate(get(data, 'date'), 'MMMM / yyyy'))}
            />
          </div>
          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('', 'Estado')}
            />
            <div className='mt-1' />
            {getGlobalInvoiceStatus()}
          </div>
        </div>
      </div>
      <div className='mb-4 mt-5'>
        <Typography
          type='body-1-bold'
          variant='secondary'
          text={I18n.get('', 'Tickets seleccionados')}
        />
      </div>
    </>
  );
}

export default GlobalInvoiceDetailHeader;
