import React, { useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { Icon } from '@alegradev/smile-ui-react';

let Accordion = props => {
  const ref = useRef();
  const [showScrollbar, setShowScrollbar] = useState(false);

  useLayoutEffect(() => {
    if (ref.current?.clientHeight < ref.current?.scrollHeight)
      setShowScrollbar(true)
    else {
      setShowScrollbar(false)
    }
  }, [ref.current?.clientHeight, ref.current?.scrollHeight])

  return (
    <div
      className={props.containerClassName}
      style={props.containerStyle}
    >
      <div
        className={"pointer d-flex justify-content-between align-items-center " + props.headerClassName}
        style={props.headerStyle}
        onClick={props.onToggle}
      >
        {props.header}
        {props.isOpen && <Icon icon='chevron-up' />}
        {!props.isOpen && <Icon icon='chevron-down' />}
      </div>

      {!!props.subheader && (
        <div
          className={props.subheaderClassName}
          style={props.subheaderStyle}
        >
          {props.subheader}
        </div>
      )}

      <div
        className={props.bodyClassName}
        style={{
          maxHeight: props.isOpen ? '500px' : '0px',
          overflowY: showScrollbar ? 'scroll' : 'hidden',
          transition: 'max-height .4s ease-in-out',
          ...props.bodyStyle
        }}
        ref={ref}
      >
        {props.body}
      </div>
    </div>
  )
}

Accordion.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  containerClassName: PropTypes.string,
  carretClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  subheaderClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  containerStyle: PropTypes.object,
  carretStyle: PropTypes.object,
  headerStyle: PropTypes.object,
  subheaderStyle: PropTypes.object,
  bodyStyle: PropTypes.object,
}

export default Accordion;