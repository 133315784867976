import dayjs from "dayjs";
import { COUNTRIES } from "../../utils/enums/countries";
const idsCompaniesProduction = [
  '1455936',
  '1456627'
];
const enviroment = process.env.REACT_APP_ALEGRA_COOKIE === 'al-test-days-of-thunder'
  ? 'testing'
  : 'production'

const deployGroupDate = dayjs('2023-06-30')

export const userIsElegibleForTip = ({ country, registryDate, idCompany }) => {
  return country === COUNTRIES.COLOMBIA || country === COUNTRIES.COSTA_RICA
}