import React from 'react'
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { useDispatch, useSelector } from 'react-redux'
import { capitalize, get } from 'lodash'

import * as mutations from '../../../graphql/mutations'
import { hasPermissionTo } from '../../../selectors/auth';
import { updateSeller as updateSellerReducer, removeSeller } from '../../../reducers/sellers';
import { handleError } from '../../../utils/errors'
import { toast } from '../../../utils'
import { APIGraphqlSelector } from '../../../selectors/app';

import { Icon, Tooltip } from '@alegradev/smile-ui-react';

const Actions = ({ seller, onRefresh, onView, startLoading, stopLoading }) => {
  const can = useSelector(hasPermissionTo);
  const dispatch = useDispatch();
  const APIGraphql = useSelector(APIGraphqlSelector);

  const deleteSeller = async () => {
    startLoading()
    try {
      await APIGraphql(graphqlOperation(mutations.deleteSeller, {
        id: seller.id
      }))
      stopLoading()
      toast.success({
        title: I18n.get('sellerDeleted', 'vendedor eliminado con éxito')
      })
      dispatch(removeSeller(seller.id));

      onRefresh()
    } catch (error) {
      stopLoading()
      toast.error({
        title: I18n.get('sellerDeletedError', 'error eliminando vendedor'),
        subtitle: handleError(error)
      })
    }
  }
  
  const updateSeller = async isActive => {
    startLoading()
    try {
      let newSeller = { ...seller, status: !!isActive ? 'active': 'inactive' }
      delete newSeller.channel
      await APIGraphql(graphqlOperation(mutations.updateSeller, {
        seller: newSeller
      }))
      stopLoading()
      toast.success({
        title: isActive
          ? I18n.get('sellerActivated', 'vendedor activado con éxito')
          : I18n.get('sellerDeactivated', 'vendedor desactivado con éxito')
      })

      dispatch(updateSellerReducer({
        id: newSeller.id,
        changes: newSeller,
      }));
      onRefresh()
    } catch (error) {
      stopLoading()
      toast.error({
        title: I18n.get('sellerStatusError', 'error cambiando estado del vendedor'),
        subtitle: handleError(error)
      })
    }
  }

  return (
    <div className="d-flex align-items-center justify-content-start table-actions">
      <button
        type="button"
        className="btn button-transparent"
        title={capitalize(I18n.get('detail', 'detalle'))}
        onClick={() => onView()}
      >
        <Icon icon='eye' extraClass="icon-primary"/>
      </button>

      <Tooltip
        visible={!can('edit', 'pos-station') || !can('toggle-status', 'sellers')}
        overlay={I18n.get('userNotAllowed.sellers.edit', 'no tienes permisos para editar vendedores')}
      >
        <button
          type="button"
          className="btn button-transparent"
          disabled={!can('edit', 'pos-station') || !can('toggle-status', 'sellers')}
          onClick={() => updateSeller(!(get(seller, 'status') === 'active'))}
          title={get(seller, 'status') === 'active'
            ? capitalize(I18n.get('deactivate', 'desactivar'))
            : capitalize(I18n.get('activate', 'activar'))
          }
        >
          {get(seller, 'status') === 'active'
            ? <Icon icon='lock-open' extraClass="icon-primary"/>
            : <Icon icon='lock' extraClass="icon-primary"/>
          }
        </button>
      </Tooltip>

      <Tooltip
        visible={!can('delete', 'sellers')}
        overlay={I18n.get('userNotAllowed.sellers.delete', 'no tienes permisos para eliminar vendedores')}
      >
        <button
          type="button"
          disabled={!can('delete', 'sellers')}
          className="btn button-transparent button-remove"
          title={capitalize(I18n.get('delete', 'eliminar'))}
          onClick={() => deleteSeller()}
        >
          <Icon icon='x' extraClass="icon-danger"/>
        </button>
      </Tooltip>
    </div>
  )
}

export default Actions;