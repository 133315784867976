import React from 'react';
import { I18n } from '@aws-amplify/core'
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { get } from 'lodash';

import { openModal } from '../../../reducers/modals';
import { sendGTMEvent } from '../../../reducers/company';

import { country as countrySelector } from '../../../selectors/company';

import { Icon } from '@alegradev/smile-ui-react';

const Bottom = props => {
  const country = useSelector(countrySelector)
  const history = useHistory();
  const dispatch = useDispatch();
  const referenceMaxLimit = country === 'costaRica' && get(props, 'values.reference.length', 0) > 20

  const handleClick = () => {
    dispatch(sendGTMEvent("item-creation-attempted", {
      creationStatus: props.hasValidationErrors ? "failure" : "success",
      creationOrigin: "advanced form",
    }))
  }

  return (
    <>
      <div className="p-md-3 mb-3">
        <div className="justify-content-between align-items-center d-flex flex-row w-100">
          <button
            type="button"
            className="btn btn-cancel text-capitalize-first mr-2 w-100"
            onClick={() => !Cookies.get('_item-creation-abort')
              ? dispatch(openModal({ modal: 'cancelItemCreation' }))
              : history.goBack()
            }
          >
            {I18n.get('cancel', 'cancelar')}
          </button>

          <button
            type="submit"
            data-testid="submit-btn"
            className="btn btn-submit absolute-center text-capitalize-first w-100"
            disabled={(!!props.submitting || !!props.disabled || referenceMaxLimit)}
            onClick={handleClick}
          >
            {props.submitting
              ? <Icon icon='loader-2' animated extraClass=" icon-white" />
              : I18n.get('save', 'guardar')
            }
          </button>
        </div>
      </div>
    </>
  )
}

export default Bottom