import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { find, get, } from 'lodash';
import { Form } from "react-final-form"
import { checkStationValues } from '../../../reducers/app';
import { clear, createRefund, setClient } from '../../../reducers/activeRefund';
import { fetchRefunds } from '../../../reducers/refunds';
import { closeModal } from '../../../reducers/modals';
import { getShiftPayments } from '../../../reducers/shifts';
import { getRefundNumerationsElectronic, getRefundNumerationsSaleTicket } from '../../../selectors/numerations';
import Modal from '../../common/Modal';
import Payments from './WizardSteps/Payments';
import ControlStepsButton from './ControlStepsButton';
import { toast } from '../../../utils';
import {
  availableCash,
  isOpen as shiftOpenSelector,
} from '../../../selectors/shifts';
import {
  country as countrySelector,
  shiftsEnabled as shiftsEnabledSelector,
  decimalPrecision
} from '../../../selectors/company';
import { stationCashBank as stationCashBankSelector } from '../../../selectors/app';
import { items, subtotal as subtotalSelector, tipSelector } from '../../../selectors/activeRefund';
import { formError, handleError } from '../../../utils/errors';
import { sendNewGTMEvent } from '../../../reducers/company';
import { validate, transform, getPrevStep } from './utils';
import Notification from '../common/Notification';
import { total as totalSelector, isSelectingItems } from '../../../selectors/activeRefund';
import EditItem from '../../forms/newRefund/EditItem';
import { getLegalStatusKey } from '../../invoices/utils';
import { Icon } from '@alegradev/smile-ui-react';
import Tip from '../../forms/newRefund/Tip';
import { endAction, startAction } from '../../../reducers/monitoring';
import { calculateActionTimeSelector } from '../../../selectors/monitoring';
import useSendGTMEvent from '../../../hooks/useSendGtmEvent/hook';

const getLegalStatus = (refund) => {
  const legalStatus = getLegalStatusKey(refund)
  return !!legalStatus ? legalStatus : 'legalStatus.toBeIssued'
}

const getTypeRefund = (method) => {
  if (method === 'creditToSales') return 'invoice';
  return method;
}

const getInvoiceType = (invoice) => {
  if (!invoice) return '';
  if (get(invoice, 'numberTemplate.documentType') === 'saleTicket') {
    const isElectronic = get(invoice, 'numberTemplate.isElectronic', false);
    return isElectronic ? 'docPOS' : 'saleTicket';
  }
  
  return get(invoice, 'numberTemplate.documentType', '');
}


const NewRefunds = () => {
  const total = useSelector(totalSelector)
  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    get(state, 'modals.newRefunds.isOpen', false)
  );
  useSendGTMEvent(isOpen, 'pos-refund-started');
  const [step, setStep] = useState(0);
  const [editItemIsOpen, setEditItemIsOpen] = useState(false);
  const [editTipIsOpen, setEditTipIsOpen] = useState(false);
  const [itemIndex, setItemIndex] = useState(null);
  const [method, setMethod] = useState(null);
  const subtotal = useSelector(subtotalSelector)
  const itemsInRefund = useSelector(items);
  const stepFromInvoice = useSelector(state => get(state, 'modals.newRefunds.params.step', 0));
  const methodFromInvoice = useSelector(state => get(state, 'modals.newRefunds.params.method', null));
  const invoiceSelected = useSelector(state => get(state, 'modals.newRefunds.params.invoice', null));
  const shiftOpen = useSelector(shiftOpenSelector);
  const shiftsEnabled = useSelector(shiftsEnabledSelector);
  const shiftCash = useSelector(availableCash);
  const stationCashBank = useSelector(stationCashBankSelector);
  const decimal = useSelector(decimalPrecision)
  const isAllItemsPupaleted = useSelector(state => get(state, 'activeRefund.isAllItemsPupaleted', false));
  const isSelectingMode = useSelector(isSelectingItems);
  const tip = useSelector(tipSelector);
  const calculateActionTime = useSelector(calculateActionTimeSelector);

  const numerationsEletronic = useSelector(getRefundNumerationsElectronic);
  const numerationsSaleTicket = useSelector(getRefundNumerationsSaleTicket);

  const prevStep = getPrevStep(step, method);

  const getNumerations = useCallback(() => {
    const typeNumeration = get(invoiceSelected, 'numberTemplate.documentType', null);
    if (typeNumeration === 'saleTicket') return numerationsSaleTicket;
    if (typeNumeration === 'invoice') return numerationsEletronic;
    return [];
  }, [numerationsSaleTicket, numerationsEletronic, invoiceSelected]);

  const getInvoiceRefundNumeration = useCallback(() => {
    const numerations = getNumerations(invoiceSelected)
    const numeration = numerations.length === 1 ? numerations[0] : find(numerations, { isDefault: true });
    return { name: get(numeration, 'name'), id: get(numeration, 'id') }
  }, [getNumerations, invoiceSelected])

  const openEditItem = (index) => {
    setItemIndex(index);
    setEditItemIsOpen(true);
  }

  const openEditTip = () => {
    setEditTipIsOpen(true);
  }

  const initialRefundMemoized = useMemo(() => ({
    refund: {
      client: get(invoiceSelected, 'client'),
      numeration: getInvoiceRefundNumeration(),
      document: { ...invoiceSelected },
      date: get(invoiceSelected, 'date'),
      step: 1,
      method: {
        value: 'creditToSales',
        label: I18n.get('creditToSales', 'Crédito a ventas'),
      }
    }
  }), [invoiceSelected, getInvoiceRefundNumeration])

  useEffect(() => {
    return () => {
      dispatch(clear());
    }
  }, [])

  useEffect(() => {
    if (get(invoiceSelected, 'client.id')) {
      dispatch(setClient(invoiceSelected.client));
    }
  }, [invoiceSelected])

  useEffect(() => {
    if (!isOpen) {
      setStep(0)
      setMethod(null)
      dispatch(clear())
    } else if (shiftsEnabled && shiftOpen) dispatch(getShiftPayments());
  }, [isOpen, shiftsEnabled, shiftOpen, dispatch])

  useEffect(() => {
    setStep(stepFromInvoice)
  }, [stepFromInvoice])

  useEffect(() => {
    setMethod(methodFromInvoice)
  }, [methodFromInvoice])

  const selectMethod = (newMethod) => {
    setStep(1);
    setMethod(newMethod);
  }

  const changeStep = useCallback((newStep) => {
    setStep(newStep);
  }, []);

  const onCloseModal = () => {
    dispatch(clear());
    dispatch(closeModal({ modal: 'newRefunds' }));
  }

  const submit = async (values, tip) => {
    if (method !== 'positiveBalance' && step !== 4) return
    if (method === 'positiveBalance' && step !== 3) return
    const transformedValues = transform(values, { tip, decimal, subtotal });

    try {
      dispatch(startAction({action: 'createRefund'}));
      const canContinue = await dispatch(
        checkStationValues({ type: 'refund' })
      );
      if (!canContinue) return;

      let subtitleText = I18n.get('refundCreatedMessage.creditNote', 'Tu nota de crédito se registró con éxito.');
      if (get(transformedValues, 'numeration.documentType', '') === 'incomeAdjustmentNote') 
        subtitleText = I18n.get('refundCreatedMessage.adjustNote', 'Tu nota de ajuste de ingreso se registró con éxito.');
      const refundCreated = await dispatch(createRefund(transformedValues));
      dispatch(clear());
      dispatch(closeModal({ modal: 'newRefunds' }));
      toast.success({
        title: I18n.get(
          'refundCreated',
          'Devolución creada 🎉'
        ),
        subtitle: subtitleText,
        params: {
          position: 'top-right'
        }
      });

      const emissionErrorMessage = get(refundCreated, 'emissionErrorMessage', '');
      const invoiceType = getInvoiceType(get(transformedValues, 'invoices[0]', false));
      const creditNoteType = get(transformedValues, 'type', '');

      dispatch(endAction({action: 'createRefund'}));

      dispatch(
        sendNewGTMEvent('pos-refund-created', {
          error: 'no error',
          responseTime: calculateActionTime("createRefund"),
          type: getTypeRefund(method),
          creditNoteType: creditNoteType === null ? '' : creditNoteType,
          invoiceType: invoiceType === null ? '' : invoiceType,
          emissionStatus: get(transformedValues, 'numeration.isElectronic', false) 
                          ? I18n.get(getLegalStatus(refundCreated))
                          : '',
          emissionError: emissionErrorMessage === null ? '' : emissionErrorMessage
        })
      );
      dispatch(fetchRefunds());
    } catch (error) {
      const parseError = handleError(error);
      dispatch(
        sendNewGTMEvent('pos-refund-created', {
          error: parseError
        })
      );
      return formError(
        error,
        I18n.get(
          'createRefundError',
          'hubo un error a la hora de crear la devolución'
        )
      );
    }
  };

  const getShowNotificationError = (method, submitError, step) => {
    if (method === 'positiveBalance') {
      return !!submitError && step === 3
    } else {
      return !!submitError && step === 4
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      className={`modal__refunds-new ${step === 0 ? '' : `step-${step}`} ${method ? `method-${method}` : ''}`}
      includeHeader={false}
      id='refund-modal-portal'
    >
      <Form
        initialValues={!!invoiceSelected ? initialRefundMemoized : {}}
        validate={values => validate(values, { total, tip, step, shiftOpen, shiftsEnabled, shiftCash, stationCashBank, itemsInRefund, isAllItemsPupaleted })}
        onSubmit={values => submit(values, tip)} >
        {
          ({ handleSubmit, form, submitError, errors, submitting }) => (
            <form onSubmit={handleSubmit} className='h-100'>
              <div className='form-body p-md-4 h-100 d-flex flex-column'>
                <div className='d-flex'>
                  {(step > 2 && !isSelectingMode) && (
                    <button
                      type='button'
                      disabled={submitting}
                      className='button-go-back-arrow d-block d-md-none'
                      onClick={() => changeStep(prevStep)}>
                      <Icon icon='arrow-left' extraClass="icon-black mr-2" />           
                    </button>
                  )}
                  <h3 className='title-new-refund'>
                    {I18n.get('newRefund', 'nueva devolución')}
                  </h3>
                </div>
                
                <div className='container-body-error'>
                  <div className='d-flex flex-column'>
                    <Payments
                      step={step}
                      method={method}
                      onChooseMethod={selectMethod}
                      changeStep={changeStep}
                      reset={form.reset}
                      openEditItem={openEditItem}
                      openEditTip={openEditTip}
                    />
                  </div>

                  <Notification isOpen={getShowNotificationError(method, submitError, step)} text={submitError} useDangerouslySetInnerHTML={true} />
                </div>


                <ControlStepsButton
                  closeModal={onCloseModal}
                  method={method}
                  step={step}
                  disabled={!!Object.keys(errors).length || submitting}
                  lastStepError={get(errors, 'refund.amount', false)}
                  submitting={submitting}
                  editItemIsOpen={editItemIsOpen}
                  changeStep={changeStep}
                />
              </div>
            </form>
          )
        }
      </Form>
      <EditItem isOpen={editItemIsOpen} selectedIndex={itemIndex} onClose={() => setEditItemIsOpen(false)} />
      <Tip isOpen={editTipIsOpen} onClose={() => setEditTipIsOpen(false)} />
    </Modal>
  );
};

export default NewRefunds;
