import React from 'react';
import { I18n } from '@aws-amplify/core';
import { get, isString } from 'lodash';

import Modal from '../common/Modal';
import Typography from '../../../common/Typography';
import Button from '../../../common/Button';
import { Icon } from '@alegradev/smile-ui-react';

const getContactIdentification = (contact, country) => {
  if (
    country === 'colombia' ||
    country === 'costaRica' ||
    country === 'argentina'
  )
    return `${get(contact, 'identificationObject.type', null)} ${get(contact, 'identificationObject.number', null)}`;
  if (country === 'peru')
    return `${get(contact, 'identification.type', null) || get(contact, 'identificationObject.type', null)} ${get(contact, 'identification.number', null) || get(contact, 'identificationObject.number', null)}`;
  if (country === 'republicaDominicana') {
    const hasIdentificationType = !!(
      get(contact, 'identification.type', null) ||
      get(contact, 'identificationObject.type', null)
    );
    return hasIdentificationType
      ? `${get(contact, 'identification.type', null) || get(contact, 'identificationObject.type', null)} ${get(contact, 'identification.number', null) || get(contact, 'identificationObject.number', null)}`
      : `${get(contact, 'identification', null)}`;
  }
  return `${get(contact, 'identification', null)}`;
};

const getContactName = (contact, country) => {
  const name = get(contact, 'name', null);
  if (!name) return null;

  if (isString(name)) return name;

  if (country === 'colombia')
    return `${get(name, 'firstName', null)} ${get(name, 'secondName', null)} ${get(name, 'lastName', null)}`;
  return null;
};

const ContactCreated = ({
  isOpen,
  onDuplicatedPressed,
  contact,
  country,
  onSelected,
  onCancel,
}) => {
  const handleSelectContact = () => {
    if (onSelected) onSelected(contact);
  };

  const handleCancelContactCreation = () => {
    if(onCancel && contact?.id) {
      onCancel(contact.id);
    }
  }

  return (
    <Modal isOpen={isOpen} newDesing>
      <div className='side-modal-information-body-info'>
        <div className='d-flex gap-2 align-items-center'>
          <Icon icon='alert-triangle' color='#FBBF24' />
          <Typography
            type='heading-4'
            text={I18n.get(
              'duplicatedIdentificationTitle',
              'Identificación duplicada'
            )}
          />
        </div>
        <Typography
          type='body-3-regular'
          withHtml
          text={I18n.get(
            'duplicatedIdentificationDescription',
            `La identificación <b>{{identification}}</b> está asociada a <b>{{name}}</b>. Puedes continuar creando el contacto duplicado o venderle al cliente existente.`
          )
            .replace(
              '{{identification}}',
              getContactIdentification(contact, country)
            )
            .replace('{{name}}', getContactName(contact, country))
            .replace(
              contact?.offline ? 'está asociada a <b>null</b>' : 'NO_ACTION_TEXT_PLACEHOLDER',
              'ya esta asociada a un cliente'
            )}
        />
      </div>

      <div className='side-modal-information-body-actions'>
        <Button variant='outline' onClick={() => onDuplicatedPressed()}>
          <Typography
            type='label-1'
            text={I18n.get(
              'duplicatedIdentificationContinueButton',
              'Crear contacto duplicado'
            )}
          />
        </Button>
        {!contact?.offline && (
          <Button onClick={() => handleSelectContact()}>
            <Typography
              type='label-1'
              text={I18n.get(
                'duplicatedIdentificationSellExistingContact',
                'Vender al cliente existente'
              )}
            />
          </Button>
        )}
        {contact?.offline && (
          <Button onClick={handleCancelContactCreation}>
            <Typography type='label-1' text={I18n.get('cancel', 'Cancelar')} />
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default ContactCreated;
