import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import _ from 'lodash';

import { toast } from '../../../../utils';
import { filter, findBarcode } from '../../../../reducers/items';
import { addItem } from '../../../../reducers/editInvoice';

import { Icon } from '@alegradev/smile-ui-react';

const Search = () => {
  const [type, setType] = useState('text');
  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  const search = useCallback((value) => {
    dispatch(filter({ text: value, searchCategories: false, limit: 30 }));
  }, [dispatch])

  const resetSearch = useCallback(() => search(''), [search]);

  useEffect(() => {
    resetSearch()
  }, [resetSearch])
  
  const searchBarcode = async () => {
    const item = await findBarcode(value);
    if (!!item) {
      dispatch(addItem(item))
      setValue('')
    } else {
      toast.error({
        title: I18n.get('noProductsSatisfySearch', 'No hay productos que satisfacen la búsqueda'),
        params: { autoClose: 3000 }
      })
    }
  }

  // eslint-disable-next-line
  const debounce = useCallback(
    _.debounce((value) => {
      search(value);
    }, 500),
    []
  );
  
  const handleChange = (event) => {
    setValue(event.target.value);
    if (type === 'text') {
      debounce(event.target.value);
    }
  };

  const handleKeyDown = (event) => {
    if (type === 'barcode') {
      if ((event.key === 'Tab' || event.key === ' ')) 
        event.preventDefault();
      if ((event.key === 'Enter' || event.key === 'Tab' || event.key === ' '))
        searchBarcode()
    }
  };

  const changeType = type => {
    setType(type)
    setValue('')
    resetSearch()
  }

  return (
    <div className="search d-flex justify-content-between mb-2">

      <div className="search-bar d-flex">
        <button
          type="button"
          className={`btn btn-primary px-2 ${type === 'text' ? 'active' : ''}`}
          onClick={() => changeType('text')}
        >
          <Icon icon='search' extraClass="icon-empty"/>
        </button>
        
        <button
          type="button"
          className={`btn btn-primary px-2 ${type === 'barcode' ? 'active' : ''}`}
          onClick={() => changeType('barcode')}
        >
          <Icon icon='barcode' extraClass="icon-empty"/>
        </button>

        <div className="search-bar-container position-relative w-100 h-100">
          <input 
            className="search-bar-input"
            type="text"
            value={value}
            onChange={event => handleChange(event)}
            onKeyDown={event => handleKeyDown(event)}
            placeholder={type === 'text'
              ? I18n.get('searchItemByText', 'buscar productos')
              : I18n.get('searchItemByBarcode', 'código de barras')
            }
          />

          {!!value && (
            <button
              type="button"
              className="btn button-transparent position-absolute"
              onClick={() => changeType(type)}
            >
              <Icon icon='x' extraClass="icon-gray"/>
            </button>
          )}
        </div>
        
      </div>
    </div>
  )
}

export default Search;
