import React, { useState, useEffect, useRef, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { capitalize, get, isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useIsMutating, onlineManager } from '@tanstack/react-query';

import { useFormat } from '../../../hooks/useFormat';
import { useInventoryAdjustmentsQuery } from './queries';
import { useDeleteInventoryAdjustment } from './mutations';
import { hasPermissionTo } from '../../../selectors/auth';
import { checkFeatureLimit } from '../../../reducers/membership';
import { Filters as FiltersClass } from '../../../utils/filter';
import { stationWarehouse as stationWarehouseSelector } from '../../../selectors/app';
import { styleBigNumber } from '../../../utils';
import { useRefresh } from '../../../hooks/queryHooks/useRefresh';
import { useCheckPersistIDBOpen } from '../../../hooks/useCheckPersistIDBExists';
import { useShowToast } from '../../../hooks/useShowToast';
import { toast, replaceAndParse } from '../../../utils';
import { sendGTMEvent, sendNewGTMEvent } from '../../../reducers/company';

import Actions from './Actions';
import Table from '../../common/InventoryAdjustmentsTable';
import Header from '../../settings/common/Header';
import Filters from './Filters';
import Details from './Details';
import SimplifiedTable from './InventoryAdjustmentsSimplified';

import DeleteInventoryAdjustmentConfirmation from '../../modals/deleteInventoryAdjustmentConfirmation/DeleteInventoryAdjustmentConfirmation';
import { Tooltip, Icon } from '@alegradev/smile-ui-react';

const filtersIntance = new FiltersClass({
  order_field: 'date',
  order_direction: 'DESC',
  fields: 'deletable,editable,totalCostOnlyAdjustment',
});

const IventoryAdjustments = () => {
  useCheckPersistIDBOpen();

  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const ref = useRef(null);
  const stationWarehouse = useSelector(stationWarehouseSelector);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [pageCount, setControlledPageCount] = useState(0);
  const [errors, setErrors] = useState();
  const [openFilters, setOpenFilters] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [detailFullscreen, setDetailFullscreen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selected, setSelected] = useState({});
  const [tableIndicators, setTableIndicators] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState({
    ...filtersIntance.getFilters(),
  });
  const history = useHistory();

  filtersIntance.setUpdater(setFilters);

  useEffect(() => {
    filtersIntance.setGlobalAdditionalFilters({
      warehouse_id: get(stationWarehouse, 'id'),
    });
  }, [stationWarehouse]);

  useEffect(() => {
    ref.current = true;
    window.dataLayer.push({
      event: 'VirtualPageView',
      virtualPageURL: '/inventory/items',
      virtualPageTitle: 'Items',
    });

    return () => (ref.current = false);
  }, []);

  const { fmt, decimal } = useFormat();
  const { data, isFetching, refetch } = useInventoryAdjustmentsQuery(filters);
  const {
    mutate,
    isPaused,
    isSuccess,
    mutateAsync,
    data: deletedInventoryAdjustment,
  } = useDeleteInventoryAdjustment();
  // const { loading } = useLoading({ ref, error, data, tableIndicators, setErrors, setControlledPageCount, blockLoading })
  const refresh = useRefresh();
  const isMutating = useIsMutating();
  const isClientOffline = !onlineManager.isOnline();

  useShowToast({
    isPaused,
    isSuccess,
    onPaused: () => {
      toast.success({
        title: I18n.get(
          'inventoryAdjustmentDeletedLocally.title',
          'Ajuste de inventario eliminado localmente.'
        ),
        subtitle: I18n.get(
          'inventoryAdjustmentDeletedLocally.subtitle',
          'La acción se sincronizará automaticamente cuando se reestablezca la conexión.'
        ),
      });
    },
    onSuccess: () => {
      toast.success({
        title: !!get(deletedInventoryAdjustment, 'isMulti')
          ? I18n.get(
              'inventoryAdjustmentsDeleted.title',
              'Ya se eliminaron tus ajustes 🗑'
            )
          : I18n.get(
              'inventoryAdjustmentDeleted.title',
              'Ya se eliminó tu ajuste 🗑'
            ),
        subtitle: !!get(deletedInventoryAdjustment, 'isMulti')
          ? I18n.get(
              'inventoryAdjustmentsDeleted.subtitle',
              'Los ajustes de inventario que seleccionaste ya no hacen parte de tus registros.'
            )
          : replaceAndParse(
              I18n.get(
                'inventoryAdjustmentDeleted.subtitle',
                'El ajuste de inventario <span class="font-weight-bold">No. {}</span> ya no hace parte de tus registros.'
              ),
              [get(deletedInventoryAdjustment, 'inventoryAdjustment.number')]
            ),
      });
    },
  });

  useEffect(() => {
    filtersIntance.updatePagination(tableIndicators);
  }, [tableIndicators]);

  const hanldeSortByField = (field) => {
    if (!data?.metadata?.total) return;
    filtersIntance.sortResults(field);
  };

  const handleOpenDetail = (row, allowOffline = true) => {
    if (!allowOffline && get(row, 'original.status') === 'offline') return;
    setOpenDetail(true);
    setSelected(row.original);
    dispatch(sendGTMEvent('inventory-adjustment-detail-seen'));
  };

  const handleOpenInventoryAdjustmentDetail = () => {
    dispatch(sendGTMEvent('new-inventory-adjustment-attempted'));
    dispatch(sendNewGTMEvent('pos-inventory-adjustment-started'));
    dispatch(
      checkFeatureLimit('inventoryAdjustments', () =>
        history.push('/inventory/inventory-adjustments/add', { filters })
      )
    );
  };

  const hadleOpenEditInventoryAdjustment = () => {
    if (!can('edit', 'inventory-adjustments')) return null;
    dispatch(
      checkFeatureLimit('inventoryAdjustments', () =>
        history.push(
          `/inventory/inventory-adjustments/edit/${selectedIds[0].id}`,
          { filters }
        )
      )
    );
  };

  const handleOpenDeleteInventoryAdjustment = () => {
    if (!can('delete', 'inventory-adjustments')) return null;
    dispatch(
      checkFeatureLimit('inventoryAdjustments', () =>
        setOpenConfirmationModal(true)
      )
    );
  };

  const handleCloseConfirmationInventoryAdjustment = () => {
    dispatch(
      sendGTMEvent('inventory-adjustment-removal-attempted', {
        inventoryAdjustmentsNumber: selectedIds.length,
        inventoryAdjustmentRemovalStatus: false,
      })
    );
    setOpenConfirmationModal(false);
  };

  const handleDeleteInventoryAdjustment = async () => {
    setOpenConfirmationModal(false);
    await Promise.all(
      selectedIds.map((inventoryAdjustment) => {
        if (!get(inventoryAdjustment, 'deletable')) return null;
        return mutateAsync(
          {
            id: inventoryAdjustment.id,
            filters,
            inventoryAdjustment: inventoryAdjustment,
            isMulti: selectedIds.length > 1,
          },
          {
            onSettled: async () => {
              await refresh('inventoryAdjustments');
            },
          }
        );
      })
    );
    dispatch(
      sendGTMEvent('inventory-adjustment-removal-attempted', {
        inventoryAdjustmentsNumber: selectedIds.length,
        inventoryAdjustmentRemovalStatus: true,
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        id: 'number',
        Header: () => (
          <div
            className='p-0 m-0 pointer'
            onClick={() => hanldeSortByField('number')}
          >
            {I18n.get('number', 'Número')}
            <Icon
              icon='arrows-sort'
              extraClass='ml-2'
              width='15'
              height='15'
              color='#c9d5e0'
            />
          </div>
        ),
        accessor: 'number',
        Cell: ({ value, row }) => {
          const showNumber = !!get(row, 'original.number');
          const isOffline = get(row, 'original.status') === 'offline';

          if (!isOffline) {
            return (
              <div
                className='w-100 h-100 d-flex justify-content-between align-items-center pointer'
                onClick={() => handleOpenDetail(row)}
              >
                <p
                  className='h4 text-truncate styled-hover m-0'
                  hovercontent={value}
                >
                  {value}
                </p>
                <div
                  className='svg-container'
                  style={{
                    animationDuration: `${5}s`,
                  }}
                >
                  <Tooltip
                    overlay='Guardado en tu punto de venta'
                    placement='top'
                  >
                    <div className='svg-icon-sucess-background'>
                      <Icon icon='check' />
                    </div>
                  </Tooltip>
                </div>
              </div>
            );
          }

          if (!showNumber) {
            return (
              <div
                className='svg-icon-inactive-background d-flex justify-content-center'
                style={{ background: !isClientOffline ? 'none' : '#e2e8f073' }}
              >
                <div
                  style={{
                    width: '16px',
                    height: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Tooltip
                    overlay={
                      !isClientOffline
                        ? 'Sincronizando'
                        : 'Se guardará al tener conexión'
                    }
                    placement='top'
                  >
                    <Icon
                      icon='refresh'
                      syncing={!isClientOffline}
                      extraClass={`${!isClientOffline && 'rotate-animation'}`}
                      color={!isClientOffline ? '#30ABA9' : null}
                    />
                  </Tooltip>
                </div>
              </div>
            );
          }

          return (
            <div
              className='w-100 h-100 d-flex justify-content-between align-items-center pointer'
              onClick={() => handleOpenDetail(row)}
            >
              <p
                className='h4 text-truncate pointer styled-hover m-0'
                hovercontent={value}
              >
                {value}
              </p>
              <div
                className='svg-icon-inactive-background'
                style={{
                  background:
                    !isClientOffline && !!isMutating ? 'none' : '#e2e8f073',
                }}
              >
                <div
                  style={{
                    width: '16px',
                    height: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Tooltip
                    overlay={
                      !isClientOffline
                        ? 'Sincronizando'
                        : 'Se guardará al tener conexión'
                    }
                    placement='top'
                  >
                    <Icon
                      icon='refresh'
                      syncing={!isClientOffline}
                      extraClass={`${!isClientOffline && !!isMutating && 'rotate-animation'}`}
                      color={!isClientOffline ? '#30ABA9' : null}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        id: 'date',
        Header: () => (
          <div
            className='p-0 m-0 pointer'
            onClick={() => hanldeSortByField('date')}
          >
            {I18n.get('date', 'Fecha')}
            <Icon
              icon='arrows-sort'
              extraClass='ml-2'
              width='15'
              height='15'
              color='#c9d5e0'
            />
          </div>
        ),
        Cell: ({ row }) => {
          const inventoryAdjustment = row.original;
          return (
            <div
              className='w-100 h-100 d-flex justify-content-between align-items-center pointer'
              onClick={() => handleOpenDetail(row, false)}
            >
              <p className='h4 text-muted m-0'>
                {!!get(inventoryAdjustment, 'date')
                  ? dayjs(inventoryAdjustment.date).format(
                      I18n.get('dateFormat', 'YYYY/MM/DD')
                    )
                  : ''}
              </p>
            </div>
          );
        },
      },
      {
        id: 'warehouse',
        Header: () => (
          <div
            className='p-0 m-0 pointer'
            onClick={() => hanldeSortByField('warehouse_name')}
          >
            {I18n.get('warehouse', 'Bodega')}
            <Icon
              icon='arrows-sort'
              extraClass='ml-2'
              width='15'
              height='15'
              color='#c9d5e0'
            />
          </div>
        ),
        Cell: ({ row }) => {
          const inventoryAdjustment = row.original;
          return (
            <div
              className='w-100 h-100 d-flex justify-content-between align-items-center pointer'
              onClick={() => handleOpenDetail(row, false)}
            >
              <p className='h4 text-muted text-truncate m-0'>
                {get(inventoryAdjustment, 'warehouse.name', '-')}
              </p>
            </div>
          );
        },
      },
      {
        id: 'totalCostOnlyAdjustment',
        Header: () => (
          <div className='w-100' style={{ textAlign: 'right' }}>
            <p className='p-0 m-0'>
              {I18n.get('adjustedTotal', 'Total ajustado')}
            </p>
          </div>
        ),
        Cell: ({ row }) => {
          const totalCostOnlyAdjustment = get(
            row,
            'original.totalCostOnlyAdjustment'
          );
          const optimisticTotalCost = get(row, 'original.totalAdjusted');
          const bigNumber = new BigNumber(totalCostOnlyAdjustment).toFormat(
            decimal,
            fmt
          );

          const { intPart, decPart } = styleBigNumber(
            !!optimisticTotalCost ? optimisticTotalCost : bigNumber,
            decimal
          );

          return (
            <div
              className='h4 text-truncate styled-hover d-flex flex-row m-0 w-100 h-100 align-items-center pointer'
              hovercontent={bigNumber}
              onClick={() => handleOpenDetail(row, false)}
            >
              <p className='text-truncate m-0'>{intPart}</p>
              <p className='text-truncate text-muted m-0'>{decPart}</p>
            </div>
          );
        },
      },
      {
        Header: I18n.get('observations', 'Observaciones'),
        accessor: 'observations',
        Cell: ({ value, row }) => {
          const inventoryAdjustment = row.original;
          return (
            <div
              className='w-100 h-100 d-flex justify-content-between align-items-center pointer'
              onClick={() => handleOpenDetail(row, false)}
            >
              <p
                className='h4 text-muted text-truncate styled-hover m-0'
                hovercontent={value || '-'}
              >
                {!!get(inventoryAdjustment, 'observations')
                  ? get(inventoryAdjustment, 'observations')
                  : '-'}
              </p>
            </div>
          );
        },
      },
      {
        id: 'actions',
        sortable: false,
        minWidth: 110,
        maxWidth: 150,
        Cell: ({ row }) => {
          const showOptions = get(row, 'original.status') !== 'offline';
          return (
            <div className='w-100 d-flex justify-content-center'>
              {showOptions && (
                <Actions
                  inventoryAdjustment={row.original}
                  filters={filters}
                  mutate={mutate}
                />
              )}
            </div>
          );
        },
      },
    ],
    [
      decimal,
      fmt,
      data?.metadata?.total,
      filters,
      mutate,
      isClientOffline,
      isMutating,
      dispatch,
    ]
  );

  return (
    <div className='d-flex h-100'>
      {!openDetail && (
        <div className='container p-5'>
          <div className='d-flex flex-column'>
            <Header
              title={
                <p className='m-0 p-0 mb-3' style={{ fontWeight: '700' }}>
                  {I18n.get('inventoryAdjustments', 'Ajustes de inventario')}
                </p>
              }
              subtitle={
                <p className='h4 text-muted text-capitalize-first'>
                  {I18n.get(
                    'inventoryAdjustmentstInfo',
                    'Registra aumentos y disminuciones manuales para controlar las cantidades de tus productos.'
                  )}
                </p>
              }
              actions={
                <div className='d-flex mb-3'>
                  <Tooltip
                    visible={!can('add', 'items')}
                    overlay={I18n.get(
                      'userNotAllowed.items.add',
                      'no tienes permisos para agregar productos'
                    )}
                  >
                    <button
                      type='button'
                      disabled={!can('add', 'inventory-adjustments')}
                      className='btn btn-submit-large d-flex justify-content-center align-items-center'
                      onClick={handleOpenInventoryAdjustmentDetail}
                    >
                      <Icon icon='plus' extraClass='icon icon-white mr-2' />
                      {I18n.get(
                        'newInventoryAdjustment',
                        'Nuevo ajuste de inventario'
                      )}
                    </button>
                  </Tooltip>
                </div>
              }
              noTextPrimary
            />
            {(openFilters || !isEmpty(data?.data)) && (
              <Filters
                setFilters={(keys) => filtersIntance.filterByKeys(keys)}
                resetFilters={() => filtersIntance.resetFilters()}
                setOpenFilters={setOpenFilters}
                filters={filters}
              />
            )}
          </div>
          <Table
            loading={isFetching && !isMutating}
            data={data?.data || []}
            total={data?.metadata?.total || 0}
            onFetchData={setTableIndicators}
            controlledPageCount={pageCount}
            error={errors}
            setSelectedIds={setSelectedIds}
            onRefresh={refetch}
            _pageSize={tableIndicators.pageSize}
            _pageIndex={tableIndicators.pageIndex}
            multipleActions={(toggleAllRowsSelected) =>
              selectedIds.length > 0 ? (
                <tr className='text-white w-100 d-flex align-items-center px-4 bg-primary'>
                  <th className='w-100 d-flex align-items-center py-0'>
                    <p className='m-0 p-0'>
                      {selectedIds.length > 1
                        ? `${selectedIds.length} ${I18n.get('selectedPlural', 'seleccionados')}`
                        : `${selectedIds.length} ${I18n.get('selected', 'seleccionado')}`}
                    </p>
                  </th>
                  <th
                    className='w-100 d-flex justify-content-end'
                    style={{ gap: '8px' }}
                  >
                    {!(selectedIds.length > 1) && (
                      <div
                        className='d-flex align-items-center pointer'
                        style={{ gap: '4px', padding: '10px 8px' }}
                        onClick={hadleOpenEditInventoryAdjustment}
                      >
                        <Icon
                          icon='pencil'
                          color='white'
                          width='15'
                          height='16.25'
                        />
                        <p className='m-0 p-0'>{I18n.get('edit', 'Editar')}</p>
                      </div>
                    )}
                    <div
                      className='d-flex align-items-center pointer'
                      style={{ gap: '4px', padding: '10px 8px' }}
                      onClick={handleOpenDeleteInventoryAdjustment}
                    >
                      <Icon
                        icon='trash'
                        color='white'
                        width='15'
                        height='16.25'
                      />
                      <p className='m-0 p-0'>
                        {I18n.get('delete', 'Eliminar')}
                      </p>
                    </div>
                    <div
                      className='d-flex align-items-center'
                      style={{ gap: '4px', padding: '10px 8px' }}
                    >
                      <p className='m-0 p-0'>|</p>
                    </div>
                    <div
                      className='d-flex align-items-center pointer'
                      style={{ gap: '4px', padding: '10px 8px' }}
                      onClick={() => toggleAllRowsSelected(false)}
                    >
                      <p className='m-0 p-0'>
                        {I18n.get('clearSelection', 'Borrar selección')}
                      </p>
                    </div>
                  </th>
                </tr>
              ) : null
            }
            noDataText={
              <div
                className='d-flex flex-column justify-content-center align-items-center text-center'
                style={{ gap: '24px' }}
              >
                {openFilters ? (
                  <div
                    className='d-flex flex-column justify-content-center align-items-center'
                    style={{ gap: '32px' }}
                  >
                    <div className='react-table-light__no-data__icon-container'>
                      <div className='react-table-light__no-data__icon'>
                        <div>
                          <Icon icon='search' width='21' height='20' />
                        </div>
                      </div>
                    </div>
                    <div>
                      <p className='h2' style={{ gap: '16px' }}>
                        {I18n.get(
                          'noInventoryAdjustmentsFound.title',
                          'Sin resultados 🔦'
                        )}
                      </p>
                      <p>
                        {capitalize(
                          I18n.get(
                            'noInventoryAdjustmentsFound.subtitle',
                            'Ajusta los filtros para encontrar lo que estás buscando'
                          )
                        )}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div
                    className='d-flex flex-column justify-content-center align-items-center'
                    style={{ gap: '32px' }}
                  >
                    <div className='react-table-light__no-data__icon-container'>
                      <div className='react-table-light__no-data__icon'>
                        <div>
                          <Icon icon='file-dollar' />
                        </div>
                      </div>
                    </div>
                    <div>
                      <p className='h2' style={{ gap: '16px' }}>
                        {I18n.get(
                          'youDontHaveCreatedInventoryAdjustments.title',
                          '¡Crea tu primer ajuste en un par de clics! ⚖'
                        )}
                      </p>
                      <p>
                        {capitalize(
                          I18n.get(
                            'youDontHaveCreatedInventoryAdjustments.subtitle',
                            'Mantén actualizada la cantidad disponible de tus productos'
                          )
                        )}
                      </p>
                    </div>
                  </div>
                )}
                <button
                  type='button'
                  className='btn btn-cancel mr-3'
                  disabled={!can('add', 'inventory-adjustments')}
                  onClick={handleOpenInventoryAdjustmentDetail}
                >
                  <p className='m-0 p-0' style={{ color: '#445469' }}>
                    {I18n.get(
                      'newInventoryAdjustment',
                      'Nuevo ajuste de inventario'
                    )}
                  </p>
                </button>
              </div>
            }
            columns={columns}
          />
        </div>
      )}

      {openDetail && (
        <div className='w-100 h-100 d-flex justify-content-center'>
          <div
            className={`detail-table-container ${detailFullscreen ? 'hide-detail-table-container' : ''}`}
          >
            <div className='d-flex flex-column'>
              <Header
                title={
                  <p className='m-0 p-0 mb-3'>
                    {I18n.get('inventoryAdjustments', 'Ajustes de inventario')}
                  </p>
                }
                subtitle={
                  <p className='h3 text-muted text-capitalize-first'>
                    {I18n.get(
                      'inventoryAdjustmentstInfo',
                      'Registra aumentos y disminuciones manuales para controlar las cantidades de tus productos.'
                    )}
                  </p>
                }
                noTextPrimary
              />
            </div>

            <div className='d-flex align-items-center justify-content-even'>
              <Filters
                setFilters={(keys) => filtersIntance.filterByKeys(keys)}
                resetFilters={() => filtersIntance.resetFilters()}
                filters={filters}
                openDetail={openDetail}
                setOpenFilters={setOpenFilters}
              />
              <Tooltip
                visible={!can('add', 'inventory-adjustments')}
                overlay={I18n.get(
                  'userNotAllowed.inventory-adjustments.add',
                  'no tienes permisos para agregar ajustes de inventario'
                )}
              >
                <button
                  type='button'
                  disabled={!can('add', 'inventory-adjustments')}
                  className='btn btn-submit d-flex justify-content-center align-items-center mb-3'
                  onClick={handleOpenInventoryAdjustmentDetail}
                >
                  <Icon icon='plus' extraClass='icon icon-white mr-2' />
                  {I18n.get('new', 'Nuevo')}
                </button>
              </Tooltip>
            </div>

            <SimplifiedTable
              setOpenDetail={setOpenDetail}
              setSelected={setSelected}
              selectedRow={selected}
              filters={filters}
              tableIndicators={tableIndicators}
              setTableIndicators={setTableIndicators}
              pageCount={pageCount}
              setControlledPageCount={setControlledPageCount}
              setSelectedIds={setSelectedIds}
              errors={errors}
              setErrors={setErrors}
              openFilters={openFilters}
              noDataText={
                <div
                  className='d-flex flex-column justify-content-center align-items-center text-center'
                  style={{ gap: '24px' }}
                >
                  {openFilters ? (
                    <div
                      className='d-flex flex-column justify-content-center align-items-center'
                      style={{ gap: '32px' }}
                    >
                      <div className='react-table-light__no-data__icon-container'>
                        <div className='react-table-light__no-data__icon'>
                          <div>
                            <Icon icon='search' width='21' height='20' />
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className='h2' style={{ gap: '16px' }}>
                          {I18n.get(
                            'noInventoryAdjustmentsFound.title',
                            'Sin resultados 🔦'
                          )}
                        </p>
                        <p>
                          {capitalize(
                            I18n.get(
                              'noInventoryAdjustmentsFound.subtitle',
                              'Ajusta los filtros para encontrar lo que estás buscando'
                            )
                          )}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div
                      className='d-flex flex-column justify-content-center align-items-center'
                      style={{ gap: '32px' }}
                    >
                      <div className='react-table-light__no-data__icon-container'>
                        <div className='react-table-light__no-data__icon'>
                          <div>
                            <Icon icon='file-dollar' />
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className='h2' style={{ gap: '16px' }}>
                          {I18n.get(
                            'youDontHaveCreatedInventoryAdjustments.title',
                            '¡Crea tu primer ajuste en un par de clics! ⚖'
                          )}
                        </p>
                        <p>
                          {capitalize(
                            I18n.get(
                              'youDontHaveCreatedInventoryAdjustments.subtitle',
                              'Mantén actualizada la cantidad disponible de tus productos'
                            )
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                  <button
                    type='button'
                    className='btn btn-cancel mr-3'
                    disabled={!can('add', 'inventory-adjustments')}
                    onClick={handleOpenInventoryAdjustmentDetail}
                  >
                    <p className='m-0 p-0' style={{ color: '#445469' }}>
                      {I18n.get(
                        'newInventoryAdjustment',
                        'Nuevo ajuste de inventario'
                      )}
                    </p>
                  </button>
                </div>
              }
            />
          </div>
          <Details
            inventoryAdjustment={selected}
            setOpenDetail={setOpenDetail}
            mutate={mutate}
            filters={filters}
            setDetailFullscreen={setDetailFullscreen}
            detailFullscreen={detailFullscreen}
          />
        </div>
      )}

      <DeleteInventoryAdjustmentConfirmation
        isOpen={openConfirmationModal}
        onRequestClose={handleCloseConfirmationInventoryAdjustment}
        onConfirm={handleDeleteInventoryAdjustment}
        body={
          selectedIds.length > 1 ? (
            <p className='h4 mb-5'>
              {replaceAndParse(
                I18n.get(
                  'deleteInventoryAdjustments.info',
                  'Ten en cuenta que al eliminar los <b> ajustes de inventario seleccionados</b>, se borrará la variación de cantidades que habían generado en tus productos.'
                )
              )}
            </p>
          ) : (
            <p className='h4 mb-5'>
              {replaceAndParse(
                I18n.get(
                  'deleteInventoryAdjustment.info',
                  'Ten en cuenta que al eliminar el <b> ajuste de inventario No. {} </b> se borrará la variación de cantidades que había generado en tus productos.'
                ),
                [get(selectedIds[0], 'number')]
              )}
            </p>
          )
        }
        submitText={
          selectedIds.length > 1
            ? I18n.get('deleteInventoryAdjustments', 'Eliminar ajustes')
            : I18n.get('deleteInventoryAdjustment', 'Eliminar ajuste')
        }
        modalTitle={
          selectedIds.length > 1
            ? I18n.get(
                'deleteInventoryAdjustments.modalTitle',
                'Eliminar ajuste de inventario'
              )
            : I18n.get(
                'deleteInventoryAdjustment.modalTitle',
                'Eliminar ajuste de inventario'
              )
        }
      />
    </div>
  );
};

export default IventoryAdjustments;
