import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { Form } from 'react-final-form';
import { get, capitalize } from 'lodash';

import * as mutations from '../../../graphql/mutations';
import { formError, handleError } from '../../../utils/errors';
import { toast } from '../../../utils';
import { closeModal } from '../../../reducers/modals';
import { getShiftStatus } from '../../../reducers/shifts';
import { sendGTMEvent, sendNewGTMEvent } from '../../../reducers/company';
import { hasPermissionTo } from '../../../selectors/auth';
import {
  station as stationSelector,
  stationBaseBank,
  APIGraphqlSelector,
} from '../../../selectors/app';
import { validate, transform } from '../../forms/openShift/utils';
import { country as countrySelector } from '../../../selectors/company';
import Body from '../../forms/openShift/OpenShift';
import Modal from '../../common/Modal';
import Bottom from '../common/Bottom';
import Notification from '../common/Notification';
import Typography from '../../common/Typography';
import { endAction, startAction } from '../../../reducers/monitoring';
import { calculateActionTimeSelector } from '../../../selectors/monitoring';

const OpenShift = () => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const isOpen = useSelector((state) =>
    get(state, 'modals.openShift.isOpen', false)
  );
  const attemptOrigin = useSelector((state) =>
    get(state, 'modals.openShift.params.attemptOrigin', false)
  );
  const station = useSelector(stationSelector);
  const baseBank = useSelector(stationBaseBank);
  const country = useSelector(countrySelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const calculateActionTime = useSelector(calculateActionTimeSelector);

  const submit = async (values) => {
    try {
      dispatch(startAction({action: 'openShift'}));
      const transformedValues = transform({
        idStation: station.id,
        ...values,
      });

      await APIGraphql(
        graphqlOperation(mutations.openShift, {
          shift: transformedValues,
        })
      );

      dispatch(getShiftStatus());
      dispatch(endAction({action: 'openShift'}));

      dispatch(closeModal({ modal: 'openShift' }));

      dispatch(sendNewGTMEvent('pos-shift-opened', {
        responseTime: calculateActionTime("openShift")
      }));

      dispatch(
        sendGTMEvent('shift-opened', {
          attemptOrigin
        })
      );

      if (country === 'spain') {
        toast.success({
          title: `${I18n.get('shiftOpenSuccessfully', '¡Caja abierta!')} 🔥`,
          subtitle: I18n.get(
            'shiftOpenSuccessfullyMessage',
            'Tu caja se abrió con éxito.'
          ),
        });
      } else {
        toast.success({
          title: I18n.get(
            'shiftOpenSuccessfully',
            'el turno fue abierto con éxito'
          ),
        });
      }
    } catch (error) {
      const parseError = handleError(error);
      dispatch(
        sendNewGTMEvent('pos-shift-opened', {
          error: parseError
        })
      );
      return formError(
        error,
        I18n.get('openShiftError', 'hubo un error en la apertura del turno')
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      includeHeader={false}
      onRequestClose={() => dispatch(closeModal({ modal: 'openShift' }))}
      title={I18n.get('openShift', 'abrir turno')}
      className='modal__open-shift'
    >
      <Form
        onSubmit={submit}
        validate={(values) => validate(values)}
        initialValues={{
          baseBank,
        }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, values, submitting, submitError }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className='px-4 pt-4 pb-0'>
              <Typography
                text={I18n.get('openShift', 'abrir turno')}
                type='body-1-bold'
                variant='primary'
              />
              <p>
                <Typography
                  type='body-3-regular'
                  htmlType='span'
                  variant='secondary'
                  text={I18n.get(
                    'openShiftMessage',
                    'los turnos sirven para llevar un control del efectivo en un período de tiempo'
                  )}
                />
                <a
                  className='btn-link ml-1'
                  href={
                    country === 'spain'
                      ? 'https://ayuda.alegra.com/es/realiza-una-apertura-y-cierre-de-turnos-en-el-sistema-punto-de-venta-pos'
                      : 'https://ayuda.alegra.com/es/apertura-y-cierre-de-turnos-en-alegra-pos'
                  }
                  target='_blank'
                  rel='noreferrer'
                >
                  <Typography
                    text={capitalize(I18n.get('seeMore', 'ver mas'))}
                    type='body-3-underline'
                    htmlType='span'
                  />
                </a>
              </p>
            </div>

            <div className='d-flex flex-column p-3'>
              <Body values={values} />
            </div>

            <Notification isOpen={!!submitError} text={submitError} />

            <Notification
              type='warning'
              isOpen={!can('open', 'pos-cashier')}
              text={I18n.get(
                'userNotAllowed.pos-cashier.open',
                'no tienes permisos para abrir turnos'
              )}
            />

            <Bottom
              disabled={!can('open', 'pos-cashier')}
              submitting={submitting}
              onClose={() => dispatch(closeModal({ modal: 'openShift' }))}
            />
          </form>
        )}
      </Form>
    </Modal>
  );
};

export default OpenShift;
