import React from "react";
import PropsTypes from "prop-types";
import { I18n } from "@aws-amplify/core";
import { Field, Form } from "react-final-form";
import { renderSelect } from "../../forms/fields/V0/Fields";

import { Icon } from '@alegradev/smile-ui-react';

const PAGINATION_OPTIONS = [
  {
    value: 10,
    label: "10",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 30,
    label: "30",
  },
];

const Pagination = ({ previousPage, handleChangeIndex, nextPage, pageIndex, pageOptions, canPreviousPage, canNextPage, onRefresh, pageCount, handleChangePageSize, pageSize }) => {
  return (
    <div className="react-table-gray__pagination bg-white">
      <div className="react-table-gray__pagination-container">
        <div className="d-flex align-items-center">
          <div className="carrent-left d-flex">
            <div className="carrent__doble mr-3 ml-3" onClick={() => handleChangeIndex(null, 0)}>
              <Icon icon='player-track-prev' color={canPreviousPage ? "#00B19D" : "#CCCCCC"} />
            </div>
            <div className="carrent__simple" onClick={canPreviousPage ? previousPage : null}>
              <Icon icon='arrow-left' color={canPreviousPage ? "#00B19D" : "#CCCCCC"} />
            </div>
          </div>

          <div className="d-flex align-items-center mx-3">
            <div className="pagination-description text-capitalize-first text-break description">{I18n.get("page", "Página")}</div>
            <input className="mx-2" type="number" value={pageIndex + 1} onChange={(e) => handleChangeIndex(e)} />
            <div className="text-break description">{`${I18n.get("of", "de")} ${pageOptions.length}`}</div>
          </div>

          <div className="carrent-right d-flex">
            <div className="carrent__simple" onClick={canNextPage ? nextPage : null}>
              <Icon icon='arrow-right' color={canNextPage ? "#00B19D" : "#CCCCCC"} />
            </div>
            <div className="carrent__doble ml-3" onClick={() => handleChangeIndex(null, pageCount - 1)}>
              <Icon icon='player-track-next' color={canNextPage ? "#00B19D" : "#CCCCCC"} />
            </div>
          </div>
          <div className="reload" onClick={() => onRefresh({ pageIndex, pageSize })}>
            <Icon icon='refresh' color="#00B19D" />
          </div>
        </div>

        <div className="d-flex align-items-center mx-3">
          <div className="pagination-description text-capitalize-first text-break description">
            {`${I18n.get("showing", "mostrando")} ${pageIndex + 1} `}
          </div>
          <div className="text-break description px-2"> {`${I18n.get("of", "de")} ${pageOptions.length}`}</div>
          <div className="text-break results">{I18n.get("resultsPerPage", "Resultados por página")}</div>
          <div className="options">
            <Form onSubmit={{}} keepDirtyOnReinitialize>
              {() => (
                <Field
                  name="countPage"
                  placeholder={pageSize}
                  component={renderSelect}
                  options={PAGINATION_OPTIONS}
                  className="m-0 pages"
                  menuPosition="absolute"
                  getOptionLabel={option => option.label}
                  getOptionValue={option => option.value}
                  onChange={option => handleChangePageSize(option.value)}
                  height={"3rem"}
                  fontSize={"12px"}
                />
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  previousPage: PropsTypes.func,
  handleChangeIndex: PropsTypes.func,
  handleChangePageSize: PropsTypes.func,
  nextPage: PropsTypes.func,
  pageIndex: PropsTypes.number,
  pageOptions: PropsTypes.any,
  canPreviousPage: PropsTypes.bool,
  canNextPage: PropsTypes.bool,
  pageCount: PropsTypes.number,
  pageSize: PropsTypes.number,

};

export default Pagination;
