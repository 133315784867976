import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';

import { activeBanks } from '../../../selectors/banks';
import { renderField, renderSelect } from '../fields/V0/Fields';
import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';

const OpenShift = ({ values }) => {
  const banks = useSelector(activeBanks)
  const { isDecimalActive } = useDecimalsVersionsGroup();

  function maxDecimals(e, key, decimals = 6) {
    if (!isDecimalActive)
      return e.target.value;

    const newButton = e.nativeEvent.data;
    values[key] = !values[key] ? "" : values[key];
    e.target.value = !e.target.value ? "" : e.target.value;

    if (newButton === '.') {
      if (!e.target.value.slice(0, -1).includes('.')) {
        return e.target.value;
      } else {
        e.target.value = values[key];
        return values[key];
      }
    }

    if (!isNaN(newButton)) {
      if (!e.target.value.includes('.') || (e.target.value.includes('.') && e.target.value.split('.')[1].length <= decimals)) {
        return e.target.value;
      } else {
        e.target.value = values[key];
        return values[key];
      }
    }

    if (isNaN(newButton)) {
      e.target.value = values[key];
      return values[key];
    }
  }

  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">
        <Field
          name="base"
          className="col-12"
          component={renderField}
          type="number"
          onChange={e => maxDecimals(e, 'base', 6)}
          label={capitalize(I18n.get('base', 'base inicial'))}
          help={capitalize(I18n.get('baseHelp', 'la base es el dinero con que se cuenta a la hora de abrir un turno'))}
          required
        />
        
        <Field
          name="baseBank"
          className="col-12"
          component={renderSelect}
          options={banks}
          label={capitalize(I18n.get('baseBank', 'banco base'))}
          help={capitalize(I18n.get('baseBankHelp', 'el banco al cual se transferirá la base inicial'))}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          menuPosition="absolute"
          required
        />
        
      </div>
    </div>
  )
}

export default OpenShift;