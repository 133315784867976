import React, {
  useMemo,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux';
import { isString, get, find, debounce } from 'lodash';

import { openSideModal } from '../../../reducers/sideModals';
import { setClient, setSettings } from '../../../reducers/activeInvoice';
import { sendGTMEvent } from '../../../reducers/company';
import { hasPermissionTo } from '../../../selectors/auth';
import {
  country as countrySelector,
  companySelector,
} from '../../../selectors/company';
import { clientsFilterText, invoiceClients } from '../../../selectors/clients';
import {
  client as clientSelector,
  numeration as numerationSelector,
} from '../../../selectors/activeInvoice';
import {
  stationInvoiceNumberNumeration,
  stationInvoiceNumeration,
} from '../../../selectors/app';
import { basicPOSClientData, isDefaultClient } from '../../../utils';

import useElectronicPayrollStatus from '../../../hooks/useElectronicPayrollStatus/hook';
import { COUNTRIES } from '../../../utils/enums/countries';
import { getInvoiceNumerations } from '../../../selectors/numerations';
import { MEXICO_DEFAULT_RFC } from '../../countriesData/mexico/rfc';
import { isMexicoFeAvailable } from '../../../utils/deploys/mexicoFeDeployGroups';
import {
  Button,
  Grid,
  Select,
  Tooltip,
  Typography,
} from '@alegradev/smile-ui-react';
import checkIfIsDefaultClient from '../../../pages/contacts/utils/isDefaultClient';
import useConnectionStatus from '../../../hooks/useConnectionStatus/hook';
import { checkFeatureLimit } from '../../../reducers/membership';
import { filter } from '../../../reducers/clients';

const renderClientName = (client) => {
  if (!!get(client, 'name')) {
    if (!!isString(get(client, 'name'))) return get(client, 'name');
    return `${get(client, 'name.firstName', '')}${
      !!get(client, 'name.secondName', null)
        ? ' ' + get(client, 'name.secondName')
        : ''
    }${
      !!get(client, 'name.lastName', null)
        ? ' ' + get(client, 'name.lastName')
        : ''
    }`;
  }
  if (!!get(client, 'nameObject')) {
    let nameObject = get(client, 'nameObject');
    return `${get(nameObject, 'firstName', '')}${
      !!get(nameObject, 'secondName', null)
        ? ' ' + get(nameObject, 'secondName')
        : ''
    }${
      !!get(nameObject, 'lastName', null)
        ? ' ' + get(nameObject, 'lastName')
        : ''
    }`;
  }
  return '';
};

const renderClientIdentification = (client, country) => {
  if (!client || isDefaultClient(client, country)) return '';

  if (!!get(client, 'identification')) {
    if (!!isString(get(client, 'identification')))
      return ` (${get(client, 'identification')})`;
    if (!!get(client, 'identification.number'))
      return ` (${get(client, 'identification.number')})`;
    return '';
  }
  if (!!get(client, 'identificationObject')) {
    if (!!isString(get(client, 'identificationObject.number')))
      return ` (${get(client, 'identificationObject.number')})`;
  }
  return '';
};

const Client = () => {
  const { Wrapper } = Grid;
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const country = useSelector(countrySelector);
  const clients = useSelector(invoiceClients);
  const client = useSelector(clientSelector);
  const numeration = useSelector(numerationSelector);
  const numerations = useSelector(getInvoiceNumerations);
  const stationNumeration = useSelector(stationInvoiceNumeration);
  const stationNumberInvoiceNumberation = useSelector(
    stationInvoiceNumberNumeration
  );
  const { isActive } = useElectronicPayrollStatus();
  const { registryDate } = useSelector(companySelector);
  const [loading, setLoading] = useState(false);
  const searchFilter = useSelector(clientsFilterText);

  const [inputSearchValue, setInputSearchValue] = useState('');

  const ref = useRef();
  const isOnline = useConnectionStatus();

  useEffect(() => {
    if (searchFilter && searchFilter !== inputSearchValue)
      setInputSearchValue(searchFilter);
  }, [searchFilter]);

  useEffect(() => {
    ref.current = true;
    return () => (ref.current = false);
  }, []);

  const search = useCallback(
    async (text = '') => {
      if (ref.current) setLoading(true);

      await dispatch(filter({ text, limit: 35 }));

      if (ref.current) setLoading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (can('view', 'contacts')) search(inputSearchValue);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [inputSearchValue, 500]);

  const isFeMexActive = useMemo(() => {
    return isMexicoFeAvailable(country, isActive);
  }, [country, registryDate, isActive]);

  useEffect(() => {
    if (!isFeMexActive) return;
    if (country === COUNTRIES.MEXICO) {
      const TICKET_NUMERATION_TYPE = 'saleTicket';
      if (client && client?.identification !== MEXICO_DEFAULT_RFC) {
        if (stationNumeration?.documentType === TICKET_NUMERATION_TYPE) {
          if (stationNumberInvoiceNumberation) {
            dispatch(
              setSettings({ numeration: stationNumberInvoiceNumberation })
            );
          } else {
            const invoiceNumeration = find(numerations, {
              documentType: 'invoice',
            });
            const favoriteNumeration = find(numerations, {
              documentType: 'invoice',
              isDefault: true,
            });
            if (favoriteNumeration) {
              dispatch(setSettings({ numeration: favoriteNumeration }));
            } else if (invoiceNumeration) {
              dispatch(setSettings({ numeration: invoiceNumeration }));
            }
          }
        }
      }
    }
  }, [client, country, stationNumeration, numerations, isFeMexActive]);

  const spainClientCodition =
    country === 'spain' &&
    !!numeration &&
    get(numeration, 'documentType') === 'invoice' &&
    !client;

  const domClientNumerationCondition =
    country === 'republicaDominicana' &&
    (!!numeration
      ? get(numeration, 'prefix') === 'E31'
      : get(stationNumeration, 'prefix') === 'E31') &&
    !client;

  const getClient = useMemo(() => {
    if (client && !checkIfIsDefaultClient(client, country)) return client;

    const defaultClient = basicPOSClientData(
      country,
      !!numeration ? numeration : stationNumeration
    );
    const findDefaultClient = clients?.find((clientOption) =>
      checkIfIsDefaultClient(clientOption, country)
    );
    if (findDefaultClient) return findDefaultClient;
    return defaultClient;
  }, [client]);

  useEffect(() => {
    if (!client) dispatch(setClient(getClient));
  }, [client]);

  const openCreateClientSideModal = (contact = null) => {
    try {
      let params = {};
      if (!!contact) params = { contact };
      dispatch(openSideModal({ sideModal: 'contact', params }));
      dispatch(
        sendGTMEvent('client-creation-attempted', {
          creationStatus: 'success',
        })
      );
    } catch {
      dispatch(
        sendGTMEvent('client-creation-attempted', {
          creationStatus: 'failure',
        })
      );
    }
  };

  const isCreateClientAvailable = useMemo(() => {
    if (country === COUNTRIES.MEXICO && !isFeMexActive) return false;

    return true;
  }, [isFeMexActive]);

  const getSelectInfo = useMemo(() => {
    const currentClient = getClient;
    if (
      country === COUNTRIES.MEXICO &&
      currentClient?.identification === MEXICO_DEFAULT_RFC
    ) {
      return I18n.get(
        '',
        'Ten en cuenta que si seleccionas un cliente, se le creará una factura de venta en lugar de un ticket'
      );
    }

    return null;
  }, [client]);

  const options = useMemo(() => {
    if (can('view', 'contacts')) {
      return clients?.map((client) => {
        return {
          ...client,
          trailing:
            isOnline &&
            !checkIfIsDefaultClient(client, country) &&
            can('edit', 'contacts') &&
            get(client, 'status') === 'active'
              ? {
                  icon: 'pencil',
                  onClick: () => openCreateClientSideModal(client),
                }
              : undefined,
        };
      });
    }

    return [];
  }, [clients, can, isOnline]);

  return (
    <Wrapper align={{ lg: 'center' }} gap={10} extraClasses='p-3'>
      <Typography
        type='label-2'
        text={I18n.get('client', 'Cliente')}
        variant='secondary'
        extraClassName='pl-3'
      />
      <Wrapper fullWidth extraClasses='invoice-top__client-select'>
        <div data-testid='select-client-invoice'>
          <Tooltip
            visible={domClientNumerationCondition || spainClientCodition}
            overlay='Es necesario asignar un contacto en la factura'
          >
            <Select
              value={getClient}
              options={options}
              isSearchable={can('view', 'contacts')}
              isLoading={loading}
              isClearable={!checkIfIsDefaultClient(getClient, country)}
              placeholder={I18n.get('selectAClient', 'Selecciona un cliente')}
              noOptionsMessage={() =>
                can('view', 'contacts')
                  ? clients.length === 0
                    ? I18n.get(
                        'createClientToSelect',
                        'Crea un nuevo cliente para seleccionarlo'
                      )
                    : I18n.get(
                        'createThisNewClientToSelect',
                        'Crea este nuevo cliente para seleccionarlo'
                      )
                  : I18n.get(
                      'userNotAllowed.contacts.view',
                      'No tienes permiso para ver el detalle de contacto. Habla con tu administrador para que te habilite el permiso y así puedas usar el POS correctamente.'
                    )
              }
              isDisabled={!isCreateClientAvailable}
              onChange={(client) =>
                dispatch(
                  setClient({
                    ...client,
                    trailing: {},
                  })
                )
              }
              onInputChange={useMemo(
                () =>
                  debounce((value) => {
                    if (can('view', 'contacts')) search(value);
                  }, 350),
                [can, search]
              )}
              cacheOptions={false}
              getOptionLabel={(option) =>
                `${renderClientName(option)}${renderClientIdentification(
                  option,
                  country
                )}`
              }
              trailing={
                isOnline &&
                !checkIfIsDefaultClient(getClient, country) &&
                can('edit', 'contacts') &&
                get(getClient, 'status') === 'active'
                  ? {
                      icon: 'pencil',
                      onClick: () => openCreateClientSideModal(getClient),
                    }
                  : undefined
              }
            />
          </Tooltip>
        </div>
      </Wrapper>
      {isCreateClientAvailable && (
        <Tooltip
          visible={!can('add', 'contacts')}
          overlay={I18n.get(
            'addContactsUnallow',
            'Te hace falta el permiso para crear contactos'
          )}
        >
          <Button
            disabled={!can('add', 'contacts') || !isCreateClientAvailable}
            onClick={() =>
              dispatch(
                checkFeatureLimit('clients', () => openCreateClientSideModal())
              )
            }
            type='button'
            size='large'
            emphasis='subtle'
            text={I18n.get('new', 'Nuevo')}
            leftIcon='user-plus'
          />
        </Tooltip>
      )}
    </Wrapper>
  );
};

export default Client;
