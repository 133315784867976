import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { get, capitalize } from 'lodash';

import { renderField } from '../../../../../components/forms/fields/V2';
import { COUNTRIES } from '../../../../../utils/enums/countries';
import { Grid } from '@alegradev/smile-ui-react';
import { useContactForm } from '../FormProvider';

const colombiaName = (props) => {
  const { Col } = Grid;
  const { searchingClient } = useContactForm();
  const kindOfPerson = get(props, 'values.kindOfPerson', '');
  const completeName = kindOfPerson === 'PERSON_ENTITY';

  return (
    <>
      <Col xs={12} md={completeName ? 6 : 12}>
        <Field
          name='firstName'
          component={renderField}
          data-testid='client-first-name-input'
          type='text'
          label={
            completeName
              ? capitalize(I18n.get('firstName', 'Primer Nombre'))
              : `${capitalize(I18n.get('socialRazon', 'razón social'))} / ${capitalize(I18n.get('fullName', 'Nombre Completo'))}`
          }
          magicLoading={searchingClient}
          required
        />
      </Col>

      {completeName && (
        <>
          <Col xs={12} md={6}>
            <Field
              name='secondName'
              component={renderField}
              magicLoading={searchingClient}
              data-testid='client-second-name-input'
              type='text'
              label={capitalize(I18n.get('secondName', 'Segundo Nombre'))}
            />
          </Col>
          <Col xs={12}>
            <Field
              name='lastName'
              component={renderField}
              data-testid='client-last-name-input'
              type='text'
              magicLoading={searchingClient}
              label={capitalize(I18n.get('lastName', 'Apellidos'))}
              required
            />
          </Col>
        </>
      )}

      <Col xs={6}  sm={12}>
        <Field
          name='email'
          component={renderField}
          magicLoading={searchingClient}
          type='text'
          label={capitalize(I18n.get('email', 'Correo'))}
        />
      </Col>
    </>
  );
};

const peruName = (props) => {
  const { Col } = Grid;
  const identification = get(props, 'values.identification.type.key', '');
  const { searchingClient } = useContactForm();

  return (
    <Col xs={12}>
      <Field
        name='firstName'
        component={renderField}
        data-testid='client-first-name-input'
        type='text'
        placeholder={
          identification === 'RUC' && props.isElectronicInvoicing
            ? capitalize(I18n.get('socialRazon', 'razón social'))
            : capitalize(I18n.get('name', 'Nombre'))
        }
        magicLoading={searchingClient}
        label={
          identification === 'RUC' && props.isElectronicInvoicing
            ? capitalize(I18n.get('socialRazon', 'razón social'))
            : capitalize(I18n.get('name', 'Nombre'))
        }
        required
      />
    </Col>
  );
};

const costaRicaName = () => {
  const { Col } = Grid;
  const { searchingClient } = useContactForm();

  return (
    <Col xs={12}>
      <Field
        name='firstName'
        component={renderField}
        magicLoading={searchingClient}
        data-testid='client-name-input'
        type='text'
        label={capitalize(I18n.get('name', 'Nombre'))}
        required
      />
    </Col>
  );
};

const argentinaName = () => {
  const { Col } = Grid;
  return (
    <Col xs={12} md={6}>
      <Field
        name='firstName'
        component={renderField}
        data-testid='client-name-input'
        type='text'
        label={capitalize(I18n.get('name', 'Nombre'))}
        required
      />
    </Col>
  );
};

const dominicanaName = () => {
  const { Col } = Grid;
  const { searchingClient } = useContactForm();

  return (
    <Col xs={12}>
      <Field
        name='firstName'
        component={renderField}
        magicLoading={searchingClient}
        data-testid='client-name-input'
        type='text'
        label={`${capitalize(I18n.get('name', 'Nombre'))}/${capitalize(I18n.get('socialRazon', 'razón social'))}`}
        required
      />
    </Col>
  );
};

const panamaName = (props) => {
  const { Col } = Grid;
  const kindOfPerson = get(props, 'values.identification.kindOfPerson', '');
  const completeName = kindOfPerson === 'PERSON_ENTITY';

  return (
    <>
      <Col xs={completeName ? 6 : 12}>
        <Field
          name='firstName'
          component={renderField}
          data-testid='client-name-input'
          type='text'
          label={
            kindOfPerson === ''
              ? I18n.get('name', 'Nombre')
              : completeName
                ? capitalize(I18n.get('firstName', 'Primer Nombre'))
                : `${capitalize(I18n.get('name', 'Nombre'))} o ${capitalize(I18n.get('socialRazon', 'razón social'))}`
          }
          required
        />
      </Col>

      {completeName && (
        <Col xs={6}>
          <Field
            name='lastName'
            component={renderField}
            data-testid='client-last-name-input'
            type='text'
            label={capitalize(I18n.get('firstLastName', 'Primer Apellido'))}
            required
          />
        </Col>
      )}
    </>
  );
};

const mexicoName = () => {
  const { Col } = Grid;
  return (
    <Col xs={6} sm={12}>
      <Field
        name='firstName'
        component={renderField}
        type='text'
        label={I18n.get('name', 'Nombre')}
        required
      />
    </Col>
  );
};

const defaultName = () => {
  const { Col } = Grid;
  return (
    <Col xs={12} sm={12}>
      <Field
        name='firstName'
        component={renderField}
        data-testid='client-name-input'
        type='text'
        label={capitalize(I18n.get('name', 'Nombre'))}
        required
      />
    </Col>
  );
};

const renderName = (props) => {
  switch (props.country) {
    case COUNTRIES.COLOMBIA:
      return colombiaName(props);
    case COUNTRIES.COSTA_RICA:
      return costaRicaName(props);
    case COUNTRIES.PERU:
      return peruName(props);
    case COUNTRIES.ARGENTINA:
      return argentinaName(props);
    case COUNTRIES.REPUBLICA_DOMINICANA:
      return dominicanaName(props);
    case COUNTRIES.PANAMA:
      return panamaName(props);
    case COUNTRIES.MEXICO:
      return mexicoName(props);
    default:
      return defaultName(props);
  }
};

export default renderName;
