import { Loader, Tabs } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';

import {
  ContactPortalEmailFormProvider,
  ContactPortalWhatsappFormProvider,
} from './methods/FormProvider';
import { useContactQuery } from '../services/hooks';

function ContactPortal({ contact, modalName }) {
  const { data, isLoading } = useContactQuery(contact.id, ['statementLink']);

  if (isLoading) {
    return (
      <Loader
        size='large'
        orientation='vertical'
        title={I18n.get('loadingContact', 'Cargando contacto')}
      />
    );
  }

  return (
    <Tabs
      defaultTab='email'
      items={[
        {
          id: 'email',
          component: (
            <ContactPortalEmailFormProvider
              contact={data}
              modalName={modalName}
            />
          ),
          title: I18n.get('email', 'Correo electrónico'),
          leftIcon: 'mail',
        },
        {
          id: 'whatsapp',
          component: (
            <ContactPortalWhatsappFormProvider
              contact={data}
              modalName={modalName}
            />
          ),
          title: I18n.get('whatsapp', 'WhatsApp'),
          leftIcon: 'brand-whatsapp',
        },
      ]}
    />
  );
}

export default ContactPortal;
