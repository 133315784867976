import React, { useMemo } from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

import {
  activateBlockApiCall,
  deactivateBlockApiCall,
  syncData,
} from '../../../reducers/app';
import { offlineData } from '../../../selectors/app';
import Typography from '../../common/Typography';
import Button from '../../common/Button';
import MenuOption from '../../common/MenuOption';
import { useConnectionStatus } from '../../../hooks/useConnectionStatus';
import { useHistory } from 'react-router-dom';
import Cookie from 'js-cookie';
import { useOfflineStatusAvailable } from '../../../hooks/useOfflineStatusAvailable';
import { useApiAvailable } from '../../../hooks/useApiAvailable';
import { Icon } from '@alegradev/smile-ui-react';

const OfflineStatus = () => {
  const dispatch = useDispatch();
  const { isCompanyAvailable } = useOfflineStatusAvailable();
  const isOnline = useConnectionStatus();
  const offlineStatus = useSelector(offlineData);
  const isApiAvailable = useApiAvailable();
  const history = useHistory();
  const syncing = useSelector((state) => get(state, 'app.syncing', false));
  const syncingInvoice = useSelector((state) =>
    get(state, 'app.syncingInvoice', false)
  );

  const itemsPercent = useSelector((state) =>
    get(state, 'items.loadingPercent', false)
  );
  const clientsPercent = useSelector((state) =>
    get(state, 'clients.loadingPercent', false)
  );

  const title = useMemo(() => {
    if (!offlineStatus) {
      return I18n.get('noDataOffline', 'Datos sincronizados');
    }

    if (syncingInvoice) {
      return I18n.get('syncing', 'Sincronizando');
    }

    if (offlineStatus) {
      return I18n.get('dataOffline', 'Sincronización pendiente');
    }

    if (!isApiAvailable) {
      return I18n.get('syncing_pending', 'Sincronización pendiente');
    }

    return I18n.get('noDataOffline', 'Datos sincronizados');
  }, [offlineStatus, syncingInvoice, isApiAvailable]);

  const description = useMemo(() => {
    if (!offlineStatus) {
      if (itemsPercent < 100 || clientsPercent < 100) {
        return I18n.get(
          'loadingResourcesSubtitle',
          'Puedes vender mientras cargan tus productos y clientes'
        );
      }
      return I18n.get(
        'noDataOfflineSubtitles',
        'tus datos están sincronizados con Alegra'
      );
    }

    if (syncingInvoice ) {
      return I18n.get(
        'syncingSales',
        'Estamos guardando las ventas que hiciste sin conexión, en un momento estará todo listo.'
      );
    }

    if (syncingInvoice && !isApiAvailable) {
      return I18n.get(
        'syncingSaleIntermittence',
        'Estamos guardando las ventas que hiciste durante la intermitencia, en un momento estará todo listo.'
      );
    }

    if(offlineStatus && !isOnline) {
      return I18n.get(
        'dataOfflineSubtitles',
        'Puedes seguir vendiendo y tus ventas se sincronizarán cuando tengas internet.'
      );
    }

  

    if (!isOnline) {
      return I18n.get(
        'dataOfflineSubtitles',
        'Puedes seguir vendiendo y tus ventas se sincronizarán cuando tengas internet.'
      );
    }

    if (!isApiAvailable) {
      return I18n.get(
        'syncing_pending_description',
        'Experimentamos fallas técnicas y guardaremos tus ventas cuando se restablezca el servicio.'
      );
    }

    if(offlineStatus) {
      return I18n.get(
        'offlineStatusSyncSubtitle',
        'Te recomendamos revisar las ventas pendientes por sincronizar para evitar la pérdida de su información.'
      );
    }

    if (itemsPercent < 100 || clientsPercent < 100) {
      return I18n.get(
        'loadingResourcesSubtitle',
        'Puedes vender mientras cargan tus productos y clientes'
      );
    }
    return I18n.get(
      'noDataOfflineSubtitles',
      'tus datos están sincronizados con Alegra'
    );
  }, [offlineStatus, syncingInvoice, syncing, isApiAvailable, itemsPercent, clientsPercent, isOnline]);

  return (
    <MenuOption
      id='offlineStatus'
      tooltip={I18n.get('synchronization', 'Sincronización')}
      eventName='Synchronization'
      extraClassName='mobile-right'
      header={
        offlineStatus ? (
          <div className='resfresg-icon-point'>
            <Icon icon='refresh-alert' className='icon-secondary' />
            <div className='resfresg-icon-point-dot'></div>
          </div>
        ) : (
          <div className='resfresg-icon-point'>
            <Icon icon='refresh' className='icon-secondary' />
          </div>
        )
      }
    >
      {isCompanyAvailable && isOnline && (
        <div className='menu-options-item d-flex align-items-center justify-content-end'>
          <Button
            type='button'
            onClick={
              Cookie.get('pos-block-api-request')
                ? deactivateBlockApiCall
                : activateBlockApiCall
            }
          >
            <Typography
              text={
                !Cookie.get('pos-block-api-request')
                  ? 'Simular caída de CA'
                  : 'Activar CA'
              }
              variant='white'
              type='body-3-bold'
            />
          </Button>
        </div>
      )}
      <div className='menu-options-item d-flex align-items-center justify-content-between gap-3'>
        <div>
          <Typography text={title} variant='primary' type='body-3-bold' />

          <Typography
            text={description}
            variant='secondary'
            type='caption-regular'
            withHtml
          />
        </div>

        <Button
          type='button'
          disabled={syncing || syncingInvoice || !isOnline || !isApiAvailable}
          onClick={() => dispatch(syncData())}
          size='icon'
        >
          <Icon
            icon='refresh'
            className={`icon-white ${syncing || (syncingInvoice && 'rotate-animation')}`}
          />
        </Button>
      </div>

      <div className='px-4 pt-2 pb-3 d-flex flex-column align-items-start justify-content-center'>
        <div
          className={`d-flex align-items-center justify-content-between w-100 ${offlineStatus && isOnline && !syncingInvoice && isApiAvailable ? 'py-0' : 'py-3'}`}
        >
          <div className='d-flex align-items-center'>
            {!offlineStatus ? (
              <Icon icon='circle-check' className='icon-primary mr-2' />
            ) : isOnline ? (
              <Icon icon='alert-circle'
                className={`${syncingInvoice || !isApiAvailable ? 'icon-secondary' : 'icon-amber'} icon-medium mr-2`}
              />
            ) : (
              <Icon icon='wifi-off' extraClass='icon-danger2 icon-medium mr-2' />
            )}

            <Typography
              text={I18n.get('sales', 'ventas')}
              variant='secondary'
              type='body-3-regular'
            />
          </div>

          {offlineStatus && isOnline && !syncingInvoice && isApiAvailable && (
            <Button size='sm' onClick={() => history.push('/invoices')}>
              <Typography
                type='label-2'
                text={I18n.get('reviewPending', 'Revisar pendientes')}
              />
            </Button>
          )}
        </div>

        <div className='d-flex align-items-center justify-content-between py-3 w-100'>
          <div className='d-flex align-items-center'>
            {itemsPercent >= 100 ? (
              <Icon icon='circle-check' className='icon-primary mr-2' />
            ) : (
              <Icon icon='download' className='icon-gray mr-2' />
            )}

            <Typography
              text={I18n.get('items', 'Productos')}
              variant='secondary'
              type='body-3-regular'
            />
          </div>

          {itemsPercent < 100 && (
            <Typography
              text={`${itemsPercent}%`}
              variant='tertiary'
              type='body-3-regular'
            />
          )}
        </div>

        <div className='d-flex align-items-center justify-content-between py-3 w-100'>
          <div className='d-flex align-items-center'>
            {clientsPercent >= 100 ? (
              <Icon icon='circle-check' className='icon-primary icon-primary mr-2' />
            ) : (
              <Icon icon='download' className='icon-gray mr-2' />
            )}

            <Typography
              text={I18n.get('clients', 'Clientes')}
              variant='secondary'
              type='body-3-regular'
            />
          </div>

          {clientsPercent < 100 && (
            <Typography
              text={`${clientsPercent}%`}
              variant='tertiary'
              type='body-3-regular'
            />
          )}
        </div>
      </div>
    </MenuOption>
  );
};

export default OfflineStatus;
