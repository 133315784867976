import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';
import PinchZoom, { make2dTransformValue } from 'react-quick-pinch-zoom'

import { stationInvoiceNumeration } from '../../../selectors/app'
import { termsAndConditions, anotation, electronicInvoicing, country as countrySelector, companySelector } from '../../../selectors/company'
import Print from '../../print/invoice/Print';
import invoice from './ExampleInvoice';

import { Icon } from '@alegradev/smile-ui-react';

const Preview = ({ values }) => {
  const isElectronic = useSelector(electronicInvoicing)
  const termsConditions = useSelector(termsAndConditions)
  const companyAnotation = useSelector(anotation)
  const numberTemplate = useSelector(stationInvoiceNumeration)
  const country = useSelector(countrySelector)
  const company = useSelector(companySelector);
  const [zoom, setZoom] = useState(false)
  const [zoomValues, setZoomValues] = useState({
    x: 0, y: 0, scale: 1
  })
  const prevRef = useRef(null)

  useEffect(() => {
    return () => {
      setZoom(false)
    }
  }, [])

  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: preview } = prevRef;

    if (preview) {
      preview.style.setProperty("transform", make2dTransformValue({ x, y, scale }))
      setZoomValues({ x, y, scale })
    }
  }, [])

  return (
    <div className="setting__print-preview-wrapper h-100 w-100 position-relative py-4 px-2
      d-flex flex-column align-items-center"
    >
      <h2 className="setting__print-preview-title text-capitalize-first text-muted mb-5">
        {I18n.get('invoicePreview', 'vista previa de tirilla')}
      </h2>

      <div className="bg-white">
        <Print setting={{
          ...values,
          template: get(values, 'template.value'),
          format: get(values, 'format.value'),
          printDemo: true
        }}
          invoice={invoice({
            isElectronic,
            termsConditions,
            companyAnotation,
            numberTemplate,
            country,
            company
          })}
          preview
        />
      </div>

      <button
        type="button"
        className="btn btn-sm rounded-circle shadow setting__print-preview-button"
        onClick={() => setZoom(true)}
      >
        <Icon icon='arrows-maximize' extraClass="icon-black" />
      </button>

      {!!zoom && (
        <div className="position-fixed setting__print-preview-zoom w-100 h-100">
          <button
            type="button"
            className="btn btn-sm button-transparent btn-close-preview-zoom"
            onClick={() => setZoom(false)}
          >
            <Icon icon='x' extraClass="icon-gray" />
          </button>

          <div className="setting__print-preview-zoom-wrapper">
            <div ref={prevRef} style={{ marginTop: "200px" }}>
              <PinchZoom
                onUpdate={onUpdate}
                children={(
                  <div className="bg-white">
                    <Print setting={{
                      ...values,
                      template: get(values, 'template.value'),
                      format: get(values, 'format.value'),
                      printDemo: true
                    }} invoice={invoice({
                      isElectronic,
                      termsConditions,
                      companyAnotation,
                      numberTemplate
                    })}
                      preview
                    />
                  </div>
                )}
              />
            </div>
          </div>

          <div className="btn-group btn-close-preview-zoom-control">
            <button
              type="button"
              className="btn btn-sm button-transparent"
              onClick={() => onUpdate({ ...zoomValues, scale: zoomValues.scale + 0.5 })}
            >
              <Icon icon='plus' extraClass="icon-gray" />
            </button>

            <button
              type="button"
              className="btn btn-sm button-transparent"
              onClick={() => zoomValues.scale > 1.5 ? onUpdate({ ...zoomValues, scale: zoomValues.scale - 0.5 }) : null}
            >
              <Icon icon='minus' className="icon-gray" />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Preview;