import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash'

import { colors } from '../../../utils'

import { addSelected, removeSelected, clearSelected } from '../../../reducers/itemCategories'
import { allItemCategories, selectedCategories } from '../../../selectors/itemCategories'

import { Icon } from '@alegradev/smile-ui-react';
import { sendNewGTMEvent } from '../../../reducers/company';

const randomColor = index => colors[index % colors.length];

const Category = ({ category, index, removeSelected, addSelected }) => {
  const [showImg, setShowImg] = useState(!!get(category, 'image.url', null))
  const dispatch = useDispatch()
  const selected = useSelector(selectedCategories)

  const searchSelectedCategory = () => {
    if(!selected.includes(parseInt(category.id))) {
      dispatch(sendNewGTMEvent('pos-search-category-selected'));
    }

    dispatch(selected.includes(parseInt(category.id))
      ? removeSelected(parseInt(category.id))
      : addSelected(parseInt(category.id)))
  }

  return (
    <div
      className={`item-category d-flex align-items-center ${(selected.includes(parseInt(category.id)) || selected.includes(category.id)) ? 'selected' : ''}`}
      onClick={searchSelectedCategory}
    >
      <div className="category-container">
        <div className="indicator">
          <div
            className="background d-flex justify-content-center align-items-center position-relative border"
            style={{ background: showImg ? 'transparent' : randomColor(index) }}
          >
            {showImg && (
              <img
                src={get(category, 'image.url')} alt=""
                onError={() => setShowImg(false)}
              />
            )}
            {!showImg && (
              <div className="initial position-absolute">
                {category.name[0]}
              </div>
            )}
          </div>
        </div>
      </div>
      <p className="name text-truncate text-break text-nowrap text-capitalize-first m-0 h5">
        {category.name}
      </p>
    </div>
  )
}

const ItemCategories = () => {
  const dispatch = useDispatch()
  const itemCategories = useSelector(allItemCategories)
  const selected = useSelector(selectedCategories)

  const hasCategories = !!itemCategories && !!itemCategories.length;

  if (!hasCategories) return null;

  return (
    <div className="item-categories d-flex flex-column">
      {!!selected.length && (
        <button
          type="button"
          className="btn btn-primary-dark w-100 rounded-0 clean-button"
          onClick={() => dispatch(clearSelected())}
        >
          <>
            <p className="text-capitalize-first text-white h5">
              {`${I18n.get('clean', 'limpiar')} (${selected.length / 2} ${selected.length / 2 === 1
                ? I18n.get('filter', 'filtro')
                : I18n.get('filters', 'filtros')
                })`}
            </p>
            <Icon icon='trash' className="icon-white" />
          </>
        </button>
      )}

      <div className="item-categories-container">
        {itemCategories.map((category, index) => (
          <Category
            key={index}
            index={index}
            category={category}
            removeSelected={removeSelected}
            addSelected={addSelected}
          />
        ))}
      </div>
    </div>
  )
}

export default ItemCategories;
