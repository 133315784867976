import { get } from 'lodash';

export const republicaDominicanaTransformer = (values, _props, newValues) => {
  const identificationType = get(values, 'identification.type', null);
  const identificationNumber = get(values, 'identification.number', null);

  const foreignId = !(
    identificationType === 'RNC' || identificationType === 'CED'
  );
  const showCountry = !!identificationType && foreignId;

  const countryKey = get(values, 'address.country', null);
  const countryValue = get(values, 'address.country', null);
  const combinedAddress = get(values, 'address.combined', null);
  const splitAddress = combinedAddress?.split(', ');
  const address = get(values, 'address.address', null);

  let newAddress = {
    country: showCountry ? countryValue : 'República Dominicana',
    address: address,
    description: address,
  };

  if (!!combinedAddress && (!foreignId || countryKey === 'DOM')) {
    newAddress = {
      ...newAddress,
      municipality: (combinedAddress && splitAddress[0]) || null,
      province: (combinedAddress && splitAddress[1]) || null,
    };
  }

  return {
    ...newValues,
    identification: {
      type: identificationType || '',
      number: identificationNumber || '',
    },
    address: newAddress,
  };
};
