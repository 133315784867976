import React, { useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';
import { capitalize, get, isEmpty, remove, uniqBy } from 'lodash';

import { renderDynamicField, renderSelect } from '../fields/V0/Fields';
import { notRequiredFields, requiredFields } from '../../../selectors/customFields';
import { country as countrySelector } from '../../../selectors/company';
import { openModal } from '../../../reducers/modals';

import { Icon } from '@alegradev/smile-ui-react';

const changeOptionsName = (customFields, country) => {
  const customFieldsNew = customFields.map((customField) => (
    {
      id: customField.id,
      key: customField.key,
      name: customField.name,
      description: customField.description,
      defaultValue: customField.defaultValue,
      optionsList: customField.options,
      resourceType: customField.resourceType,
      settings: customField.settings,
      status: customField.status,
      type: customField.type,
      value: customField.value,
    }
  ))

  if (country === 'mexico') {
    return customFieldsNew.filter(customField => get(customField, 'key') !== 'importRequest');
  }
  return customFieldsNew;
}

const AdicionalFields = ({ values, form, existentCustomFields }) => {
  const requiredCustomFields = useSelector(requiredFields);
  const customFields = useSelector(notRequiredFields);
  const country = useSelector(countrySelector);
  const [selectedCustomFields, setSelectedCustomFields] = useState([]);
  const dispatch = useDispatch();

  const removeCustomField = (id, values) => {
    const newCustomFields = remove(selectedCustomFields, (customField) => (
      customField.id !== id
    ));
    setSelectedCustomFields(newCustomFields);
    values.selectedCustomFields = newCustomFields;
    delete values.customFields[`cf${id}`];
  }

  useEffect(() => {
    setSelectedCustomFields(uniqBy(changeOptionsName(customFields).filter((customField) => (
      JSON.stringify(get(existentCustomFields, `cf${get(customField, 'id')}`))
    )), 'id'))
  }, [customFields, existentCustomFields])

  useEffect(() => {
    if (!isEmpty(values.selectedCustomFields))
      setSelectedCustomFields(uniqBy(values.selectedCustomFields, 'id'))
    else
      setSelectedCustomFields([])
  }, [values.selectedCustomFields])

  return (
    <div className="form-body__fields justify-content-start mt-3">
      <div className="form-row col-12 justify-content-between  p-0">
        <Field
          name="selectedCustomFields"
          className="col-9"
          component={renderSelect}
          showFavoriteIcon={false}
          options={changeOptionsName(customFields, country)}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          defaultValue={selectedCustomFields}
          addOptionText={`+ ${capitalize(I18n.get('newCustomField', 'Nuevo campo adicional'))}`}
          addOptionAction={() => dispatch(openModal({ modal: 'customField' }))}
          isMulti
        />
        {/* <Tooltip
          tooltipActive={!get(values, 'selectedCustomFields.length', false)}
          overlay={(
            <p className="text-capitalize-first">
              {I18n.get("addCustomFieldHelp", "Selecciona los campos adicionales que deseas agregar")}
            </p>
          )}
          className="col-2"
        >
          <button
            type="button"
            className="btn btn-submit"
            onClick={() => setSelectedCustomFields(values.selectedCustomFields)}
            disabled={!get(values, 'selectedCustomFields.length', false)}
          >
            {capitalize(I18n.get('add', 'Agregar'))}
          </button>
        </Tooltip> */}
      </div>

      {
        selectedCustomFields.map((selectedCustomField, index) => {
          return (
            <div key={index} className="form-row col-6 align-items-end p-0">
              <Field
                name={`customFields.cf${get(selectedCustomField, 'id')}`}
                label={get(selectedCustomField, 'name')}
                fieldType={get(selectedCustomField, 'type')}
                options={get(selectedCustomField, 'optionsList')}
                initialValue={existentCustomFields ?
                  existentCustomFields[`cf${get(selectedCustomField, 'id')}`] :
                  get(selectedCustomField, 'defaultValue')
                }
                component={renderDynamicField}
                className="col-10"
              />
              <button
                type="button"
                className="btn button-transparent col-2 mb-3 align-self-end"
                style={{ height: '3.6rem', outline: 'none', boxShadow: 'none' }}
                onClick={() => removeCustomField(get(selectedCustomField, 'id'), values, form)}
              >
                <Icon icon='x' extraClass="icon-secondary" />
              </button>
            </div>
          )
        })
      }

      <div className="form-row col-12 justify-content-between p-0 mt-3">
        {requiredCustomFields.map((field, index) => {
          return (
            <Field
              key={index}
              name={`customFields.cf${get(field, 'id')}`}
              label={get(field, 'name')}
              fieldType={get(field, 'type')}
              options={get(field, 'options')}
              component={renderDynamicField}
              value={get(
                existentCustomFields,
                `cf${get(field, 'id')}`,
                get(field, 'defaultValue')
              )}
              className='col-6'
              required
            />
          );
        })}
      </div>
    </div>
  )
}

export default AdicionalFields;