import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  country as countrySelector,
  companySelector,
} from '../../selectors/company';
import { COUNTRIES } from '../../utils/enums/countries';

export const CONTACTS_ACTIVE_COUNTRIES = [
  COUNTRIES.INTERNATIONAL,
  COUNTRIES.USA,
  COUNTRIES.SPAIN,
];

export const CONTACTS_ACTIVE_IDS = [
  '114410',
  '114501',
  '114506',
  '119107',
  '120123',
  '120350',
  '1406829',
  '1407099',
  '1423840',
  '1171753',
];

const useContactsVersionsGroup = () => {
  const [isContactsActive, setIsContactsActive] = useState(false);
  const company = useSelector(companySelector);
  const country = useSelector(countrySelector);

  useEffect(() => {
    if (process.env.REACT_APP_ALEGRA_COOKIE === 'al-test-days-of-thunder') {
      setIsContactsActive(true);
    }

    if (
      CONTACTS_ACTIVE_COUNTRIES.includes(country) ||
      CONTACTS_ACTIVE_IDS.includes(company?.id)
    ) {
      setIsContactsActive(true);
    }
  }, [company, country]);

  return {
    isContactsActive,
  };
};

export default useContactsVersionsGroup;
