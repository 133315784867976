import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { get, lowerFirst, isEmpty, isUndefined, isNull } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';

import * as mutations from '../../../graphql/mutations';
import * as itemsDB from '../../../database/itemsDB';
import { toast, replaceAndParse } from '../../../utils';
import { formError, handleError } from '../../../utils/errors';
import { itemTransformer } from '../../../utils/transformers';
import { isItemFromStation } from '../../../utils/items';
import { closeModal } from '../../../reducers/modals';
import { sendGTMEvent, sendNewGTMEvent } from '../../../reducers/company';
import {
  country as countrySelector,
  regime as regimeSelector,
  electronicInvoicing,
} from '../../../selectors/company';
import { APIGraphqlSelector, stationWarehouse } from '../../../selectors/app';
import { requiredFields } from '../../../selectors/customFields';
import { refresh } from '../../../reducers/items';
import Modal from '../../common/Modal';
import Body from '../../forms/itemSimplified';
import { validate, transform } from '../../forms/itemSimplified/utils';
import Notification from '../common/Notification';
import SelectType from './SelectType';
import Bottom from './Bottom';
import { primaryText, secondaryText, helpText } from './utils';
import { endAction, startAction } from '../../../reducers/monitoring';
import { calculateActionTimeSelector } from '../../../selectors/monitoring';

const NewItem = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const isOpen = useSelector((state) =>
    get(state, 'modals.item.isOpen', false)
  );
  const onRefresh = useSelector((state) =>
    get(state, 'modals.item.params.onRefresh', false)
  );
  const origin = useSelector((state) =>
    get(state, 'modals.item.params.origin', 'Invoicing')
  );
  const country = useSelector(countrySelector);
  const customFields = useSelector(requiredFields);
  const warehouse = useSelector(stationWarehouse);
  const regime = useSelector(regimeSelector);
  const isElectronic = useSelector(electronicInvoicing);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const calculateActionTime = useSelector(calculateActionTimeSelector);

  const close = () => {
    dispatch(closeModal({ modal: 'item' }));
  };

  if (isOpen && get(customFields, 'length') > 10) {
    history.push('/items/add');
    close();
  }

  const submit = async (values) => {
    try {
      dispatch(startAction({action: 'createItem'}));
      setLoading(true);
      const transformedValues = transform(values, {
        country,
        customFields,
      });

      const response = await APIGraphql(
        graphqlOperation(mutations.createItem, {
          item: itemTransformer(transformedValues),
        })
      );

      const { id: idWarehouse } = warehouse;
      const item = get(response, 'data.createItem', null);

      dispatch(endAction({action: 'createItem'}));
      dispatch(
        sendGTMEvent('item-created', {
          creationOrigin: 'basic form',
          hasVariants: get(item, 'type', null) === 'variantParent',
          hasAdditionalFields: !isEmpty(get(item, 'customFields', [])),
          itemType: get(item, 'type', null),
          hasCategory: !!get(item, 'itemCategory', null),
        })
      );

      dispatch(
        sendNewGTMEvent('pos-item-created', {
          id: get(item, 'id', null),
          error: 'no error',
          responseTime: calculateActionTime("createItem"),
          type: get(item, 'type', null),
          method: 'basic',
          origin,
          field: Object.keys(transformedValues).filter(
            (key) =>
              !isUndefined(transformedValues[key]) &&
              !isNull(transformedValues[key])
          ),
        })
      );

      if (isItemFromStation(item, idWarehouse)) {
        await itemsDB.put({ ...item, id: parseInt(get(item, 'id')) });
        dispatch(refresh());
      }

      close();

      if (!!onRefresh) onRefresh();

      toast.success({
        title: `${I18n.get('itemCreatedSuccessfully', 'Directo al blanco')}`,
        subtitle: replaceAndParse(
          I18n.get(
            'itemCreatedSuccessfullyMessage',
            'El {} {} ya está disponible en tu cuenta.'
          ),
          [
            get(item, 'type') !== 'simple'
              ? lowerFirst(I18n.get(get(item, 'type'), 'producto'))
              : lowerFirst(I18n.get('product', 'producto')),
            `<span class="font-weight-bold">${get(item, 'name', '')}</span>`,
          ]
        ),
      });
    } catch (error) {
      setLoading(false);
      const parseError = handleError(error);
      dispatch(
        sendNewGTMEvent('pos-item-created', {
          error: parseError,
          method: 'basic',
        })
      );
      return formError(
        error,
        I18n.get('createItemError', 'hubo un error en la creación del producto')
      );
    }
    setLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => close()}
      hideClose={loading}
      title={
        <div className='d-flex justify-content-between align-items-center w-100'>
          <div className='modal__header-title text-capitalize-first h2 text-title-secundary text-truncate'>
            {I18n.get('basicItemForm', 'Formulario básico de items')}
          </div>
        </div>
      }
      className='modal__item'
      id='item-modal-portal'
    >
      <Form
        onSubmit={submit}
        validate={(values) =>
          validate(values, {
            country,
            customFields,
            regime,
            isElectronic,
          })
        }
        initialValues={{
          type: 'product',
          warehouse,
        }}
        keepDirtyOnReinitialize
      >
        {({
          handleSubmit,
          values,
          form,
          error,
          submitting,
          submitError,
          submitFailed,
          hasValidationErrors,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className='d-flex flex-column form-body modal__item-body '>
              <SelectType values={values} />

              <p className='py-2 h5 text-subtitle'>{helpText(values.type)}</p>

              <Body type={values.type} values={values} form={form} />
            </div>

            {!!error && !!submitFailed && (
              <Notification isOpen={true} text={error} type='error' />
            )}

            {!!submitError && !!submitFailed && (
              <Notification isOpen={true} text={submitError} type='error' />
            )}

            <Bottom
              type={values.type}
              primaryText={primaryText(values.type)}
              secondaryText={secondaryText(values.type)}
              goToAdvancedForm={() => {
                history.push('/items/add', {
                  type: values.type,
                  initValues: values,
                });
                close();
              }}
              disabled={submitting}
              submitting={submitting}
              hasValidationErrors={hasValidationErrors}
            />
          </form>
        )}
      </Form>
    </Modal>
  );
};

export default NewItem;
