import { I18n } from 'aws-amplify';

export const empty = {
  title: I18n.get('noContacts', 'No hay contactos'),
  description: I18n.get('noContactsDescription', 'No se encontraron contactos'),
  hideAction: true,
};

export const noCreatedContacts = (history) => ({
  generalIcon: 'user-plus',
  title: I18n.get('createFirstClient', '¡Crea tu primer cliente! 👥'),
  description: I18n.get(
    'createFirstClientDescription',
    'Aquí podrás gestionar todos los contactos de tu punto de venta.'
  ),
  action: {
    label: I18n.get('createClient', 'Crear cliente'),
    handler: () => history?.push('/contacts/contact'),
  },
});
