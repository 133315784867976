export const filters = {
  id: 'status',
  title: 'Filtrar',
  options: [
    {
      label: 'Estado',
      value: 'status',
      icon: 'bulb',
      filterOptions: [
        {
          label: 'Activo',
          value: 'active',
          icon: 'bulb',
        },
        {
          label: 'Inactivo',
          value: 'inactive',
          icon: 'bulb-off',
        },
      ],
    },
  ],
};
