function panamaInitialValues({
  contact,
  defaultAccountReceivableCategory,
  defaultDebtToPayCategory,
  isElectronic,
}) {
  if (!!contact) {
    return {
      ...contact,
      ...contact?.nameObject,
      firstName: contact?.nameObject?.firstName || contact?.name,
      identification: {
        ...contact?.identificationObject,
        number: contact?.identificationObject
          ? contact?.identificationObject?.number
          : contact?.identification?.number,
        type: contact?.thirdsType,
      },
      email: contact?.email,
      address: {
        ...contact?.address,
        combined: `${contact?.address?.township}, ${contact?.address?.district}, ${contact?.address?.province}`,
      },
      seller: contact?.seller?.id ? contact?.seller?.id : contact?.seller,
      priceList: contact?.priceList?.id
        ? contact?.priceList?.id
        : contact?.priceList,
      term: contact?.term?.id ? contact?.term?.id : contact?.term,
      statementAttached: contact?.statementAttached,
      accounting: {
        accountReceivable: contact?.accounting?.accountReceivable?.id,
        debtToPay: contact?.accounting?.debtToPay?.id,
      },
    };
  }

  return {
    accounting: {
      accountReceivable: defaultAccountReceivableCategory?.id,
      debtToPay: defaultDebtToPayCategory?.id,
    },
    isCorrectRuc: null,
    isElectronic,
    statementAttached: true,
    ignoreRepeated: false,
  };
}

export default panamaInitialValues;
