import React, { useState, useEffect } from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { companySelector, country as countrySelector } from '../../../selectors/company';
import { economicActivitiesSelector } from '../../../selectors/auth';

import { Icon } from '@alegradev/smile-ui-react';

const EconomicActivityNotification = () => {
  const company = useSelector(companySelector);
  const country = useSelector(countrySelector);
  const [notification, setNotification] = useState({ 'economicActivity': false });
  const hasFEactive = get(company, 'settings.electronicInvoicing', false);
  const listEconomicActivities = useSelector(economicActivitiesSelector);
  
  const closeNotification = key => setNotification(prev => ({ ...prev, [key]: false }))
  const openNotification = key => setNotification(prev => ({ ...prev, [key]: true }))

  useEffect(() => {
    // fix temporal for user cri
    if(company.id === '672963') return;
    const showEAnotif = !Cookies.get('_ea-cri-n');
    if(country === 'costaRica' && hasFEactive && listEconomicActivities.length === 1 && showEAnotif){
      openNotification('economicActivity');
    }
  }, [company, country, hasFEactive, listEconomicActivities.length])

  const buttonAction = () => {
    window.open("https://ayuda.alegra.com/es/configura-la-actividad-econ%C3%B3mica-de-tus-facturas-en-el-punto-de-venta", "_blank");
  }

  const closeAction = () => {
    closeNotification('economicActivity');
    Cookies.set('_ea-cri-n', Date.now());
  }

  return (
    <>
      {notification.economicActivity && (
        <Notification
          info
          title={I18n.get('youCanConfigureYourEconomicActivities', '¡Configura todas tus actividades económicas!⚡')}
          description={I18n.get('youCanConfigureYourEconomicActivities.description', 'Marca como preferida la que más utilizas y cámbiala si es necesario al momento de vender desde tu Punto de venta.')}
          buttonText={I18n.get('goToConfigureActivities', 'Cómo configurarlas')}
          buttonAction={buttonAction}
          closeAction={closeAction} />
      )}
    </>
  )
}

const Notification = ({ title, description, buttonText, buttonAction, closeAction, warning, info }) => {
  if (warning && info) throw new Error("La notificación no puede ser de tipo warning y info a la misma vez")

  const styles = {
    border: warning ? "#F99D36" : "#4A90E2",
    background: warning ? "#FFF7EB" : "#EEFCFF",
    iconBackground: warning ? "#FF980033" : "",
    buttonColor: warning ? "#FF9811" : "#4A90E2"
  }

  return (
    <div className="main-notification d-block d-md-block">
      <div className="main-notification__notification d-flex align-items-start p-2" style={{ borderBottom: `.2rem solid ${styles.border}`, backgroundColor: styles.background }}>
        <div className="align-self-center d-flex align-items-center justify-content-center ml-3" style={{ backgroundColor: styles.iconBackground, minWidth: "36px", height: "36px", borderRadius: ".7rem" }}>
          {warning
            ? <Icon icon='alert-triangle' />
            : <Icon icon='info-circle' />
          }
        </div>

        <div className="d-flex flex-column align-items-start justify-content-between w-100 p-1 px-3">
          <div className="m-0 h4">
            <div className="m-0 h5 d-inline">
              <b>{title}</b>
              <p className="m-0 mt-1">{description}</p>
            </div>
          </div>
        </div>

        {buttonText && (
          <button
            onClick={buttonAction}
            className="btn text-capitalize-first align-self-center"
            style={{
              margin: 0,
              minWidth: '13rem',
              border: `1px solid ${styles.border}`,
              boxSizing: 'border-box',
              borderRadius: '3px',
              fontSize: '1.1rem',
              fontWeight: 900,
              backgroundColor: styles.buttonColor,
              color: '#FFFFFF',
              padding: '.3rem 0',
              outline: 'none',
              '&:hover': {
                '&:not(:disabled)': {
                  border: `1px solid ${styles.border} !important`,
                }
              },
              '&:focus': {
                border: `1px solid ${styles.border} !important`,
              }
            }}
          >
            {buttonText}
          </button>
        )}
        <div className="text-right px-2 pointer" onClick={closeAction}>
          <Icon icon='x' extraClass="icon icon-title" />
        </div>
      </div>
    </div>
  )
}

Notification.propTypes = {
  warning: PropTypes.bool,
  info: PropTypes.bool
}

export default EconomicActivityNotification