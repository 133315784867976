import React from 'react';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import { Field, useForm } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, useModal } from '@alegradev/smile-ui-react';
import { renderField } from '../../../../../components/forms/fields/V2';
import { hasPermissionTo } from '../../../../../selectors/auth';
import { activePriceLists } from '../../../../../selectors/priceLists';
import { activeSellers } from '../../../../../selectors/sellers';
import { isOnlyInvoicingPlan } from '../../../../../selectors/company';
import { activePaymentTerms } from '../../../../../selectors/paymentTerms';
import { checkFeatureLimit } from '../../../../../reducers/membership';

import Seller from '../modals/Seller';
import PriceList from '../modals/PriceList';
import PaymentTerm from '../modals/PaymentTerm';
import { sendNewGTMEvent } from '../../../../../reducers/company';

const RenderCommom = (props) => {
  const { Col } = Grid;
  const { openModal } = useModal();
  const form = useForm();
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const priceLists = useSelector(activePriceLists);
  const paymentTerms = useSelector(activePaymentTerms);
  const sellers = useSelector(activeSellers);
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan);

  return (
    <>
      <Col xs={6} sm={12}>
        <Field
          name='priceList'
          component={renderField}
          fieldType='select'
          disabled={onlyInvoicing}
          onlyInvoicingTooltip={onlyInvoicing}
          label={capitalize(I18n.get('priceList', 'Lista de precios'))}
          placeholder={I18n.get('select', 'Seleccionar')}
          options={
            !can('index', 'price-lists') || !can('view', 'price-lists')
              ? []
              : priceLists?.map((option) => ({
                  text: option.name,
                  value: `${option.id}`,
                }))
          }
          footer={
            !can('index', 'price-lists') || !can('view', 'price-lists')
              ? undefined
              : {
                  action: {
                    text: !!props.newPriceListAction
                      ? capitalize(
                          I18n.get('newPriceList', 'Nueva lista de precio')
                        )
                      : null,
                    emphasis: 'text',
                    onClick: () =>
                      dispatch(
                        checkFeatureLimit('priceLists', () =>
                          openModal({
                            name: 'priceList',
                            title: I18n.get(
                              'newPriceList',
                              'nueva lista de precio'
                            ),
                            type:
                              props?.from === 'sideModal'
                                ? 'sideModal'
                                : 'default',
                            component: PriceList,
                            props: {
                              modalName: 'priceList',
                              onCreated: (priceList) =>
                                form.change('priceList', priceList?.id),
                            },
                          })
                        )
                      ),
                  },
                }
          }
          search={!can('index', 'price-lists') || !can('view', 'price-lists')}
          dropdownLabel={
            !can('index', 'price-lists') || !can('view', 'price-lists')
              ? I18n.get(
                  'userNotAllowed.price-lists.view',
                  'no tienes permisos para ver la información de las listas de precio'
                )
              : null
          }
          noOptionsText={I18n.get('noPriceLists', 'No hay listas de precios')}
        />
      </Col>

      <Col xs={6} sm={12}>
        <Field
          name='term'
          component={renderField}
          fieldType='select'
          label={capitalize(I18n.get('paymentTerm', 'plazo de pago'))}
          placeholder={I18n.get('select', 'Seleccionar')}
          dropdownLabel={
            !can('index', 'terms')
              ? I18n.get(
                  'userNotAllowed.terms.view',
                  'no tienes permisos para ver los plazos de pago'
                )
              : null
          }
          search={!can('index', 'terms')}
          noOptionsText={I18n.get('noTerms', 'No hay plazos de pago')}
          footer={
            !can('index', 'terms')
              ? undefined
              : {
                  action: {
                    text: !!props.newPaymentTermAction
                      ? capitalize(
                          I18n.get('newPaymentTerm', 'Nuevo plazo de pago')
                        )
                      : null,
                    emphasis: 'text',
                    onClick: () =>
                      openModal({
                        name: 'paymentTerm',
                        title: I18n.get(
                          'newPaymentTerm',
                          'Nuevo plazo de pago'
                        ),
                        type:
                          props?.from === 'sideModal' ? 'sideModal' : 'default',
                        component: PaymentTerm,
                        props: {
                          modalName: 'paymentTerm',
                          onCreated: (term) => form.change('term', term?.id),
                        },
                      }),
                  },
                }
          }
          options={
            !can('index', 'terms')
              ? []
              : paymentTerms?.map((option) => ({
                  text: option.name,
                  value: `${option.id}`,
                }))
          }
        />
      </Col>

      <Col xs={6} sm={12}>
        <Field
          name='seller'
          component={renderField}
          fieldType='select'
          label={capitalize(I18n.get('seller', 'vendedor'))}
          placeholder={I18n.get('select', 'Seleccionar')}
          options={sellers?.map((option) => ({
            text: option.name,
            value: `${option.id}`,
          }))}
          search
          noOptionsText={I18n.get('noSellers', 'No hay vendedores')}
          footer={{
            action: {
              text:
                can('add', 'sellers') && !!props.newSellerAction
                  ? capitalize(I18n.get('newSeller', 'Nuevo vendedor'))
                  : null,
              emphasis: 'text',
              onClick: () =>
                dispatch(
                  checkFeatureLimit(
                    'sellers',
                    can('add', 'sellers')
                      ? () =>
                          openModal({
                            name: 'seller',
                            title: I18n.get('newSeller', 'Nuevo vendedor'),
                            type:
                              props?.from === 'sideModal'
                                ? 'sideModal'
                                : 'default',
                            component: Seller,
                            props: {
                              modalName: 'seller',
                              from: props?.from || 'default',
                              onCreated: (seller) => {
                                form.change('seller', seller?.id);
                              },
                            },
                          })
                      : null
                  )
                ),
            },
          }}
        />
      </Col>
    </>
  );
};

export default RenderCommom;
