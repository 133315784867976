import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { capitalize, get, isObject } from 'lodash';
import { handleError } from '../../../utils/errors';
import { checkFeatureLimit } from '../../../reducers/membership';
import { openModal } from '../../../reducers/modals';
import { hasPermissionTo } from '../../../selectors/auth';
import { country as countrySelector } from '../../../selectors/company';
import { useIsMutating } from '@tanstack/react-query';

import Table from '../../common/GrayTable';
import Header from '../../settings/common/Header';
import Actions from './Actions';
import { getWarehouseHelpUrl } from '../../../utils';
import { useConnectionStatus } from '../../../hooks/useConnectionStatus';
import { useDeleteWarehouse, useUpdateWarehouse } from './mutations';
import { useWarehousesQuery } from './queries';
import { Filters as FiltersClass } from '../../../utils/filter';
import { Tooltip } from '@alegradev/smile-ui-react';

import { Icon } from '@alegradev/smile-ui-react';
import { sendNewGTMEvent } from '../../../reducers/company';

const filtersInstance = new FiltersClass({
  metadata: true,
  order_field: 'name',
  order_direction: 'ASC',
});

const Warehouses = () => {
  const connectionStatus = useConnectionStatus();
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const ref = useRef(null);
  const [error, setError] = useState();
  const [tableIndicators, setTableIndicators] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const country = useSelector(countrySelector);

  const [filters, setFilters] = useState({
    ...filtersInstance.getFilters(),
  });
  filtersInstance.setUpdater(setFilters);

  const { mutate: deleteWarehouse } = useDeleteWarehouse();
  const { mutate: updateWarehouse } = useUpdateWarehouse();

  const isMutating = useIsMutating();

  useEffect(() => {
    ref.current = true;
    window.dataLayer.push({
      event: 'VirtualPageview',
      virtualPageURL: '/inventory/warehouses',
      virtualPageTitle: 'Warehouses Inventory',
    });

    return () => (ref.current = false);
  }, []);

  const {
    data,
    isFetching,
    refetch,
    error: queryError,
    isError,
  } = useWarehousesQuery(filters);

  useEffect(() => {
    if (isError)
      setError(
        handleError(
          queryError,
          I18n.get(
            'warehousesLoadError',
            'Sucedió un error cargando las bodegas'
          )
        )
      );
  }, [queryError, isError]);

  useEffect(() => {
    filtersInstance.updatePagination(tableIndicators);
  }, [tableIndicators]);

  const handleOpenWarehouseModal = () => {
    dispatch(
      checkFeatureLimit('warehouses', () => {
        dispatch(
          openModal({
            modal: 'warehouse',
            params: {
              onRefresh: () => refetch(),
            },
          })
        );
        dispatch(sendNewGTMEvent('pos-warehouse-started'));
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: capitalize(I18n.get('name', 'Nombre')),
        sortable: false,
        accessor: 'name',
        Cell: ({ value, row }) => (
          <p
            className='h4 text-primary text-truncate pointer styled-hover'
            onClick={() =>
              can('edit', 'items')
                ? dispatch(
                    openModal({
                      modal: 'warehouse',
                      params: {
                        warehouse: {
                          ...row.original,
                          address: isObject(row?.original?.address)
                            ? row?.original?.address?.description ?? ''
                            : row?.original?.address,
                        },
                        onRefresh: () => refetch(),
                      },
                    })
                  )
                : null
            }
            hovercontent={value}
          >
            {value}
          </p>
        ),
      },
      {
        Header: capitalize(I18n.get('address', 'dirección')),
        accessor: 'address',
        Cell: ({ value }) => {
          if (isObject(value)) {
            return value?.description;
          } else {
            return <p className='h5 text-muted text-truncate'>{value}</p>;
          }
        },
      },
      {
        Header: capitalize(I18n.get('observations', 'observaciones')),
        accessor: 'observations',
        Cell: ({ value }) => (
          <p className='h5 text-muted text-truncate'>{value}</p>
        ),
      },
      {
        Header: capitalize(I18n.get('costCenter', 'centro de costo')),
        accessor: 'costCenter',
        Cell: ({ value }) => (
          <p className='h5 text-muted text-truncate'>
            {get(value, 'name', I18n.get('none', 'Ninguno'))}
          </p>
        ),
      },
      {
        Header: capitalize(I18n.get('actions', 'Acciones')),
        id: 'actions',
        sortable: false,
        minWidth: 110,
        maxWidth: 150,
        Cell: ({ row }) => (
          <Actions
            warehouse={row.original}
            onRefresh={() => refetch()}
            onView={() =>
              dispatch(
                openModal({
                  modal: 'warehouse',
                  params: {
                    warehouse: {
                      ...row.original,
                      address: isObject(row?.original?.address)
                        ? row?.original?.address?.description ?? ''
                        : row?.original?.address,
                    },
                    onRefresh: () => refetch(),
                  },
                })
              )
            }
            deleteWarehouse={deleteWarehouse}
            updateWarehouse={updateWarehouse}
          />
        ),
      },
    ],
    [refetch, dispatch, can, deleteWarehouse, updateWarehouse]
  );

  return (
    <div className='container p-5'>
      <div className='d-flex flex-column'>
        <Header
          title={
            <p className='text-title-secundary'>
              {I18n.get('warehouses', 'bodegas')}
            </p>
          }
          subtitle={
            <p className='h5 text-subtitle-muted text-capitalize-first'>
              {I18n.get(
                'warehousesInventorySubtitle',
                'Crea bodegas para gestionar tu inventario en diferentes lugares de almacenamiento y distribución.'
              )}
              <a
                href={getWarehouseHelpUrl(country)}
                target='_blank'
                rel='noreferrer'
              >
                {I18n.get('knowMore', 'Saber más')}
              </a>
            </p>
          }
          actions={
            <div className='d-flex'>
              <Tooltip
                visible={!can('add', 'warehouses')}
                overlay={I18n.get(
                  'userNotAllowed.items.add',
                  'no tienes permisos para agregar productos'
                )}
              >
                <button
                  type='button'
                  disabled={!can('add', 'warehouses') || !connectionStatus}
                  className='btn btn-submit-large d-flex justify-content-center align-items-center'
                  style={{ padding: '8px', width: '187px' }}
                  onClick={handleOpenWarehouseModal}
                >
                  <Icon icon='plus' extraClass='icon icon-white pr-1' />
                  {I18n.get('newWarehouse', 'Nueva bodega')}
                </button>
              </Tooltip>
            </div>
          }
        />

        <div className='shadowp-4'>
          <Table
            loading={isFetching || Boolean(isMutating)}
            data={data?.data ?? []}
            total={data?.metadata?.total || 0}
            error={error}
            onRefresh={refetch}
            columns={columns}
            onFetchData={setTableIndicators}
            _pageSize={tableIndicators.pageSize}
            // controlledPageCount={controlledPageCount}
            noDataText={
              <div className='text-center'>
                <p>
                  {capitalize(
                    I18n.get(
                      'warehouseNoDataText',
                      'Crea tu primera bodega en segundos. 🙌'
                    )
                  )}
                </p>
                <Tooltip
                  visible={!can('add', 'warehouses')}
                  overlay={I18n.get(
                    'userNotAllowed.warehouses.add',
                    'no tienes permisos para agregar bodegas'
                  )}
                >
                  <button
                    type='button'
                    className='btn btn-submit mr-3'
                    onClick={handleOpenWarehouseModal}
                  >
                    {I18n.get('newWarehouse', 'Nueva bodega')}
                  </button>
                </Tooltip>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Warehouses;
