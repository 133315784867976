import { I18n } from 'aws-amplify';
import { get, capitalize } from 'lodash';

export const colombiaValidation = (values, { isElectronic }) => {
  let errors = {};

  const identificationType = get(values, 'identification.type', '');
  const identificationNumber = get(values, 'identification.number', '');
  const lastName = get(values, 'lastName', '');
  const kindOfPerson = get(values, 'kindOfPerson', '');
  const completeName =
    (identificationType === 'NIT' && kindOfPerson === 'PERSON_ENTITY') ||
    (identificationType !== 'NIT' && identificationType !== 'FOREIGN_NIT');

  if (!identificationType)
    errors.identification = {
      ...errors.identification,
      type: capitalize(
        I18n.get(
          'selectTheIdentificationType',
          'seleccione el tipo de identificación'
        )
      ),
    };

  if (!identificationNumber)
    errors.identification = {
      ...errors.identification,
      number: capitalize(I18n.get('enterAnID', 'Ingresa una identificación')),
    };

  if (
    identificationNumber &&
    ['RC', 'NIT', 'CC', 'NUIP', 'TI'].includes(identificationType)
  ) {
    const isCorrectFormat = /^[0-9]+$/.test(identificationNumber);
    if (!isCorrectFormat)
      errors.identification = {
        ...errors.identification,
        number: I18n.get(
          'onlyNumericValuesAreSupported',
          'Solo se admiten valores numéricos'
        ),
      };
  }

  if (!lastName && completeName)
    errors.lastName = capitalize(
      I18n.get(
        'youMustIncludeAtLeastOneLastName',
        'Debe incluir al menos un apellido'
      )
    );

  if (isElectronic) {
    const foreignId =
      identificationType === 'DIE' ||
      identificationType === 'PP' ||
      identificationType === 'TE' ||
      identificationType === 'FOREIGN_NIT';

    const combinedAddress = get(values, 'address.combined.key', '');
    const address = get(values, 'address.address', '');
    const regime = get(values, 'regime', '');
    const isRequired = () => {
      if (address || combinedAddress) {
        return true;
      }
      return false;
    };
    if (!kindOfPerson && identificationType === 'NIT')
      errors.kindOfPerson = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );
    if (!regime && identificationType === 'NIT')
      errors.regime = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );

    if (!foreignId) {
      if (isRequired()) {
        if (!combinedAddress)
          errors.address = {
            ...errors.address,
            combined: capitalize(
              I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
            ),
          };
        if (!address)
          errors.address = {
            ...errors.address,
            address: capitalize(
              I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
            ),
          };
      }
    }
  }
  return errors;
};
