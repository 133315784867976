import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  country as countrySelector,
  companySelector,
} from '../../selectors/company';
import { COUNTRIES } from '../../utils/enums/countries';

export const DECIMALS_ACTIVE_COUNTRIES = [
  COUNTRIES.INTERNATIONAL,
  COUNTRIES.SPAIN,
  COUNTRIES.CHILE,
  COUNTRIES.ARGENTINA,
];

const MEXICO_DECIMALS_ACTIVE_IDS = [];

const COLOMBIA_DECIMALS_ACTIVE_IDS = [
  '224386',
  '868768',
  '1233763',
  '519598',
  '1286884',
  '1218006',
  '394725',
  '268664',
  '293904',
  '89273',
  '1272521',
  '1344979',
  '1326432',
  '921591',
  '295148',
  '528234',
  '435970',
  '406725',
  '819555',
  '422030',
  '775327',
  '427377',
  '279643',
  '444629',
  '1344979',
  '357259',
  '295148',
  '408310',
  '1326432',
  '382520',
  '365463',
  '1176899',
  '700285',
  '1355496',
  '733751',
  '1157050',
  '538491',
  '257565',
  '921591',
  '775327',
  '1240227',
  '404507',
  '380571',
  '929766',
  '498978',
  '431961',
  '336003',
  '17883',
  '723632',
  '932579',
  '642327',
  '234904',
  '528234',
  '114190',
  '1401330',
  '633905',
  '1107863',
  '622865',
  '186602',
  '460261',
  '617082',
  '303572',
  '915834',
  '499995',
  '113779',
  '1160390',
  '417850',
  '1215115',
  '588132',
  '301847',
  '1264283',
  '926281',
  '1399639',
  '127667',
  '1334435',
  '37667',
  '418137',
  '176018',
  '295065',
  '156413',
  '801212',
  '627092',
  '888320',
  '401164',
  '418738',
  '406668',
  '1422013',
  '1284395',
  '301815',
  '1057652',
  '476484',
  '761592',
  '1195992',
  '1395017',
  '314434',
  '743651',
  '627814',
  '385882',
  '758727',
  '358603',
  '797654',
  '429766',
  '888320',
  '301847',
  '311184',
  '386659',
  '1460976',
  '1433587',
  '1259525',
  '1447673',
  '1349729',
  '2183',
  '55012',
  '1414639',
  '884012',
  '493347',
  '1212056',
  '1346398',
  '1441595',
  '753821',
  '118681',
  '492005',
  '1176899',
  '982000',
  '372181',
  '1422229',
  '542745',
  '1161251',
  '352113',
  '252105',
  '593434',
  '759850',
  '210667',
  '427927',
  '128404',
  '414884',
  '471020',
  '1223987',
  '854027',
  '1439431',
  '578589',
  '156185',
  '170358',
  '67998',
  '579854',
  '413314',
  '1404049',
  '1422229',
  '477057',
  '1243448',
  '1300487',
  '1427317',
  '1365437',
  '561594',
  '990205',
  '1387451',
  '217528',
  '1196063',
  '990205',
  '955803',
  '1448188',
  '1483204',
  '1280761',
  '360817',
  '1436942',
  '1451883',
  '460894',
  '1015466',
  '476390',
  '424870',
  '771419',
  '4742',
  '396594',
  '1486505',
  '62961',
  '163105',
  '249496',
  '291111',
  '295455',
  '289070',
  '271524',
  '306310',
  '322616',
  '324743',
  '326936',
  '371870',
  '314058',
  '381331',
  '382484',
  '394365',
  '394876',
  '395658',
  '396345',
  '399019',
  '364863',
  '383927',
  '400479',
  '400814',
  '402147',
  '403137',
  '406211',
  '406288',
  '408747',
  '409835',
  '410306',
  '411821',
  '414018',
  '414465',
  '414470',
  '414856',
  '393840',
  '403208',
  '418120',
  '418765',
  '419083',
  '419098',
  '419333',
  '420150',
  '420953',
  '421938',
  '423311',
  '423592',
  '423986',
  '424247',
  '425096',
  '426923',
  '427979',
  '432293',
  '433671',
  '408542',
  '412612',
  '423081',
  '424758',
  '444597',
  '448756',
  '452724',
  '459110',
  '410336',
  '417947',
  '395533',
  '441592',
  '476456',
  '481371',
  '503044',
  '507386',
  '517747',
  '529449',
  '532050',
  '428623',
  '537282',
  '545172',
  '566857',
  '581851',
  '485709',
  '501889',
  '591962',
  '602650',
  '613866',
  '575167',
  '664168',
  '55012',
  '1388262',
  '547919',
  '454655',
  '1242411',
  '1472878',
  '436778',
  '203889',
  '1315247',
  '39728',
  '656635',
  '483816',
  '362404',
  '1417820',
];

export const DECIMALS_ACTIVE_IDS = [
  ...MEXICO_DECIMALS_ACTIVE_IDS,
  ...COLOMBIA_DECIMALS_ACTIVE_IDS,
];

const useDecimalsVersionsGroup = () => {
  const [isDecimalActive, setIsDecimalActive] = useState(false);
  const company = useSelector(companySelector);
  const country = useSelector(countrySelector);

  useEffect(() => {
    if (
      DECIMALS_ACTIVE_COUNTRIES.includes(country) ||
      DECIMALS_ACTIVE_IDS.includes(company?.id)
    ) {
      setIsDecimalActive(true);
    }
  }, [company, country]);

  return {
    isDecimalActive,
  };
};

export default useDecimalsVersionsGroup;
