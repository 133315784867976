import { I18n } from '@aws-amplify/core';
import { useQuery } from '@tanstack/react-query';
import toastHandler from '../../../../utils/toast-handler';
import * as queries from '../../../../graphql/queries';
import { graphqlOperation } from '@aws-amplify/api';
import { handleError } from '../../../../utils/errors';
import alegraAPI from '../../../../reducers/alegraAPI';
import { get } from 'lodash';
import { format } from 'date-fns';

export const globalInvoiceKeys = {
  all: 'globalInvoiceTickets',
  detail: 'globalInvoiceTicketDetail',
};

const fetchGlobalInvoiceTicketDetail = async (id) => {
  const response = await alegraAPI.get(`/global-invoices/${id}?fields=pdf`);
  return response.data;
};

const fetchGlobalInvoiceTickets = async (metadata, api) => {
  const params = {
    batch: {
      start: 0,
      limit: 30,
      sortDirection: metadata?.pagination?.order_direction || 'DESC',
    },
    filter: {},
    includeMetadata: true,
  };

  if (metadata?.filters?.datePicker) {
    params.filter['startDate'] = format(
      metadata?.filters?.datePicker[0],
      'yyyy-MM-dd'
    );
    params.filter['endDate'] = format(
      metadata?.filters?.datePicker[1],
      'yyyy-MM-dd'
    );
  }

  const data = await api(
    graphqlOperation(queries.selectableInvoicesForGlobal, params)
  );

  return get(data, 'data.selectableInvoicesForGlobal', {});
};

export const useGlobalInvoiceTicketItemsQuery = (metadata, api) => {
  return useQuery({
    queryKey: [globalInvoiceKeys.all, metadata, api],
    queryFn: () => fetchGlobalInvoiceTickets(metadata, api),
    onError: (error) => {
      toastHandler({
        title: I18n.get('', 'Sucedió un error cargando las facturas'),
        description: handleError(error),
        type: 'error',
      });
    },
    keepPreviousData: false,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    enabled: !!metadata,
  });
};

export const useGlobalInvoiceTicketDetailQuery = (id) => {
  return useQuery({
    queryKey: [globalInvoiceKeys.detail, id],
    queryFn: () => fetchGlobalInvoiceTicketDetail(id),
    onError: (error) => {
      toastHandler({
        title: I18n.get('', 'Sucedió un error cargando la factura global'),
        description: handleError(error),
        type: 'error',
      });
    },
    keepPreviousData: false,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    enabled: !!id,
  });
};
