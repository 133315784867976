import { get, isNull, isUndefined } from 'lodash';
import { v4 } from 'uuid';

import { COUNTRIES } from '../../../../../utils/enums/countries';
import { colombiaTransformer } from './colombia/transformer';
import { mexicoTransformer } from './mexico/transformer';
import { argentinaTransformer } from './argentina/transformer';
import { costaRicaTransformer } from './costaRica/transformer';
import { peruTransformer } from './peru/transformer';
import { republicaDominicanaTransformer } from './republicaDominicana/transformer';
import { spainTransformer } from './spain/transformer';
import { panamaTransformer } from './panama/transformer';
import { trimValues } from '../../../../../utils/trimValues';
import { cleanObject } from '../../../../../utils/cleanObject';

const commomValues = (
  values,
  {
    contact,
    ignoreRepeated = false,
    defaultAccountReceivableCategory,
    defaultDebtToPayCategory,
  }
) => ({
  id: !!contact && !ignoreRepeated ? contact.id : v4(),
  type: ['client'],
  offline: true,
  name: get(values, 'firstName', null),
  identification: get(values, 'identification.number', null),
  address: get(values, 'address', null),
  mobile: get(values, 'mobile', null),
  phonePrimary: get(values, 'phonePrimary', null),
  phoneSecondary: get(values, 'phoneSecondary', null),
  email: get(values, 'email', null),
  priceList: get(values, 'priceList', null),
  seller: get(values, 'seller', null),
  ignoreRepeated,
  term: get(values, 'term', null) === -1 ? null : get(values, 'term', null),
  statementAttached: get(values, 'statementAttached', false) ? 'yes' : 'no',
  accounting: {
    accountReceivable: get(
      values,
      'accounting.accountReceivable',
      defaultAccountReceivableCategory?.id?.toString()
    ),
    debtToPay: get(
      values,
      'accounting.debtToPay',
      defaultDebtToPayCategory?.id?.toString()
    ),
  },
});

export const transform = (values, props) => {
  let trimmedValues = trimValues(values);
  let newValues = commomValues(trimmedValues, props);

  switch (props.country) {
    case COUNTRIES.COLOMBIA:
      newValues = colombiaTransformer(values, props, newValues);
      break;
    case COUNTRIES.COSTA_RICA:
      newValues = costaRicaTransformer(values, props, newValues);
      break;
    case COUNTRIES.ARGENTINA:
      newValues = argentinaTransformer(values, props, newValues);
      break;
    case COUNTRIES.PERU:
      newValues = peruTransformer(values, props, newValues);
      break;
    case COUNTRIES.REPUBLICA_DOMINICANA:
      newValues = republicaDominicanaTransformer(values, props, newValues);
      break;
    case COUNTRIES.PANAMA:
      newValues = panamaTransformer(values, props, newValues);
      break;
    case COUNTRIES.SPAIN:
      newValues = spainTransformer(values, props, newValues);
      break;
    case COUNTRIES.MEXICO:
      newValues = mexicoTransformer(values, props, newValues);
      break;
    default:
      break;
  }

  return cleanObject(newValues);
};
