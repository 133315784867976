import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, get, pick } from 'lodash';

import {
  accountMexicoReceivableCategories,
  accountReceivableCategories,
} from '../../../selectors/categories';
import { renderSelect } from '../fields/V0/Fields';
import { country as countrySelector } from '../../../selectors/company';
import { COUNTRIES } from '../../../utils/enums/countries';

const Accounting = (props) => {
  const accountReceivableOptions = useSelector(accountReceivableCategories);
  const accountMexicoReceivableOptions = useSelector(
    accountMexicoReceivableCategories
  );
  const country = useSelector(countrySelector);
  //const debtToPayOptions = useSelector(debtToPayCategories)

  const thirdType = get(props, 'values.thirdType', []);

  const mexicoReceivableOptions = useMemo(() => {
    if (country === COUNTRIES.MEXICO) {
      if (thirdType === 'NATIONAL') {
        return accountMexicoReceivableOptions?.national;
      }

      return accountMexicoReceivableOptions?.foreign;
    }
  }, [thirdType, country]);

  return (
    <div className='form-fields form-row justify-content-start'>
      <Field
        name='accounting.accountReceivable'
        component={renderSelect}
        className='col-12'
        label={capitalize(I18n.get('accountReceivable', 'cuenta por cobrar'))}
        options={
          country === COUNTRIES.MEXICO
            ? mexicoReceivableOptions
            : accountReceivableOptions
        }
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
      />

      {/* <Field
        name="accounting.debtToPay"
        component={renderSelect}
        className="col-12"
        label={capitalize(I18n.get('debtToPay', 'cuenta por pagar'))}
        options={debtToPayOptions}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
      /> */}
    </div>
  );
};

export default Accounting;
