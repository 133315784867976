import { combineReducers } from 'redux';

import AppReducer from './app';
import AuthReducer from './auth';
import ActiveInvoiceReducer from './activeInvoice';
import EditInvoiceReducer from './editInvoice';
import ModalsReducer from './modals';
import SideModalsReducer from './sideModals';
import ToursReducer from './tours';
import ItemsReducer from './items';
import ClientsReducer from './clients';
import ShiftsReducer from './shifts';
import BanksReducer from './banks';
import NumerationsReducer from './numerations';
import VariantsReducer from './variants';
import WarehousesReducer from './warehouses';
import CategoriesReducer from './categories';
import PriceListsReducer from './priceLists';
import StationsReducer from './stations';
import ItemCategoriesReducer from './itemCategories';
import TaxesReducer from './taxes';
import CurrenciesReducer from './currencies';
import CostCentersReducer from './costCenters';
import SellersReducer from './sellers';
import PaymentTermsReducer from './paymentTerms';
import ActiveRefundReducer from './activeRefund';
import InvoicesReducer from './invoices';
import PaymentsReducer from './payments';
import QRPaymentsReducer from './qrPayments';
import RefundsReducer from './refunds';
import RegimesReducer from './regimes';
import PendingInvoicesReducer from './pendingInvoices';
import PrintReducer from './print';
import CustomFieldsReducer from './customFields';
import UsersReducer from './users';
import MembershipReducer from './membership';
import UnitsReducer from './units';
import EconomicActivityReducer from './economicActyivity';
import AditionalSettingsReducer from './aditionalSettings';
import NotificationsWCReducer from './notificationsWC';
import MonitoringReducer from './monitoring';
import ShareInvoiceReducer from './shareInvoice';
import InventoryAdjustmentsNumerationsReducer from './inventoryAdjustmentsNumerations';

const rootReducer = combineReducers({
  app: AppReducer,
  auth: AuthReducer,
  activeInvoice: ActiveInvoiceReducer,
  editInvoice: EditInvoiceReducer,
  modals: ModalsReducer,
  sideModals: SideModalsReducer,
  tours: ToursReducer,
  items: ItemsReducer,
  clients: ClientsReducer,
  shifts: ShiftsReducer,
  banks: BanksReducer,
  taxes: TaxesReducer,
  costCenters: CostCentersReducer,
  sellers: SellersReducer,
  paymentTerms: PaymentTermsReducer,
  numerations: NumerationsReducer,
  variants: VariantsReducer,
  warehouses: WarehousesReducer,
  categories: CategoriesReducer,
  priceLists: PriceListsReducer,
  stations: StationsReducer,
  itemCategories: ItemCategoriesReducer,
  activeRefund: ActiveRefundReducer,
  currencies: CurrenciesReducer,
  invoices: InvoicesReducer,
  payments: PaymentsReducer,
  qrPayments: QRPaymentsReducer,
  refunds: RefundsReducer,
  regimes: RegimesReducer,
  pendingInvoices: PendingInvoicesReducer,
  print: PrintReducer,
  customFields: CustomFieldsReducer,
  users: UsersReducer,
  membership: MembershipReducer,
  units: UnitsReducer,
  economicActivities: EconomicActivityReducer,
  aditionalSettings: AditionalSettingsReducer,
  notificationsWC: NotificationsWCReducer,
  monitoring: MonitoringReducer,
  shareInvoice: ShareInvoiceReducer,
  inventoryAdjustmentsNumerations: InventoryAdjustmentsNumerationsReducer,
});

export default rootReducer;
