function defaultInitialValues({
  contact,
  defaultAccountReceivableCategory,
  defaultDebtToPayCategory,
}) {
  if (!!contact) {
    return {
      ...contact,
      firstName: contact?.name,
      identification: {
        number: contact?.identification,
      },
      seller: contact?.seller?.id ? contact?.seller?.id : contact?.seller,
      priceList: contact?.priceList?.id
        ? contact?.priceList?.id
        : contact?.priceList,
      term: contact?.term?.id ? contact?.term?.id : contact?.term,
      statementAttached: contact?.statementAttached,
      accounting: {
        accountReceivable: contact?.accounting?.accountReceivable?.id,
        debtToPay: contact?.accounting?.debtToPay?.id,
      },
    };
  }

  return {
    accounting: {
      accountReceivable: defaultAccountReceivableCategory?.id,
      debtToPay: defaultDebtToPayCategory?.id,
    },
    statementAttached: true,
    ignoreRepeated: false,
  };
}

export default defaultInitialValues;
