import React, { useState, useEffect } from 'react'
import { I18n } from '@aws-amplify/core';

import { Icon } from '@alegradev/smile-ui-react';

const Pagination = ({ onFetchData, paginationData }) => {
  const [ page, setPage ] = useState(0);

  useEffect(() => {
    if (!!paginationData) {
      const { start, limit } = paginationData;
      setPage(Math.ceil(start / limit) + 1)
    }
  }, [paginationData])

  if (!onFetchData || !paginationData)
    return null;

  const { total, start, limit, sortDirection } = paginationData;


  const nextDisabled = !!total ? start + limit >= total : false;
  const prevDisabled = !!total ? start - limit < 0 : false;

  return (
    <div className="pagination-bottom py-2 d-flex bg-primary justify-content-between align-items-center">
      <div className="text-capitalize-first text-truncate text-wrap text-white p-2">
        { !!total &&
          `${I18n.get('showing', 'mostrando')} ${start + 1} - ${Math.min(total, start + limit)} ${I18n.get('of', 'de')} ${total}`
        }
      </div>

      <div className="pagination-bottom-actions d-flex justify-content-between align-items-center">
        <button
          type="button"
          className="btn button-transparent"
          disabled={prevDisabled}
          onClick={() => onFetchData({
            start: Math.max(start - limit, 0),
            limit,
            sortDirection,
          })}
        >
          <Icon icon='arrow-left' extraClass="icon-white" />
        </button>

        <form noValidate className="my-0 mx-1" onSubmit={event => {
          event.preventDefault();
          onFetchData({
            start: (page - 1) * limit,
            limit,
            sortDirection,
          })
        }}>
          <input 
            type="number" 
            className="pagination-per-page"
            value={page} 
            min="1" 
            max={Math.ceil(total / limit)}
            onChange={event => {
              if (+event.target.value > Math.ceil(total / limit))
                return setPage(Math.ceil(total / limit))
              if (+event.target.value < 1)
                return setPage(1)
              setPage(+event.target.value)
            }}
            onBlur={() => setPage(Math.ceil(start / limit) + 1)}
          />
          <input style={{display: 'none'}} type="submit" />
        </form>

        <button
          type="button"
          className="btn button-transparent"
          disabled={nextDisabled}
          onClick={() => onFetchData({
            start: start + limit,
            limit,
            sortDirection,
          })}
        >
          <Icon icon='arrow-right' extraClass="icon-white" />
        </button>
      </div>
    </div>
  )
}

export default Pagination;