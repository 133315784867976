import { I18n } from '@aws-amplify/core';
import { get, capitalize } from 'lodash';

import { COUNTRIES } from '../../../../../utils/enums/countries';
import { colombiaValidation } from './colombia/validations';
import { mexicoValidation } from './mexico/validations';
import { argentinaValidation } from './argentina/validations';
import { costaRicaValidation } from './costaRica/validations';
import { peruValidation } from './peru/validations';
import { republicaDominicanaValidation } from './republicaDominicana/validations';
import { spainValidation } from './spain/validations';
import { panamaValidation } from './panama/validations';
import { trimValues } from '../../../../../utils/trimValues';

const commonValidations = (values) => {
  let errors = {};

  const name = get(values, 'firstName', '');
  const email = get(values, 'email', '');

  if (!name)
    errors.firstName = capitalize(
      I18n.get(
        'youMustIndicateTheNameOfYourClient',
        'Debes indicar el nombre de tu cliente'
      )
    );

  if (!!email) {
    if (!/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(email)) {
      errors.email = capitalize(
        I18n.get('KeepTheFormEmail', 'Conserva la forma: ejemplo@correo.com')
      );
    } else if (!/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email)) {
      errors.email = capitalize(
        I18n.get(
          'avoidUsingSpacesAccentsAndSpecialSigns',
          'Evite el uso de espacios, acentos y signos especiales'
        )
      );
    }
  }
  return errors;
};

export const validationPreviousCreation = (values, props) => {
  if (
    !get(values, 'accounting.accountReceivable') ||
    !get(values, 'accounting.debtToPay')
  )
    throw new Error(
      I18n.get(
        'contactAccountingError',
        'Algo impidió traer la configuración contable para tu cliente, recarga e intenta crearlo de nuevo.'
      )
    );

  if (props.country === 'republicaDominicana') {
    const identificationType = get(values, 'identification.type', '');
    const identificationNumber = get(values, 'identification.number', '');

    if (
      identificationType &&
      identificationType.key === 'RNC' &&
      identificationNumber &&
      identificationNumber.length !== 9
    )
      throw new Error(
        I18n.get(
          'identificationFailed.rnc',
          'El RNC debe ser un valor numérico de 9 dígitos de longitud'
        )
      );
    if (
      identificationType &&
      identificationType.key === 'CED' &&
      identificationNumber &&
      identificationNumber.length !== 11
    )
      throw new Error(
        I18n.get(
          'identificationFailed.ced',
          'La cédula debe ser un valor numérico de 11 dígitos de longitud'
        )
      );
  }
};

const validationsPerCountryCountry = (values, { country, ...props }) => {
  switch (country) {
    case COUNTRIES.COLOMBIA:
      return colombiaValidation(values, props);
    case COUNTRIES.COSTA_RICA:
      return costaRicaValidation(values, props);
    case COUNTRIES.SPAIN:
      return spainValidation(values, props);
    case COUNTRIES.PERU:
      return peruValidation(values, props);
    case COUNTRIES.ARGENTINA:
      return argentinaValidation(values, props);
    case COUNTRIES.REPUBLICA_DOMINICANA:
      return republicaDominicanaValidation(values, props);
    case COUNTRIES.PANAMA:
      return panamaValidation(values, props);
    case COUNTRIES.MEXICO:
      return mexicoValidation(values, props);
    default:
      break;
  }
};

export const validate = (values, props) => {
  let trimmedValues = trimValues(values);
  let errors = {
    ...commonValidations(trimmedValues, props),
    ...validationsPerCountryCountry(trimmedValues, props),
  };

  return errors;
};
