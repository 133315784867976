import React from "react";
import PropsTypes from "prop-types";
import { I18n } from "@aws-amplify/core";
import { Field, Form } from "react-final-form";
import { renderSelect } from "../../forms/fields/V0/Fields";

import { Icon } from '@alegradev/smile-ui-react';

const PAGINATION_OPTIONS = [
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 30,
    label: "30",
  },
];

const Pagination = ({ previousPage, handleChangeIndex, nextPage, pageIndex, pageOptions, canPreviousPage, canNextPage, onRefresh, handleChangePageSize, pageSize, results, total }) => {

  const allPages = () => {
    return pageOptions.map(page => ({
      value: page + 1,
      label: (page + 1).toString(),
    }))
  }

  return (
    <div className="react-table-light__pagination bg-white">
      <div className="react-table-light__pagination-container">

        <div className="d-flex align-items-center">
          <div className="text-break results ml-3 pr-3">
            {I18n.get("itemsPerPage", "Items por página")}:
          </div>
          <div className="options mr-4 pr-4">
            <Form onSubmit={{}} keepDirtyOnReinitialize>
              {() => (
                <Field
                  name="countPage"
                  placeholder={pageSize}
                  component={renderSelect}
                  options={PAGINATION_OPTIONS}
                  className="m-0 pages"
                  menuPosition="absolute"
                  getOptionLabel={option => option.label}
                  getOptionValue={option => option.value}
                  onChange={option => handleChangePageSize(option.value)}
                  height="2.5rem"
                />
              )}
            </Form>
          </div>
          <div className="text-muted text-break description px-2"> {`${results}-${pageSize} ${I18n.get("of", "de")} ${total} ${I18n.get("itemsRaw", "items")}`}</div>
        </div>

        <div className="d-flex align-items-center mx-3">
          <div className="d-flex align-items-center mr-4">
            <div className="pagination-description text-capitalize-first text-break description mr-3">{I18n.get("page", "Página")}</div>
            <div className="mr-3" style={{ width: "7rem" }}>
              <Form onSubmit={{}} keepDirtyOnReinitialize>
                {() => (
                  <Field
                    name="pageNumber"
                    placeholder={pageIndex + 1}
                    component={renderSelect}
                    options={allPages()}
                    className="m-0 pages"
                    menuPosition="absolute"
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.value}
                    onChange={option => handleChangeIndex(null, option.value - 1)}
                    height="2.5rem"
                  />
                )}
              </Form>
            </div>
            <div className="text-break description">{`${I18n.get("of", "de")} ${pageOptions.length}`}</div>
          </div>

          <div className="carrent-left d-flex mr-4">
            <div className="carrent__simple" onClick={canPreviousPage ? previousPage : null}>
              <Icon icon='arrow-left' color={canPreviousPage ? "#00B19D" : "#CCCCCC"} />
            </div>
          </div>
          <div className="carrent-right d-flex mr-4">
            <div className="carrent__simple" onClick={canNextPage ? nextPage : null}>
              <Icon icon='arrow-right' color={canNextPage ? "#00B19D" : "#CCCCCC"} />
            </div>
          </div>

          <div className="reload" onClick={() => onRefresh()}>
            <Icon icon='refresh' color="#00B19D" />
          </div>
        </div>

      </div>
    </div>
  );
};

Pagination.propTypes = {
  previousPage: PropsTypes.func,
  handleChangeIndex: PropsTypes.func,
  handleChangePageSize: PropsTypes.func,
  nextPage: PropsTypes.func,
  pageIndex: PropsTypes.number,
  pageOptions: PropsTypes.any,
  canPreviousPage: PropsTypes.bool,
  canNextPage: PropsTypes.bool,
  handleGotoPage: PropsTypes.func,
  pageSize: PropsTypes.number,
  results: PropsTypes.number,
  total: PropsTypes.number,
};

export default Pagination;
