import { useSelector } from "react-redux";
import Button from "../../../common/Button";
import { I18n } from '@aws-amplify/core';

import { isSelectingItems } from "../../../../selectors/activeRefund";

import { useFormState } from "react-final-form";
import { get } from "lodash";
import { Tooltip } from "@alegradev/smile-ui-react";

import { Icon } from '@alegradev/smile-ui-react';

export const CreditToSales = ({ step, changeStep, closeModal, disabled, submitting, lastStepError }) => {
  const { values } = useFormState();

  const isElectronic = get(values, 'refund.document.numberTemplate.isElectronic', false);
  const submitText = isElectronic ? I18n.get("emit", "Emitir") : I18n.get("save", "Guradar");

  const isSelectingMode = useSelector(isSelectingItems);
  if (isSelectingMode && step === 3)
    return null;

  return (
    <div className='d-flex flex-end justify-content-end bottom container-buttons-controls'>
      <button type="button" className='refunds-cancel-button mr-md-4 ' onClick={closeModal} disabled={submitting}>        
        {I18n.get('cancel', 'Cancelar')}
      </button>
      {
        (step === 1 || step === 2) && (
          <Button className='ml-4' style={{ width: "116px" }} onClick={() => changeStep(3)} disabled={disabled}>
            {I18n.get("continue", "Continuar")}
          </Button>
        )
      }
      {
        (step === 3 && !isSelectingMode) && (
          <Tooltip
            visible={!!lastStepError}
            placement="top"
            overlay={lastStepError}>
            <Button className={`ml-md-4 ${lastStepError ? 'disabled-button-creditToSales' : ''}`} style={{ width: "116px" }} onClick={() => changeStep(4)} disabled={disabled}>
              {I18n.get("continue", "Continuar")}
            </Button>
          </Tooltip>
        )
      }
      {
        (step === 4) && (
          <Button style={{ width: "116px" }} type="submit" disabled={disabled}>
            {submitting 
              ? <Icon icon='loader-2' animated extraClass=" icon-white" />
              : submitText}
          </Button>
      )
      }
    </div>
  )
}