import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { get, capitalize } from 'lodash';

import kindOfPersonOptionsColombia from '../../../countriesData/colombia/kindOfPerson';
import kindOfPersonOptionsPanama from '../../../countriesData/panama/kindOfPerson';
import { renderSelect, renderSmileRadioButtons } from '../../fields/V0/Fields';

const colombiaAddressKindOfPerson = props => {
  const identification = get(props, 'values.identification.type.key', '');
  return props.country === 'colombia' && identification === 'NIT'
    ? (
      <Field
        name="kindOfPerson"
        component={renderSelect}
        className="col-md-6"
        label={capitalize(I18n.get('kindOfPerson', 'tipo de persona'))}
        options={kindOfPersonOptionsColombia}
        getOptionLabel={option => option.value}
        getOptionValue={option => option.key}
        required={props.isElectronic}
      />
    ) : null;
}

const panamaKindOfPerson = props => {
  const identificationType = get(props, 'values.identification.type.key', '');

  const isRequiredField = () => {
    let isRequired = true;
    if (identificationType === 'FINAL_CONSUMER') isRequired = false;
    return isRequired;
  }

  if (identificationType === 'FOREIGN' || identificationType === 'FOREIGN_COMPANY') return null;

  return (
    <Field
      name="kindOfPerson"
      component={renderSelect}
      className="col-md-6"
      label={capitalize(I18n.get('kindOfPerson', 'tipo de persona'))}
      options={kindOfPersonOptionsPanama(identificationType)}
      getOptionLabel={option => option.value}
      getOptionValue={option => option.key}
      required={props.isElectronic ? isRequiredField() : false}
    />
  )
}

const renderKindOfPerson = props => {
  switch (props.country) {
    case 'colombia':
      return colombiaAddressKindOfPerson(props);
    case 'panama':
      return panamaKindOfPerson(props);
    default:
      return null;
  }
}

export default renderKindOfPerson;
