const numberTemplates = [
  {
    id: '5',
    name: 'Comprobante para exportación (16)',
    prefix: 'B16',
    maxInvoiceNumber: null,
    invoiceText: null,
    isDefault: false,
    status: 'active',
    autoincrement: true,
    documentType: 'invoice',
    deletable: true,
    nextInvoiceNumber: 1,
    branchOffice: 'Principal',
    startDate: null,
    endDate: null,
    subDocumentType: {
      code: 'B16',
      name: 'Comprobante para exportación',
    },
    isElectronic: false,
  },
  {
    id: '2',
    name: 'Consumo (02)',
    prefix: 'B02',
    maxInvoiceNumber: null,
    invoiceText: null,
    isDefault: false,
    status: 'active',
    autoincrement: true,
    documentType: 'invoice',
    deletable: true,
    nextInvoiceNumber: 1,
    branchOffice: 'Principal',
    startDate: null,
    endDate: null,
    subDocumentType: {
      code: 'B02',
      name: 'Consumo',
    },
    isElectronic: false,
  },
  {
    id: '1',
    name: 'Crédito fiscal (01)',
    prefix: 'B01',
    maxInvoiceNumber: null,
    invoiceText: null,
    isDefault: true,
    status: 'active',
    autoincrement: true,
    documentType: 'invoice',
    deletable: false,
    nextInvoiceNumber: 1,
    branchOffice: 'Principal',
    startDate: null,
    endDate: null,
    subDocumentType: {
      code: 'B01',
      name: 'Crédito fiscal',
    },
    isElectronic: false,
  },
  {
    id: '4',
    name: 'Gubernamentales (15)',
    prefix: 'B15',
    maxInvoiceNumber: null,
    invoiceText: null,
    isDefault: false,
    status: 'active',
    autoincrement: true,
    documentType: 'invoice',
    deletable: true,
    nextInvoiceNumber: 1,
    branchOffice: 'Principal',
    startDate: null,
    endDate: null,
    subDocumentType: {
      code: 'B15',
      name: 'Gubernamentales',
    },
    isElectronic: false,
  },
  {
    id: '3',
    name: 'Régimen especial de tributación (14)',
    prefix: 'B14',
    maxInvoiceNumber: null,
    invoiceText: null,
    isDefault: false,
    status: 'active',
    autoincrement: true,
    documentType: 'invoice',
    deletable: true,
    nextInvoiceNumber: 1,
    branchOffice: 'Principal',
    startDate: null,
    endDate: null,
    subDocumentType: {
      code: 'B14',
      name: 'Régimen especial de tributación',
    },
    isElectronic: false,
  },
];

export default numberTemplates;
