import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import {
  getRegimeOptionsByRFC,
  allRegimes,
} from '../../../../../components/countriesData/mexico/regimes';
import { get } from 'lodash';
import { Grid } from '@alegradev/smile-ui-react';
import { renderField } from '../../../../../components/forms/fields/V2';
import { COUNTRIES } from '../../../../../utils/enums/countries';

const mexicoRegime = (props) => {
  const { Col } = Grid;
  const thirdType = get(props, 'values.thirdType', '');
  const favoriteRegime = get(props, 'values.favoriteRegime', '');

  useEffect(() => {
    if (thirdType === 'FOREIGN') {
      props.form.change('regime', allRegimes.SIMPLIFIED_REGIME.value);
    }
  }, [thirdType]);

  return (
    <Col xs={12}>
      <Field
        name='regime'
        component={renderField}
        fieldType='select'
        canFavorite
        multiple
        changeAction={(value) => {
          if (value?.isFavorite) {
            props.form.change('favoriteRegime', value?.value);
          }
        }}
        defaultFavoriteOption={favoriteRegime}
        options={getRegimeOptionsByRFC(get(props, 'values.rfc', ''))?.map(
          (option) => ({
            text: option.label,
            value: option.value,
          })
        )}
        label={capitalize(I18n.get('regime', 'Regímenes fiscales'))}
        required
        disabled={thirdType === 'FOREIGN'}
        forceDefaultOptions
      />
    </Col>
  );
};

const renderRegime = (props) => {
  switch (props.country) {
    case COUNTRIES.MEXICO:
      return mexicoRegime(props);
    default:
      return null;
  }
};

export default renderRegime;
