import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash'
import { BigNumber } from 'bignumber.js';

import { country as countrySelector, decimalPrecision, tipsSelector } from '../../../selectors/company';
import { currency, items as itemsSelector, noITBMS, numeration as numerationSelector } from '../../../selectors/activeInvoice'
import { getMainCurrency } from '../../../selectors/currencies'
import { subtotal, discount, discSubtotal, taxes, itemsCount } from '../../../selectors/activeInvoice';
import { electronicInvoicing } from '../../../selectors/app';
import { openSideModal } from '../../../reducers/sideModals';

import { setTip } from '../../../reducers/activeInvoice';
import { Icon } from '@alegradev/smile-ui-react';
import { COUNTRIES } from '../../../utils/enums/countries';

const Summary = () => {
  const invoiceSubtotal = useSelector(subtotal);
  const invoiceDiscount = useSelector(discount);
  const invoiceDiscSubtotal = useSelector(discSubtotal);
  const invoiceTaxes = useSelector(taxes);
  const invoiceItemsCount = useSelector(itemsCount);
  const decimal = useSelector(decimalPrecision)
  const mainCurrency = useSelector(getMainCurrency)
  const selectedCurrency = useSelector(currency)
  const tips = useSelector(tipsSelector);
  const invoiceTip = useSelector(state => get(state, 'activeInvoice.tip', null))

  const items = useSelector(itemsSelector)
  const country = useSelector(countrySelector)
  const numeration = useSelector(numerationSelector)
  const isElectronic = useSelector(electronicInvoicing(numeration))
  const showNoITBMSWarning = useSelector(noITBMS) && isElectronic

  const prefix = !!get(selectedCurrency, 'symbol') ? get(selectedCurrency, 'symbol')
    : !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : ''

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  }

  const domCondition = country === 'republicaDominicana' && isElectronic && items.map(
    item => get(item, 'tax').some(tax => get(tax, 'type') === 'ITBIS' || get(tax, 'type') === 'EXENTO')
  ).some(value => value === false)

  if (invoiceItemsCount === 0 || domCondition || showNoITBMSWarning) return null

  return (
    <div className="invoice-bottom__summary d-flex flex-column mx-4 mt-3">
      {(invoiceDiscount.gt(0) || !!invoiceTaxes.length) && (
        <div className="invoice-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first desc">
            {I18n.get('subtotal', 'subtotal')}
          </div>
          <div className="text-capitalize-first text-muted number">
            {invoiceSubtotal.toFormat(decimal, fmt)}
          </div>
        </div>
      )}

      {invoiceDiscount.gt(0) && (
        <div className="invoice-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first desc">
            {I18n.get('discount', 'descuento')}
          </div>
          <div className="text-capitalize-first text-muted number">
            -{invoiceDiscount.toFormat(decimal, fmt)}
          </div>
        </div>
      )}

      {invoiceDiscount.gt(0) && (
        <div className="invoice-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first desc">
            {I18n.get('subtotal', 'subtotal')}
          </div>
          <div className="text-capitalize-first text-muted number">
            {invoiceDiscSubtotal.toFormat(decimal, fmt)}
          </div>
        </div>
      )}

      {invoiceTaxes.map((tax, index) => (
        <div key={index} className="invoice-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
          <div className='text-capitalize-first desc'>
            {tax.name}{' '}
            {country === COUNTRIES.COLOMBIA &&
              (get(tax, 'rate') === 'Exento' ||
                get(tax, 'rate') === 'Excluido') &&
              tax.rate}
          </div>
          <div className="text-capitalize-first text-muted number">
            {tax.value.toFormat(decimal, fmt)}
          </div>
        </div>
      ))}

      {
        get(tips, "enabled", false) && <Tips tip={invoiceTip} subtotal={invoiceSubtotal} tipSettings={tips} />
      }

    </div>
  )
}

const Tips = ({ tip, subtotal, tipSettings }) => {
  const dispatch = useDispatch();
  const selectedCurrency = useSelector(currency)
  const country = useSelector(countrySelector)
  const decimal = useSelector(decimalPrecision)
  const mainCurrency = useSelector(getMainCurrency)
  const prefix = !!get(selectedCurrency, 'symbol') ? get(selectedCurrency, 'symbol')
    : !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : ''

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  }

  const tipValue = get(tip, "value", 0)
  const tipPercentage = get(tip, "percentage", 10)
  const tipType = get(tip, "type", 'PERCENTAGE')

  const percentageLabel = tipType === 'PERCENTAGE' && `(${tipPercentage}%)`

  return (
    <div className="invoice-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
      {get(tip, "include", false) ?
        (<>
          <div className="text-capitalize-first desc d-flex">
            {I18n.get('tips', 'Propinas')} {percentageLabel}
            <div className='ml-2 edit-tips' onClick={() => dispatch(openSideModal({ sideModal: 'tip' }))}>
              <Icon icon='pencil' />
            </div>
          </div>
          <div className="text-capitalize-first text-muted number">
            {
              tipType === 'VALUE' && new BigNumber(tipValue).toFormat(decimal, fmt)
            }
            {tipType === 'PERCENTAGE' && subtotal.times(tipPercentage).div(100).toFormat(decimal, fmt)}
          </div>
        </>)
        :
        (
          <button className='btn-add-tip' onClick={() => dispatch(setTip({ include: true, type: "PERCENTAGE", percentage: get(tipSettings, "suggestedValue", 10) }))}>
            <Icon icon='plus' extraClass="icon-primary" />{I18n.get('tips.add', 'Agregar propina')}
          </button>
        )
      }
    </div>
  )
}

export default Summary;
