import { useDispatch, useSelector } from 'react-redux';
import {
  companySelector,
  country as countrySelector,
} from '../../selectors/company';
import { loading as loadingSelector } from '../../selectors/app';
import { Icon } from '@alegradev/smile-ui-react';
import { get } from 'lodash';
import { openModal } from '../../reducers/modals';

const SupportButton = () => {
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);
  const registryDate = get(company, 'registryDate');

  const handleClick = () => {
    dispatch(openModal({ modal: 'contactSupport' }));
  };

  if (loading || !country || !registryDate) return null;

  return (
    <div id='husbpot-chat'>
      <div
        className='btn-chat-container custom-husbpot-btn-background'
        onClick={() => handleClick()}
      >
        <Icon icon='help' size='extraLarge' color='white' />
      </div>
    </div>
  );
};

export default SupportButton;
