import React from 'react';

import Contact from './contact/Contact';
import NewEditItem from './editItem/NewEditItem';
import Item from './item/Item';
import InvoiceSettings from './invoiceSettings/InvoiceSettings';
import Company from './company/Company';
import Tip from './tip/Tip';
import ContactSideModal from '../../pages/contacts/sideModal/ContactSideModal';
import useContactsVersionsGroup from '../../hooks/useContactsVersionsGroup/hook';

const SideModals = () => {
  const { isContactsActive } = useContactsVersionsGroup();
  return (
    <>
      {isContactsActive ? <ContactSideModal /> : <Contact />}
      <Item />
      <InvoiceSettings />
      <Company />
      <NewEditItem />
      <Tip />
    </>
  );
};

export default SideModals;
