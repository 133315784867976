import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { get, sortBy, head, filter, find } from 'lodash';
import { country as countrySelector } from '../../../selectors/company';
import AlegraMembership from '../../../reducers/membershipsAPI';
import { closeModal } from '../../../reducers/modals';

import Modal from '../../common/Modal';
import Typography from '../../common/Typography';
import Button from '../../common/Button';

import { solutionTypes, solutionUris } from './solution-types';

import SkeletonComponent from '../../common/Skeleton';
import { formatCurrencyMoney } from '../../../utils/format-money';
import { COUNTRIES } from '../../../utils/enums/countries';

import { Icon } from '@alegradev/smile-ui-react';

function SolutionsModal() {
  const dispatch = useDispatch();
  const country = useSelector(countrySelector);
  const [plan, setPlan] = useState(null);
  const isOpen = useSelector((state) =>
    get(state, 'modals.solutions.isOpen', false)
  );
  const type = useSelector((state) =>
    get(state, 'modals.solutions.params.type', '')
  );

  const toggleCloseModal = () => {
    dispatch(closeModal({ modal: 'solutions' }));
    setPlan(null);
  };

  const modalAssets = () => {
    const assets = solutionTypes(country);
    return assets[type];
  };

  const modalUris = () => {
    const uris = solutionUris(country);
    return uris[type];
  };

  useEffect(() => {
    return () => {
      setPlan(null);
    };
  }, []);

  const getModalData = async () => {
    if (type && modalAssets()) {
      const response = await AlegraMembership.get('/plans/available', {
        product: modalAssets()?.product,
        appVersion: country,
      });

      const plans = get(response, 'data', []);
      const plan = find(
        plans,
        (planItem) => planItem?.keyword === 'emprendedor'
      );
      if (plan) {
        setPlan({
          currency: plan?.currency,
          price: formatCurrencyMoney(plan?.price),
        });
      } else {
        const filteredPlans = filter(
          sortBy(plans, 'price'),
          (item) => item?.price !== 0
        );
        if (filteredPlans.length > 0) {
          setPlan({
            currency: head(filteredPlans)?.currency,
            price: formatCurrencyMoney(head(filteredPlans)?.price, country),
          });
        }
      }
    }
  };

  useEffect(() => {
    getModalData();
  }, [type]);

  const getPlanInfo = () => {
    const text = get(modalAssets(), 'subTitle', '');
    return text
      .replace('{{price}}', get(plan, 'price', 0))
      .replace('{{currency}}', get(plan, 'currency', ''));
  };

  const toggleButton = () => {
    const uris = modalUris();
    if (uris) {
      window.open(modalUris()?.uri, '_blank');
      dispatch(closeModal({ modal: 'solutions' }));
      setPlan(null);
    }
  };

  const toggleMoreInfo = () => {
    const uris = modalUris();
    if (uris) {
      window.open(modalUris()?.helpUrl, '_blank');
    }
  };

  const isPlanAvailable = () => {
    if(type === 'invoice' && country === COUNTRIES.PANAMA) return false
    return true
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => toggleCloseModal()}
      includeHeader={false}
      className='modal__mobile-bottom modal__rounded'
    >
      <div className='solutions-modal'>
        <div
          className='solutions-modal-close'
          onClick={() => toggleCloseModal()}
        >
          <Icon icon='x' />
        </div>
        <div className='solutions-modal-body'>
          <div className='solutions-modal-body-header'>
            <div className='solutions-modal-body-header-logo'>
              <Typography
                type='label-2'
                variant='green'
                text={modalAssets()?.supTitle}
              />
              {modalAssets()?.logo}
            </div>
            <Typography
              type='body-3-regular'
              variant='secondary'
              text={modalAssets()?.description}
            />
          </div>
          <div className='solutions-modal-body-actions'>
            <Button
              type='button'
              variant='filled'
              size='md'
              onClick={toggleButton}
            >
              <Typography
                type='label-1'
                variant='inverse'
                text={modalAssets()?.buttonMainText}
              />
            </Button>
            <Button
              type='button'
              variant='ghost'
              size='md'
              onClick={toggleMoreInfo}
            >
              <Typography
                type='label-1'
                variant='green'
                text={modalAssets()?.buttonSecondaryText}
              />
            </Button>
          </div>
          {isPlanAvailable() && !plan?.price && <SkeletonComponent />}
          {isPlanAvailable() && plan?.price && (
            <Typography
              type='caption-regular'
              variant='tertiary'
              text={getPlanInfo()}
            />
          )}
        </div>
        <div className='solutions-modal-illustration'>
          <div className='shape' />
          <div className='illustration-preview'>
            {modalAssets()?.illustration}
          </div>
        </div>
      </div>
    </Modal>
  );
}

SolutionsModal.propTypes = {};

export default SolutionsModal;
