import React, { useState } from 'react'
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux'
import { capitalize, get } from 'lodash'
import { useHistory } from 'react-router-dom';
import { useQueryClient } from "@tanstack/react-query";

import { checkFeatureLimit } from '../../../reducers/membership';
import { hasPermissionTo } from '../../../selectors/auth';
import { stationWarehouse as stationWarehouseSelector } from '../../../selectors/app';
import { useRefresh } from '../../../hooks/queryHooks/useRefresh';
import { replaceAndParse } from '../../../utils';
import { useConnectionStatus } from '../../../hooks/useConnectionStatus';
import { sendGTMEvent } from '../../../reducers/company';

import DeleteInventoryAdjustmentConfirmation from '../../modals/deleteInventoryAdjustmentConfirmation/DeleteInventoryAdjustmentConfirmation';

import { Tooltip, Icon } from '@alegradev/smile-ui-react';

const Actions = ({ inventoryAdjustment, mutate, filters }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const refresh = useRefresh();
  const isOnline = useConnectionStatus();
  const stationWarehouse = useSelector(stationWarehouseSelector);
  const can = useSelector(hasPermissionTo);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);


  const openEdit = async () => {
    history.push(`/inventory/inventory-adjustments/edit/${inventoryAdjustment.id}`, { filters });
  }

  const isInventoryAdjustmentInMemory = queryClient.getQueryData(["inventoryAdjustment", { "id": get(inventoryAdjustment, 'id') }]) !== undefined;

  return (
    <div className="d-flex align-items-center justify-content-start table-light-actions">
      <Tooltip
        visible={!can('edit', 'inventory-adjustments') || (!isInventoryAdjustmentInMemory && !isOnline)}
        overlay={(!isInventoryAdjustmentInMemory && !isOnline)
          ? (
            I18n.get('needConnectionToEditAdjustment', 'Necesitas tener conexión para editar este ajuste.')
          ) : (
            I18n.get('userNotAllowed.inventory-adjustments.edit', 'no tienes permisos para editar ajustes de inventario')
          )
        }
      >
        <button
          type="button"
          className="btn button-transparent"
          disabled={!can('edit', 'inventory-adjustments')
            || !get(inventoryAdjustment, 'editable', true)
            || get(inventoryAdjustment, 'warehouse.id') !== get(stationWarehouse, 'id')
            || (!isInventoryAdjustmentInMemory && !isOnline)
          }
          onClick={() => dispatch(checkFeatureLimit("inventoryAdjustments", () => openEdit()))}
          title={capitalize(I18n.get('edit', 'editar'))}
        >
          <Icon icon='pencil' />
        </button>
      </Tooltip>

      <Tooltip
        visible={!can('delete', 'inventory-adjustments')}
        overlay={I18n.get('userNotAllowed.inventory-adjustments.delete', 'no tienes permisos para eliminar ajustes de inventario')}
      >
        <button
          type="button"
          disabled={!can('delete', 'inventory-adjustments')
            || !get(inventoryAdjustment, 'deletable', true)
          }
          className="btn button-transparent button-remove"
          title={capitalize(I18n.get('delete', 'eliminar'))}
          onClick={() => dispatch(checkFeatureLimit("inventoryAdjustments", () => {
            setOpenConfirmationModal(true);
          }))}
        >
          <Icon icon='trash' />
        </button>
      </Tooltip>
      <DeleteInventoryAdjustmentConfirmation
        isOpen={openConfirmationModal}
        onRequestClose={() => {
          dispatch(sendGTMEvent("inventory-adjustment-removal-attempted", {
            inventoryAdjustmentsNumber: 1,
            inventoryAdjustmentRemovalStatus: false,
          }))
          setOpenConfirmationModal(false)
        }}
        onConfirm={() => {
          mutate(
            {
              id: inventoryAdjustment.id,
              filters, inventoryAdjustment: inventoryAdjustment
            },
            {
              onSettled: async () => {
                await refresh('inventoryAdjustments');
              }
            }
          )
          dispatch(sendGTMEvent("inventory-adjustment-removal-attempted", {
            inventoryAdjustmentsNumber: 1,
            inventoryAdjustmentRemovalStatus: true,
          }))
        }
        }
        body={<p className="h4 mb-5">
          {replaceAndParse(I18n.get("deleteInventoryAdjustment.info", "Ten en cuenta que al eliminar el <b> ajuste de inventario No. {} </b> se borrará la variación de cantidades que había generado en tus productos."), [
            get(inventoryAdjustment, "number")
          ])}
        </p>}
        submitText={I18n.get("deleteInventoryAdjustment", "Eliminar ajuste")}
        modalTitle={I18n.get("deleteInventoryAdjustment.modalTitle", "Eliminar ajuste de inventario")}
      />
    </div>
  )
}

export default Actions;