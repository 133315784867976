import { handleError } from '../../../utils/errors';
import { toastHandler } from '@alegradev/smile-ui-react';

export const contactsKeys = {
  all: 'allContacts',
  detail: 'contactDetail',
  delete: 'deleteContact',
};

export const DEFAULT_QUERY_PARAMS = ({ errorTitle }) => ({
  onError: (error) => {
    toastHandler({
      title: errorTitle,
      description: handleError(error),
      type: 'error',
    });
  },
  keepPreviousData: true,
  staleTime: 1000 * 60 * 60 * 24,
  refetchOnReconnect: 'always',
});
