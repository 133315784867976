import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';

import { PageHeading } from '@alegradev/smile-ui-react';

function GlobalInvoicesHeader() {
  const history = useHistory();

  return (
    <PageHeading
      title={I18n.get('globalInvoices', 'Facturas globales')}
      description={I18n.get(
        'globalInvoicesInfo',
        'Timbra tus facturas globales para reportar al SAT tus tickets de venta al público en general. '
      )}
      actions={[
        {
          text: I18n.get('newGlobalInvoice', 'Nueva factura global'),
          leftIcon: 'plus',
          action: () => history.push('/global-invoices/invoice'),
        },
      ]}
      descriptionExtra={{
        text: I18n.get('knowMore', 'Saber más'),
        href: 'https://ayuda.alegra.com/es/timbra-tus-facturas-globales',
      }}
    />
  );
}

export default GlobalInvoicesHeader;
