import React, { useEffect } from 'react';

import renderIdentification, {
  searchIdentification,
} from './BasicData/Identification';
import renderName from './BasicData/Name';
import renderAddress from './BasicData/Address';
import renderInfo from './BasicData/Info';
import renderKindOfPerson from './BasicData/KindOfPerson';
import renderTaxLiability from './BasicData/TaxLiability';
import renderIVACondition from './BasicData/IVACondition';
import kindOfPersonOptions from '../../countriesData/colombia/kindOfPerson';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { APIGraphqlSelector } from '../../../selectors/app';
import renderThirdType from './BasicData/ThirdType';
import renderRegime from './BasicData/Regime';
import renderFiscalId from './BasicData/FiscalId';
import { address as addressSelector, country as countrySelector} from '../../../selectors/company';
import { COUNTRIES } from '../../../utils/enums/countries';
import { getDefaultForeigMexicoAccountReceivableCategory, getDefaultForeigMexicoDebtToPayCategory, getDefaultMexicoAccountReceivableCategory, getDefaultMexicoDebtToPayCategory } from '../../../selectors/categories';
import { regimeOptions } from './utils';

const BasicData = props => {
  const identificationType = get(props, 'values.identification.type.key', '');
  const kinfOfPerson = get(props, 'values.kindOfPerson.key', '');
  const thirdType = get(props, 'values.thirdType', '');
  const identificationRuc = get(props, 'values.identification.ruc', '');
  const APIGraphql = useSelector(APIGraphqlSelector);
  const address = useSelector(addressSelector);
  const country = useSelector(countrySelector);

  // Mex default accounting
  const mexicoDefaultAccountReceivableCategory = useSelector(
    getDefaultMexicoAccountReceivableCategory
  );
  const mexicoDefaultDebtToPayCategory = useSelector(
    getDefaultMexicoDebtToPayCategory
  );

  const mexicoDefaultForeigAccountReceivableCategory = useSelector(
    getDefaultForeigMexicoAccountReceivableCategory
  );
  const mexicoDefaultFoiregDebtToPayCategory = useSelector(
    getDefaultForeigMexicoDebtToPayCategory
  );

  useEffect(() => {
    if (props.country === 'colombia') {
      props.form.mutators.setValues('address', {});
      if (identificationType === 'FOREIGN_NIT' || identificationType === 'NIT') {
        props.form.mutators.setValues(
          'kindOfPerson',
          kindOfPersonOptions.find((option) => option.key === 'LEGAL_ENTITY')
        );

        props.form.mutators.setValues(
          'regime',
          regimeOptions(props).find((option) => option.key === 'COMMON_REGIME')
        );
      } else {
        props.form.mutators.setValues(
          'kindOfPerson',
          kindOfPersonOptions.find((option) => option.key === 'PERSON_ENTITY')
        );

        props.form.mutators.setValues(
          'regime',
          regimeOptions(props).find((option) => option.key === 'SIMPLIFIED_REGIME')
        );
      }
    }
  }, [identificationType]);

  useEffect(() => {
    if (country === COUNTRIES.MEXICO) {
      props.form.change('address.zipCode', get(address, 'zipCode', ''));
    }
  }, [address, country]);

  useEffect(() => {
    if (country === COUNTRIES.MEXICO) {
      if(thirdType === 'FOREIGN') {
        props.form.change('accounting.accountReceivable', mexicoDefaultForeigAccountReceivableCategory);
        props.form.change('accounting.debtToPay', mexicoDefaultFoiregDebtToPayCategory);
      }
      
      if(thirdType === 'NATIONAL') {
        props.form.change('accounting.accountReceivable', mexicoDefaultAccountReceivableCategory);
        props.form.change('accounting.debtToPay', mexicoDefaultDebtToPayCategory);
      }
    }
  }, [thirdType, country]);

  useEffect(() => {
    if (props.country === 'panama') {
      props.form.change('kindOfPerson', null);
    }
  }, [identificationType]);

  useEffect(() => {
    if (props.country === 'panama') {
      if (
        kinfOfPerson &&
        kinfOfPerson !== 'PERSON_EMPTY' &&
        identificationRuc
      ) {
        searchIdentification(APIGraphql, kinfOfPerson, props);
      }
      if (kinfOfPerson === 'PERSON_EMPTY') {
        props.form.change('kindOfPerson', null);
      }
    }
  }, [kinfOfPerson]);

  return (
    <div className='form-body__fields form-row justify-content-start'>
      {renderThirdType(props)}
      
      {renderIdentification(props)}

      {renderKindOfPerson(props)}

      {renderTaxLiability(props)}

      {renderName(props)}

      {renderFiscalId(props)}

      {renderRegime(props)}

      {renderIVACondition(props)}

      {renderAddress(props)}

      {renderInfo(props)}
    </div>
  );
};

export default BasicData;
