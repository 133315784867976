import { useSelector } from "react-redux";
import Button from "../../../common/Button";
import { I18n } from '@aws-amplify/core';
import { isSelectingItems } from "../../../../selectors/activeRefund";

import { Icon } from '@alegradev/smile-ui-react';

export const Combined = ({step, changeStep, closeModal, disabled, submitting}) => {
  const isSelectingMode = useSelector(isSelectingItems);
  if (isSelectingMode && step === 3)
    return null;

  return (
    <div className='d-flex flex-end justify-content-end bottom container-buttons-controls'>
      <button type="button" className='refunds-cancel-button' onClick={closeModal} disabled={submitting}>
        {I18n.get('cancel','Cancelar')}
      </button>
      {
        (step === 1 || step === 2) && (
          <Button style={{ width: "116px" }} className='ml-4' disabled={disabled} onClick={() => changeStep(3)} >
            {I18n.get("continue", "Continuar")}
          </Button>
        )
      }
      {
        (step === 3 && !isSelectingMode) && (
          <Button style={{ width: "116px" }} className='ml-4' onClick={() => changeStep(4)} disabled={disabled}>
            {I18n.get("continue", "Continuar")}
          </Button>
        )
      }
      {
        step === 4 && (
          <Button style={{ width: "116px" }} className='ml-4' disabled={disabled} type="submit">
            {submitting 
              ? <Icon icon='loader-2' animated extraClass=" icon-white" />
              : I18n.get("save", "Guradar")}
          </Button>
        )
      }
    </div>
  )
}