import React from 'react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';
import { capitalize, get, lowerCase, deburr } from 'lodash';

import { country as countrySelector, decimalPrecision } from '../../../selectors/company'
import { activeWarehouses } from '../../../selectors/warehouses'
import { renderField, renderSelect } from '../fields/V0/Fields';
import NewPrice from './NewPrice';
import configAPI from '../../../reducers/configAPI';
import { maxDecimals } from '../../../utils/decimals/maxDecimals';
import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';

const filterOptions = (query, options) =>
  options.filter(option =>
    lowerCase(deburr(option.value)).includes(lowerCase(deburr(query)))
    || lowerCase(deburr(option.code)).includes(lowerCase(deburr(query))))

const EditBasicData = props => {
  const warehouses = useSelector(activeWarehouses);
  const country = useSelector(countrySelector);
  const decimal = useSelector(decimalPrecision);
  const { isDecimalActive } = useDecimalsVersionsGroup();

  return (
    <div className="form-body__fields form-row justify-content-start m-0">
      {/* <Field
        name="hasVariants"
        component={(fieldProps) => (
          <div
            className="form-group col-12 my-4"
            style={{
              maxHeight: props.values.type === 'variantParent' || props.values.type === 'simple' || props.values.type === 'product' ? 'unset' : '0px',
              overflow: 'hidden',
              transition: 'all .4s ease'
            }}
          >
            <p className="text-capitalize-first h5 text-subtitle mb-3">
              {I18n.get('productWithVariantsHelp', 'Indica si este producto tiene variantes como color, talla u otra cualidad.')}
            </p>
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ maxWidth: '45rem' }}
            >
              <p
                className={`h5 mb-2 text-${!props.values.hasVariants ? 'primary' : 'subtitle'}`}
              >
                <RadioButton width="12" height="12" checked={!fieldProps.input.value} />
                {' '}
                {capitalize(I18n.get('productWithoutVariants', 'producto sin variantes'))}
              </p>

              <p
                className={`h5 mb-2 text-${props.values.hasVariants ? 'primary' : 'subtitle'}`}
              >
                <RadioButton width="12" height="12" checked={fieldProps.input.value} />
                {' '}
                {capitalize(I18n.get('productWithVariants', 'producto con variantes'))}
              </p>
            </div>

            <div className="mt-3 border-bottom" />
          </div>
        )}
      /> */}

      <Field
        name="name"
        component={renderField}
        className="col-6"
        type="text"
        required
        label={capitalize(I18n.get('name', 'nombre'))}
      />

      <Field
        name="unit"
        component={renderSelect}
        className="col-6"
        loadOptions={async inputValue => {
          try {
            // ex queries.allUnits
            const response = await configAPI.v2.get('/units', {
              application_version: country,
            })
            let data = get(response, 'data', []);
            if (country === "republicaDominicana") {
              data = data.map(unit => ({ ...unit, unitType: unit.itemType }))
            }

            return filterOptions(inputValue, data)
          } catch {
            return []
          }
        }}
        defaultOptions
        getOptionLabel={option => I18n.get(`units.${option.key}`, option.value)}
        getOptionValue={option => option.key}
        label={capitalize(I18n.get('measurementUnit', 'unidad de medida'))}
        required
      />

      {((get(props, 'values.type', '') === 'product' || get(props, 'values.type', '') === 'simple') && !!(get(props, 'values.inventory.unitCost'))) && (
        <>
          <Field
            name="unitCost"
            component={renderField}
            type="number"
            className="col-6"
            required
            label={capitalize(I18n.get('unitCost', 'costo inicial'))}
            onChange={e => maxDecimals({e, key: 'unitCost', decimals: decimal, isActive: isDecimalActive, values: get(props, 'values', {})})}
          />
          <div className="w-100" />
        </>
      )}

      {get(props, 'values.type', '') === 'kit' && (
        <>
          <Field
            name="kitWarehouse"
            component={renderSelect}
            options={warehouses}
            className="col-6"
            required
            label={capitalize(I18n.get('warehouse', 'bodega'))}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
          />
          <div className="w-100" />
        </>
      )}

      <NewPrice {...props} />

      <Field
        name="description"
        component={renderField}
        className="col-12"
        type="textarea"
        label={capitalize(I18n.get('description', 'descripción'))}
      />
    </div>
  )
}

export default EditBasicData;
