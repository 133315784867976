import { I18n } from 'aws-amplify';
import { get } from 'lodash';

import { basicPOSClientData } from '../../../../utils';
import {
  handleDeleteClient,
  handleSendAlegraPortalLink,
} from '../../utils/modals';
import { can } from '../../../../utils/can';

export const actions = ({
  row,
  country,
  history,
  openModal,
  closeModal,
  handleDeleteContact,
  handleChangeContactStatus,
  permissions,
}) => {
  const defaultClient = basicPOSClientData(country);

  if (
    get(row, 'identification') === defaultClient.identification ||
    get(row, 'identification') === get(defaultClient, 'identification.number')
  ) {
    return [];
  }

  const actions = [
    {
      title: I18n.get('edit', 'Editar'),
      icon: 'pencil',
      type: 'option',
      disabled: !can(permissions, 'edit', 'contacts'),
      action: () => history.push(`/contacts/contact/${row.id}`),
      disabledTooltip: !can('edit', 'contacts')
        ? I18n.get(
            'editContactsUnallow',
            'Te hace falta el permiso para editar contactos'
          )
        : undefined,
    },
    {
      title:
        row?.status === 'active'
          ? I18n.get('deactivate', 'Desactivar')
          : I18n.get('activate', 'Activar'),
      icon: row?.status === 'active' ? 'bulb-off' : 'bulb',
      type: 'option',
      disabled: !can(permissions, 'edit', 'contacts'),
      disabledTooltip: !can('edit', 'contacts')
        ? I18n.get(
            'changeStatusContactsUnallow',
            'Te hace falta el permiso para cambiar el estado de contactos'
          )
        : undefined,
      action: () =>
        handleChangeContactStatus({
          id: row.id,
          status: row?.status,
        }),
    },
    { type: 'divider' },
    {
      title: I18n.get(
        'sendAlegraPortalLink',
        'Enviar enlace del Portal Alegra'
      ),
      icon: 'send',
      type: 'option',
      action: () =>
        handleSendAlegraPortalLink({
          contact: row,
          openModal,
        }),
    },
  ];

  if (can(permissions, 'delete', 'contacts')) {
    actions.push({ type: 'divider' });
    actions.push({
      title: I18n.get('delete', 'Eliminar'),
      icon: 'trash',
      disabled: !can(permissions, 'delete', 'contacts'),
      disabledTooltip: !can('delete', 'contacts')
        ? I18n.get(
            'deleteContactsUnallow',
            'Te hace falta el permiso para eliminar contactos'
          )
        : undefined,
      destructive: true,
      type: 'option',
      action: () =>
        handleDeleteClient({
          contact: row,
          handleDeleteContact,
          openModal,
          closeModal,
        }),
    });
  }

  return actions;
};
