import React, { useEffect, useState } from 'react'
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form } from 'react-final-form';
import { get, repeat } from 'lodash';

import Header from '../common/NewHeader';
import Switch from '../../common/Switch';
import { renderSelect } from '../../forms/fields/V0/Fields';

import { setUser } from '../../../reducers/auth';
import { hasPermissionTo } from '../../../selectors/auth'
import { getCategoryById, groupedCategories as categoriesSelector, defaultInCategory, defaultOutCategory } from '../../../selectors/categories'
import {
  isOnlyInvoicingPlan,
  localSettings as localSettingsSelector,
  shiftsEnabled as shiftsEnabledSelector,
  multicurrency as multicurrencySelector,
} from '../../../selectors/company'
import { editCategoryValue, editCompanyValue } from './utils';
import { APIGraphqlSelector } from '../../../selectors/app';

import { Icon } from '@alegradev/smile-ui-react';

const General = (props) => {
  const can = useSelector(hasPermissionTo);
  const [propertyLoading, setPropertyLoading] = useState({ shifts: false, multicurrency: false, saleTickets: false });
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan);
  const posSettings = useSelector(localSettingsSelector)
  const companyIncome = useSelector(getCategoryById(posSettings.idCategoryDefaultIncome))
  const defaultIncome = useSelector(defaultInCategory)
  const companyExpense = useSelector(getCategoryById(posSettings.idCategoryDefaultExpense))
  const defaultExpense = useSelector(defaultOutCategory)
  const multicurrency = useSelector(multicurrencySelector);
  const categories = useSelector(categoriesSelector);
  const shiftsEnabled = useSelector(shiftsEnabledSelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const dispatch = useDispatch();

  const updateCompanyValue = async (newValues, property) => {
    setPropertyLoading(prev => ({ ...prev, [property]: true }))
    const response = await editCompanyValue(APIGraphql, newValues);
    if (response)
      dispatch(setUser(get(response, 'data.updateUser')))
    setPropertyLoading(prev => ({ ...prev, [property]: false }))
  }

  const handleCategoryChange = async (name, category) => {
    const response = await editCategoryValue(APIGraphql, name, category.id)
    if (response)
      dispatch(setUser(get(response, 'data.updateUser')))
  }

  useEffect(() => {
    window.dataLayer.push({
      'event': 'VirtualPageview',
      'virtualPageURL': '/settings/company',
      'virtualPageTitle': 'Company Settings'
    });
  }, [])

  return (
    <div className="settings-container d-flex flex-column">
      <Header
        title={I18n.get('generalPOSFeatures', 'Funciones generales del POS')}
        subtitle={I18n.get('generalPOSFeatures.desc', 'Configura las funcionalidades que vas a usar en todas las terminales de tu punto de venta')}
        info={!can('edit', 'company') && I18n.get(('userNotAllowed.company.edit', 'no tienes permisos suficientes para editar la información de la compañía'))}
      />
      <div className="general-settings-body d-flex flex-column align-items-center justify-content-between bg-white">
        <div className="w-100 border-bottom d-flex align-items-center justify-content-between">
          <div>
            <p className={`settings-body-title${propertyLoading.shifts ? '-disabled' : ''} text-capitalize-first`}>
              {I18n.get('shiftsField', 'Turnos de caja')}
            </p>
            <p className={`settings-body-subtitle${propertyLoading.shifts ? '-disabled' : ''} text-capitalize-first`}>
              {I18n.get('shiftsField.subtitle', 'Ten el control de tu dinero usando la apertura y cierre de caja')}
            </p>
          </div>
          <div className="d-flex align-items-center" style={{ marginBottom: '10px' }}>
            {propertyLoading.shifts
              ? <>
                <span className="pr-3" style={{ fontSize: '12px' }}>
                  <span>
                    {I18n.get('savingChanges', 'Guardando tus cambios...')}
                  </span>
                  <Icon icon='loader-2' animated extraClass=" icon-primary icon x1" />
                </span>
                <Switch
                  active={!shiftsEnabled}
                  disabled
                  large
                />
              </>
              : <Switch
                active={shiftsEnabled}
                disabled={propertyLoading.shifts}
                onChange={() => updateCompanyValue({ localSettings: { shiftsEnabled: !shiftsEnabled } }, 'shifts')}
                large
              />}
          </div>
        </div>
        <div className="w-100 border-bottom d-flex align-items-center justify-content-between pt-4">
          <div>
            <p className={`settings-body-title${propertyLoading.multicurrency ? '-disabled' : ''} text-capitalize-first`}>
              {I18n.get('multicurrency', 'Multimoneda')}
            </p>
            <p className={`settings-body-subtitle${propertyLoading.multicurrency ? '-disabled' : ''} text-capitalize-first`}>
              {I18n.get('multicurrency.subtitle', 'Registra tus documentos de venta en diferentes monedas')}
            </p>
          </div>
          <div className="d-flex align-items-center" style={{ marginBottom: '10px' }}>
            {propertyLoading.multicurrency
              ? <>
                <span className="pr-3" style={{ fontSize: '12px' }}>
                  <span>
                    {I18n.get('savingChanges', 'Guardando tus cambios...')}
                  </span>
                  <Icon icon='loader-2' animated extraClass=" icon-primary icon x1" />
                </span>
                <Switch
                  active={!multicurrency}
                  disabled
                  large
                />
              </>
              : <Switch
                active={multicurrency}
                disabled={multicurrency}
                onChange={() => updateCompanyValue({ multicurrency: !multicurrency }, 'multicurrency')}
                large
              />}
          </div>
        </div>

        <Form
          initialValues={{
            idCategoryDefaultIncome: !onlyInvoicing
              ? !!companyIncome ? companyIncome : defaultIncome
              : defaultIncome,
            idCategoryDefaultExpense: !onlyInvoicing
              ? !!companyExpense ? companyExpense : defaultExpense
              : defaultExpense,
          }}
          onSubmit={() => { }}>
          {({ form, values, invalid }) => {
            return (
              <form className="w-100 row pt-4 pb-5" noValidate>
                <div className="col-6 p-0 pr-3 d-flex flex-column">
                  <p className="settings-body-title text-capitalize-first">
                    {I18n.get('cashManagementIncomeLabel', 'Cuenta contable para ingresos de efectivo')}
                  </p>
                  <p className="settings-body-subtitle text-muted text-capitalize-first">
                    {I18n.get('cashManagementIncomeLabel.subtitle', 'Elige la cuenta contable para el registro de ingresos diferentes a ventas')}
                  </p>
                  {values.idCategoryDefaultIncome instanceof Promise
                    ? <Icon icon='loader-2' animated extraClass=" icon-primary icon x1" />
                    : <Field
                      name="idCategoryDefaultIncome"
                      component={renderSelect}
                      options={categories}
                      disabled={onlyInvoicing}
                      onlyInvoicingTooltip={onlyInvoicing}
                      getOptionValue={option => option.id}
                      getOptionLabel={option => `${!!option.depth ? repeat('-', option.depth - 1) : ''} ${option.name}`}
                      onChange={(e) => handleCategoryChange('idCategoryDefaultIncome', e)}
                    />}
                </div>
                <div className="col-6 p-0 pl-3 d-flex flex-column">
                  <p className="settings-body-title text-capitalize-first">
                    {I18n.get('cashManagementExpenseLabel', 'Cuenta contable para salidas de efectivo')}
                  </p>
                  <p className="settings-body-subtitle text-muted text-capitalize-first">
                    {I18n.get('cashManagementExpenseLabel.subtitle', 'Elige la cuenta contable para el registro de tus gastos desde el POS')}
                  </p>
                  {values.idCategoryDefaultExpense instanceof Promise
                    ? <Icon icon='loader-2' animated extraClass=" icon-primary icon x1" />
                    : <Field
                      name="idCategoryDefaultExpense"
                      component={renderSelect}
                      options={categories}
                      disabled={onlyInvoicing}
                      onlyInvoicingTooltip={onlyInvoicing}
                      getOptionValue={option => option.id}
                      getOptionLabel={option => `${!!option.depth ? repeat('-', option.depth - 1) : ''} ${option.name}`}
                      onChange={(e) => handleCategoryChange('idCategoryDefaultExpense', e)}
                    />}
                </div>
              </form>
            )
          }}
        </Form>
      </div>
    </div>
  )
}

export default General;