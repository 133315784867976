import React from 'react'
import PropTypes from 'prop-types';
import { get } from 'lodash'

import { Icon } from '@alegradev/smile-ui-react';

const Invoice = ({ invoice, selected, index, onSelect, onOptions }) => {
  const pending = !!get(invoice, 'invoice.items.length') || get(invoice, 'invoice.client');

  return (
    <div 
      onClick={onSelect}
      data-testid="pending-invoice-view"
      className={`footer__invoice d-flex align-items-center justify-content-between pl-3 pr-1 position-relative\
      ${selected === index ? 'active': ''}`}
    >
      <div className="invoice-icon mr-3 d-flex align-items-center position-relative">
        <Icon icon='basket' extraClass={selected === index ? 'icon-primary' : 'icon-secondary'}/>
        {selected !== index && pending && (
          <div className="position-absolute invoice-pending-indicator"/>
        )}
      </div>

      <div className="invoice-name w-100 text-truncate text-nowrap text-break pr-2">
        {invoice.name}
      </div>

      {(selected === index && !!index) && (
        <button 
          type="button" 
          className="invoice-options btn-show-invoice-options btn button-transparent p-0"
          onClick={event => onOptions(event)}
        >
          <Icon icon='dots-vertical' extraClass="btn-show-invoice-options"/>
        </button>
      )}

    </div>
  )
}

Invoice.propTypes = {
  selected: PropTypes.number,
  index: PropTypes.number,
  onSelect: PropTypes.func,
  onOptions: PropTypes.func,
  invoice: PropTypes.object
}

export default Invoice;