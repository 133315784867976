import {
  Button,
  Grid,
  toastHandler,
  useModal,
} from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { useForm } from 'react-final-form';

function ContactPortalFooter({
  method = 'email',
  errors,
  modalName,
  loading = false,
  contact,
}) {
  const { Wrapper } = Grid;
  const { closeModal } = useModal();
  const form = useForm();

  const handleCopyLink = () => {
    const ALEGRA_PORTAL_LINK = contact?.statementLink
      ? contact.statementLink
      : process.env.REACT_APP_ALEGRA_PORTAL || 'https://portal.alegra.com';
    navigator.clipboard.writeText(ALEGRA_PORTAL_LINK);
    toastHandler({
      type: 'success',
      title: I18n.get('linkCopied', 'Enlace copiado'),
    });
  };

  return (
    <Wrapper
      fullWidth
      justify={{ lg: 'between' }}
      direction={{ sm: 'column' }}
      gap={10}
      extraClasses='mt-4'
    >
      <Wrapper>
        <Button
          onClick={handleCopyLink}
          text={I18n.get('copyLink', 'Copiar enlace')}
          emphasis='text'
          leftIcon='link'
          type='button'
        />
      </Wrapper>
      <Wrapper gap={10}>
        <Button
          onClick={() => closeModal(modalName)}
          text={I18n.get('cancel', 'Cancelar')}
          emphasis='outline'
          type='button'
        />

        <Button
          type='button'
          onClick={() => form.submit()}
          disabled={Object.keys(errors).length > 0}
          full
          loading={loading}
          text={
            method === 'email'
              ? I18n.get('sendEmail', 'Enviar correo')
              : I18n.get('sendWhatsapp', 'Enviar por WhatsApp')
          }
        />
      </Wrapper>
    </Wrapper>
  );
}

export default ContactPortalFooter;
