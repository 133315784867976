import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'

import { openModal } from '../../reducers/modals';
import { hasPermissionTo } from '../../selectors/auth';
import { companySelector, country as countrySelector } from '../../selectors/company';
import { isUserElegibleForNewRefund } from './utils';
import { Tooltip, Icon } from '@alegradev/smile-ui-react';

const DetailHeader = ({ refund, onListView }) => {
  const dispatch = useDispatch()
  const can = useSelector(hasPermissionTo)
  const country = useSelector(countrySelector)
  const company = useSelector(companySelector)

  if (!refund) return null;

  const getNumber = () => {
    const valuePrefix = I18n.get('number', 'número');

    const number = get(refund, 'numberTemplate.number', null);
    const fullNumber = get(refund, 'numberTemplate.fullNumber', null);
    const prefix = !!get(refund, 'numberTemplate.prefix', null)
      ? get(refund, 'numberTemplate.prefix', null) : '';

    if (!!fullNumber)
      return `${valuePrefix || ''} ${fullNumber || ''}`

    return `${valuePrefix || ''} ${prefix || ''}${number || ''}`
  }

  return (
    <div className="px-4 pt-4 d-flex justify-content-between align-items-center">
      <h2 className="title text-capitalize-first">
        {getNumber()}
      </h2>
      <div className="actions d-flex align-items-center">
        <div className="w-100 h-25 d-flex justify-content-between align-items-center">
          <Tooltip
            visible={!can('add', 'credit-notes')}
            overlay={I18n.get('userNotAllowed.credit-notes.add', 'no tienes permisos para agregar devoluciones')}
          >
            <button
              type="button"
              disabled={!can('add', 'credit-notes')}
              className="btn btn-submit d-none d-sm-block"
              onClick={() => {
                isUserElegibleForNewRefund(country, company)
                  ? dispatch(openModal({ modal: 'newRefunds' }))
                  : dispatch(openModal({ modal: 'refunds' }))
              }}
            >
              {I18n.get('newRefund', 'nueva devolución')}
            </button>
          </Tooltip>

          <button
            type="button"
            className="btn btn-submit d-block d-sm-none"
            onClick={() => onListView()}
          >
            <Icon icon='list' extraClass="icon-white" />
          </button>
        </div>
      </div>
    </div>
  )
}

DetailHeader.propTypes = {
  refund: PropTypes.object,
  onListView: PropTypes.func
}

export default DetailHeader;