import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux'
import { get } from 'lodash';

import { activeWarehouses } from '../../../selectors/warehouses'
import { country as countrySelector } from '../../../selectors/company';
import { getWarehouseHelpUrl } from '../../../utils';
import { renderSelect, renderField } from '../fields/V0/Fields';
import { maxDecimals } from '../../../utils/decimals/maxDecimals';
import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';

const Inventory = ({ values }) => {
  const warehouses = useSelector(activeWarehouses)
  const country = useSelector(countrySelector);
  const { isDecimalActive } = useDecimalsVersionsGroup();

  function setUpDecimals(key, decimals = 6) {
    if (!isDecimalActive)
      return;
    if (values[key] && values[key].includes('.')) {
      if (values[key].slice(-1) === '.') {
        values[key] = values[key].slice(0, -1);
        return;
      }

      if (values[key].split('.')[1].length > decimals)
        values[key] = Number(values[key]).toFixed(decimals)
    }
  }

  return (
    <>
      {!!(warehouses.length === 1) && (
        <Field
          name="warehouse"
          component={renderField}
          className="col-6"
          type="number"
          label={capitalize(I18n.get('warehouse', 'bodega'))} validate={value => !value ? capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) : null}
          placeholder={get(warehouses[0], 'name')}
          helpTooltip={(
            <p className='text-white'>
              {I18n.get('productDistributionHelp.init', 'Conoce')}
              <a href={getWarehouseHelpUrl(country)} target="_blank" rel="noreferrer">
                {I18n.get('productDistributionHelp.mid', ' cómo distribuir en diferentes bodegas')}
              </a>
              {I18n.get('productDistributionHelp.end', ' este producto.')}
            </p>
          )}
          disabled
        />
      )}
      {!!(warehouses.length > 1) && (
        <Field
          name="warehouse"
          component={renderSelect}
          className="col-6"
          options={warehouses}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          label={capitalize(I18n.get('warehouse', 'bodega'))}
          menuPosition="absolute"
          required
        />
      )}

      <Field
        name="initialQuantity"
        component={renderField}
        className="col-3"
        type="number"
        label={capitalize(I18n.get('quantity', 'cantidad'))}
        onChange={e => maxDecimals({e, key: 'initialQuantity', decimals: 4, isActive: isDecimalActive, values})}
        onBlur={() => setUpDecimals("initialQuantity", 4)}
        required
      />

      <Field
        name="unitCost"
        component={renderField}
        className="col-3"
        type="number"
        onChange={e => maxDecimals({e, key: 'unitCost', decimals: 6, isActive: isDecimalActive, values})}
        label={capitalize(I18n.get('unitCostSimplified', 'costo inicial'))}
        helpTooltip={I18n.get('unitCostHelp', "Indica el valor promedio de adquisición de las unidades iniciales de este producto.")}
        required
      />
    </>
  )
}

export default Inventory;
