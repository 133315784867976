import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { get } from 'lodash';
import { I18n } from '@aws-amplify/core';

import * as contactDB from '../../../database/contactsDB';

import { closeSideModal } from '../../../reducers/sideModals';
import { saveClient, replaceOfflineClient, syncOffline } from '../../../reducers/clients';
import { setClient } from '../../../reducers/activeInvoice';
import {
  country as countrySelector,
  address,
  electronicInvoicing,
} from '../../../selectors/company';
import { hasPermissionTo } from '../../../selectors/auth';
import { activePriceLists } from '../../../selectors/priceLists';
import { activePaymentTerms } from '../../../selectors/paymentTerms';
import { activeSellers } from '../../../selectors/sellers';
import {
  getDefaultAccountReceivableCategory,
  getDefaultDebtToPayCategory,
  accountReceivableCategories,
  debtToPayCategories,
  accountMexicoReceivableCategories,
  debtToPayMexicoCategories,
  getDefaultMexicoAccountReceivableCategory,
  getDefaultMexicoDebtToPayCategory,
} from '../../../selectors/categories';
import { formError } from '../../../utils/errors';
import PriceList from '../modals/priceList/PriceList';
import Seller from '../modals/seller/Seller';
import PaymentTerm from '../modals/paymentTerm/PaymentTerm';
import ContactCreated from '../modals/contactCreated/ContactCreated';
import {
  validate,
  transform,
  validationPreviousCreation,
} from '../../forms/contact/utils';
import SideModal from '../../common/SideModal';
import Header from '../common/Header';
import Notification from '../common/Notification';
import Bottom from '../common/Bottom';
import Body from './FormBody';
import initialValues from './initialValues';
import { COUNTRIES } from '../../../utils/enums/countries';

import { Icon } from '@alegradev/smile-ui-react';

const initialModalState = {
  seller: false,
  priceList: false,
  paymentTerm: false,
};

const Contact = () => {
  const [modals, setModals] = useState(initialModalState);
  const [contactCreated, setContactCreated] = useState(null);
  const [isDuplicatedContact, setIsDuplicatedContact] = useState(false);
  const [isValidationClient, setValidationClient] = useState(false);
  const can = useSelector(hasPermissionTo);

  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    get(state, 'sideModals.contact.isOpen', false)
  );
  const contact = useSelector((state) =>
    get(state, 'sideModals.contact.params.contact', null)
  );
  const error = useSelector((state) =>
    get(state, 'sideModals.contact.params.error', null)
  );
  const country = useSelector(countrySelector);
  const isElectronic = useSelector(electronicInvoicing);
  const companyAddress = useSelector(address);
  const priceLists = useSelector(activePriceLists);
  const paymentTerms = useSelector(activePaymentTerms);
  const sellers = useSelector(activeSellers);
  const accountReceivableOptions = useSelector(accountReceivableCategories);
  const debtToPayOptions = useSelector(debtToPayCategories);
  const defaultAccountReceivableCategory = useSelector(
    getDefaultAccountReceivableCategory
  );
  const defaultDebtToPayCategory = useSelector(getDefaultDebtToPayCategory);

  // Only for mexico
  const mexicoAccountReceivableOptions = useSelector(
    accountMexicoReceivableCategories
  );
  const mexicoDebtToPayOptions = useSelector(debtToPayMexicoCategories);
  const mexicoDefaultAccountReceivableCategory = useSelector(
    getDefaultMexicoAccountReceivableCategory
  );
  const mexicoDefaultDebtToPayCategory = useSelector(
    getDefaultMexicoDebtToPayCategory
  );

  const accountingOptions = useMemo(() => {
    if (country === COUNTRIES.MEXICO) {
      return {
        options: mexicoAccountReceivableOptions.national,
        default: mexicoDefaultAccountReceivableCategory,
      };
    }

    return {
      options: accountReceivableOptions,
      default: defaultAccountReceivableCategory,
    };
  }, [country, accountReceivableOptions, defaultAccountReceivableCategory]);

  const debtToPayCategoryOptions = useMemo(() => {
    if (country === COUNTRIES.MEXICO) {
      return {
        options: mexicoDebtToPayOptions.national,
        default: mexicoDefaultDebtToPayCategory,
      };
    }

    return {
      options: debtToPayOptions,
      default: defaultDebtToPayCategory,
    };
  }, [country, debtToPayOptions, defaultDebtToPayCategory]);

  const formRef = useRef(null);

  useEffect(() => {
    setModals(initialModalState);
    setContactCreated(null);
    setIsDuplicatedContact(false);
  }, [isOpen]);

  const submit = async (values) => {
    const transformedValues = transform(values, {
      country,
      isElectronic,
      contact,
      isDuplicatedContact
    });
    
    try {
      validationPreviousCreation(values, { country, isElectronic });
      if (!!contact) {
        await dispatch(replaceOfflineClient(contact.id, transformedValues));
      } else {
        const client = await dispatch(saveClient(transformedValues));
        await dispatch(setClient(client));
      }

      dispatch(closeSideModal({ sideModal: 'contact' }));
    } catch (error) {
      return formError(
        error,
        I18n.get(
          'createClientError',
          'hubo un error en la creación del contacto'
        )
      );
    }
  };

  const onSelectedExistingContact = (contact) => {
    if (contact) {
      dispatch(setClient(contact));
      dispatch(closeSideModal({ sideModal: 'contact' }));
    }
  }

  const isDuplicatedError = !!contact && !!error && error.includes('existe');

  const onCancelCreation = async (clientId) => {
    dispatch(closeSideModal({ sideModal: 'contact' }))
  }

  return (
    <SideModal
      open={isOpen}
      onRequestClose={() => dispatch(closeSideModal({ sideModal: 'contact' }))}
      destroyOnClose
    >
      <Form
        onSubmit={submit}
        validate={(values) => validate(values, { country, isElectronic })}
        mutators={{
          setValues: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        initialValues={initialValues({
          country,
          companyAddress,
          priceLists,
          paymentTerms,
          sellers,
          defaultAccountReceivableCategory: accountingOptions.default,
          defaultDebtToPayCategory: debtToPayCategoryOptions.default,
          accountReceivableOptions: accountingOptions.options,
          debtToPayOptions: debtToPayCategoryOptions.options,
          isElectronic,
          contact,
          values: formRef,
        })}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, values, form, submitting, submitError }) => {
          formRef.current = values;
          return (
            <>
              <form
                noValidate
                className='w-100 h-100 bg-white d-flex flex-column'
                onSubmit={handleSubmit}
              >
                <Header
                  title={I18n.get('newClient', 'nuevo cliente')}
                  icon={<Icon icon='user' color="#00b19d" extraClass="icon-white" />}
                  description={I18n.get('newClientSubtitle', 'Crea los contactos que asociarás en tus facturas de venta.')}
                  seeMoreUrl={country === 'colombia' ? "https://ayuda.alegra.com/es/gestiona-tus-contactos-en-el-sistema-punto-de-venta-pos-colombia" : "https://ayuda.alegra.com/es/gestiona-tus-contactos-en-alegra-pos"}
                  onClose={() => dispatch(closeSideModal({ sideModal: 'contact' }))}
                />

                <div className='side-modal__body'>
                  <Body
                    values={values}
                    form={form}
                    country={country}
                    isElectronic={isElectronic}
                    newSellerAction={
                      can('add', 'sellers')
                        ? () => setModals({ ...modals, seller: true })
                        : null
                    }
                    newPriceListAction={() =>
                      setModals({ ...modals, priceList: true })
                    }
                    newPaymentTermAction={() =>
                      setModals({ ...modals, paymentTerm: true })
                    }
                    setValidatingClient={(status) => setValidationClient(status)}
                    contactCreated={(contact) => {
                      if(!contact) {
                        setIsDuplicatedContact(false);
                      }

                      setContactCreated(contact)
                    }}
                  />
                </div>

                <Notification isOpen={!!submitError} text={submitError} />
                <Notification isOpen={!!error} text={error} />
                <Notification
                  isOpen={!can('add', 'contacts')}
                  type='warning'
                  text={I18n.get(
                    'userNotAllowed.contacts.add',
                    'no tienes permisos para agregar clientes'
                  )}
                />

                <Bottom
                  onClose={() =>
                    dispatch(closeSideModal({ sideModal: 'contact' }))
                  }
                  disabled={isValidationClient || submitting || !can('add', 'contacts')}
                  submitting={submitting}
                />
              </form>

              <Seller
                isOpen={!!modals.seller}
                onRequestClose={() => setModals({ ...modals, seller: false })}
                onCreated={(seller) => form.change('seller', seller)}
              />

              <PriceList
                isOpen={!!modals.priceList}
                onRequestClose={() =>
                  setModals({ ...modals, priceList: false })
                }
                onCreated={(priceList) => form.change('priceList', priceList)}
              />

              <PaymentTerm
                isOpen={!!modals.paymentTerm}
                onRequestClose={() =>
                  setModals({ ...modals, paymentTerm: false })
                }
                onCreated={(paymentTerm) =>
                  form.change('paymentTerm', paymentTerm)
                }
              />

              <ContactCreated
                isOpen={(!!contactCreated || isDuplicatedError) && !isDuplicatedContact}
                contact={contact || contactCreated}
                country={country}
                onDuplicatedPressed={() => [setContactCreated(null), setIsDuplicatedContact(true)]}
                onSelected={(contact) => onSelectedExistingContact(contact)}
                onCancel={(id) => onCancelCreation(id)}
              />
            </>
          );
        }}
      </Form>
    </SideModal>
  );
};

export default Contact;
