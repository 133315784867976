import React from 'react';

import { capitalize } from 'lodash';
import { I18n } from '@aws-amplify/core';

import { Icon } from '@alegradev/smile-ui-react';

const Header = ({ title, icon, onClose, hideClose, seeMoreUrl, description }) => (
  <div className="side-modal__header w-100 d-flex justify-content-between align-items-center 
    p-3"
  >
    <div className="d-flex align-items-center">
      {!!icon && <div className="side-modal__header-icon mr-3">{icon}</div>}
      {!!title 
      && <div className="side-modal__header-title text-capitalize-first">
          {title}
          { description && 
            <p className="text-capitalize-first h5 text-muted">
              {description}{' '}
              { seeMoreUrl &&
                <a href={seeMoreUrl} target="_blank" rel="noreferrer">
                  {capitalize(I18n.get('seeMore', 'Ver más'))}
                </a>
              }
            </p>
          }
          
        </div>
      }
    </div>

    <button
      className={`btn button-transparent ${hideClose ? 'invisible': 'visible'}`} 
      type="button"
      onClick={() => onClose()}
    >
      <Icon icon='x' extraClass="icon-gray"/>
    </button>
  </div>
);

export default Header;
