import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { get, capitalize } from 'lodash'
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { Field } from 'react-final-form';

import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';
import { handleError } from '../../../../utils/errors';
import { APIGraphqlSelector, station as stationSelector } from '../../../../selectors/app';
import { renderField } from '../../fields/V0/Fields';

import NequiExt from '../../../svg/paymentMethods/NequiExt';
import { replaceAndParse } from '../../../../utils';
import { useFormat } from '../../../../hooks/useFormat';
import { sendGTMEvent } from '../../../../reducers/company';
import {
  numeration as numerationSelector,
  currency as currencySelector,
  itemsCount as itemsCountSelector,
} from '../../../../selectors/activeInvoice';

import { Icon } from '@alegradev/smile-ui-react';

const API_CALL_DELAY = 2000;

const PushNotification = ({ values, form, total }) => {
  const ref = useRef()
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [checking, setChecking] = useState(false);
  const [checkingError, setCheckingError] = useState();
  const [success, setSuccess] = useState(false);
  const [clientEmail, setClientEmail] = useState(null);
  const [clientPhone, setClientPhone] = useState(null);
  const [paymentId, setPaymentId] = useState();

  const station = useSelector(stationSelector);
  const numeration = useSelector(numerationSelector);
  const currency = useSelector(currencySelector);
  const itemsCount = useSelector(itemsCountSelector);
  const APIGraphql = useSelector(APIGraphqlSelector);

  const { fmt, decimal } = useFormat();

  // const getQRPromise = APIGraphql(graphqlOperation())

  useEffect(() => {
    ref.current = true
    return () => ref.current = false
  }, [])

  const checkConditionCallback = (responseData) => {
    const stopConditions = ["APPROVED", "DECLINED", "ERROR"];
    const { state } = responseData;

    if (state === 'ERROR') {
      setCheckingError(capitalize(I18n.get('externalPaymentError', 'falló la verificación del pago')))
      return true;
    }

    if (state === 'DECLINED') {
      setCheckingError(capitalize(I18n.get('externalPaymentDeclined', 'el pago ha sido rechazado')))
      return true;
    }

    return stopConditions.includes(state);
  }

  const getProvider = useCallback(() => {
    switch (values.method) {
      case "nequiPush":
        return 'wompi'

      default:
        return values.method
    }
  }, [values.method])

  const getPaymentInfo = useCallback(() => {
    switch (values.method) {
      case "nequiPush":
        return {
          "type": "NEQUI",
          "phone_number": clientPhone,
          "sandbox_status": "DECLINED"
        }

      default:
        return null
    }
  }, [values.method, clientPhone])


  const generatePushNotification = useCallback(async () => {
    setLoading(true)
    setError(null)

    try {
      const response = await APIGraphql(graphqlOperation(mutations.createProviderPayment, {
        payment: {
          provider: getProvider(),
          terminal_id: get(station, 'id'),
          amount: total * 100,
          customer_email: clientEmail,
          payment_method: getPaymentInfo(),
        }
      }))

      if (!!get(response, 'data.createProviderPayment.id')) {
        setPaymentId(get(response, 'data.createProviderPayment.id'))
      }

      if (ref.current) {
        setLoading(false)
        setError(null)
      }
    } catch (error) {
      if (ref.current) {
        setLoading(false)
        setError(handleError(error, capitalize(I18n.get('getQrCodeError', 'ocurrió un problema al crear el pago, intente de nuevo'))))
      }
    }
  }, [station, total, clientEmail, getPaymentInfo, getProvider, APIGraphql])

  useEffect(() => {
    if (!clientEmail) {
      return;
    }

    generatePushNotification();
  }, [clientEmail, generatePushNotification])

  const checkPaymentLongPolling = useCallback(async (paymentId) => {
    setChecking(true)
    setCheckingError(null)
    let response = {}
    try {
      response = await APIGraphql(graphqlOperation(queries.getProviderPayment, {
        payment: {
          id: paymentId,
          provider: getProvider(),
        }
      }))

      if (checkConditionCallback(response.data.getProviderPayment)) {

        if (get(response, 'data.getProviderPayment.state') === 'APPROVED') {
          form.change('transfer', total)
          form.change('qrId', paymentId)
          setSuccess(true);
        }

        if (ref.current) {
          setChecking(false)
          setCheckingError(null)
        }
        return;
      }
    } catch (error) {
      if (ref.current) {
        setChecking(false)
        setCheckingError(handleError(error, capitalize(I18n.get('checkQrCodeError', 'no se pudo verificar el estado del pago, intente de nuevo'))))
      }
      return;
    } finally {
      if (!checkConditionCallback(response.data.getProviderPayment)) {
        setTimeout(() => checkPaymentLongPolling(paymentId), API_CALL_DELAY)
      }
    }
    //eslint-disable-next-line
  }, [getProvider, total, form?.values?.transfer, APIGraphql])

  const submit = async (values) => {
    if (!paymentId) {
      setClientPhone(get(values, 'phone'));
      setClientEmail(get(values, 'email'));
    }
  }

  useEffect(() => {
    if (!paymentId)
      return;

    checkPaymentLongPolling(paymentId)
  }, [checkPaymentLongPolling, paymentId])

  if (success)
    return (
      <div className='w-100 d-flex flex-column justify-content-center align-items-center'>
        <NequiExt className="mb-3" />
        <div className="svg-icon-sucess-background justify-content-center align-items-center mb-3" style={{ width: "5.6rem", height: "5.6rem" }}>
          <Icon icon='check' size='extraLarge' />
        </div>
        <p className='h2 px-3 py-2 inter-regular' style={{ color: "#16A34A" }}>{I18n.get("", "¡Pago exitoso!")}</p>
        <p className='h4 py-2'>{replaceAndParse(I18n.get("youReceived", "Recibiste {}"), [total.toFormat(decimal, fmt)])}</p>
      </div >
    )
  return (
    <>
      <div className='d-flex flex-column'>
        <NequiExt />
        <p className='h5 px-3 py-2 text-muted'>{I18n.get("sendNequiNotificationToClient", "Envía una notificación de pago al Nequi de tu cliente.")}</p>
      </div>
      <div className="w-100 d-flex flex-column justify-content-center align-items-center">
        <Field
          name="phone"
          className="w-100"
          component={renderField}
          disabled={loading || checking || checkingError}
          type="number"
          label={capitalize(I18n.get('clientPhone', 'Celular del cliente'))}
          required
        />
        <Field
          name="email"
          className="w-100 pb-3"
          component={renderField}
          disabled={loading || checking || checkingError}
          type="text"
          label={capitalize(I18n.get('clientEmail', 'Correo del cliente'))}
          required
        />
        <button
          type='button'
          className={checking ? 'd-none' : !checkingError ? 'btn btn-submit absolute-center w-100' : 'btn-action'}
          disabled={(!get(values, 'email') || !get(values, 'phone') || checking)}
          onClick={() => {
            if (!paymentId) {
              dispatch(sendGTMEvent('wompi-nequi-request-sent', {
                isElectronicInvoice: get(numeration, 'isElectronic', false),
                customerPaymentMethod: 'wompi-nequi',
                currency: get(currency, 'code', 'COP'),
                invoiceTotal: parseFloat(total),
                totalItems: itemsCount,
              }))
              submit(values)
              return;
            }
            if (!!checkingError) {
              dispatch(sendGTMEvent('payment-verification-retried', {
                isElectronicInvoice: get(numeration, 'isElectronic', false),
                customerPaymentMethod: 'wompi-nequi',
                currency: get(currency, 'code', 'COP'),
                invoiceTotal: parseFloat(total),
                totalItems: itemsCount,
              }))
              checkPaymentLongPolling(paymentId)
              return
            }
          }}
        >
          {(loading || checking)
            ? <Icon icon='loader-2' animated extraClass=" icon-white" />
            : !!paymentId
              ? checkingError
                ? <div><Icon icon='repeat' extraClass="mr-2" />{I18n.get("retry", "Reintentar")}</div>
                : I18n.get("sent", "Enviado")
              : I18n.get("sendPaymentRequest", "Enviar solicitud de pago")
          }
        </button>
      </div >
      {(!error && !checking && !success && !paymentId) && (
        <p className='h5 px-3 py-2 text-muted text-capitalize-first'>
          {I18n.get('paymentNotVerified', 'Pago sin verificar')}
        </p>
      )}

      {!!error && (
        <p className='h5 px-3 py-2 text-danger text-capitalize-first'>
          {error}
        </p>
      )}

      {!!checkingError && (
        <div className='w-100 d-flex justify-content-center align-items-center mt-3'>
          <p className='h5 px-3 py-2 text-danger text-capitalize-first '>
            {checkingError}
          </p>
        </div>
      )}

      {checking && (
        <div className='d-flex justify-content-center align-items-center px-3 py-2'>
          <Icon icon='loader-2' animated extraClass=" icon-primary mr-2" />
          <p className='h5 text-muted text-capitalize-first m-0 p-0'>
            {I18n.get('checkingPayment', 'Verificando el pago...')}
          </p>
        </div>
      )}
    </>
  )
}

export default PushNotification;