import React from 'react'
import { I18n } from '@aws-amplify/core';

import { Icon } from '@alegradev/smile-ui-react';

const Pagination = ({ onFetchData, paginationData }) => {
  if (!onFetchData || !paginationData)
    return null;

  const { offlineTotal, start, to, limit, hasNextPage, sortDirection } = paginationData;

  const nextDisabled = !!to 
    ? !hasNextPage
    : Math.floor((offlineTotal - start) / limit)
  const prevDisabled = !start;

  return (
    <div className="pagination-bottom py-2 d-flex bg-primary justify-content-between align-items-center">
      <div className="text-capitalize-first text-truncate text-wrap text-white p-2">
        {`${I18n.get('showing', 'mostrando')} ${start + 1} - ${start + limit}`}
      </div>

      <div className="pagination-bottom-actions d-flex justify-content-between align-items-center">
        <button
          type="button"
          className="btn button-transparent"
          disabled={prevDisabled}
          onClick={() => onFetchData({
            start: Math.max(start - limit, 0),
            limit,
            sortDirection,
          })}
        >
          <Icon icon='arrow-left' extraClass="icon-white" />
        </button>

        <button
          type="button"
          className="btn button-transparent"
          disabled={nextDisabled}
          onClick={() => onFetchData({
            start: start + limit,
            limit,
            sortDirection,
          })}
        >
          <Icon icon='arrow-right' extraClass="icon-white" />
        </button>
      </div>
    </div>
  )
}

export default Pagination;