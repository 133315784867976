import React from 'react';
import { I18n } from '@aws-amplify/core';

import ElectronicPayrollStatus from '../../../settings/Invoices/ElectronicPayrollStatus';
import useElectronicPayrollStatus from '../../../../hooks/useElectronicPayrollStatus/hook';
import NavigationMenu from '../../../common/Navigation';
import { openModal } from '../../../../reducers/modals';
import { useDispatch, useSelector } from 'react-redux';
import { useHeader } from '../../context/header.context';
import { country as countrySelector } from '../../../../selectors/company';
import { Icon } from '@alegradev/smile-ui-react';
import { COUNTRIES } from '../../../../utils/enums/countries';

const MenuOptions = () => {
  const { setActiveMenu } = useHeader();
  const country = useSelector(countrySelector)
  const { isActive, canElectronicPayroll } = useElectronicPayrollStatus();
  const dispatch = useDispatch();

  return (
    <>
      <NavigationMenu
        items={[
          {
            to: 'electronicMenu',
            menuName: 'electronicMenu',
            isMenu: false,
            hover: !isActive,
            hidden: !canElectronicPayroll || country === COUNTRIES.COLOMBIA,
            component: <ElectronicPayrollStatus />,
            action: () => setActiveMenu(''),
            layout: 'big',
          },
          {
            to: `${process.env.REACT_APP_ALEGRA_SMILE}consumption`,
            menuName: 'planConsumption',
            target: '_blank',
            icon: <Icon icon='chart-bar' size='small' />,
            title: I18n.get('planConsumption', 'Consumo del plan'),
            isMenu: false,
            extraIcon: <Icon icon='external-link' size='small'  />,
            action: () => setActiveMenu(''),
          },
          {
            to: `${process.env.REACT_APP_ALEGRA_SMILE}profile`,
            menuName: 'profile',
            target: '_blank',
            icon: <Icon icon='user-circle' size='small' />,
            title: I18n.get('profile', 'Mi Perfil'),
            isMenu: false,
            extraIcon: <Icon icon='external-link' size='small' />,
            action: () => setActiveMenu(''),
          },
          {
            to: `${process.env.REACT_APP_ALEGRA_SMILE}enablements`,
            menuName: 'enablements',
            target: '_blank',
            icon: <Icon icon='checklist' size='small' />,
            title: I18n.get('dianEnablements', 'Habilitación DIAN'),
            hidden: country !== COUNTRIES.COLOMBIA,
            isMenu: false,
            extraIcon: <Icon icon='external-link' size='small' />,
            action: () => setActiveMenu(''),
          },
          {
            to: `${process.env.REACT_APP_ALEGRA_SMILE}security`,
            target: '_blank',
            menuName: 'security',
            icon: <Icon icon='lock' size='small' />,
            title: I18n.get('security', 'Seguridad'),
            isMenu: false,
            extraIcon: <Icon icon='external-link' size='small'  />,
            action: () => setActiveMenu(''),
          },
          {
            to: `/settings`,
            menuName: 'security',
            icon: <Icon icon='settings' size='small' />,
            title: I18n.get('settings', 'Configuraciones'),
            isMenu: false,
            action: () => setActiveMenu(''),
          },
          {
            menuName: 'logout',
            icon: <div><Icon icon='logout' size='small' /></div>,
            title: I18n.get('logout', 'cerrar sesión'),
            action: () => dispatch(openModal({ modal: 'logoutConfirm' })),
            isMenu: false,
            dataTestId: 'logout-component',
          },
        ]}
      />
    </>
  );
};

export default MenuOptions;