import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, get, isArray } from 'lodash';

import { activeTaxes } from '../../../selectors/taxes'
import { multitax, country as countrySelector } from '../../../selectors/company'
import { languageSelector } from '../../../selectors/auth';
import { renderField, renderSelect } from '../fields/V0/Fields';
import getNoTax from '../../countriesData/general/noTax';
import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';
import { validateSelectTaxes } from '../../../utils';

const EditItem = (props) => {
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const [noTaxActive, setNoTaxActive] = useState(false);
  const taxes = useSelector(activeTaxes);
  const isMultitax = useSelector(multitax);
  const country = useSelector(countrySelector);
  const language = useSelector(languageSelector);

  const noTax = useMemo(
    () => getNoTax(language.substring(0, 2))
    , [language])

  const allTaxes = [noTax, ...taxes];

  useEffect(() => {
    if (isMultitax && (country !== 'peru')) {
      const taxes = props?.values?.tax;
      if (isArray(taxes)) {
        taxes[taxes.length - 1]?.id === -1
          ? setNoTaxActive(true)
          : setNoTaxActive(false)
      }
      else
        setNoTaxActive(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noTax, props.values.tax])

  useEffect(() => {
    if (isMultitax && (country !== 'peru')) {
      if (noTaxActive)
        props.form.change('tax', [noTax]);
      else if (!noTaxActive && !!(props?.values?.tax))
        props.form.change('tax', props?.values?.tax?.filter((tax) =>
          tax !== noTax
        ))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noTaxActive])

  function maxDecimals(e, key, decimals = 6) {
    if (!isDecimalActive)
      return e.target.value;

    const newButton = e.nativeEvent.data;
    const values = props.values;
    values[key] = !values[key] ? "" : values[key];
    e.target.value = !e.target.value ? "" : e.target.value;

    if (newButton === '.') {
      if (!e.target.value.slice(0, -1).includes('.')) {
        return e.target.value;
      } else {
        e.target.value = values[key];
        return values[key];
      }
    }

    if (!isNaN(newButton)) {
      if (!e.target.value.includes('.') || (e.target.value.includes('.') && e.target.value.split('.')[1].length <= decimals)) {
        return e.target.value;
      } else {
        e.target.value = values[key];
        return values[key];
      }
    }

    if (isNaN(newButton)) {
      e.target.value = values[key];
      return values[key];
    }
  }

  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">
        <Field
          name="price"
          className="col-md-6"
          component={renderField}
          type="number"
          label={capitalize(I18n.get('price', 'precio'))}
          onChange={e => maxDecimals(e, 'price', 6)}
        />

        <Field
          name="quantity"
          className="col-md-6"
          component={renderField}
          type="number"
          label={capitalize(I18n.get('quantity', 'cantidad'))}
          onChange={e => maxDecimals(e, 'quantity', 4)}
        // onBlur={() => setUpDecimals('quantity', 4)}
        />

        <Field
          name="tax"
          className="col-md-6"
          component={renderSelect}
          showFavoriteIcon={false}
          options={allTaxes}
          label={capitalize(I18n.get('tax', 'impuesto'))}
          getOptionLabel={option => `${option.name} (${option.percentage}%)`}
          getOptionValue={option => option.id}
          isMulti={country === "peru" ? false : isMultitax}
          menuPosition="absolute"
          showClearIndicator
          hideDropdownIndicator
          onChange={(values) => validateSelectTaxes({ values, country, isMultitax })}
        />

        <Field
          name="discount"
          className="col-md-6"
          component={renderField}
          type="number"
          label={capitalize(I18n.get('discount', 'descuento')) + '(%)'}
          onChange={e => maxDecimals(e, 'discount', 1)}
        // onBlur={() => setUpDecimals('discount', 1)}
        />

        {country === 'costaRica' && (
          <Field
            name="discountNature"
            className="col-12"
            component={renderField}
            type="textarea"
            label={capitalize(I18n.get('discountNature', 'naturaleza del descuento'))}
          />
        )}

        <Field
          name="description"
          className="col-12"
          component={renderField}
          type="textarea"
          label={capitalize(I18n.get('description', 'Descripción'))}
        />

      </div>
    </div>
  )
}

export default EditItem;