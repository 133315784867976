import citiesDepartments from '../../../../../../components/countriesData/colombia/citiesDepartments.json';

function mexicoInitialValues({
  contact,
  defaultAccountReceivableCategory,
  defaultDebtToPayCategory,
 ...props
}) {
  if (!!contact) {
    return {
      ...contact,
      ...contact?.nameObject,
      firstName: contact?.nameObject?.firstName || contact?.name,
      identification: {
        ...contact?.identificationObject,
        number: contact?.identificationObject
          ? contact?.identificationObject?.number
          : contact?.identification?.number,
        type: contact?.identificationObject
          ? contact?.identificationObject?.type?.key
            ? contact?.identificationObject?.type?.key
            : contact?.identificationObject?.type
          : contact?.identification?.type,
      },
      email: contact?.email,
      address: {
        ...contact?.address,
        combined: citiesDepartments.find(
          (item) =>
            item?.value ===
            `${contact?.address?.city}, ${contact?.address?.department}`
        ),
      },
      seller: contact?.seller?.id ? contact?.seller?.id : contact?.seller,
      priceList: contact?.priceList?.id
        ? contact?.priceList?.id
        : contact?.priceList,
      term: contact?.term?.id ? contact?.term?.id : contact?.term,
      regime: contact?.regime,
      kindOfPerson: contact?.kindOfPerson,
      statementAttached: contact?.statementAttached,
      accounting: {
        accountReceivable: contact?.accounting?.accountReceivable?.id,
        debtToPay: contact?.accounting?.debtToPay?.id,
      },
    };
  }

  return {
    accounting: {
      accountReceivable: defaultAccountReceivableCategory?.id,
      debtToPay: defaultDebtToPayCategory?.id,
    },
    regime: ['SIMPLIFIED_REGIME'],
    thirdType: 'NATIONAL',
    statementAttached: true,
    ignoreRepeated: false,
  };
}

export default mexicoInitialValues;
