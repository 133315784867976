import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';
import dayjs from 'dayjs';
import BigNumber from 'bignumber.js'

import {
  decimalPrecision,
  country as countrySelector,
  dateFormat as dateFormatSelector,
  companySelector,
} from '../../../selectors/company';
import { calculateItemsValues, calculateSingleItemValues, calculateSingleItemValuesWithDecimals } from '../../../utils';
import { getNumber } from '../../../utils/invoices';
import creditNoteTypes from '../../countriesData/colombia/creditNoteTypes';
import adjustmentNoteTypes from '../../countriesData/colombia/adjustmentNote';
import { getLegalStatusKey } from '../../invoices/utils';
import { legalStatusToColor } from '../utils';
import { DECIMALS_ACTIVE_COUNTRIES, DECIMALS_ACTIVE_IDS } from '../../../hooks/useDecimalsVersionsGroup/hook';

const getItemReference = (item) => {
  try {
    return !!get(item, 'reference.reference')
      ? JSON.parse(get(item, 'reference.reference')) : !!get(item, 'reference')
        ? JSON.parse(get(item, 'reference')) : ''
  } catch {
    return ''
  }
}

const getRefundTypeLabel = (refund) => {
  const isAdjustNote = get(refund, 'numberTemplate.documentType') === 'incomeAdjustmentNote';
  let refundType

  if (isAdjustNote) {
    refundType = adjustmentNoteTypes.find(type => type.key === refund.type);
  } else {
    refundType = creditNoteTypes.find(type => type.key === refund.type);
  }

  return !!refundType ? refundType.value : creditNoteTypes[0].value
}

const selectStatusColor = (refund) => {
  const legalStatusKey = getLegalStatusKey(refund);
  let statusColor = legalStatusToColor(legalStatusKey, 'colombia');
  return statusColor;
}

const getLegalStatus = (refund) => {
  const legalStatus = getLegalStatusKey(refund)
  return !!legalStatus ? legalStatus : 'legalStatus.toBeIssued'
}

const DetailBody = ({ refund }) => {
  const decimal = useSelector(decimalPrecision);
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const dateFormat = useSelector(dateFormatSelector);

  if (!refund) return null;

  const prefix = !!get(refund, 'currency.symbol')
    ? get(refund, 'currency.symbol') : null;

  const fmt = prefix ? {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  } : null;

  const isElectronic = get(refund, 'numberTemplate.isElectronic', false);
  const itemsValues = calculateItemsValues({items: refund.items, decimal, country, company});

  return (
    <div className="p-4 position-relative overflow-hidden">
      <div>
        <table className="detail-info-table rounded-lg mb-4 table-body-refunds" style={{ tableLayout: 'fixed' }}>
          <tbody>
            <tr>
              <td className="caption-bold">
                {I18n.get('client', 'cliente')}
                <span className="text-primary ml-1">*</span>
              </td>
              <td>{get(refund, 'client.name', '')}</td>
            </tr>
            {!!isElectronic && (
              <>
                <tr>
                  <td className="caption-bold">{I18n.get('emissionStatus', 'Estado DIAN')}</td>
                  <td className={`emission-status-${selectStatusColor(refund)}`}>{I18n.get(getLegalStatus(refund))}</td>
                </tr>
                <tr>
                  <td className="caption-bold">{I18n.get('concept', 'Concepto')}</td>
                  <td>{getRefundTypeLabel(refund)}</td>
                </tr>
                {!!get(refund, 'cause', null) && (
                  <tr>
                    <td className="caption-bold">{I18n.get('reasonRefund', 'Razón')}</td>
                    <td>{get(refund, 'cause', '')}</td>
                  </tr>
                )}
                <tr>
                  <td className="caption-bold">CUDE</td>
                  <td>{get(refund, 'stamp.cude') || get(refund, 'stamp.cufe')}</td>
                </tr>
                {!!(get(refund, 'stamp.warnings') || []).length && (
                  <tr>
                    <td className="caption-bold">{I18n.get('notifications', 'Notificaciones')}</td>
                    <td>
                      {refund.stamp.warnings.map((w, i) => <span className="d-block" key={i}>{w.replace(/^"|"$/g, '')}</span>)}
                    </td>
                  </tr>
                )}
              </>
            )}
            {(!!get(refund, 'cause', null) && !isElectronic) && (
              <tr>
                <td className="caption-bold">{I18n.get('reasonRefund', 'Razón')}</td>
                <td>{get(refund, 'cause', '')}</td>
              </tr>
            )}
            <tr>
              <td className="caption-bold">{I18n.get('creation', 'creación')}</td>
              <td>{dayjs(refund.dateTime).format(!!dateFormat ? `${dateFormat.toUpperCase()} h:mm a` : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a'))}</td>
            </tr>
            <tr>
              <td className="caption-bold">{I18n.get('warehouse', 'bodega')}</td>
              <td>{get(refund, 'warehouse.name', '')}</td>
            </tr>
            {!!get(refund, 'note', null) && (
              <tr>
                <td className="caption-bold">{I18n.get('notes', 'Notas')}</td>
                <td>{get(refund, 'note', '')}</td>
              </tr>
            )}
            <tr>
              <td className="caption-bold">{I18n.get('costCenter', 'centro de costo')}</td>
              <td>{get(refund, 'costCenter.name', '')}</td>
            </tr>
            {!!get(refund, 'currency', null) && (
              <tr>
                <td className="caption-bold">{I18n.get('currency', 'moneda')}</td>
                <td>{get(refund, 'currency.code', '')}</td>
              </tr>
            )}
            {!!get(refund, 'idShift', null) && (
              <tr>
                <td className="caption-bold">{I18n.get('shiftNumber', 'número de turno')}</td>
                <td>{get(refund, 'idShift', '')}</td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="detail-items-table-wrapper mb-4">
          <table className="detail-items-table rounded-lg">
            <thead>
              <tr>
                <th className="caption-bold">{I18n.get('item', 'producto')}</th>
                <th className="caption-bold">{I18n.get('reference', 'referencia')}</th>
                <th className="caption-bold">{I18n.get('price', 'precio')}</th>
                <th className="caption-bold">{I18n.get('discount', 'descuento')}</th>
                <th className="caption-bold">{I18n.get('taxes', 'impuestos')}</th>
                <th className="caption-bold">{I18n.get('quantity', 'cantidad')}</th>
                <th className="caption-bold">{I18n.get('total', 'total')}</th>
              </tr>
            </thead>
            <tbody>
              {refund.items.map((item, index) => {
                const itemValues = DECIMALS_ACTIVE_COUNTRIES.includes(country) || DECIMALS_ACTIVE_IDS.includes(company?.id) ? calculateSingleItemValuesWithDecimals(item, decimal) : calculateSingleItemValues(item, decimal)
                return (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>
                      {getItemReference(item)}
                    </td>
                    <td>{itemValues.price.toFormat(decimal, fmt)}</td>
                    <td>{itemValues.discount}%</td>
                    <td>{itemValues.taxes}</td>
                    <td>{itemValues.quantity}</td>
                    <td>{itemValues.total.toFormat(decimal, fmt)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-end align-items-start flex-wrap">
          <table className="detail-summary-table rounded-lg mb-4">
            <tbody>
              <tr>
                <td className="text-capitalize-first body-2-bold">{I18n.get('subtotal', 'subtotal')}</td>
                <td>{itemsValues.subtotal.toFormat(decimal, fmt)}</td>
              </tr>
              <tr>
                <td className="text-capitalize-first body-2-bold">{I18n.get('discount', 'descuento')}</td>
                <td>{itemsValues.discount.toFormat(decimal, fmt)}</td>
              </tr>
              <tr>
                <td className="text-capitalize-first body-2-bold">{I18n.get('subtotal', 'subtotal')}</td>
                <td>{itemsValues.discSubtotal.toFormat(decimal, fmt)}</td>
              </tr>
              {itemsValues.taxes.map((tax, index) => (
                <tr key={index}>
                  <td className="body-2-bold">{tax.name}</td>
                  <td>{tax.value.toFormat(decimal, fmt)}</td>
                </tr>
              ))}
              {get(refund, "additionalCharges[0].amount", 0) > 0 &&
                <tr>
                  <td className="text-capitalize-first body-2-bold">{I18n.get('tip', 'Propina')}</td>
                  <td>{new BigNumber(get(refund, "additionalCharges[0].amount", 0)).toFormat(decimal, fmt)}</td>
                </tr>
              }
              <tr>
                <td className="text-capitalize-first heading-3">{I18n.get('total', 'total')}</td>
                <td className="font-weight-bold">{new BigNumber(refund.total).toFormat(decimal, fmt)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {!!get(refund, 'invoices.length', '') && (
          <>
            <p className="text-capitalize-first body-3-bold">
              {I18n.get('creditToSales', 'Crédito a ventas')}
            </p>

            <div className="detail-items-table-wrapper mb-4">
              <table className="detail-items-table rounded-lg">
                <thead>
                  <tr>
                    <th className="caption-bold">{I18n.get('invoice', 'factura')}</th>
                    <th className="caption-bold">{I18n.get('date', 'fecha')}</th>
                    <th className="caption-bold">{I18n.get('dueDate', 'vencimiento')}</th>
                    <th className="caption-bold">{I18n.get('total', 'total')}</th>
                    <th className="caption-bold">{I18n.get('paid', 'pagado')}</th>
                    <th className="caption-bold">{I18n.get('pending', 'pendiente')}</th>
                    <th className="caption-bold">{I18n.get('amount', 'monto')}</th>
                  </tr>
                </thead>
                <tbody>
                  {refund.invoices.map((invoice, index) => (
                    <tr key={index}>
                      <td>{getNumber(invoice, country, true)}</td>
                      <td>{dayjs(invoice.date).format(!!dateFormat ? dateFormat.toUpperCase() : I18n.get('dateFormat', 'DD/MM/YYYY'))}</td>
                      <td>{dayjs(invoice.dueDate).format(!!dateFormat ? dateFormat.toUpperCase() : I18n.get('dateFormat', 'DD/MM/YYYY'))}</td>
                      <td>{new BigNumber(invoice.total).toFormat(decimal, fmt)}</td>
                      <td>
                        {
                          new BigNumber(invoice.total)
                            .minus(invoice.amount)
                            .minus(invoice.balance)
                            .toFormat(decimal, fmt)
                        }
                      </td>
                      <td>{new BigNumber(invoice.balance).toFormat(decimal, fmt)}</td>
                      <td>{new BigNumber(invoice.amount).toFormat(decimal, fmt)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {!!get(refund, 'refunds.length', '') && (
          <>
            <p className="text-capitalize-first body-3-bold">
              {I18n.get('refunds', 'devoluciones')}
            </p>

            <div className="detail-items-table-wrapper mb-4">
              <table className="detail-items-table rounded-lg">
                <thead>
                  <tr>
                    <th className="caption-bold">{I18n.get('date', 'fecha')}</th>
                    <th className="caption-bold">{I18n.get('bankAccount', 'cuenta bancaria')}</th>
                    <th className="caption-bold">{I18n.get('annotations', 'observaciones')}</th>
                    <th className="caption-bold">{I18n.get('amount', 'monto')}</th>
                  </tr>
                </thead>
                <tbody>
                  {refund.refunds.map((refund, index) => (
                    <tr key={index}>
                      <td>{dayjs(refund.date).format(!!dateFormat ? dateFormat.toUpperCase() : I18n.get('dateFormat', 'DD/MM/YYYY'))}</td>
                      <td>{get(refund, 'account.name', '')}</td>
                      <td>{get(refund, 'observations', '')}</td>
                      <td>{new BigNumber(refund.amount).toFormat(decimal, fmt)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

DetailBody.propTypes = {
  refund: PropTypes.object
}

export default DetailBody;