import { useDispatch, useSelector } from 'react-redux';
import { get, upperCase } from 'lodash';
import { graphqlOperation } from 'aws-amplify';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';

import Modal from '../../common/Modal';
import {
  companySelector,
  country as countrySelector,
  idCompanySelector,
  membershipSelector,
  notificationsSelector,
} from '../../../selectors/company';
import { closeModal, openModal } from '../../../reducers/modals';
import Typography from '../../common/Typography';
import Button from '../../common/Button';
import { useCallback, useEffect, useState } from 'react';
import * as mutations from '../../../graphql/mutations';
import { setUser } from '../../../reducers/auth';
import { APIGraphqlSelector } from '../../../selectors/app';
import { notificationsTransformer } from '../../notifications/utils';
import { userIsElegibleForTip } from '../../settings/utils';
import { TipNotificationSvg } from '../../svg/TipNotificationSvg';
import dayjs from 'dayjs';
import { billsCategoriesWithoutFilter, pasivesCategoriesWithoutFilter } from '../../../selectors/categories';
import alegraAPI from '../../../reducers/alegraAPI';
import { editCompanyValue } from '../../settings/General/utils';

import { Icon } from '@alegradev/smile-ui-react';
import { sendNewGTMEvent } from '../../../reducers/company';
import { COUNTRIES } from '../../../utils/enums/countries';
import { getDefaultCategoryIdPurchases, getDefaultCategoryIdToCreateCharge } from '../../../utils/tips';

const deployDate = {
  COL: '2024-04-16',
  CR: '2024-07-08',
};
const sectorsToActivateTips = ['Alimentación / Gastronomía', 'Cuidado personal / Estética', 'Hotelería / Turismo']

function ActivateTipModalNotification() {
  const [loading, setLoading] = useState({ skip: false, activate: false});
  const dispatch = useDispatch();
  const company = useSelector(companySelector);
  const idCompany = useSelector(idCompanySelector)
  const notifications = useSelector(notificationsSelector);
  const country = useSelector(countrySelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const COOKIE_NAME = `activate-tips-modal-notification`;
  const membership = useSelector(membershipSelector);
  const isOpen = useSelector((state) =>
    get(state, 'modals.tipsModalNotification.isOpen', false)
  );
  const history = useHistory();
  const registryDate = get(company, 'registryDate', '');
  const categories = useSelector(pasivesCategoriesWithoutFilter);
  const categoriesPurchases = useSelector(billsCategoriesWithoutFilter);
  const defaultCategoryIdToCreateCharge = getDefaultCategoryIdToCreateCharge(categories, country, registryDate);
  const defaultCategoryIdPurchases = getDefaultCategoryIdPurchases(categoriesPurchases, registryDate);
  
  const showCopySector = sectorsToActivateTips.includes(get(company, 'sector', ''));

  const activateTips = async () => {
    try {
      const categoryId = defaultCategoryIdToCreateCharge
      const categoryIdPurchases = country === COUNTRIES.COSTA_RICA 
        ? defaultCategoryIdPurchases
        : categoryId
      
      const aditionalCharge = await alegraAPI.post('/additional-charges/', {
        "name": I18n.get('tip', 'Propina'),
        "code": "01",
        "description": "Cantidad de dinero que se da voluntariamente para agradecer un servicio.",
        "percentage": 0,
        "categoryPurchasesId": categoryIdPurchases,
        "categorySalesId": categoryId,
        "status": "active",
        "companyId": idCompany
      });
      
      const additionalChargeId = get(aditionalCharge, 'data.id', '0')
      if (additionalChargeId === '0') {
        throw new Error('No se pudo crear el cargo adicional')
      }
    
      const tipsSettings = {
        localSettings: {
          tipEnabled: true,
          tipAdditionalChargeId: additionalChargeId,
          tipAccount: categoryId,
          tipAccountPurchases: categoryIdPurchases,
        }
      }

      const response = await editCompanyValue(APIGraphql, tipsSettings);
      if (response) {
        dispatch(setUser(get(response, 'data.updateUser')))
        dispatch(sendNewGTMEvent('pos-tip-configured', {
          action: 'activate'
        }))
      }

    } catch (error) {
      console.error('Error while creating additional charge', error)
    }
  }

  const availableNotification = useCallback(() => {
    if (notifications?.find((notification) => notification[COOKIE_NAME]))
      return false;
    const membershipPlan = upperCase(get(membership, 'plan.keyword', ''));

    if (
      !membershipPlan ||
      membershipPlan === '' ||
      membershipPlan.includes('DEMO') ||
      membershipPlan.includes('READONLY')
    ) {
      return false;
    }

    if (country === COUNTRIES.COSTA_RICA) {
      if (dayjs(registryDate).isAfter(deployDate.CR)) return false;
    } else {
      if (dayjs(registryDate).isAfter(deployDate.COL)) return false;
    }

    if (!userIsElegibleForTip({country, idCompany, registryDate})) return false;

    return true;
  }, [country, notifications, membership,company, idCompany]);

  const closeTipNotidication = async () => {
    if (!availableNotification()) return;

    try {
      const response = await APIGraphql(
        graphqlOperation(
          mutations.updateUser,
          notificationsTransformer(COOKIE_NAME)
        )
      );
      if (!!get(response, 'data.updateUser.id', null)) {
        dispatch(setUser(get(response, 'data.updateUser')));
      }
    } catch (err) {
      console.log('error while creating notification', err);
    }
  };

  useEffect(() => {
    if (!availableNotification()) return;
    dispatch(openModal({ modal: 'tipsModalNotification' }));
    closeTipNotidication();
  }, []);

  const titleText = showCopySector
                      ? I18n.get('tips.modalTitleSector', '¡Impuesto de servicio! 💰')
                      : I18n.get('tips.modalTitle', '¡Activa el impuesto de servicio! 💰')

  const text = showCopySector 
                ? I18n.get('tips.modalDescriptionSector', 'Configura tu porcentaje de propina y activa la opción para sugerirla de forma automática en cada venta.')
                : I18n.get('tips.modalDescription', 'Puedes activar esta función para incluir el cobro de propina al crear tus facturas de venta.');

  const textButton = showCopySector 
                      ? I18n.get('tips.activateTipsSector', 'Configurar propinas')
                      : I18n.get('tips.activateTips', 'Ir a activar propinas')

  return (
    <Modal
      isOpen={isOpen}
      includeHeader={false}
      className='modal__small-size modal__mobile-bottom tip-notification-modal'
    >
      <div>
        <div className='d-flex justify-content-center'>
          <TipNotificationSvg />
        </div>
        <div className='text-center px-4'>
          <Typography type="heading-4" text={titleText} />
          <Typography type="body-3-regular" text={text} />
        </div>
        <div className='tip-notification-modal__actions d-flex justify-content-center'>
          <Button
            variant='ghost'
            onClick={async () => {
              if (loading.activate || loading.skip) return;
              setLoading({ skip: true, activate: false});
              if (sectorsToActivateTips.includes(get(company, 'sector', ''))) {
                await activateTips();
              }
              setLoading({ skip: false, activate: false});
              dispatch(closeModal({ modal: 'tipsModalNotification' }));
            }}
          >
            {
              loading.skip
              ? <Icon icon='loader-2' color='primary' animated />
              : I18n.get('skip', 'Omitir')
            }
          </Button>
          <Button
            onClick={async () => {
              if (loading.activate || loading.skip) return;
              setLoading({ skip: false, activate: true});
              if (sectorsToActivateTips.includes(get(company, 'sector', ''))) {
                await activateTips();
              }
              setLoading({ skip: false, activate: false});
              dispatch(closeModal({ modal: 'tipsModalNotification' }));
              history.push('/settings/SellPreferences');
            }}
          >
            {
              loading.activate
              ? <Icon icon='loader-2' animated extraClass='icon-white' />
              : textButton
            }
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ActivateTipModalNotification;
