import React from 'react'
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { clear } from '../../../../reducers/activeRefund';

import { Icon } from '@alegradev/smile-ui-react';

const Header = ({ openSettings }) => {
  const dispatch = useDispatch()

  return (
    <div className="refund-top__header d-flex w-100 bg-secondary text-white text-uppercase align-items-center justify-content-between">
      <button 
        type="button"
        className="btn button-transparent"
        onClick={() => openSettings()}
      >
        <Icon icon='adjustments-horizontal' extraClass="icon-white"/>
      </button>

      <button 
        type="button"
        className="btn button-transparent"
        onClick={() => dispatch(clear())}
      >
        <Icon icon='trash' extraClass="icon-white"/>
      </button>
    </div>
  )
}

Header.propTypes = {
  openSettings: PropTypes.func,
}

export default Header;