import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'
import { Form, Field } from 'react-final-form';

import { closeModal } from '../../../reducers/modals';
import { logout } from '../../../reducers/app';
import { offlineData } from '../../../selectors/app'
import Modal from '../../common/Modal';
import { renderField } from '../../forms/fields/V0/Fields';

import { Icon } from '@alegradev/smile-ui-react';

const LogoutConfirm = () => {
  const isOpen = useSelector(state => get(state, 'modals.logoutConfirm.isOpen', false));
  const dispatch = useDispatch();
  const areDataOffline = useSelector(offlineData);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => dispatch(closeModal({ modal: 'logoutConfirm' }))}
        title={I18n.get('logout', 'cerrar sesión')}
        className="modal__medium-size modal__logout"
      >
        <Form
          onSubmit={({ confirmation }) => {
            if (!areDataOffline || confirmation === I18n.get('close', 'Cerrar'))
              dispatch(logout())
          }}
          validate={({ confirmation }) => ({
            confirmation: !!areDataOffline && confirmation !== I18n.get('close', 'Cerrar')
              ? I18n.get('firstYouNeedTypeCloseToCloseSession', 'Para cerrar sesión primero confirma escribiendo "Cerrar"')
              : undefined
          })}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="p-4 d-flex flex-column justify-content-center align-items-center form-body">
                {!!areDataOffline && <Icon icon='info-circle' extraClass="icon-danger icon x4" />}

                <div className="my-4">
                  {!!areDataOffline
                    ? (
                      <>
                        <p className="text-center h4 text-capitalize-first mb-1 font-weight-bold">
                          {I18n.get('youHaveOfflineData', 'Tienes datos pendientes por sincronizarse.')}
                        </p>
                        <p className="text-center h4 text-capitalize-first">
                          {I18n.get('ifYouCloseSessionThenYouLostData', 'Puedes cancelar para sincronizarlos y evitar perderlos permanentemente.')}
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="text-center h4 text-capitalize-first mb-1">
                          {I18n.get('logoutMessage', 'verifica que los datos en offline hayan sido sincronizados antes de cerrar')}
                        </p>
                        <p className="text-center h4 text-capitalize-first">
                          {I18n.get('logoutMessageQuestion', '¿Desea cerrar sesión?')}
                        </p>
                      </>
                    )}
                </div>


                {!!areDataOffline && (
                  <>
                    <p className="m-0 h4 pb-3">{I18n.get('writeToConfirm', 'Confirma escribiendo la palabra')} <strong>{I18n.get('close', 'Cerrar')}</strong></p>
                    <Field
                      name="confirmation"
                      component={renderField}
                      className="col-6"
                      type="text"
                    />
                  </>
                )}
              </div>

              <div className="modal__bottom w-100 p-3 d-flex justify-content-end align-items-center shadow">
                <div className="modal__bottom-buttons d-flex">
                  <button
                    type="button"
                    className="btn btn-cancel text-capitalize-first"
                    onClick={() => dispatch(closeModal({ modal: 'logoutConfirm' }))}
                  >
                    {I18n.get('cancel', 'cancelar')}
                  </button>

                  <button
                    type="submit"
                    data-testid="modal-submit-btn"
                    className={`btn btn-submit text-capitalize-first ${areDataOffline ? 'btn-danger' : ''}`}
                  >
                    {I18n.get('accept', 'aceptar')}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Form>
      </Modal>
    </>
  )
}

export default LogoutConfirm;