import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { decimalPrecision } from '../../../selectors/company';
import { renderField } from '../fields/V0/Fields';
import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';

const CloseShift = ({ values }) => {
  const decimal = useSelector(decimalPrecision)
  const { isDecimalActive } = useDecimalsVersionsGroup();

  function maxDecimals(e, key, decimals = 6) {
    if (!isDecimalActive)
      return e.target.value;

    const newButton = e.nativeEvent.data;
    values[key] = !values[key] ? "" : values[key];
    e.target.value = !e.target.value ? "" : e.target.value;

    if (newButton === '.') {
      if (!e.target.value.slice(0, -1).includes('.')) {
        return e.target.value;
      } else {
        e.target.value = values[key];
        return values[key];
      }
    }

    if (!isNaN(newButton)) {
      if (!e.target.value.includes('.') || (e.target.value.includes('.') && e.target.value.split('.')[1].length <= decimals)) {
        return e.target.value;
      } else {
        e.target.value = values[key];
        return values[key];
      }
    }

    if (isNaN(newButton)) {
      e.target.value = values[key];
      return values[key];
    }
  }

  return (
    <>
      <div className="d-flex flex-column justify-content-start">
        <p>{capitalize(I18n.get('expectedCash', 'valor esperado en caja'))} <span className="text-primary">*</span></p>
        <p className="description">{capitalize(I18n.get('expectedCashHelp', 'valor de efectivo esperado en caja al terminar el turno'))}</p>
        <Field
          name="expectedCash"
          className="w-100"
          component={renderField}
          type="number"
          onChange={e => maxDecimals(e, 'expectedCash', decimal)}
          required />
      </div>
      <div className="d-flex flex-column justify-content-start observations">
        <p>{capitalize(I18n.get('observations', 'observaciones'))}</p>
        <Field
          name="observations"
          component={renderField}
          type="text" />
      </div>
    </>
  )
}

export default CloseShift;