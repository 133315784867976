import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { renderSelect } from '../../fields/V0/Fields';
import { COUNTRIES } from '../../../../utils/enums/countries';
import {
  getRegimeOptionsByRFC,
  allRegimes,
} from '../../../countriesData/mexico/regimes';
import { get } from 'lodash';

const mexicoRegime = (props) => {
  const thirdType = get(props, 'values.thirdType', '');

  if (thirdType === 'FOREIGN') {
    props.form.change('regime', allRegimes.SIMPLIFIED_REGIME);
  }

  return (
    <Field
      name='regime'
      component={renderSelect}
      type='text'
      selectFavorite
      options={getRegimeOptionsByRFC(get(props, 'values.rfc', ''))}
      label={capitalize(I18n.get('regime', 'Regímenes fiscales'))}
      className='col-md-12'
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      required
      isMulti
      defaultValue={allRegimes.SIMPLIFIED_REGIME}
      disabled={thirdType === 'FOREIGN'}
    />
  );
};

const renderRegime = (props) => {
  switch (props.country) {
    case COUNTRIES.MEXICO:
      return mexicoRegime(props);
    default:
      return null;
  }
};

export default renderRegime;
