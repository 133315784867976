import { Field } from 'react-final-form';
import { capitalize } from 'lodash';
import { I18n } from '@aws-amplify/core';

import Typography from '../../common/Typography';
import {
  renderDatePickerField,
  renderField,
  renderMonthPickerField,
  renderSelect,
} from '../fields/V0/Fields';
import { sub } from 'date-fns';
import { useGlobalInvoice } from '../../../pages/globalInvoices/invoice/context';
import { periodicity } from './utils';
import { Icon, Tooltip } from '@alegradev/smile-ui-react';
import { getAllInvoiceNumerations } from '../../../selectors/numerations';
import { useSelector } from 'react-redux';

function GlobalInvoiceFormHeader() {
  const numerations = useSelector(getAllInvoiceNumerations);
  const { selectedRows, toggleNumeration } = useGlobalInvoice();

  const toggleOpenNumerations = () => {
    window.open(`${process.env.REACT_APP_ALEGRA}resolution`, '_blank');
  };

  return (
    <div className='row custom-global-invoices-form-header'>
      <div className='col-md custom-global-invoices-form-header-title'>
        <Typography
          type='body-1-bold'
          variant='secondary'
          text={I18n.get('', 'Información general')}
        />
        <Typography
          type='body-3-regular'
          variant='secondary'
          text={I18n.get(
            '',
            'Selecciona los datos con los que se va a crear tu factura global'
          )}
        />
      </div>
      <Field
        name='numeration'
        required
        label={capitalize(I18n.get('', 'Numeración'))}
        component={renderSelect}
        options={numerations}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        onChange={(e) => {
          toggleNumeration(e);
          return e;
        }}
        className='col-md-3'
        menuPosition='absolute'
      />
      <div className='col-md-2 d-flex justify-content-between custom-global-invoices-form-header-numeration'>
        <Field
          name='idNumberTemplate'
          required
          label={I18n.get('', 'Folio')}
          placeholder={I18n.get('', 'Folio')}
          component={renderField}
          type='text'
          disabled
          className='col-md-9 p-0'
        />
        <div className='col-md-2 col-sm-2 numerations-tooltip'>
          <Tooltip
            placement='bottom'
            overlay={
              <div
                className='d-flex align-items-center pointer'
                onClick={toggleOpenNumerations}
              >
                <Typography
                  type='caption-bold'
                  variant='inverse'
                  text={I18n.get('', 'Administrar mis numeraciones')}
                />
                <Icon icon='external-link' className='icon-white icon-small ml-2' />
              </div>
            }
          >
            <Icon icon='settings' />
          </Tooltip>
        </div>
      </div>

      <div className='col-md-12' />
      <Field
        name='creationDate'
        required
        hideYear
        hideMonth
        label={I18n.get('', 'Fecha de creación')}
        placeholder={I18n.get('', 'Fecha de creación')}
        component={renderDatePickerField}
        inputFormat='DD/MM/YYYY'
        minDate={sub(new Date(), { days: 3 })}
        className='col-md'
        helpTooltip={I18n.get('globalInvoiceCreationDateTooltip', 'Fecha con la que se timbrará tu factura ante el SAT.')}
      />
      <Field
        name='periodicityPeriod'
        required
        label={I18n.get('', 'Periodicidad')}
        component={renderSelect}
        options={periodicity}
        className='col-md'
        helpTooltip={I18n.get('globalInvoicePeriodicityPeriodTooltip', 'Rango de tiempo al que corresponden los tickets que vas a incluir en tu factura.')}
      />
      <Field
        name='periodicityMonth'
        required
        hideYear
        hideMonth
        label={I18n.get('', 'Mes')}
        placeholder={I18n.get('', 'Mes')}
        component={renderMonthPickerField}
        noHeader
        className='col-md-3 custom-global-invoices-form-header-month'
        helpTooltip={I18n.get('globalInvoicePeriodicityMonthTooltip', 'Mes en el que creaste los tickets que vas a incluir en tu factura.')}
      />
      <Field
        name='periodicityYear'
        required
        hideYear
        hideMonth
        onlyYearPicker
        disabled={
          selectedRows.length > 0
            ? selectedRows.every(
                (row) =>
                  new Date(row.date).getFullYear() === new Date().getFullYear()
              )
            : true
        }
        label={I18n.get('', 'Año')}
        placeholder={I18n.get('', 'Año')}
        component={renderDatePickerField}
        className='col-md-2 custom-global-invoices-form-header-year'
        helpTooltip={I18n.get('globalInvoicePeriodicityYearTooltip', 'Año en el que creaste los tickets, solo pueden corresponder al año actual o al anterior.')}
      />
    </div>
  );
}

export default GlobalInvoiceFormHeader;
