import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays'
import { capitalize, get } from 'lodash';
import { I18n } from '@aws-amplify/core';

import { customFieldTypes as getCustomFieldTypes } from '../../countriesData/general/customFieldTypes';
import { renderField, renderSelect, renderCheckbox, renderDynamicField, renderDate } from '../fields/V0/Fields';

import { Icon } from '@alegradev/smile-ui-react';

const CustomField = ({ fieldType, languagePrefix, form, values }) => {
  const customFieldTypes = getCustomFieldTypes(languagePrefix);

  const today = useMemo(() => new Date(), [])

  const addOption = () => {
    form.mutators.push("options");
  }

  return (
    <div className="form-body h-100 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">
        <Field
          name="name"
          className="col-6"
          component={renderField}
          type="text"
          label={capitalize(I18n.get("name", "Nombre"))}
          disabled={get(values, 'id') === "1"}
          required
        />
        <Field
          name="type"
          className="col-6"
          component={renderSelect}
          options={customFieldTypes}
          label={capitalize(I18n.get("customFieldType", "Tipo de campo"))}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.value}
          isSearchable={true}
          helpTooltip={I18n.get('customFieldTypeHelp', 'Con esta opción podrás definir la estructura y formato de tu campo.')}
          menuPosition="absolute"
          disabled={get(values, 'id') === "1"}
          required
        />
        {
          (fieldType !== 'optionsList' && fieldType !== 'boolean' && fieldType !== 'date') && (
            <Field
              name="defaultValue"
              className="col-6"
              fieldType={fieldType}
              component={renderDynamicField}
              switchLabel={I18n.get('customFieldType.boolean', 'Si/No')}
              labelExtraCSS={"text-muted"}
              label={capitalize(I18n.get('defaultValue', 'Valor por defecto'))}
              helpTooltip={I18n.get('defaultValueHelp.customField', 'Define el valor predeterminado que tomará este campo al asociarlo a un producto.')}
              inheritHelpTooltip={false}
              portalId="custom-field-modal-portal"
              disabled={get(values, 'id') === "1"}
            />
          )
        }

        {
          fieldType === 'date' && (
            <Field
              name="defaultValue"
              className="col-6"
              component={renderDate}
              helpTooltip={I18n.get('defaultValueHelp.customField.boolean', 'Marca esta opción para que este campo venga activo al asociarlo a uno de tus productos.')}
              inheritHelpTooltip={true}
              defaultValue={today}
              portalId="custom-field-modal-portal"
            />
          )
        }

        {
          fieldType === 'boolean' && (
            <Field
              name="defaultValue"
              className="col-6"
              component={renderCheckbox}
              switchLabel={capitalize(I18n.get('defaultValue.boolean', 'Activo por defecto'))}
              labelExtraCSS={"text-muted"}
              helpTooltip={I18n.get('defaultValueHelp.customField.boolean', 'Marca esta opción para que este campo venga activo al asociarlo a uno de tus productos.')}
              inheritHelpTooltip={true}
              fromModal
            />
          )
        }

        {
          fieldType === 'optionsList' && (
            <>
              <Field
                name="defaultValue"
                className="col-md-6"
                component={renderField}
                type="text"
                label={capitalize(I18n.get('defaultValue', 'Valor por defecto'))}
                helpTooltip={I18n.get('defaultValueHelp.customField', 'Define el valor predeterminado que tomará este campo al asociarlo a un producto.')}
              />
              <div className="col-md-7">
                <div className="form-group">
                  <div className="form-group__label">
                    <div className="label-wrapper d-flex align-items-center">
                      <label className="h3">
                        {I18n.get('options', 'Opciones')}
                      </label>
                    </div>
                  </div>

                  <FieldArray name="options">
                    {({ fields }) =>
                      fields.map((name, index) => {
                        return (
                          <div key={index} className="form-row align-items-start">
                            <Field
                              name={`${name}.value`}
                              className="col-10"
                              component={renderField}
                              type="text"
                              validate={value => !value ? capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) : null}
                            />

                            <button
                              type="button"
                              className="btn button-transparent col-2 mb-3"
                              disabled={fields.length <= 1}
                              style={{ height: '3.6rem', outline: 'none', boxShadow: 'none', display: values?.options?.length > 1 ? 'inline' : 'none' }}
                              onClick={() => fields.remove(index)}
                            >
                              <Icon icon='x' extraClass="icon-secondary" />
                            </button>
                          </div>
                        )
                      })
                    }
                  </FieldArray>
                </div>
              </div>

              <div className="col-12 text-center" style={{ maxWidth: '50rem' }}>
                <button
                  type="button"
                  className="btn btn-secondary mb-3 primary"
                  style={{ border: "none", outline: "none", boxShadow: "none" }}
                  onClick={() => addOption()}
                >
                  <Icon icon='plus' extraClass="icon icon-primary mr-2" />
                  {capitalize(I18n.get('addOption', 'agregar opción'))}
                </button>
              </div>
            </>
          )
        }

        <Field
          name="mandatory"
          className="col-12 mt-3"
          component={renderCheckbox}
          switchLabel={I18n.get('required', 'Obligatorio')}
          labelExtraCSS={"text-muted"}
          helpTooltip={I18n.get('requiredHelp.customField', 'Si activas esta opción, siempre deberás definir un valor para este campo en tus productos.')}
        />
        {/* <Field
          name="printOnInvoice"
          className="col-12"
          component={renderCheckbox}
          switchLabel={I18n.get('printOnInvoice', 'Imprimir en facturas')}
          labelExtraCSS={"text-muted"}
        /> */}
        <Field
          name="description"
          className="col-12"
          component={renderField}
          type="textarea"
          label={capitalize(I18n.get("description", "Descripción"))}
          disabled={get(values, 'id') === "1"}
        />

      </div>
    </div>
  );
};

export default CustomField;