import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux';
import { isString, get, debounce, find } from 'lodash';

import { openSideModal } from '../../../reducers/sideModals';
import { filter } from '../../../reducers/clients';
import { setClient, setSettings } from '../../../reducers/activeInvoice';
import { sendGTMEvent } from '../../../reducers/company';
import { hasPermissionTo } from '../../../selectors/auth';
import { country as countrySelector, companySelector } from '../../../selectors/company';
import { invoiceClients } from '../../../selectors/clients';
import {
  client as clientSelector,
  numeration as numerationSelector,
} from '../../../selectors/activeInvoice';
import { stationInvoiceNumberNumeration, stationInvoiceNumeration } from '../../../selectors/app';
import { basicPOSClientData, isDefaultClient } from '../../../utils';

import Select from '../../common/Select';
import useElectronicPayrollStatus from '../../../hooks/useElectronicPayrollStatus/hook';
import { COUNTRIES } from '../../../utils/enums/countries';
import Typography from '../../common/Typography';
import { getInvoiceNumerations } from '../../../selectors/numerations';
import { MEXICO_DEFAULT_RFC } from '../../countriesData/mexico/rfc';
import { isMexicoFeAvailable } from '../../../utils/deploys/mexicoFeDeployGroups';
import { Icon, Tooltip } from '@alegradev/smile-ui-react';

const renderClientName = (client) => {
  if (!!get(client, 'name')) {
    if (!!isString(get(client, 'name'))) return get(client, 'name');
    return `${get(client, 'name.firstName', '')}${
      !!get(client, 'name.secondName', null)
        ? ' ' + get(client, 'name.secondName')
        : ''
    }${
      !!get(client, 'name.lastName', null)
        ? ' ' + get(client, 'name.lastName')
        : ''
    }`;
  }
  if (!!get(client, 'nameObject')) {
    let nameObject = get(client, 'nameObject');
    return `${get(nameObject, 'firstName', '')}${!!get(nameObject, 'secondName', null)
      ? ' ' + get(nameObject, 'secondName') : ''}${!!get(nameObject, 'lastName', null)
        ? ' ' + get(nameObject, 'lastName') : ''}`;
  }
  return ''
}

const renderClientIdentification = (client, country) => {
  if (!client || isDefaultClient(client, country)) return '';

  if (!!get(client, 'identification')) {
    if (!!isString(get(client, 'identification')))
      return ` (${get(client, 'identification')})`;
    if (!!get(client, 'identification.number'))
      return ` (${get(client, 'identification.number')})`;
    return '';
  }
  if(!!get(client, 'identificationObject')) {
    if (!!isString(get(client, 'identificationObject.number')))
      return ` (${get(client, 'identificationObject.number')})`
  }
  return ''
}

const Client = () => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const country = useSelector(countrySelector);
  const clients = useSelector(invoiceClients);
  const client = useSelector(clientSelector);
  const numeration = useSelector(numerationSelector);
  const numerations = useSelector(getInvoiceNumerations);
  const stationNumeration = useSelector(stationInvoiceNumeration);
  const stationNumberInvoiceNumberation = useSelector(stationInvoiceNumberNumeration);
  const { isActive } = useElectronicPayrollStatus();
  const { registryDate } = useSelector(companySelector);

  const ref = useRef();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ref.current = true;
    return () => (ref.current = false);
  }, []);

  const isFeMexActive = useMemo(() => {
    return isMexicoFeAvailable(country, isActive);
  },[country, registryDate, isActive])

  useEffect(() => {
    if(!isFeMexActive) return;
    if (country === COUNTRIES.MEXICO) {
      const TICKET_NUMERATION_TYPE = 'saleTicket';
      if (client && client?.identification !== MEXICO_DEFAULT_RFC) {
        if (stationNumeration?.documentType === TICKET_NUMERATION_TYPE) {
          if (stationNumberInvoiceNumberation) {
            dispatch(
              setSettings({ numeration: stationNumberInvoiceNumberation })
            );
          } else {
            const invoiceNumeration = find(numerations, {
              documentType: 'invoice',
            });
            const favoriteNumeration = find(numerations, {
              documentType: 'invoice',
              isDefault: true,
            });
            if (favoriteNumeration) {
              dispatch(setSettings({ numeration: favoriteNumeration }));
            } else if (invoiceNumeration) {
              dispatch(setSettings({ numeration: invoiceNumeration }));
            }
          }
        }
      }
    }
  }, [client,country,stationNumeration,numerations, isFeMexActive])
  
  const spainClientCodition =
    country === 'spain' &&
    !!numeration &&
    get(numeration, 'documentType') === 'invoice' &&
    !client;

  const domClientNumerationCondition =
    country === 'republicaDominicana' &&
    (!!numeration
      ? get(numeration, 'prefix') === 'E31'
      : get(stationNumeration, 'prefix') === 'E31') &&
    !client;

  const search = useCallback(
    async (text = '') => {
      if (ref.current) setLoading(true);

      await dispatch(filter({ text, limit: 35 }));

      if (ref.current) setLoading(false);
    },
    [dispatch]
  );

  const getClient = () => {
    if (client) return client;
    return basicPOSClientData(
      country,
      !!numeration ? numeration : stationNumeration
    );
  };

  const openCreateClientSideModal = () => {
    try {
      dispatch(openSideModal({ sideModal: 'contact' }));
      dispatch(
        sendGTMEvent('client-creation-attempted', {
          creationStatus: 'success',
        })
      );
    } catch {
      dispatch(
        sendGTMEvent('client-creation-attempted', {
          creationStatus: 'failure',
        })
      );
    }
  };

  const isCreateClientAvailable = useMemo(() => {
    if (country === COUNTRIES.MEXICO && !isFeMexActive) return false;

    return true;
  }, [isFeMexActive]);

  const getSelectInfo = useMemo(() => {
    const currentClient = getClient();
    if (country === COUNTRIES.MEXICO && currentClient?.identification === MEXICO_DEFAULT_RFC) {
      return (
        <span>
          <Typography
          variant='green'
          type='label-3'
          text={I18n.get(
            '',
            'Ten en cuenta que si seleccionas un cliente, se le creará una factura de venta en lugar de un ticket'
          )}
        />
        </span>
      );
    }

    return null;
  }, [client]);

  return (
    <div className="invoice-top__client p-3 d-flex w-100 align-items-center">
      <p className="invoice-top__client-title fast-option-title m-auto pl-3 pr-2">{I18n.get('client', 'Cliente')}:</p>
      <div style={{ marginRight: ".9rem" }} className="w-100 pl-2"  data-testid="select-client-invoice">
        <Tooltip
          width='full'
          visible={domClientNumerationCondition || spainClientCodition}
          overlay="Es necesario asignar un contacto en la factura"
          placement="top">
          <Select
            value={getClient()}
            options={can('view', 'contacts') ? clients : []}
            isLoading={loading}
            placeholder={I18n.get('selectAClient', 'selecciona un cliente')}
            noOptionsMessage={() =>
              can('view', 'contacts')
                ? clients.length === 0
                  ? I18n.get(
                      'createClientToSelect',
                      'Crea un nuevo cliente para seleccionarlo'
                    )
                  : I18n.get(
                      'createThisNewClientToSelect',
                      'Crea este nuevo cliente para seleccionarlo'
                    )
                : I18n.get(
                    'userNotAllowed.contacts.view',
                    'No tienes permiso para ver el detalle de contacto. Habla con tu administrador para que te habilite el permiso y así puedas usar el POS correctamente.'
                  )
            }
            getOptionLabel={(option) =>
              `${renderClientName(option)}${renderClientIdentification(
                option,
                country
              )}`
            }
            selectInfo={getSelectInfo}
            getOptionValue={(option) => option.id}
            onChange={(client) => dispatch(setClient(client))}
            isClearable={isCreateClientAvailable}
            isDisabled={!isCreateClientAvailable}
            showClearIndicator={!!client}
            hideDropdownIndicator={!!client}
            onInputChange={useMemo(
              () =>
                debounce((value) => {
                  if (can('view', 'contacts')) search(value);
                }, 350),
              [can, search]
            )}
            cacheOptions={false}
            className={domClientNumerationCondition || spainClientCodition ? "invoice-top__client-selector border border-danger rounded" : ""}
          />
        </Tooltip>
      </div>
      {isCreateClientAvailable && <Tooltip
        visible={!can('add', 'contacts')}
        overlay={I18n.get(
          'userNotAllowed.contacts.add',
          'no tienes permisos para agregar contactos'
        )}
      >
        <button
          id='add-client-button'
          type='button'
          disabled={!can('add', 'contacts') || !isCreateClientAvailable}
          className='btn button-transparent d-flex justify-content-between align-items-center btn-add-contact mr-3'
          onClick={openCreateClientSideModal}
        >
          <Icon icon='user-plus' extraClass="icon-primary" />
          <div className="text-capitalize-first ml-2">{I18n.get('new', 'nuevo')}</div>
        </button>
      </Tooltip>}
    </div>
  );
};

export default Client;
