import React from 'react'
import { I18n } from '@aws-amplify/core';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { BigNumber } from 'bignumber.js'

import { decimalPrecision, country as countrySelector, isTaxesResponsable as isTaxesResponsableSelector, companySelector } from '../../../selectors/company'
import { activeNumerations } from '../../../selectors/numerations'
import { calculateItemsValues } from '../../../utils';
import { assingITBISTaxesToLetter, getDefaultCurrencyValue, getSubDocumentType, isAfterDateToProduction } from './utilities'
import { useCheckTaxesSummaryAvailability } from '../../../hooks/featureAvailability/useCheckTaxesSummaryAvailability';

import taxTypeByPercentage from '../../countriesData/republicaDominicana/taxes'
import TaxDetail from './TaxDetail';

const shouldShowDiscount = (country, invoice, numerations) => {
  switch (country) {
    case 'argentina':
      const subDocumentType = getSubDocumentType(invoice, numerations)
      if (subDocumentType === 'INVOICE_B' || subDocumentType === 'INVOICE_C')
        return false
      return true

    default:
      return true
  }
}

const shouldShowTaxes = (country, invoice, numerations) => {
  switch (country) {
    case 'argentina':
      const subDocumentType = getSubDocumentType(invoice, numerations)
      if (subDocumentType === 'INVOICE_B' || subDocumentType === 'INVOICE_C')
        return false
      return true
    case 'republicaDominicana':
      return !get(invoice, 'items', []).some(item => item.tax.some(tax => tax.name.includes('ITBIS') || tax.type.includes('ITBIS')))
    default:
      return true
  }
}

const MulticurrencyPayments = ({ invoice, setting: { template, totalLines }, isMulticurrencySell, currentDefaultCurrency }) => {
  let { items, currency, totalReceived, cashReturned } = invoice
  const decimal = useSelector(decimalPrecision);
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const numerations = useSelector(activeNumerations);
  const isTaxesResponsable = useSelector(isTaxesResponsableSelector);

  const customTaxTypeByPercentage = country === 'republicaDominicana' ? assingITBISTaxesToLetter(items) : {};

  const isTaxesSummaryAvailable = useCheckTaxesSummaryAvailability();

  if (!items) return null;

  const prefix = !!get(currency, 'symbol')
    ? get(currency, 'symbol') : null;

  const fmt = prefix ? {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  } : null;

  const originalPriceItems = items.map(item => {
    const originalPrice = getDefaultCurrencyValue(invoice.currency, item.price);
    const originalTotal = getDefaultCurrencyValue(invoice.currency, item.total);

    return {
      ...item,
      price: originalPrice,
      total: originalTotal,
    }
  })

  const { subtotal, total, taxes, discount, discSubtotal, subTotalByTax, taxesByType } = calculateItemsValues({items, decimal, country, company, ignoreDisableTaxes: true});
  const { subtotal: originalSubtotal,
    total: originalTotal,
    taxes: originalTaxes,
    discount: originalDiscount,
    discSubtotal: originalDiscSubtotal,
    subTotalByTax: originalSubTotalByTax,
    taxesByType: originalTaxesByType } = calculateItemsValues({items: originalPriceItems, decimal, country, company});
  const showDiscount = discount.gt(0) && shouldShowDiscount(country, invoice, numerations)
  const showTaxes = shouldShowTaxes(country, invoice, numerations)
  const showITBISTaxes = country === 'republicaDominicana' && get(invoice, 'items', []).some(item => item.tax.some(tax => tax.name.includes('ITBIS') || tax.type.includes('ITBIS')))

  const isAfterDate = isAfterDateToProduction(invoice, "2023-04-25");
  const isElectronic = get(invoice, "stamp", null);

  const taxDetailProps = {
    invoice,
    taxes,
    subTotalByTax,
    subtotal,
    total,
    originalTaxes,
    originalSubTotalByTax,
    originalSubtotal,
    originalTotal,
    isMulticurrencySell,
    currentDefaultCurrency,
  }

  return (
    <div className="mb-3">

      <div className="container-text-flex justify-content-center align-items-center form-row mb-2" style={{ borderBottom: '1px dashed' }}>
        <div className="d-flex flex-column col-3 text-left font-weight-bold">
        </div>
        <div className="d-flex flex-column col-4 text-center font-weight-bold">
          <h3 className='font-weight-bold'>
            {I18n.get('USD', 'USD')}
          </h3>
        </div>
        <div className="d-flex flex-column col-5 text-right font-weight-bold">
          <h3 className='font-weight-bold'>
            {I18n.get('COP', 'COP')}
          </h3>
        </div>
      </div>

      <div className="container-text-flex justify-content-center align-items-center form-row">
        <div className="d-flex flex-column col-3 text-left font-weight-bold">
          {I18n.get('subtotal', 'subtotal')}
        </div>
        <div className="d-flex flex-column col-4 text-center ">
          {subtotal.toFormat(decimal, fmt)}
        </div>
        <div className="d-flex flex-column col-5 text-right " style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
          {originalSubtotal.toFormat(decimal, fmt)}
        </div>
      </div>

      {!!showDiscount && (
        <>
          <div className="container-text-flex justify-content-center align-items-center form-row">
            <div className="d-flex flex-column col-3 text-left font-weight-bold">
              {I18n.get('discount', 'descuento')}
            </div>
            <div className="d-flex flex-column col-4 text-center ">
              {`-${discount.toFormat(decimal, fmt)}`}
            </div>
            <div className="d-flex flex-column col-5 text-right ">
              {`-${originalDiscount.toFormat(decimal, fmt)}`}
            </div>
          </div>
          <div className="container-text-flex justify-content-center align-items-center form-row">
            <div className="d-flex flex-column col-3 text-left font-weight-bold">
              {I18n.get('subtotal', 'subtotal')}
            </div>
            <div className="d-flex flex-column col-4 text-center ">
              {discSubtotal.toFormat(decimal, fmt)}
            </div>
            <div className="d-flex flex-column col-5 text-right " style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
              {originalDiscSubtotal.toFormat(decimal, fmt)}
            </div>
          </div>
        </>
      )}

      {!!showTaxes
        ? (country === 'colombia' && isTaxesSummaryAvailable && isTaxesResponsable)
          ? taxesByType.map((tax, index) => (
            <div key={index} className="container-text-flex justify-content-center align-items-center form-row">
              <div className="d-flex flex-column col-3 text-left font-weight-bold">
                {`${I18n.get('total', 'Total')} ${tax.type}`}
              </div>
              <div className="d-flex flex-column col-4 text-center ">
                {tax.value.toFormat(decimal, fmt)}
              </div>
              <div className="d-flex flex-column col-5 text-right " style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
                {originalTaxesByType[index].value.toFormat(decimal, fmt)}
              </div>
            </div>
          ))
          : taxes.map((tax, index) => (
            <div key={index} className={`container-text-${(country === "panama" && isAfterDate && isElectronic) ? "grid" : "flex"} justify-content-center align-items-center form-row`}>
              <div className="d-flex flex-column col-3 text-left font-weight-bold" style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
                {(country === "panama" && isAfterDate && isElectronic) ? I18n.get('taxes', 'Impuestos') :
                  tax.name}:
              </div>
              <div className="d-flex flex-column col-4 text-center ">
                {tax.value.toFormat(decimal, fmt)}
              </div>
              <div className="d-flex flex-column col-5 text-right " style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
                {originalTaxes[index].value.toFormat(decimal, fmt)}
              </div>
            </div>
          ))
        : null
      }


      <div className="container-text-flex justify-content-center align-items-center form-row">
        <div className="d-flex flex-column col-3 text-left font-weight-bold" style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
          <h3>
            {I18n.get('total', 'total')}
          </h3>
        </div>
        <div className="d-flex flex-column col-4 text-center" style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
          <h3>
            {total.toFormat(decimal, fmt)}
          </h3>
        </div>
        <div className="d-flex flex-column col-5 text-right" style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
          <h3>
            {originalTotal.toFormat(decimal, fmt)}
          </h3>
        </div>
      </div>

      {(country === 'colombia' && isTaxesSummaryAvailable && isTaxesResponsable) && (
        <TaxDetail  {...taxDetailProps} />
      )}

      <div className="text-left">
        {!!totalReceived && (
          <p>
            <strong>{I18n.get('totalReceived', 'total recibido')}</strong>
            {`${new BigNumber(totalReceived).toFormat(decimal, fmt)} ${invoice?.currency?.code}`}
          </p>
        )}
        {!!cashReturned && (
          <p>
            <strong>{I18n.get('cashReturned', 'cambio')}</strong>
            {new BigNumber(cashReturned).toFormat(decimal, fmt)}
          </p>
        )}
        {
          totalLines && (
            <>
              <p>
                <strong>{I18n.get('totalRows', 'total de líneas')}</strong>
                {items.length}
              </p>
              <p>
                <strong>{I18n.get('totalItems', 'total de productos')}</strong>
                {items.map(item => +get(item, 'quantity', 0)).reduce((prev, curr) => prev + curr, 0)}
              </p>
            </>
          )
        }

      </div>

    </div>
  )
};

export default MulticurrencyPayments;