import React, { useEffect } from 'react'
import { graphqlOperation } from 'aws-amplify'
import { I18n } from '@aws-amplify/core'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import Cookies from 'js-cookie'

import * as queries from '../../graphql/queries'
import alegraAPI from '../../reducers/alegraAPI'
import notificationsAPI from '../../reducers/notificationsAPI'
import { userSelector } from '../../selectors/auth'
import { country as countrySelector } from '../../selectors/company'
import { APIGraphqlSelector } from '../../selectors/app'
import { COUNTRIES } from '../../utils/enums/countries'

const UserWithPendingInvoicesNotification = () => {
  const user = useSelector(userSelector);
  const country = useSelector(countrySelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const dispatch = useDispatch();

  const getDocumentTypeHashMap = (invoices) => {
    const documentTypeHashMap = {
      "invoice": 0,
      "saleTicket": 0,
      "creditNote": 0,
      "adjustNote": 0,
    }

    invoices.forEach(invoice => {
      if (documentTypeHashMap.hasOwnProperty(invoice?.numberTemplate?.documentType)) {
        documentTypeHashMap[invoice?.numberTemplate?.documentType] += 1;
      }
    });

    return documentTypeHashMap;
  }

  useEffect(() => {
    async function checkUserHasPendingInvoices() {
      if (![COUNTRIES.COLOMBIA, COUNTRIES.REPUBLICA_DOMINICANA, COUNTRIES.ARGENTINA, COUNTRIES.COSTA_RICA, COUNTRIES.PANAMA].includes(country)) return;

      const cookieName = "userHasPendingInvoices"
      const cookieValue = Cookies.get(cookieName)
      const cookieDate = !!cookieValue ? cookieValue : null;
      const sendNotification = !cookieDate ? true : dayjs().isAfter(parseInt(cookieDate))

      if (!sendNotification) return;

      try {
        const { data } = await alegraAPI.get('/invoices/pending-emission?limitDays=10');
        if (data.invoices.length > 0) {
          const invoicesIDs = data.invoices.join(',')
          const response = await APIGraphql(graphqlOperation(queries.checkCreationInPOS, {
            invoicesIDs
          }))
          const completeInvoices = await alegraAPI.get('/invoices?id=' + invoicesIDs)
          const documentTypeHashMap = getDocumentTypeHashMap(completeInvoices.data);

          if (response.data.checkCreationInPOS !== "[]") {
            try {
              Cookies.set(cookieName, dayjs().add(1, 'day').valueOf());

              // Filter document types for only "invoice" or "saleTicket"
              const documentTypes = Object.keys(documentTypeHashMap).filter(key => ['invoice', 'saleTicket'].includes(key) && documentTypeHashMap[key] > 0);
              if (documentTypes.length > 0) {
                const actionCall = I18n.get('youHavePendingInvoicesToEmit.link', 'https://ayuda.alegra.com/es/consulta-estado-emision-documentos-electronicos-pos-colombia');

                notificationsAPI.post('/notifications',
                  notificationBody({
                    userId: user.idGlobal,
                    title: I18n.get('youHavePendingInvoicesToEmit', '¡Tienes facturas por emitir! 🚨'),
                    text: I18n.get('youHavePendingInvoicesToEmit.description', 'Ingresa a las facturas con estado de emisión “Por emitir” y envíalas a la DIAN con un clic.'),
                    actionCall,
                    actionLabel: I18n.get('howToEmit', 'Cómo emitirlos'),
                    type: "warning"
                  }));
              }
            } catch (err) {
              console.log("error while creating notification", err);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    checkUserHasPendingInvoices();
  }, [dispatch, country, user, APIGraphql])

  return (
    <>
    </>
  )
}

const notificationBody = ({ userId, from = null, to = null, section = null, title, text, actionCall, actionLabel, type }) => ({
  name: "user-has-pending-invoices",
  teamInCharge: "pos",
  receptors: {
    users: [userId]
  },
  availability: {
    from: from ? from : dayjs().toISOString(),
    to: to ? to : dayjs().add(15, 'days').toISOString()
  },
  message: [
    {
      channel: "web",
      type: "localized",
      closeable: true,
      section: section ? section : "index-pos-home",
      title,
      text,
      application: "alegra-pos",
      action: {
        actionCall,
        actionLabel
      },
      style: {
        type,
        icon: "notification"
      }
    }
  ]
})

export default UserWithPendingInvoicesNotification;
