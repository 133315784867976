import { I18n } from 'aws-amplify';
import { useConnectionStatus } from '../../../../hooks/useConnectionStatus';
import { useEffect, useMemo, useState } from 'react';
import { useApiAvailable } from '../../../../hooks/useApiAvailable';

import { Popover, ChipStatus } from '@alegradev/smile-ui-react';

const STATUSES = {
  online: 'online',
  offline: 'offline',
  intermittent: 'intermittent',
};

const IntermittenciesStatus = () => {
  const isApiAvailable = useApiAvailable();
  const connection = useConnectionStatus();

  const [intermittentStatus, setIntermittentStatus] = useState({
    lastStatus: STATUSES.online,
    currentStatus: STATUSES.online,
    currentStatusTime: 0,
    showed: false,
  });

  useEffect(() => {
    const defaultNewValues = {
      currentStatusTime: 0,
      lastStatus: intermittentStatus.currentStatus,
      showed: false,
    };

    if (connection) {
      if (!isApiAvailable) {
        setIntermittentStatus({
          ...intermittentStatus,
          ...defaultNewValues,
          currentStatus: STATUSES.intermittent,
        });
      } else {
        setIntermittentStatus({
          ...intermittentStatus,
          ...defaultNewValues,
          currentStatus: STATUSES.online,
        });
      }
    } else {
      setIntermittentStatus({
        ...intermittentStatus,
        ...defaultNewValues,
        currentStatus: STATUSES.offline,
      });
    }
  }, [connection, isApiAvailable]);

  useEffect(() => {
    const addMinutesToCurrentStatusTime = () => {
      if (intermittentStatus.currentStatus === STATUSES.online) return;
      setIntermittentStatus({
        ...intermittentStatus,
        currentStatusTime: intermittentStatus.currentStatusTime + 1,
      });
    };

    const interval = setInterval(addMinutesToCurrentStatusTime, 15000);

    return () => clearInterval(interval);
  }, []);

  const popoverInfo = useMemo(() => {
    let title, overlayText, icon, variant, label;
    if (intermittentStatus.currentStatus === STATUSES.offline) {
      title = I18n.get('wifi_disabled', '⚠ Sin conexión a internet');
      overlayText = I18n.get(
        'wifi_disabled_description',
        'Puedes seguir vendiendo y tus ventas se sincronizarán cuando recuperes tu conexión.'
      );
      icon = 'wifi-off';
      variant = 'warning';
      label = I18n.get('wifi_unavailable', 'Vendiendo sin conexión');
    } else if (intermittentStatus.currentStatus === STATUSES.intermittent) {
      title = I18n.get('api_failured', 'Error en el servicio de la API');
      overlayText = I18n.get(
        'api_failure_description',
        'Ha ocurrido un error en el servicio de la API. Por favor, inténtalo de nuevo más tarde.'
      );
      icon = 'hourglass-off';
      variant = 'warning';
      label = I18n.get('api_failure', 'Con intermitencia');
    } else {
      title = connection
        ? I18n.get('wifi_enabled', 'Punto de venta disponible ⚡')
        : I18n.get('wifi_disabled', '⚠ Sin conexión a internet');
      overlayText = connection
        ? I18n.get(
            'wifi_enabled_description',
            'Tienes conexión y todas las funciones están disponibles para hacer crecer tu negocio.'
          )
        : I18n.get(
            'wifi_disabled_description',
            'Puedes seguir vendiendo y tus ventas se sincronizarán cuando recuperes tu conexión.'
          );
      icon = connection ? 'wifi' : 'wifi-off';
      variant = connection ? 'success' : 'warning';
      label = connection
        ? I18n.get('wifi_available', 'Disponible')
        : I18n.get('wifi_unavailable', 'Vendiendo sin conexión');
    }

    return { title, overlayText, icon, variant, label };
  }, [intermittentStatus]);

  if (
    intermittentStatus.currentStatus === STATUSES.online &&
    intermittentStatus.currentStatusTime >= 5
  )
    return null;

  return (
    <Popover
      title={popoverInfo.title}
      description={popoverInfo.overlayText}
      placement='bottom'
      full
    >
      <ChipStatus
        label={popoverInfo.label}
        icon={popoverInfo.icon}
        variant={popoverInfo.variant}
      />
    </Popover>
  );
};

export default IntermittenciesStatus;
