import { get } from 'lodash';

export const peruTransformer = (values, _props, newValues) => {
  const identificationType = get(values, 'identification.type', null);
  const identificationNumber = get(values, 'identification.number', null);

  const foreignId = !(
    identificationType === 'RUC' || identificationType === 'DNI'
  );
  const showCountry = !!identificationType && foreignId;

  const countryKey = get(values, 'address.country', null);
  const countryValue = get(values, 'address.country', null);
  const combinedAddress = get(values, 'address.combined', null);
  const splitAddress = combinedAddress?.split(', ');
  const address = get(values, 'address.address', null);

  let newAddress = {
    country: showCountry ? countryValue : 'Perú',
    address: address,
  };

  if (!!combinedAddress && (!foreignId || countryKey === 'PER')) {
    const urbanization = get(values, 'address.urbanization', null);

    newAddress = {
      ...newAddress,
      state: (combinedAddress && splitAddress[2]) || null,
      city: (combinedAddress && splitAddress[1]) || null,
      district: (combinedAddress && splitAddress[0]) || null,
      urbanization,
      ubigeoCode: (combinedAddress && splitAddress[3]) || null,
    };
  }

  return {
    ...newValues,
    identification: {
      type: identificationType,
      number: identificationNumber,
    },
    address: newAddress,
  };
};
