import { Field } from 'react-final-form';
import { renderField } from '../fields/V2';
import { COUNTRIES_PHONE_PREFIXES } from '../../../utils/enums/countries';
import { useSelector } from 'react-redux';
import {
  companySelector,
  country as countrySelector,
} from '../../../selectors/company';
import { useEffect, useRef, useState } from 'react';
import countries from '../../countriesData/general/countries';
import { I18n } from 'aws-amplify';
import { Icon, Tooltip, Typography } from '@alegradev/smile-ui-react';
import { get } from 'lodash';
import {
  getExternalClientHash,
  getMailTemplate,
} from '../../modals/ShareInvoice/utils';
import { toast } from 'react-toastify';
import useCountryOptions from '../../../hooks/useCountryOptions/hook';

const SendInvoiceWhatsapp = ({
  invalid,
  submitting,
  invoiceCreated,
  setHash,
  setWhatsappTemplate,
  templateLoaded,
  setTemplateLoaded,
  form,
  setCountryKey,
  setPhoneValue,
  setPhonePrefix,
}) => {
  const fetchCalled = useRef(false);
  const [searchValue, setSearchValue] = useState('');
  const [countryCode, setCountryCode] = useState('');

  const company = useSelector(companySelector);
  const country = useSelector(countrySelector);
  const countryOptions = useCountryOptions(countries, searchValue);

  useEffect(() => {
    const fetchData = async (setHash, setWhatsappTemplate) => {
      const idClient = get(invoiceCreated, 'client.id', null);
      const idInvoice = get(invoiceCreated, 'id', null);
      const idCompany = get(company, 'id', null);
      if (idClient && idInvoice && idCompany) {
        try {
          const template = await getMailTemplate();
          setWhatsappTemplate(template);
          setTemplateLoaded(true);
        } catch (error) {
          toast.error({
            title: I18n.get('', 'Ocurrió un error al obtener la plantilla'),
          });
          console.error(error);
        }
      }
    };

    if (!fetchCalled.current) {
      fetchCalled.current = true;
      fetchData(setHash, setWhatsappTemplate);
    }
  }, [company, invoiceCreated, setHash, setWhatsappTemplate]);

  useEffect(() => {
    if (typeof get(form.getFieldState('phone'), 'value') === 'string') {
      setPhoneValue(get(form.getFieldState('phone'), 'value'));
    }
  }, [form.getFieldState('phone')]);

  return (
    <div className='d-flex align-items-start w-100'>
      {templateLoaded ? (
        <>
          <div className='w-80'>
            <Field
              name='phone'
              render={renderField}
              type='number'
              leadingAddOn={{
                defaultOption: COUNTRIES_PHONE_PREFIXES[country.toUpperCase()],
                width: 80,
                search: {
                  enabled: true,
                  onSearch: (value) => setSearchValue(value),
                },
                onChange: (value) => {
                  setCountryKey(value?.countryKey);
                  setPhonePrefix(value?.value);
                },
                options: countryOptions.map((country) => ({
                  countryKey: country?.key,
                  value: country.dialCode,
                  text: country.dialCode,
                  overflowText: `${country.value} ${country.dialCode ? `(${country.dialCode})` : ''}`,
                  leading: {
                    type: 'slot',
                    slot: (
                      <span
                        className={`fi fi-${country.flag}`}
                        style={{ fontSize: 16 }}
                      />
                    ),
                  },
                })),
              }}
              disabled={invalid || submitting}
              required
              placeholder={I18n.get(
                'enterYourClientsPhone',
                'Escribe el teléfono de tu cliente'
              )}
            />
          </div>
          <Tooltip
            visible={!get(form.getFieldState('phone'), 'value')}
            overlay={I18n.get(
              'youNeedToIncludePhone',
              'Necesitas incluir un número de teléfono para el envío'
            )}
          >
            <button
              className='w-20 share-invoice-btn-send btn'
              type='submit'
              disabled={!get(form.getFieldState('phone'), 'value')}
              style={{
                borderTopRightRadius: '1rem',
                borderBottomRightRadius: '1rem',
                padding: '0 1.5rem',
              }}
            >
              <Typography
                type='label-1'
                text={I18n.get('send', 'enviar')}
                variant='secondary'
              />
            </button>
          </Tooltip>
        </>
      ) : (
        <div className='d-flex justify-content-center'>
          <Icon icon='loader-2' animated extraClass='icon-primary' />
        </div>
      )}
    </div>
  );
};

export default SendInvoiceWhatsapp;
