import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux'
import { capitalize, get } from 'lodash'

import { hasPermissionTo } from '../../../selectors/auth';

import { APIGraphqlSelector } from '../../../selectors/app';
import { Tooltip, Icon } from '@alegradev/smile-ui-react';

const Actions = ({ priceList, onRefresh, onView, updatePriceList, deletePriceList }) => {
    const can = useSelector(hasPermissionTo);
    const APIGraphql = useSelector(APIGraphqlSelector);

    return (
        <div className="d-flex align-items-center justify-content-start table-gray-actions">
            <Tooltip
                visible={!can('edit', 'price-lists')}
                overlay={I18n.get('userNotAllowed.price-lists.edit', 'no tienes permisos para editar listas de precio')}
            >
                <button
                    type="button"
                    className="btn button-transparent"
                    title={capitalize(I18n.get('edit', 'editar'))}
                    onClick={() => onView()}
                    disabled={!can('edit', 'price-lists')
                        || !get(priceList, 'editable', true)
                    }
                >
                    <Icon icon='pencil' />
                </button>
            </Tooltip>

            <Tooltip
                visible={!can('edit', 'price-lists')}
                overlay={I18n.get('userNotAllowed.price-lists.edit', 'no tienes permisos para editar listas de precio')}
            >
                <button
                    type="button"
                    className="btn button-transparent"
                    onClick={() => updatePriceList({
                        api: APIGraphql,
                        priceList,
                        isActive: !(get(priceList, 'status') === 'active')
                    })}
                    title={get(priceList, 'status') === 'active'
                        ? capitalize(I18n.get('deactivate', 'desactivar'))
                        : capitalize(I18n.get('activate', 'activar'))
                    }
                    disabled={!can('edit', 'price-lists')}
                >
                    {get(priceList, 'status') === 'active'
                        ? <Icon icon='lock' extraClass="icon-primary" />
                        : <Icon icon='lock-open' extraClass="icon-primary" />
                    }
                </button>
            </Tooltip>

            <Tooltip
                visible={!can('delete', 'price-lists')}
                overlay={I18n.get('userNotAllowed.price-lists.edit', 'no tienes permisos para eliminar listas de precio')}
            >
                <button
                    type="button"
                    className="btn button-transparent button-remove"
                    title={capitalize(I18n.get('delete', 'eliminar'))}
                    onClick={() => deletePriceList({ api:APIGraphql, id: priceList.id })}
                    disabled={!can('delete', 'price-lists')
                        || !get(priceList, "deletable", true)
                    }
                >
                    <Icon icon='trash' />
                </button>
            </Tooltip>

        </div>
    )
}

export default Actions;