import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';

import Combined from '../../svg/Combined'
import { Icon } from '@alegradev/smile-ui-react';

const Methods = ({ values }) => {
  return (
    <div className="form-body h-100 px-4 py-5 overflow-hidden">
      <div className="form-body__fields form-row align-items-center justify-content-around">
        <Field
          name="method"
          className="col-md-4"
        >
          {props => (
            <button
              type="button"
              className={`btn btn-refund-method button-transparent border d-flex flex-column 
                align-items-center justify-content-between ${values.method === 'cash' ? 'border-primary': ''}`}
              onClick={() => props.input.onChange('cash')}
            >
              <div className="d-flex flex-column align-items-center justify-content-start">
                <Icon icon='circle-arrow-up-right' type='primary' />
                <p className="text-capitalize-first h4 text-break font-weight-bold">{I18n.get('refundCash', 'Devolución de dinero')}</p>
              </div>
              <p className="text-capitalize-first h5 text-break text-muted">{I18n.get('refundRefundCashMethodDescription', 'Se descontará el efectivo de la cuenta que elijas')}</p>
            </button>
          )}
        </Field>
        
        <Field
          name="method"
          className="col-md-4"
        >
          {props => (
            <button
              type="button"
              className={`btn btn-refund-method button-transparent border d-flex flex-column 
                align-items-center justify-content-between ${values.method === 'invoice' ? 'border-primary': ''}`}
              onClick={() => props.input.onChange('invoice')}
            >
              <div className="d-flex flex-column align-items-center justify-content-start">
                <Icon icon='file-invoice' type='primary' />
                <p className="text-capitalize-first h4 text-break font-weight-bold">{I18n.get('creditToInvoice', 'crédito a factura')}</p>
              </div>
              <p className="text-capitalize-first h5 text-break text-muted">{I18n.get('refundCreditToInvoiceMethodDescription', 'Disminuirá el valor por pagar en la factura de tu cliente')}</p>
            </button>
          )}
        </Field>
        
        <Field
          name="method"
          className="col-md-4"
        >
          {props => (
            <button
              type="button"
              className={`btn btn-refund-method button-transparent border d-flex flex-column 
                align-items-center justify-content-between ${values.method === 'combined' ? 'border-primary': ''}`}
              onClick={() => props.input.onChange('combined')}
            >
              <div className="d-flex flex-column align-items-center justify-content-start">
                <Combined />
                <p className="text-capitalize-first h4 text-break font-weight-bold">{I18n.get('combined', 'combinado')}</p>
              </div>
              <p className="text-capitalize-first h5 text-break text-muted">{I18n.get('refundCombinedMethodDescription', 'Podrás utilizar devolución de dinero y crédito a factura')}</p>
            </button>
          )}
        </Field>
        
        <Field
          name="method"
          className="col-md-4"
        >
          {props => (
            <button
              type="button"
              className={`btn btn-refund-method button-transparent border d-flex flex-column 
                align-items-center justify-content-between ${values.method === 'positiveBalance' ? 'border-primary': ''}`}
              onClick={() => props.input.onChange('positiveBalance')}
            >
              <div className="d-flex flex-column align-items-center justify-content-start">
                <Icon icon='cash' type='primary' />
                <p className="text-capitalize-first h4 text-break font-weight-bold">{I18n.get('positiveBalance', 'saldo a favor')}</p>
              </div>
              <p className="text-capitalize-first h5 text-break text-muted">{I18n.get('refundPositiveBalanceMethodDescription', 'Quedará como anticipo para una nueva factura')}</p>
            </button>
          )}
        </Field>
      </div>
    </div>
  )
}

export default Methods;